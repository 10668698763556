import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
export const useApprovalRequestIn = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [dataApprovalRequestIn, setDataApprovalRequestIn] = useState();
  const [isReload, setIsReload] = useState(false);
  const [popUpRequestIn, setPopUpRequestIn] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [dataPickApproval, setDataPickApproval] = useState();
  const [approvalType, setApprovalType] = useState("");

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getApproval`,
      { tim: dataUser.tim },
      dataUser.auth,
      setDataApprovalRequestIn,
      ""
    );
    setIsReload(false);
  }, [isReload]);

  const handleSubmitApproval = async () => {
    await postAxios(
      `${process.env.REACT_APP_ENDPOINT}/approvalRequestIn`,
      {
        company: dataPickApproval.company,
        namaBahan: dataPickApproval.namaBahan,
        tipeBahan: dataPickApproval.tipeBahan,
        color: dataPickApproval.color,
        jumlah: dataPickApproval.jumlah,
        requestor: dataPickApproval.requestor,
        date: dataPickApproval.date,
        time: dataPickApproval.time,
        jenisBahan: dataPickApproval.jenisBahan,
        requestId: dataPickApproval && dataPickApproval.requestId,
        approver: dataUser.name,
        status: approvalType,
        tim: dataUser.tim,
      },
      dataUser.auth,
      "",
      ""
    );
    setIsReload(true);
    setPopUpRequestIn(false);
  };

  return {
    dataApprovalRequestIn,
    setDataPickApproval,
    handleSubmitApproval,
    setApprovalType,
    setPopUpRequestIn,
    popUpRequestIn,
    popUpMessage,
    setPopUpMessage,
  };
};
