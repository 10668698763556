import React from "react";
import "./CardComponent.css";
const CardComponent = ({ active, title, onClick, logo }) => {
  return (
    <div
      className={active ? "card_container card_active" : "card_container"}
      onClick={onClick}
    >
      <img src={logo} className="logo_company" alt="company_logo" />
      <p className="name_company">{title}</p>
    </div>
  );
};
export default CardComponent;
