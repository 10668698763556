import React from "react";
import CardApproval from "../../components/CardApproval/CardApproval";
import { useApprovalRequestIn } from "./useApprovalRequestIn";
import "./ApprovalRequestIn.css";

const ApprovalRequestIn = () => {
  const {
    dataApprovalRequestIn,
    setDataPickApproval,
    handleSubmitApproval,
    setApprovalType,
    popUpRequestIn,
    setPopUpRequestIn,
    popUpMessage,
    setPopUpMessage,
  } = useApprovalRequestIn();

  return (
    <div className="container_approval">
      <p className="title_inventory">APPROVAL</p>
      <div className="wrapper_approval_cards">
        {dataApprovalRequestIn &&
          dataApprovalRequestIn.map((obj, i) => (
            <CardApproval
              data={obj}
              key={i}
              dataPick={setDataPickApproval}
              submitApproval={() => handleSubmitApproval()}
              approvalType={setApprovalType}
              popUpRequestIn={popUpRequestIn}
              setPopUpRequestIn={setPopUpRequestIn}
              popUpMessage={popUpMessage}
              setPopUpMessage={setPopUpMessage}
            />
          ))}
      </div>
    </div>
  );
};
export default ApprovalRequestIn;
