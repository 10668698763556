import React from "react";
import LogoGs from "../../Assets/GS-Logo.png";
import LogoYBI from "../../Assets/YBI-Logo.jpeg";
import LogoTBP from "../../Assets/TBP-Logo.jpeg";
import LogoMelco from "../../Assets/MELCO-Logo.png";
import LogoTIM from "../../Assets/TimLogo.png";
import LogoCBI from "../../Assets/CBI-Logo.png";
import CardComponent from "../../components/Card/CardComponent";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import TableListProduk from "../../components/TableListProduk/TableListProduk";
import { useListProduk } from "./useListProduk";
import "./Produk.css";

const ListProduk = () => {
  const {
    ProdukList,
    popUp,
    setPopUp,
    companyPickList,
    setCompanyPickList,
    handleCancelAddBahan,
    dataListProduk,
    handleDeleteProduk,
    setDataListDelete,
    popUpDeleteProduk,
    setPopUpDeleteProduk,
    setNamaProduk,
    setColor,
    setMaterial,
    setRunner,
    setBahan,
    setTotal,
    setAddNewCompany,
    handleKirim,
    dataMaterial,
    setWarnaMaterial,
    setTipeMaterial,
    bahan,
    popUpEditProduk,
    setPopUpEditProduk,
    handleEditProduk,
    editRunner,
    setEditRunner,
  } = useListProduk();

  return (
    <div className="container_list_bahan">
      {popUp ? (
        <PopUpComponent style={{ margin: "calc(20vh - 20px) auto 0" }}>
          <div className="wrapper_content_popup">
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Perusahaan<label style={{ color: "red" }}>*</label>
              </p>
              <select
                defaultValue="Pilih Nama Perusahaan"
                onChange={(e) => setAddNewCompany(e.target.value)}
                className="input_select"
                style={{ width: "192px" }}
              >
                <option selected disabled>
                  Pilih Nama Perusahaan
                </option>
                {dataListProduk &&
                  dataListProduk.map((data) => (
                    <option value={data.company}>{data.company}</option>
                  ))}
              </select>
            </div>
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Nama Produk<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="text"
                className="input_jumlah"
                placeholder="Masukkan Nama Produk"
                onChange={(e) => setNamaProduk(e.target.value)}
              />
            </div>
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Color<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="text"
                className="input_jumlah"
                placeholder="Masukkan Color"
                onChange={(e) => setColor(e.target.value)}
              />
            </div>
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Bahan<label style={{ color: "red" }}>*</label>
              </p>
              <select
                defaultValue="Pilih Bahan"
                onChange={(e) => setBahan(e.target.value)}
                className="input_select"
                style={{ width: "192px" }}
              >
                <option selected disabled>
                  Pilih Bahan
                </option>
                <option value={"BAKU"}>Bahan Baku</option>
                <option value={"PENDUKUNG"}>Bahan Pendukung</option>
              </select>
            </div>
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Material<label style={{ color: "red" }}>*</label>
              </p>
              <select
                defaultValue="Pilih Nama Material"
                onChange={(e) => setMaterial(e.target.value)}
                className="input_select"
                style={{ width: "192px" }}
              >
                <option selected disabled>
                  Pilih Nama Material
                </option>
                {dataMaterial &&
                  dataMaterial.map((data) => (
                    <option value={data.material}>{data.material}</option>
                  ))}
              </select>
            </div>

            <div className="wrapper_input">
              <p className="font_weight_bold">
                Material Type<label style={{ color: "red" }}>*</label>
              </p>
              <select
                defaultValue="Pilih Tipe Material"
                onChange={(e) => setTipeMaterial(e.target.value)}
                className="input_select"
                style={{ width: "192px" }}
              >
                <option selected disabled>
                  Pilih Tipe Material
                </option>
                {dataMaterial &&
                  dataMaterial.map((data) => (
                    <option value={data.type}>{data.type}</option>
                  ))}
              </select>
            </div>
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Material Color<label style={{ color: "red" }}>*</label>
              </p>
              <select
                defaultValue="Pilih Warna Material"
                onChange={(e) => setWarnaMaterial(e.target.value)}
                className="input_select"
                style={{ width: "192px" }}
              >
                <option selected disabled>
                  Pilih Warna Material
                </option>
                {dataMaterial &&
                  dataMaterial.map((data) => (
                    <option value={data.color}>{data.color}</option>
                  ))}
              </select>
            </div>

            <div className="wrapper_input">
              <p className="font_weight_bold">
                Runner<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="number"
                className="input_jumlah"
                placeholder="Masukkan Runner"
                onChange={(e) => setRunner(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Total<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="number"
                className="input_jumlah"
                placeholder="Masukkan Total"
                onChange={(e) => setTotal(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <div
              className="wrapper_button_submit_popup"
              style={{ marginTop: "18px" }}
            >
              <button
                className="button_popup"
                onClick={() => handleCancelAddBahan()}
              >
                Cancel
              </button>
              <button
                className="button_popup background_red"
                onClick={() => handleKirim()}
              >
                Kirim
              </button>
            </div>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <div className="wrapper_header">
        <p className="title_inventory">LIST Produk</p>
        <div className="wrapper_tambah_list" onClick={() => setPopUp(true)}>
          <div className="icon_add_list">+&nbsp;</div>
          <div className="font_tambah_button">Tambah</div>
        </div>
      </div>
      <div className="flex_wrap">
        <CardComponent
          active={companyPickList === "GS"}
          title="PT. GS"
          logo={LogoGs}
          onClick={() => setCompanyPickList("GS")}
        />
        <CardComponent
          active={companyPickList === "YBI"}
          title="PT. YBI"
          logo={LogoYBI}
          onClick={() => setCompanyPickList("YBI")}
        />
        <CardComponent
          active={companyPickList === "TBP"}
          title="PT. TBP"
          logo={LogoTBP}
          onClick={() => setCompanyPickList("TBP")}
        />
        <CardComponent
          active={companyPickList === "MELCO"}
          title="PT. MELCO"
          logo={LogoMelco}
          onClick={() => setCompanyPickList("MELCO")}
        />
        <CardComponent
          active={companyPickList === "CBI"}
          title="PT. CBI"
          logo={LogoCBI}
          onClick={() => setCompanyPickList("CBI")}
        />
        <CardComponent
          active={companyPickList === "TIM"}
          title="PT. TIM"
          logo={LogoTIM}
          onClick={() => setCompanyPickList("TIM")}
        />
      </div>
      <TableListProduk
        data={ProdukList && ProdukList.data}
        setDataPickDelete={setDataListDelete}
        popUpDeleteProduk={popUpDeleteProduk}
        setPopUpDeleteProduk={setPopUpDeleteProduk}
        onClick={() => handleDeleteProduk()}
        onClickEdit={() => handleEditProduk()}
        bahan={bahan}
        popUpEditProduk={popUpEditProduk}
        setPopUpEditProduk={setPopUpEditProduk}
        editRunner={editRunner}
        setEditRunner={setEditRunner}
      />
    </div>
  );
};
export default ListProduk;
