import React from "react";
import PopUpComponent from "../PopUp/PopUpComponent";
import EditLogo from "../../Assets/editLogo.png";
import "./TableListMold.css";

const TableListMold = ({
  data,
  onClick,
  onClickEdit,
  popUpDeleteProduk,
  setPopUpDeleteProduk,
  popUpEditProduk,
  setPopUpEditMold,
  setDataPickDelete,
  newTargetProduk,
  editRunner,
  setEditRunner,
  bahan,
  setNewTargetProduk,
  editNamaMold,
  setEditNamaMold,
  editNamaBarangJadi,
  setEditNamaBarangJadi,
  radioValue,
  setRadioValue,
  setEditColorMold,
  editColorMold,
}) => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const handleDeleteMold = (button, data) => {
    setDataPickDelete(data);
    if (button === "Cancel") {
      setPopUpDeleteProduk(false);
    }
  };
  const handleEditMold = (button, data) => {
    setDataPickDelete(data);
    setEditRunner(data.runner);
    setEditNamaMold(data.name);
    setEditColorMold(data.color);
    setRadioValue(data.bagian);
    setEditNamaBarangJadi(data.namaBarang);
    setNewTargetProduk(data.targetProduk);
    if (button === "Cancel") {
      setPopUpEditMold(false);
    }
  };

  return (
    <div className="wrapper_table">
      {popUpDeleteProduk ? (
        <PopUpComponent>
          <p>
            Apakah Kamu yakin untuk <b>MENGHAPUS</b> Mold ini?
          </p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup"
              onClick={() => handleDeleteMold("Cancel")}
            >
              Cancel
            </button>
            <button className="button_popup background_red" onClick={onClick}>
              Kirim
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {popUpEditProduk ? (
        <PopUpComponent>
          <div
            style={{ display: "flex", flexWrap: "wrap", marginBottom: "16px" }}
          >
            {dataUser.role === "produksi_admin" ||
            dataUser.role === "super_user" ? (
              <div className="wrapper_input">
                <p className="font_weight_bold">Target Produk PerCavity</p>
                <input
                  type="number"
                  className="input_jumlah"
                  placeholder="Target Harian Produk"
                  value={newTargetProduk}
                  onChange={(e) => setNewTargetProduk(e.target.value)}
                  onWheel={(e) => e.target.blur()}
                />
              </div>
            ) : (
              ""
            )}

            {dataUser.role === "qc_admin" || dataUser.role === "super_user" ? (
              <div className="wrapper_input">
                <p className="font_weight_bold">Runner (gr)</p>
                <div className="wrapper_radio">
                  <input
                    className="input_jumlah"
                    type="number"
                    placeholder="Runner"
                    name="runner"
                    value={editRunner}
                    onChange={(e) => setEditRunner(e.target.value)}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {dataUser.role === "mold_admin" ||
            dataUser.role === "super_user" ? (
              <>
                <div className="wrapper_input">
                  <p className="font_weight_bold">
                    Nama Mold
                    <br />
                    (Tidak Boleh Sama)
                  </p>
                  <div className="wrapper_radio">
                    <input
                      className="input_jumlah"
                      type="text"
                      placeholder="Nama Mold"
                      value={editNamaMold}
                      onChange={(e) => setEditNamaMold(e.target.value)}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                </div>
                <div className="wrapper_input">
                  <p className="font_weight_bold">Nama Barang Jadi</p>
                  <br />
                  <div className="wrapper_radio">
                    <input
                      className="input_jumlah"
                      type="text"
                      placeholder="Nama Barang"
                      value={editNamaBarangJadi}
                      onChange={(e) => setEditNamaBarangJadi(e.target.value)}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                </div>
                <div className="wrapper_input">
                  <p className="font_weight_bold">Bagian</p>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "12px 0",
                      }}
                      onClick={() => setRadioValue("WH")}
                    >
                      <input
                        type="radio"
                        value="WH"
                        style={{ margin: "0 6px 0 0" }}
                        checked={radioValue === "WH"}
                      />
                      <label style={{ fontSize: "14px" }}>Warehouse</label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "12px 0",
                      }}
                      onClick={() => setRadioValue("SP")}
                    >
                      <input
                        type="radio"
                        value="SP"
                        style={{ margin: "0 6px 0 0" }}
                        checked={radioValue === "SP"}
                      />
                      <label style={{ fontSize: "14px" }}>Second Process</label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "12px 0",
                      }}
                      onClick={() => setRadioValue("PR")}
                    >
                      <input
                        type="radio"
                        value="PR"
                        style={{ margin: "0 6px 0 0" }}
                        checked={radioValue === "PR"}
                      />
                      <label style={{ fontSize: "14px" }}>Printing</label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "12px 0",
                      }}
                      onClick={() => setRadioValue("SC")}
                    >
                      <input
                        type="radio"
                        value="SC"
                        style={{ margin: "0 6px 0 0" }}
                        checked={radioValue === "SC"}
                      />
                      <label style={{ fontSize: "14px" }}>Sub Count</label>
                    </div>
                  </div>
                </div>
                <div className="wrapper_input">
                  <p className="font_weight_bold">Warna Mold</p>
                  <div className="wrapper_radio">
                    <input
                      className="input_jumlah"
                      type="text"
                      placeholder="Nama Mold"
                      value={editColorMold}
                      onChange={(e) => setEditColorMold(e.target.value)}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup"
              onClick={() => setPopUpEditMold(false)}
            >
              Cancel
            </button>
            <button
              className="button_popup background_red"
              onClick={onClickEdit}
            >
              Kirim
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <div className="container_header_list_bahan">
        <div className="table_header_list_produk width_delete_button">No.</div>
        <div className="table_header_list_produk width_mold_name">Mold</div>
        <div className="table_header_list_produk">Color</div>
        {dataUser.role === "produksi_admin" ||
        dataUser.role === "super_user" ||
        dataUser.role === "ppic_admin" ? (
          <div className="table_header_list_produk width_delete_button">
            Target Per
            <br />
            Cavity
          </div>
        ) : (
          ""
        )}
        {dataUser.role === "qc_admin" ||
        dataUser.role === "super_user" ||
        dataUser.role === "ppic_admin" ? (
          <div className="table_header_list_produk width_delete_button">
            Runner
          </div>
        ) : (
          ""
        )}
        {dataUser.role === "qc_admin" ||
        dataUser.role === "mold_admin" ||
        dataUser.role === "produksi_admin" ? (
          <div className="table_header_list_produk width_delete_button">
            Edit
          </div>
        ) : (
          ""
        )}
        {dataUser.role === "super_user" || dataUser.role === "ppic_admin" ? (
          <div className="table_header_list_produk width_delete_button none">
            Edit
          </div>
        ) : (
          ""
        )}

        {dataUser.role === "super_user" ||
        dataUser.role === "ppic_admin" ||
        dataUser.role === "produksi_admin" ||
        dataUser.role === "mold_admin" ? (
          <div className="table_header_list_produk width_delete_button">
            Delete
          </div>
        ) : (
          ""
        )}
      </div>
      {data &&
        data.map((obj, i) => {
          return (
            <div
              className={
                i % 2 === 0
                  ? "container_data_list_bahan"
                  : "container_data_list_bahan background_odd_list_bahan"
              }
              key={i}
            >
              <div className="table_header_list_produk font_data_produk width_delete_button">
                {i + 1}
              </div>
              <div className="table_header_list_produk font_data_produk width_mold_name">
                {obj.name}
              </div>
              <div className="table_header_list_produk font_data_produk">
                {obj.color}
              </div>
              {dataUser.role === "produksi_admin" ||
              dataUser.role === "super_user" ||
              dataUser.role === "ppic_admin" ? (
                <div className="table_header_list_produk font_data_produk width_delete_button">
                  {obj.targetProduk}
                </div>
              ) : (
                ""
              )}
              {dataUser.role === "qc_admin" ||
              dataUser.role === "super_user" ||
              dataUser.role === "ppic_admin" ? (
                <div className="table_header_list_produk font_data_produk width_delete_button">
                  {obj.runner} &nbsp; gr
                </div>
              ) : (
                ""
              )}
              <div className="table_header_list_produk width_delete_button">
                {dataUser.role === "qc_admin" ||
                dataUser.role === "mold_admin" ||
                dataUser.role === "produksi_admin" ? (
                  <div
                    className="table_header_list_produk width_delete_button "
                    onClick={() => handleEditMold("", data[i])}
                  >
                    <img
                      className="width_edit_logo"
                      src={EditLogo}
                      onClick={() => setPopUpEditMold(true)}
                      alt="edit_logo"
                    />
                  </div>
                ) : (
                  ""
                )}
                {dataUser.role === "super_user" ||
                dataUser.role === "ppic_admin" ? (
                  <div
                    className="table_header_list_produk width_delete_button none"
                    onClick={() => handleEditMold("", data[i])}
                  >
                    <img
                      className="width_edit_logo"
                      src={EditLogo}
                      onClick={() => setPopUpEditMold(true)}
                      alt="edit_logo"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>

              {dataUser.role === "super_user" ||
              dataUser.role === "ppic_admin" ||
              dataUser.role === "produksi_admin" ||
              dataUser.role === "mold_admin" ? (
                <div
                  className="table_header_list_produk width_delete_button"
                  onClick={() => handleDeleteMold("", data[i])}
                >
                  <button
                    className="button_delete_list_bahan"
                    onClick={() => setPopUpDeleteProduk(true)}
                  >
                    Delete
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
    </div>
  );
};
export default TableListMold;
