import React from "react";
import LogoGs from "../../Assets/GS-Logo.png";
import LogoYBI from "../../Assets/YBI-Logo.jpeg";
import LogoTBP from "../../Assets/TBP-Logo.jpeg";
import LogoMelco from "../../Assets/MELCO-Logo.png";
import LogoTIM from "../../Assets/TimLogo.png";
import LogoCBI from "../../Assets/CBI-Logo.png";
import CardComponent from "../../components/Card/CardComponent";
import TableListBahan from "../../components/TableListBahan/TableListBahan";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import { useListBahan } from "./useListBahan";
import "./ListBahan.css";

const ListBahan = () => {
  const {
    popUp,
    setAddCompany,
    setAddMaterial,
    addMaterial,
    handleAddBahan,
    setPopUp,
    companyPickList,
    setCompanyPickList,
    MaterialList,
    dataListBahan,
    handleDeleteListBahan,
    setPopUpDeleteBahan,
    popUpDeleteBahan,
    setDataListBahanDelete,
    setAddNamaBahan,
    setTipeBahan,
    setWarnaBahan,
    handleCancelAddBahan,
  } = useListBahan();
  return (
    <div className="container_list_bahan">
      {popUp ? (
        <PopUpComponent>
          <div className="wrapper_content_popup">
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Perusahaan<label style={{ color: "red" }}>*</label>
              </p>
              <select
                defaultValue="Pilih Nama Perusahaan"
                onChange={(e) => setAddCompany(e.target.value)}
                className="input_select"
              >
                <option selected disabled>
                  Pilih Nama Perusahaan
                </option>
                {dataListBahan &&
                  dataListBahan.map((data) => (
                    <option value={data.company}>{data.company}</option>
                  ))}
              </select>
            </div>
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Jenis Bahan<label style={{ color: "red" }}>*</label>
              </p>
              <div
                onClick={() => setAddMaterial("BAKU")}
                className="wrapper_radio"
              >
                <input
                  type="radio"
                  value="BAKU"
                  checked={addMaterial === "BAKU"}
                />
                <label style={{ fontSize: "14px" }}>BAHAN BAKU</label>
              </div>
              <div
                onClick={() => setAddMaterial("PENDUKUNG")}
                className="wrapper_radio"
              >
                <input
                  type="radio"
                  value="PENDUKUNG"
                  checked={addMaterial === "PENDUKUNG"}
                />
                <label style={{ fontSize: "14px" }}>BAHAN PENDUKUNG</label>
              </div>
            </div>
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Nama Bahan<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="text"
                className="input_jumlah"
                placeholder="Masukkan Nama"
                onChange={(e) => setAddNamaBahan(e.target.value)}
              />
            </div>
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Tipe Bahan<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="text"
                className="input_jumlah"
                placeholder="Masukkan Tipe"
                onChange={(e) => setTipeBahan(e.target.value)}
              />
            </div>
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Warna Bahan<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="text"
                className="input_jumlah"
                placeholder="Masukkan Warna"
                onChange={(e) => setWarnaBahan(e.target.value)}
              />
            </div>
            <div className="wrapper_button_submit_popup">
              <button
                className="button_popup"
                onClick={() => handleCancelAddBahan()}
              >
                Cancel
              </button>
              <button
                className="button_popup background_red"
                onClick={() => handleAddBahan()}
              >
                Kirim
              </button>
            </div>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <div className="wrapper_header">
        <p className="title_inventory">LIST BAHAN</p>
        <div className="wrapper_tambah_list" onClick={() => setPopUp(true)}>
          <div className="icon_add_list">+&nbsp;</div>
          <div className="font_tambah_button">Tambah</div>
        </div>
      </div>
      <div className="flex_wrap">
        <CardComponent
          active={companyPickList === "GS"}
          title="PT. GS"
          logo={LogoGs}
          onClick={() => setCompanyPickList("GS")}
        />
        <CardComponent
          active={companyPickList === "YBI"}
          title="PT. YBI"
          logo={LogoYBI}
          onClick={() => setCompanyPickList("YBI")}
        />
        <CardComponent
          active={companyPickList === "TBP"}
          title="PT. TBP"
          logo={LogoTBP}
          onClick={() => setCompanyPickList("TBP")}
        />
        <CardComponent
          active={companyPickList === "MELCO"}
          title="PT. MELCO"
          logo={LogoMelco}
          onClick={() => setCompanyPickList("MELCO")}
        />
        <CardComponent
          active={companyPickList === "CBI"}
          title="PT. CBI"
          logo={LogoCBI}
          onClick={() => setCompanyPickList("CBI")}
        />
        <CardComponent
          active={companyPickList === "TIM"}
          title="PT. TIM"
          logo={LogoTIM}
          onClick={() => setCompanyPickList("TIM")}
        />
      </div>
      <p className="title_inventory">LIST BAHAN BAKU PT. {companyPickList}</p>
      <TableListBahan
        data={MaterialList && MaterialList.bahanBaku}
        onClick={() => handleDeleteListBahan()}
        setPopUpDeleteBahan={setPopUpDeleteBahan}
        popUpDeleteBahan={popUpDeleteBahan}
        setDataPickDelete={setDataListBahanDelete}
      />
      <p className="title_inventory">
        LIST BAHAN PENDUKUNG PT. {companyPickList}
      </p>
      <TableListBahan
        data={MaterialList && MaterialList.bahanPendukung}
        onClick={() => handleDeleteListBahan()}
        setPopUpDeleteBahan={setPopUpDeleteBahan}
        popUpDeleteBahan={popUpDeleteBahan}
        setDataPickDelete={setDataListBahanDelete}
      />
    </div>
  );
};
export default ListBahan;
