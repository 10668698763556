import React from "react";
import "./TableStockOut.css";
const TableStockOut = ({ dataProduksi, jenisBahan }) => {
  const formatDot = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const sortData =
    dataProduksi &&
    dataProduksi.data.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="wrapper_table_memo_ppic">
      <div className="container_header_memo_ppic">
        <div className="table_header_memo_ppic width_number_mesin">Mesin</div>
        <div className="table_header_memo_ppic width_customer_stockOut">
          Cust
        </div>
        <div className="table_header_memo_ppic width_material_stockOut">
          Material
        </div>
        <div className="table_header_memo_ppic width_total_stockOut">
          Bahan Terpakai
        </div>
      </div>
      {sortData &&
        sortData.map((input, index) => {
          const runner = input.runner ? input.runner : 0;
          const formulaBaku =
            (input.totalBarang * runner +
              input.totalBarang * input.beratQc +
              input.totalGumpalan) /
            1000;

          const reject = input.totalReject ? input.totalReject : 0;
          const formulaPendukung = reject + input.totalBahan;
          return (
            <div
              className={
                index % 2 === 0
                  ? "container_data_memo_ppic"
                  : "container_data_memo_ppic background_odd"
              }
              key={index}
            >
              {jenisBahan === "BAKU" ? (
                <>
                  <div className="width_number_mesin">{input.mesin}</div>
                  <div className="width_customer_stockOut">
                    {input.customer}
                  </div>
                  <div className="width_material_stockOut">
                    {input.material}
                  </div>
                  <div className="width_total_stockOut">
                    {formatDot(Math.round(formulaBaku))} Kg
                  </div>
                </>
              ) : (
                <>
                  <div className="width_number_mesin">{input.mesin}</div>
                  <div className="width_customer_stockOut">
                    {input.customer}
                  </div>
                  <div className="width_material_stockOut">
                    {Array.isArray(input.material)
                      ? input.material.map((el) => (
                          <>
                            {el.material}
                            <br />
                            <br />
                          </>
                        ))
                      : input.material}
                  </div>
                  <div className="width_total_stockOut">
                    {formatDot(Math.round(formulaPendukung))} Pcs
                  </div>
                </>
              )}
            </div>
          );
        })}
    </div>
  );
};
export default TableStockOut;
