import React from "react";
import { useSecurity } from "./useSecurity";
import ScanLogo from "../../Assets/scan-logo.png";
import PopUpComponent from "../../components/PopUp/PopUpComponent";

const Security = () => {
  const { ref, handleScan, popUp, setPopUp, errMsg } = useSecurity();

  return (
    <div className="container_approval">
      {popUp ? (
        <PopUpComponent>
          <p>Selamat! Scan Barcode Berhasil!</p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup background_red"
              onClick={() => setPopUp(false)}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <div style={{ margin: "18px 0 18px 0" }} ref={ref} id="reader"></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {errMsg !== "" ? (
          <p style={{ color: "red", fontWeight: "bold" }}>{errMsg}</p>
        ) : (
          ""
        )}
        <img
          style={{ width: "42px", cursor: "pointer", margin: "0 6px" }}
          src={ScanLogo}
          onClick={() => handleScan()}
        />
        <p>Scan Barcode</p>
      </div>
    </div>
  );
};
export default Security;
