import React from "react";
import { constantProduksi } from "../../util/constant";
import "./TableProduksi.css";

const TableProduksi = ({ handleFormChange, dataSatuan, itemProduksi }) => {
  return (
    <div>
      <div className="container_header_produksi">
        <div className="header_no_mesin_produksi">Mesin</div>
        <div className="header_table_produksi font_bold">
          Gumpalan <br />
          (gr)
        </div>
        <div className="header_table_produksi font_bold">Catatan</div>
        {/* <div className="header_table_produksi font_bold">
          Downtime
          <br />
          (Menit)
        </div> */}
      </div>

      {itemProduksi &&
        itemProduksi.map((data, index) => (
          <div
            className={
              index % 2 === 0
                ? "container_data_memo_ppic"
                : "container_data_memo_ppic background_odd"
            }
            key={index}
          >
            <div className="header_no_mesin_produksi">{data.mesin}</div>
            <div className="container_input_total_produksi">
              <input
                className="width_input_produksi font_12_mobile"
                type="number"
                placeholder="Gumpalan"
                name="totalGumpalan"
                onChange={(e) => handleFormChange(index, e, "BAKU")}
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <div className="container_input_total_produksi">
              <textarea
                style={{
                  width: "120px",
                  height: "48px",
                  fontSize: "12px",
                  fontFamily: "sans-serif",
                  resize: "none",
                }}
                onChange={(e) => handleFormChange(index, e, "BAKU")}
                name="catatan"
                placeholder="Catatan"
              />
            </div>
          </div>
        ))}
    </div>
  );
};
export default TableProduksi;
