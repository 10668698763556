import React from "react";
import { constantNoMesin } from "../../util/constant";
import DeleteIcon from "../../Assets/delete_icon.png";
import "./TableMemoGantiMold.css";

const TableMemoGantiMold = ({
  dataMemo,
  jenisBahan,
  memoBahan,
  handleFormChange,
  isUtama,
  isHistory,
  noHeader,
  handleDeleteMemoGantiMold,
}) => {
  return (
    <div className="wrapper_table_memo_history_ganti_mold">
      {!noHeader ? (
        <div className="container_header_memo_ppic">
          <div
            className="table_header_memo_ppic width_number_mesin"
            style={{ maxWidth: "10%" }}
          >
            Mesin
          </div>
          <div
            className="table_header_memo_ppic width_number_mesin"
            style={{ maxWidth: "10%" }}
          >
            Urutan
          </div>
          <div
            className="table_header_memo_ppic width_number_mesin"
            style={{ maxWidth: "10%" }}
          >
            Cust
          </div>
          <div
            className={
              isHistory
                ? "table_header_memo_ppic width_table_ganti_mold width_table_ganti_mold_history_27"
                : "table_header_memo_ppic width_table_ganti_mold"
            }
          >
            Mold
          </div>

          {isHistory ? (
            ""
          ) : (
            <div className="table_header_memo_ppic width_table_ganti_mold">
              Produk
            </div>
          )}
          <div className="table_header_memo_ppic width_table_ganti_mold">
            Pendukung
          </div>
          <div className="table_header_memo_ppic width_table_ganti_mold">
            Produk
            <br />
            Color
          </div>
          {isHistory ? (
            ""
          ) : (
            <div className="table_header_memo_ppic width_table_ganti_mold">
              Material
            </div>
          )}
          {isHistory ? (
            ""
          ) : (
            <div className="table_header_memo_ppic width_number_mesin">
              Material <br />
              Color
            </div>
          )}
          <div className="table_header_memo_ppic width_table_ganti_mold_12">
            Total
            <br />
            Produk
          </div>
        </div>
      ) : (
        ""
      )}
      {memoBahan &&
        memoBahan.map((input, index) => {
          return (
            <div
              className={
                index % 2 === 0
                  ? "container_data_memo_ppic padding_0"
                  : "container_data_memo_ppic padding_0 background_odd"
              }
              key={index}
            >
              {isUtama ? (
                isHistory ? (
                  <div
                    className="width_number_mesin"
                    style={{ maxWidth: "10%" }}
                  >
                    {input.mesin}
                  </div>
                ) : (
                  <div
                    className="width_number_mesin"
                    style={{ maxWidth: "10%" }}
                  >
                    <select
                      defaultValue="Pilih No Mesin"
                      onChange={(e) => handleFormChange(index, e, jenisBahan)}
                      className="input_select_memo_ppic"
                      style={{ width: "50%" }}
                      name="mesin"
                      value={input.mesin}
                    >
                      <option selected disabled>
                        Pilih Nomor Mesin
                      </option>
                      {constantNoMesin &&
                        constantNoMesin.map((data) => (
                          <option value={data}>{data}</option>
                        ))}
                    </select>
                  </div>
                )
              ) : (
                <div className="width_number_mesin" style={{ maxWidth: "10%" }}>
                  <input
                    type="text"
                    onChange={(e) => handleFormChange(index, e, jenisBahan)}
                    className="input_select_memo_ppic"
                    style={{ width: "50%" }}
                    name="mesin"
                    placeholder="Mesin"
                    value={input.mesin}
                  />
                </div>
              )}
              <div className="width_number_mesin" style={{ maxWidth: "10%" }}>
                <input
                  type="text"
                  onChange={(e) => handleFormChange(index, e, jenisBahan)}
                  className="input_select_memo_ppic"
                  style={{ width: "50%" }}
                  name="urutan"
                  placeholder="Urutan"
                  value={input.urutan}
                />
              </div>
              {isHistory ? (
                <div className="width_number_mesin" style={{ maxWidth: "10%" }}>
                  {input.customer}
                </div>
              ) : (
                <div className="width_number_mesin" style={{ maxWidth: "10%" }}>
                  <select
                    defaultValue="Pilih Customer"
                    onChange={(e) => handleFormChange(index, e, jenisBahan)}
                    className="input_select_memo_ppic"
                    style={{ width: "60%" }}
                    value={input.customer ? input.customer : "Pilih Customer"}
                    name="customer"
                  >
                    {input.customer === "" ? (
                      <option selected={input.customer === ""} disabled>
                        Pilih Customer
                      </option>
                    ) : (
                      <option value={input.customer} disabled>
                        {input.customer}
                      </option>
                    )}
                    {dataMemo &&
                      dataMemo.map((data) => (
                        <option value={data.company}>{data.company}</option>
                      ))}
                  </select>
                </div>
              )}
              {isHistory ? (
                <div
                  className="width_table_ganti_mold width_table_ganti_mold_history_27"
                  style={{ maxWidth: "10%" }}
                >
                  {input.moldProduk}
                </div>
              ) : (
                <div
                  className="width_table_ganti_mold"
                  style={{ maxWidth: "10%" }}
                >
                  <select
                    defaultValue="Pilih Mold"
                    onChange={(e) => handleFormChange(index, e, jenisBahan)}
                    className="input_select_memo_ppic"
                    style={{ width: "75%" }}
                    name="moldProduk"
                    value={input.moldProduk}
                  >
                    <option selected disabled>
                      Pilih Mold
                    </option>
                    {input.listMoldProduk &&
                      input.listMoldProduk.map((data) => (
                        <option value={data.name}>{data.name}</option>
                      ))}
                  </select>
                </div>
              )}
              {isHistory ? (
                ""
              ) : (
                <div
                  className="width_table_ganti_mold"
                  style={{ maxWidth: "10%" }}
                >
                  <select
                    defaultValue="Pilih Produk"
                    onChange={(e) => handleFormChange(index, e, jenisBahan)}
                    className="input_select_memo_ppic"
                    style={{ width: "75%" }}
                    name="mold"
                    value={input.mold ? input.mold : "Pilih Produk"}
                  >
                    {input.mold === "" ? (
                      <option selected disabled>
                        Pilih Produk
                      </option>
                    ) : (
                      <option value={input.mold} disabled>
                        {input.mold}
                      </option>
                    )}
                    {input.listProdukBaku &&
                      input.listProdukBaku.map((data) => (
                        <option value={data.mold}>{data.mold}</option>
                      ))}
                  </select>
                </div>
              )}
              <div
                className="width_table_ganti_mold"
                style={{ maxWidth: "10%" }}
              >
                <select
                  defaultValue="Produk Pendukung"
                  onChange={(e) => handleFormChange(index, e, jenisBahan)}
                  className="input_select_memo_ppic"
                  style={{ width: "75%" }}
                  name="produkPendukung"
                  value={
                    input.produkPendukung
                      ? input.produkPendukung
                      : "Produk Pendukung"
                  }
                >
                  {input.produkPendukung === "" ? (
                    <option selected disabled>
                      Produk Pendukung
                    </option>
                  ) : (
                    <option value={input.produkPendukung} disabled>
                      {input.produkPendukung}
                    </option>
                  )}
                  {input.listProdukPendukung &&
                    input.listProdukPendukung.map((data) => (
                      <option value={data.mold}>{data.mold}</option>
                    ))}
                </select>
              </div>
              <div className="width_table_ganti_mold">{input.color}</div>
              {isHistory ? (
                ""
              ) : (
                <div className="width_table_ganti_mold">
                  {Array.isArray(input.material)
                    ? input.material.map((el) => {
                        return (
                          <>
                            {el.material} <br /> <br />
                          </>
                        );
                      })
                    : input.material}
                </div>
              )}
              {isHistory ? (
                ""
              ) : (
                <div className="width_number_mesin">{input.colorMaterial}</div>
              )}
              {isHistory ? (
                <div className="width_number_mesin" style={{ maxWidth: "10%" }}>
                  {Array.isArray(input.material)
                    ? input.material.map((el) => {
                        return (
                          <>
                            {input.totalBahan === null
                              ? el.total
                              : input.totalBahan}
                            &nbsp;
                            {`${input.totalBahan === null ? "Kg" : "Pcs"}`}
                            <br />
                            <br />
                          </>
                        );
                      })
                    : `${
                        input.totalBahan === null
                          ? input.total
                          : input.totalBahan
                      } ${input.totalBahan === null ? "Kg" : "Pcs"}`}
                </div>
              ) : (
                <>
                  {isUtama ? (
                    <div
                      className="width_number_mesin"
                      style={{ maxWidth: "10%" }}
                    >
                      <input
                        type="number"
                        onChange={(e) => handleFormChange(index, e, jenisBahan)}
                        className="input_select_memo_ppic"
                        style={{ width: "50%" }}
                        name="totalBahan"
                        placeholder="Total"
                        value={input.totalBahan ? input.totalBahan : "Total"}
                        onWheel={(e) => e.target.blur()}
                      />{" "}
                      Pcs
                    </div>
                  ) : (
                    <div>
                      <input
                        type="number"
                        onChange={(e) => handleFormChange(index, e, jenisBahan)}
                        className="input_select_memo_ppic"
                        style={{ width: "50%" }}
                        name="totalBahan"
                        placeholder="Total"
                        value={input.totalBahan ? input.totalBahan : "Total"}
                        onWheel={(e) => e.target.blur()}
                      />
                      &nbsp; Pcs
                    </div>
                  )}
                  <img
                    style={{ width: "24px", cursor: "pointer" }}
                    src={DeleteIcon}
                    alt="delete-icon"
                    onClick={() => handleDeleteMemoGantiMold(input)}
                  />
                </>
              )}
            </div>
          );
        })}
    </div>
  );
};
export default TableMemoGantiMold;
