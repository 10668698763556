import React from "react";
import "./TableQualityControl.css";
const TableQualityControl = ({
  dataQc,
  shiftQc,
  setShiftQc,
  dateQc,
  setDateQc,
  handleCariQc,
  satuanQc,
  dataTopPlate,
}) => {
  return (
    <div className="container_qc" style={{ margin: "0" }}>
      <div className="wrapper_table_memo_ppic">
        <div style={{ marginBottom: "12px", display: "flex" }}>
          <div className="margin_right_27_qc">
            <p>Pilih Shift</p>
            <select
              className="input_select_shift"
              value={shiftQc}
              onChange={(e) => setShiftQc(e.target.value)}
            >
              <option selected={shiftQc === "Pilih Shift"} disabled>
                Pilih Shift
              </option>
              <option value="1">Shift 1</option>
              <option value="2">Shift 2</option>
              <option value="3">Shift 3</option>
            </select>
          </div>
          <div className="margin_right_27_qc">
            <p>Pilih Tanggal</p>
            <input
              type="date"
              className="date_pick_memo_ppic"
              value={dateQc}
              onChange={(e) => setDateQc(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "28px 0 0 0",
            }}
          >
            <button
              style={{
                height: "32px",
                width: "62px",
                border: "none",
                background: "red",
                color: "white",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={() => handleCariQc()}
            >
              Cari
            </button>
          </div>
        </div>
        <div className="container_header_memo_ppic">
          <div className="table_header_memo_ppic width_number_mesin_qc">
            Mesin
          </div>
          <div className="table_header_memo_ppic customer_width width_number_mesin_qc">
            Customer
          </div>
          <div className="table_header_memo_ppic min_width_qc_27">Mold</div>
          <div className="table_header_memo_ppic min_width_qc_27">Bahan</div>
          <div className="table_header_memo_ppic color_width none">Color</div>
          <div className="table_header_memo_ppic jumlah_width_qc width_number_mesin_qc">
            Berat (gr)
          </div>
        </div>
        {dataQc &&
          dataQc.map((data, index) => (
            <div
              className={
                index % 2 === 0
                  ? "container_data_memo_ppic"
                  : "container_data_memo_ppic background_odd"
              }
              key={index}
            >
              <div className="width_number_mesin_qc">
                <p style={{ margin: "0 6px" }}>{data.mesin}</p>
              </div>
              <div className="min_width customer_width width_number_mesin_qc">
                {data.customer}
              </div>
              <div className="min_width column_qc_width">{data.moldType}</div>
              <div className="min_width column_qc_width">{data.material}</div>
              <div className="min_width color_width none">{data.color}</div>
              <div
                className="jumlah_width_qc width_number_mesin_qc"
                style={{ display: "flex", alignItems: "center" }}
              >
                {data.beratQc ? data.beratQc : "0"} &nbsp; gr
              </div>
            </div>
          ))}

        {dataTopPlate && dataTopPlate ? (
          <>
            <p className="title_inventory">Data Top Plate</p>
            <div className="container_header_memo_ppic">
              <div className="table_header_memo_ppic width_number_mesin_qc">
                Mesin
              </div>
              <div className="table_header_memo_ppic customer_width width_number_mesin_qc">
                Customer
              </div>
              <div className="table_header_memo_ppic min_width_qc_27">Mold</div>
              <div className="table_header_memo_ppic min_width_qc_27">
                Bahan
              </div>
              <div className="table_header_memo_ppic color_width none">
                Type
              </div>
              <div className="table_header_memo_ppic jumlah_width_qc width_number_mesin_qc">
                Berat (gr)
              </div>
            </div>
            {dataTopPlate &&
              dataTopPlate.map((data, index) => (
                <div
                  className={
                    index % 2 === 0
                      ? "container_data_memo_ppic"
                      : "container_data_memo_ppic background_odd"
                  }
                  key={index}
                >
                  <div className="width_number_mesin_qc">
                    <p style={{ margin: "0 6px" }}>{data.mesin}</p>
                  </div>
                  <div className="min_width customer_width width_number_mesin_qc">
                    {data.customer}
                  </div>
                  <div className="min_width column_qc_width">
                    {data.moldType}
                  </div>
                  <div className="min_width column_qc_width">
                    {data.material}
                  </div>
                  <div className="min_width color_width none">
                    {data.typeMaterial}
                  </div>
                  <div
                    className="jumlah_width_qc width_number_mesin_qc"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {data.berat ? data.berat : "0"} &nbsp; gr
                  </div>
                </div>
              ))}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default TableQualityControl;
