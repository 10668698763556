import React from "react";
import { constantNoMesin } from "../../util/constant";
import "./TableMemoPPIC.css";

const TableMemoPPIC = ({
  dataMemo,
  jenisBahan,
  memoBahan,
  handleFormChange,
  isEditJumlah,
  setIsEditJumlah,
}) => {
  return (
    <div className="wrapper_table_memo_ppic">
      <div className="container_header_memo_ppic">
        <div className="table_header_memo_ppic width_number_mesin">
          No. Mesin
        </div>
        <div
          className="table_header_memo_ppic"
          style={jenisBahan === "BAKU" ? { minWidth: "16.4%" } : {}}
        >
          Customer
        </div>
        {jenisBahan === "SATUAN" ? (
          <div
            className="table_header_memo_ppic"
            style={jenisBahan === "SATUAN" ? { minWidth: "18.25%" } : {}}
          >
            Jenis
          </div>
        ) : (
          ""
        )}
        {jenisBahan === "BAKU" ? (
          <div
            className="table_header_memo_ppic"
            style={jenisBahan === "BAKU" ? { minWidth: "16.4%" } : {}}
          >
            Mold
          </div>
        ) : (
          ""
        )}
        <div
          className="table_header_memo_ppic"
          style={jenisBahan === "BAKU" ? { minWidth: "16.4%" } : {}}
        >
          Produk
        </div>
        <div
          className="table_header_memo_ppic"
          style={jenisBahan === "BAKU" ? { minWidth: "16.4%" } : {}}
        >
          Nama Bahan
        </div>
        <div
          className="table_header_memo_ppic none"
          style={
            jenisBahan === "BAKU" ? { minWidth: "16.4%" } : { minWidth: "10%" }
          }
        >
          Color
        </div>
        <div className="table_header_memo_ppic width_number_mesin none">
          Total
        </div>
        <div></div>
        {/* {jenisBahan === "PENDUKUNG" ? (
          <div
            className="table_header_memo_ppic width_number_mesin none"
            style={{ minWidth: "7.4%" }}
          >
            Dibawa
          </div>
        ) : (
          ""
        )} */}
      </div>
      {memoBahan &&
        memoBahan.map((input, index) => {
          return (
            <>
              {!input.isProduksi ? (
                <div
                  className={
                    index % 2 === 0
                      ? "container_data_memo_ppic"
                      : "container_data_memo_ppic background_odd"
                  }
                  key={index}
                >
                  {jenisBahan === "BAKU" ? (
                    <div className="width_number_mesin">
                      <p style={{ margin: "0 6px" }}>{input.mesin}</p>
                    </div>
                  ) : jenisBahan === "PENDUKUNG" ? (
                    <div>
                      <select
                        defaultValue="Pilih No Mesin"
                        onChange={(e) => handleFormChange(index, e, jenisBahan)}
                        className="input_select_memo_ppic"
                        style={{ width: "50%" }}
                        name="mesin"
                        value={input.mesin}
                      >
                        <option selected disabled>
                          Pilih Nomor Mesin
                        </option>
                        {constantNoMesin &&
                          constantNoMesin.map((data) => (
                            <option value={data}>{data}</option>
                          ))}
                      </select>
                    </div>
                  ) : (
                    <div>
                      <input
                        type="text"
                        onChange={(e) => handleFormChange(index, e, jenisBahan)}
                        className="input_select_memo_ppic"
                        style={{ width: "50%" }}
                        name="mesin"
                        placeholder="Mesin"
                        value={input.mesin}
                      />
                    </div>
                  )}
                  <div
                    className="min_width_memo_ppic"
                    style={jenisBahan === "BAKU" ? { minWidth: "16.4%" } : {}}
                  >
                    {jenisBahan === "BAKU" ? (
                      <select
                        defaultValue="Pilih Customer"
                        onChange={(e) => handleFormChange(index, e, jenisBahan)}
                        className="input_select_memo_ppic"
                        value={
                          input.customer ? input.customer : "Pilih Customer"
                        }
                        name="customer"
                      >
                        {input.customer === "" ? (
                          <option selected={input.customer === ""} disabled>
                            Pilih Customer
                          </option>
                        ) : (
                          <option value={input.customer} disabled>
                            {input.customer}
                          </option>
                        )}
                        {dataMemo &&
                          dataMemo.map((data) => (
                            <option value={data.company}>{data.company}</option>
                          ))}
                      </select>
                    ) : (
                      <select
                        onChange={(e) => handleFormChange(index, e, jenisBahan)}
                        className="input_select_memo_ppic"
                        name="customer"
                        value={
                          input.customer ? input.customer : "Pilih Customer"
                        }
                      >
                        {input.customer === "" ? (
                          <option selected={input.customer === ""} disabled>
                            Pilih Customer
                          </option>
                        ) : (
                          <option value={input.customer} disabled>
                            {input.customer}
                          </option>
                        )}
                        {dataMemo &&
                          dataMemo.map((data) => (
                            <option value={data.company}>{data.company}</option>
                          ))}
                      </select>
                    )}
                  </div>
                  {jenisBahan === "SATUAN" ? (
                    <div
                      style={
                        jenisBahan === "SATUAN" ? { minWidth: "18.25%" } : {}
                      }
                    >
                      <select
                        defaultValue="Pilih Jenis Bahan"
                        onChange={(e) => handleFormChange(index, e, jenisBahan)}
                        name="jenisBahan"
                        className="input_select_memo_ppic"
                        value={
                          input.jenisBahan
                            ? input.jenisBahan
                            : "Pilih Jenis Bahan"
                        }
                        style={{ width: "192px" }}
                      >
                        <option selected disabled>
                          Pilih Jenis Bahan
                        </option>
                        <option value={"BAKU"}>Bahan Baku</option>
                        <option value={"PENDUKUNG"}>Bahan Pendukung</option>
                      </select>
                    </div>
                  ) : (
                    ""
                  )}
                  {jenisBahan === "BAKU" ? (
                    <div className="min_width_memo_ppic">
                      <select
                        defaultValue="Pilih Mold"
                        onChange={(e) => handleFormChange(index, e, jenisBahan)}
                        className="input_select_memo_ppic"
                        style={
                          jenisBahan === "SATUAN" ? { minWidth: "18.25%" } : {}
                        }
                        name="moldType"
                        value={input.moldType ? input.moldType : "Pilih Mold"}
                      >
                        {!input.moldType || input.moldType === "" ? (
                          <option selected disabled>
                            Pilih Mold
                          </option>
                        ) : (
                          <option value={input.moldType} disabled>
                            {input.moldType}
                          </option>
                        )}
                        {input.listMoldProduk &&
                          input.listMoldProduk.map((data) => (
                            <option value={data.name}>{data.name}</option>
                          ))}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="min_width_memo_ppic">
                    <select
                      defaultValue="Pilih Produk"
                      onChange={(e) => handleFormChange(index, e, jenisBahan)}
                      className="input_select_memo_ppic"
                      style={
                        jenisBahan === "SATUAN" ? { minWidth: "18.25%" } : {}
                      }
                      name="mold"
                      value={input.mold ? input.mold : "Pilih Produk"}
                    >
                      {input.mold === "" ? (
                        <option selected disabled>
                          Pilih Produk
                        </option>
                      ) : (
                        <option value={input.mold} disabled>
                          {input.mold}
                        </option>
                      )}
                      {input.listMoldTemp &&
                        input.listMoldTemp.map((data) => (
                          <option value={data.mold}>{data.mold}</option>
                        ))}
                    </select>
                  </div>
                  <div
                    className="min_width_memo_ppic"
                    style={
                      jenisBahan === "SATUAN" ? { minWidth: "18.25%" } : {}
                    }
                  >
                    {Array.isArray(input.material)
                      ? input.material.map((el) => {
                          return (
                            <>
                              {el.material} <br /> <br />
                            </>
                          );
                        })
                      : input.material}
                  </div>
                  <div
                    className="min_width_memo_ppic"
                    style={
                      jenisBahan === "BAKU"
                        ? { minWidth: "16.4%" }
                        : { minWidth: "10%" }
                    }
                  >
                    {input.color}
                  </div>
                  {jenisBahan === "SATUAN" ? (
                    <div>
                      <input
                        type="number"
                        value={input.totalBahan ? input.totalBahan : "Total"}
                        onChange={(e) => handleFormChange(index, e, jenisBahan)}
                        className="input_select_memo_ppic"
                        style={{ width: "50%" }}
                        name="totalBahan"
                        placeholder="Total"
                        onWheel={(e) => e.target.blur()}
                      />
                      &nbsp; Pcs
                    </div>
                  ) : (
                    <>
                      {isEditJumlah ? (
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          onChange={(e) =>
                            handleFormChange(index, e, jenisBahan)
                          }
                          name="totalBahan"
                          placeholder="Total"
                          style={{
                            height: "36px",
                            width: "56px",
                            marginRight: "6px",
                          }}
                        />
                      ) : (
                        <div className="width_number_mesin">
                          {input.totalBahan
                            ? Array.isArray(input.material)
                              ? input.material.map((el) => {
                                  return (
                                    <>
                                      {el.perbandingan * input.totalBahan}{" "}
                                      &nbsp;
                                      {`${
                                        jenisBahan === "BAKU" ? "Kg" : "Pcs"
                                      }`}
                                      <br />
                                      <br />
                                    </>
                                  );
                                })
                              : `${input.totalBahan} ${
                                  jenisBahan === "BAKU" ? "Kg" : "Pcs"
                                }`
                            : Array.isArray(input.material)
                            ? input.material.map((el) => {
                                return (
                                  <>
                                    {el.total} &nbsp;
                                    {`${jenisBahan === "BAKU" ? "Kg" : "Pcs"}`}
                                    <br />
                                    <br />
                                  </>
                                );
                              })
                            : `${input.total} ${
                                jenisBahan === "BAKU" ? "Kg" : "Pcs"
                              }`}
                        </div>
                      )}
                    </>
                  )}
                  {jenisBahan === "PENDUKUNG" ? (
                    <div
                      onClick={setIsEditJumlah}
                      style={{ color: "red", cursor: "pointer" }}
                    >
                      Edit
                    </div>
                  ) : (
                    ""
                  )}
                  {/* {jenisBahan === "PENDUKUNG" ? (
                    <div
                      className="table_header_memo_ppic width_number_mesin none"
                      style={{ minWidth: "5%" }}
                    >
                      <input
                        type="checkbox"
                        style={{ width: "24px", height: "24px" }}
                        value={input.isBawa}
                        checked={input.isBawa ? input.isBawa : false}
                        onChange={(e) => handleFormChange(index, e, jenisBahan)}
                        name="isBawa"
                      />
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
              ) : (
                ""
              )}
            </>
          );
        })}
    </div>
  );
};
export default TableMemoPPIC;
