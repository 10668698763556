import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import { validateRole } from "../../util/utilFunction";
import { today, noFormat } from "../../util/formatDate";
import { v4 as uuidv4 } from "uuid";
import { getBulanNow, getTahunNow } from "../../util/formatDate";

export const useSecondProcess = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);
  const v4Id = uuidv4();

  const [produkMasukSP, setProdukMasukSP] = useState("Pilih Produk");
  const [customerSP, setCustomerSP] = useState("Pilih Customer");
  const [jumlahMasukSP, setJumlahMasukSP] = useState("");
  const [listProduk, setListProduk] = useState();
  const [listProdukPrinting, setListProdukPrinting] = useState();
  const [subMenuPrinting, setSubMenuPrinting] = useState("laporan");

  const [dateLaporanSP, setDateLaporanSP] = useState(today);
  const [warnaPrinting, setWarnaPrinting] = useState("Pilih Warna");
  const [dataProdukPick, setDataProdukPick] = useState();
  const [mesinSP, setMesinSP] = useState("Pilih Mesin");
  const [jenisPekerjaan, setJenisPekerjaan] = useState("Pilih Jenis Pekerjaan");
  const [produkLaporanSP, setProdukLaporanSP] = useState("Pilih Produk");
  const [keteranganLaporanSP, setKeteranganLaporanSP] = useState("");
  const [jumlahOkLaporanSP, setJumlahOkLaporanSP] = useState("");
  const [rejectLaporanSP, setRejectLaporanSP] = useState("");
  const [repairLaporanSP, setRepairLaporanSP] = useState("");
  const [jumlahPengerjaanSP, setJumlahPengerjaanSP] = useState("");
  const [errMsgLaporanSP, setErrMsgLaporanSP] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [dataReject, setDataReject] = useState([]);
  const [addPopUpNewReject, setAddPopUpNewReject] = useState(false);
  const [bagianReject, setBagianReject] = useState("");
  const [rejectDescProduksi, setRejectDescProduksi] = useState();
  const [shiftProduksi, setShiftProduksi] = useState("Pilih Shift");
  const [jumlahReject, setJumlahReject] = useState("");
  const [downtime, setDowntime] = useState("");
  const [alasanRejectSP, setAlasanRejectSP] = useState("Pilih Alasan");
  const [dateReject, setDateReject] = useState(today);
  const [alasanRejectProduksi, setAlasanRejectProduksi] =
    useState("Pilih Alasan");
  const [dataBarangSP, setDataBarangSP] = useState();
  const [dataBarangPolos, setDataBarangPolos] = useState();
  const [idBarangPolos, setIdBarangPolos] = useState("Pilih Produk");

  const [dataListSP, setDataListSP] = useState();
  const [isRefresh, setIsRefresh] = useState(false);
  const [bulanRejectProduksi, setBulanRejectProduksi] = useState(getBulanNow);
  const [tahunRejectProduksi, setTahunRejectProduksi] = useState(getTahunNow);

  useEffect(() => {
    if (dataUser.role === "produksi_admin") {
      setSubMenuPrinting("rejectProduksi");
    }
  }, []);

  useEffect(() => {
    if (subMenuPrinting === "rejectProduksi") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/checkSheet`,
        {
          tim: dataUser.tim,
          month: `${bulanRejectProduksi} ${tahunRejectProduksi}`,
          produk: "",
          jenisPekerjaan: "",
          bagian: "",
        },
        dataUser.auth,
        setDataListSP,
        ""
      );
      setIsRefresh(false);
    }
  }, [isRefresh, subMenuPrinting, bulanRejectProduksi, tahunRejectProduksi]);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/secondProcess`,
      { tim: dataUser.tim, bagian: validateRole(), type: "getProduk" },
      dataUser.auth,
      setListProduk,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListPerincian`,
      {},
      dataUser.auth,
      setRejectDescProduksi,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListMold`,
      { tim: dataUser.tim },
      dataUser.auth,
      setDataBarangSP,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/secondProcess`,
      {
        tim: dataUser.tim,
        type: "getBarangPolos",
        bagian: validateRole(),
      },
      dataUser.auth,
      setDataBarangPolos,
      ""
    );
  }, []);

  useEffect(() => {
    listProduk &&
      listProduk.find((o) =>
        o.customer === customerSP ? setDataProdukPick(o.data) : ""
      );
  }, [customerSP]);

  let dataMerge = [];

  dataListSP &&
    dataListSP.forEach((element) => {
      element.dataReject &&
        element.dataReject.forEach((obj) => {
          if (obj.bagian === "Produksi") {
            dataMerge.push({
              bagian: obj.bagian,
              dateReject: obj.dateReject,
              id: obj.id,
              reject: obj.reject,
              shift: obj.shift,
              produk: element.barangPolos.produk,
              color: element.barangPolos.color,
              customer: element.customer,
            });
          }
        });
    });

  const handlePickCustomer = (e) => {
    setCustomerSP(e);

    const find =
      dataBarangSP && dataBarangSP.find((x) => (x.company === e ? x : ""));
    let data = [];

    for (let index = 0; index < find.data.length; index++) {
      const element = find.data[index];
      const found = data.some((el) => el.namaBarang === element.namaBarang);
      if (element.bagian === validateRole() && !found) {
        data.push(element);
      }
    }
    setListProdukPrinting(data);
  };

  const handlePickBagian = (data) => {
    setBagianReject(data);
    if (data === "SP") {
      setJumlahReject("");
      setAlasanRejectSP("Pilih Alasan");
    } else if (data === "Produksi") {
      setAlasanRejectProduksi("Pilih Alasan");
      setShiftProduksi("Pilih Shift");
      setJumlahReject("");
    }
  };

  const addFields = () => {
    if (bagianReject === "SP") {
      let newfield = {
        dateReject: dateReject,
        id: alasanRejectSP,
        reject: jumlahReject,
        bagian: "SP",
      };
      setDataReject([...dataReject, newfield]);
    } else if (bagianReject === "Produksi") {
      let newfield = {
        dateReject: dateReject,
        id: alasanRejectProduksi,
        shift: shiftProduksi,
        reject: jumlahReject,
        bagian: "Produksi",
      };
      setDataReject([...dataReject, newfield]);
    }
    setAddPopUpNewReject(false);
    setAlasanRejectProduksi("Pilih Alasan");
    setShiftProduksi("Pilih Shift");
    setJumlahReject("");
    setAlasanRejectSP("Pilih Alasan");
    setBagianReject("");
  };

  const removeFields = () => {
    let data = [...dataReject];
    if (data.length > 0) {
      data.splice(data.length - 1);
      setDataReject(data);
    }
  };

  let firstFindFromInventory =
    dataBarangPolos && dataBarangPolos.find((o) => o.company === customerSP);
  let secondFindFromInventory =
    firstFindFromInventory &&
    firstFindFromInventory.data.find((o) => o.id === idBarangPolos);

  const handleKirimLaporanSP = () => {
    const getDate = new Date(dateLaporanSP);
    const month = getDate.getMonth();
    const year = getDate.getFullYear();
    if (
      customerSP !== "Pilih Customer" &&
      mesinSP !== "Pilih Mesin" &&
      produkLaporanSP !== "Pilih Produk" &&
      jenisPekerjaan !== "Pilih Jenis Pekerjaan" &&
      jumlahOkLaporanSP !== "" &&
      jumlahPengerjaanSP !== "" &&
      idBarangPolos !== "Pilih Produk"
    ) {
      const validateTarget = () => {
        if (validateRole() === "PR") {
          const data =
            dataProdukPick &&
            dataProdukPick.find((x) => (x.produk === produkLaporanSP ? x : ""));
          if (jenisPekerjaan === "Print") {
            return data.targetPrint;
          } else if (jenisPekerjaan === "Pasang Sticker") {
            return data.targetPasangStiker;
          } else if (jenisPekerjaan === "Punching") {
            return data.targetPunching;
          }
        } else {
          const data =
            dataProdukPick &&
            dataProdukPick.find((x) => (x.produk === produkLaporanSP ? x : ""));
          if (jenisPekerjaan === "Ultrasonic") {
            return data.targetUltrasonic;
          } else if (jenisPekerjaan === "Test Bocor") {
            return data.targetTestBocor;
          } else if (jenisPekerjaan === "Filling Plug") {
            return data.targetFilling;
          } else if (jenisPekerjaan === "Pasang Handle") {
            return data.targetFilling;
          } else if (jenisPekerjaan === "Pemasangan BNW") {
            return data.targetFilling;
          } else if (jenisPekerjaan === "Pemasangan Valve & Filter") {
            return data.targetFilling;
          } else if (jenisPekerjaan === "Putus Ronce") {
            return data.targetFilling;
          } else if (jenisPekerjaan === "Sortir") {
            return data.targetFilling;
          }
        }
      };

      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/secondProcess`,
        {
          id: v4Id,
          tim: dataUser.tim,
          date: dateLaporanSP,
          mesin: mesinSP,
          customer: customerSP,
          produk: produkLaporanSP,
          jenisPekerjaan: jenisPekerjaan,
          color: warnaPrinting !== "Pilih Warna" ? warnaPrinting : "",
          jumlahOk: Number(jumlahOkLaporanSP),
          reject: Number(rejectLaporanSP),
          repair: Number(repairLaporanSP),
          jumlahPengerjaan: jumlahPengerjaanSP,
          keterangan: keteranganLaporanSP,
          target: validateTarget(),
          dateSubmit: noFormat,
          operator: dataUser.name,
          isKepala: false,
          isAdmin: false,
          isWarehouse: false,
          bagian: validateRole(),
          dataReject: dataReject,
          downtime: Number(downtime),
          month: `${month + 1} ${year}`,
          type: "laporan",
          barangPolos: {
            id: secondFindFromInventory.id,
            produk: secondFindFromInventory.produk,
            color: secondFindFromInventory.color,
          },
        },
        dataUser.auth,
        "",
        ""
      );

      setCustomerSP("Pilih Customer");
      setMesinSP("Pilih Mesin");
      setWarnaPrinting("Pilih Warna");
      setProdukLaporanSP("Pilih Produk");
      setIdBarangPolos("Pilih Produk");
      setJenisPekerjaan("Pilih Jenis Pekerjaan");
      setKeteranganLaporanSP("");
      setJumlahOkLaporanSP("");
      setJumlahPengerjaanSP("");
      setRepairLaporanSP("");
      setRejectLaporanSP("");
      setDowntime("");
      setDataProdukPick();
      setDataReject([]);
      setDateReject(today);
      setErrMsgLaporanSP(false);
      setIsSubmit(true);
    } else {
      setErrMsgLaporanSP(true);
    }
  };

  let firstSortProdukPolos =
    dataBarangPolos && dataBarangPolos.find((o) => o.company === customerSP);

  const handleKirimBarangMasuk = () => {
    if (
      produkMasukSP !== "Pilih Produk" &&
      jumlahMasukSP !== "" &&
      jumlahMasukSP !== "0"
    ) {
      let firtSort =
        dataBarangSP && dataBarangSP.find((o) => o.company === customerSP);
      let secondSort =
        firtSort && firtSort.data.find((o) => o.namaBarang === produkMasukSP);

      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/secondProcess`,
        {
          idBarang: v4Id,
          tim: dataUser.tim,
          customer: customerSP,
          type: "addBarangProduksi",
          total: Number(jumlahMasukSP),
          creator: dataUser.name,
          data: secondSort,
          dateMasuk: today,
        },
        dataUser.auth,
        "",
        ""
      );
      setProdukMasukSP("Pilih Produk");
      setJumlahMasukSP("");
      setCustomerSP("Pilih Customer");
    }
  };

  return {
    dataUser,
    produkMasukSP,
    setProdukMasukSP,
    jumlahMasukSP,
    setJumlahMasukSP,
    listProduk,
    customerSP,
    handlePickCustomer,
    listProdukPrinting,
    subMenuPrinting,
    setSubMenuPrinting,
    jenisPekerjaan,
    mesinSP,
    setMesinSP,
    warnaPrinting,
    setWarnaPrinting,
    dataProdukPick,
    produkLaporanSP,
    setProdukLaporanSP,
    setJenisPekerjaan,
    handleKirimLaporanSP,
    keteranganLaporanSP,
    setKeteranganLaporanSP,
    jumlahOkLaporanSP,
    setJumlahOkLaporanSP,
    rejectLaporanSP,
    setRejectLaporanSP,
    repairLaporanSP,
    setRepairLaporanSP,
    jumlahPengerjaanSP,
    setJumlahPengerjaanSP,
    dateLaporanSP,
    setDateLaporanSP,
    errMsgLaporanSP,
    isSubmit,
    setIsSubmit,
    addFields,
    removeFields,
    dataReject,
    addPopUpNewReject,
    setAddPopUpNewReject,
    bagianReject,
    setBagianReject,
    rejectDescProduksi,
    alasanRejectProduksi,
    setAlasanRejectProduksi,
    shiftProduksi,
    setShiftProduksi,
    handlePickBagian,
    jumlahReject,
    setJumlahReject,
    alasanRejectSP,
    setAlasanRejectSP,
    dateReject,
    setDateReject,
    downtime,
    setDowntime,
    dataBarangSP,
    handleKirimBarangMasuk,
    dataBarangPolos,
    firstSortProdukPolos,
    idBarangPolos,
    setIdBarangPolos,
    dataMerge,
    bulanRejectProduksi,
    setBulanRejectProduksi,
    tahunRejectProduksi,
    setTahunRejectProduksi,
  };
};
