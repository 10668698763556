import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import { getDateUtil, today } from "../../util/formatDate";

export const useProduksi = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [itemProduksi, setItemProduksi] = useState();
  const [itemPendukung, setItemPendukung] = useState();
  const [itemSatuan, setItemSatuan] = useState();
  const [shiftProduksi, setShiftProduksi] = useState("0");
  const [dateProduksi, setDateProduksi] = useState(today);
  const [popUpProduksi, setPopUpProduksi] = useState(false);
  const [subMenuProduksi, setSubMenuProduksi] = useState("BAKU");

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getMemoPpic`,
      { date: getDateUtil(today), jenis: "BAKU", tim: dataUser.tim },
      dataUser.auth,
      setItemProduksi,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getLaporanProduksi`,
      { date: getDateUtil(today), jenis: "SATUAN", tim: dataUser.tim },
      dataUser.auth,
      setItemSatuan,
      ""
    );
  }, []);

  useEffect(() => {
    if (subMenuProduksi === "PENDUKUNG") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getLaporanPendukung`,
        { date: getDateUtil(today), tim: dataUser.tim },
        dataUser.auth,
        setItemPendukung,
        ""
      );
    }
  }, [subMenuProduksi]);

  const handleFormChange = (index, event, jenis) => {
    if (jenis === "BAKU") {
      let data = [...itemProduksi];

      if (event.target.name === "catatan") {
        data[index][event.target.name] = event.target.value;
      } else {
        data[index][event.target.name] = Number(event.target.value);
      }
      setItemProduksi(itemProduksi);
    } else {
      let data = [...itemSatuan];

      data[index][event.target.name] = Number(event.target.value);
      setItemSatuan(data);
    }
  };

  const handleFormChangePendukung = (index, event, jenis) => {
    if (jenis === "PENDUKUNG") {
      let data = [...itemPendukung];

      data[index][event.target.name] = Number(event.target.value);
      setItemPendukung(data);
    } else {
      let data = [...itemSatuan];

      data[index][event.target.name] = Number(event.target.value);
      setItemSatuan(data);
    }
  };

  const handleKirim = () => {
    if (shiftProduksi !== "0" && dateProduksi !== "") {
      const reStructure = {
        shift: shiftProduksi,
        date: getDateUtil(dateProduksi),
        data: itemProduksi,
        bahan: "BAKU",
        tim: dataUser.tim,
      };
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/laporanProduksi`,
        { data: reStructure },
        dataUser.auth,
        "",
        ""
      );
      setShiftProduksi("0");
      setDateProduksi("");
      setPopUpProduksi(true);
    } else {
      let scrollDiv = document.getElementById("selectProduksi").offsetTop;
      window.scrollTo({ top: scrollDiv, behavior: "smooth" });
    }
  };

  const handleKirimPendukung = () => {
    if (shiftProduksi !== "0" && dateProduksi !== "") {
      const reStructure = {
        shift: shiftProduksi,
        date: getDateUtil(dateProduksi),
        data: itemPendukung,
        satuan: itemSatuan,
        bahan: "PENDUKUNG",
        tim: dataUser.tim,
      };

      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/laporanProduksi`,
        {
          data: reStructure,
        },
        dataUser.auth,
        "",
        ""
      );
      setShiftProduksi("0");
      setDateProduksi("");
      setPopUpProduksi(true);
    } else {
      let scrollDiv = document.getElementById("selectProduksi").offsetTop;
      window.scrollTo({ top: scrollDiv, behavior: "smooth" });
    }
  };

  return {
    handleFormChange,
    setDateProduksi,
    setShiftProduksi,
    handleKirim,
    shiftProduksi,
    dateProduksi,
    popUpProduksi,
    setPopUpProduksi,
    subMenuProduksi,
    setSubMenuProduksi,
    itemPendukung,
    handleKirimPendukung,
    handleFormChangePendukung,
    itemSatuan,
    itemProduksi,
  };
};
