import { useState, useEffect } from "react";
import { postAxios } from "../../util/apiCall";
import { getDateUtil, today } from "../../util/formatDate";
import moment from "moment";
import * as XLSX from "xlsx";

export const useP3 = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [shift, setShift] = useState("Pilih");
  const [dateP3, setDateP3] = useState(today);
  const [kepalaShift, setKepalaShift] = useState("Pilih");
  const [itemProduksi, setItemProduksi] = useState();
  const [isPopUp, setIsPopUp] = useState(false);
  const [subMenu, setSubMenu] = useState("Laporan");
  const [dateSubmit, setDateSubmit] = useState(today);
  const [dataP3, setDataP3] = useState();
  const [dataExport, setDataExport] = useState();
  const [popUpExport, setPopUpExport] = useState(false);
  const [bulan, setBulan] = useState("Pilih Bulan");
  const [tahun, setTahun] = useState(
    moment().utcOffset("+0700").format("YYYY")
  );

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getMemoPpic`,
      { date: getDateUtil(today), jenis: "BAKU", tim: dataUser.tim },
      dataUser.auth,
      setItemProduksi,
      ""
    );
  }, []);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getHistoryP3`,
      {
        date: getDateUtil(dateP3),
        shift: shift,
        type: "History",
        tim: dataUser.tim,
      },
      dataUser.auth,
      setDataP3,
      ""
    );
  }, [dateP3, shift]);

  const handleSubmitP3 = () => {
    if (shift !== "Pilih" && kepalaShift !== "Pilih") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/inputBahanP3`,
        {
          date: getDateUtil(dateSubmit),
          data: itemProduksi,
          shift: shift,
          kepalaShift: kepalaShift,
          user: dataUser.name,
          tim: dataUser.tim,
        },
        dataUser.auth,
        "",
        ""
      );
      setShift("Pilih");
      setIsPopUp(true);
    }
  };

  const handleFormChange = (index, event, jenis) => {
    let data = [...itemProduksi];

    if (event.target.name === "catatan") {
      data[index][event.target.name] = event.target.value;
    } else {
      data[index][event.target.name] = Number(event.target.value);
    }
    setItemProduksi(itemProduksi);
  };

  const handleExportP3 = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getHistoryP3`,
      {
        date: getDateUtil(dateP3),
        shift: shift,
        type: "Export",
        tim: dataUser.tim,
        month: bulan,
        year: tahun,
      },
      dataUser.auth,
      setDataExport,
      ""
    );
    setPopUpExport(false);
    setBulan("Pilih Bulan");
    setTahun(moment().utcOffset("+0700").format("YYYY"));

    // "creator": "DENI MARDANI",
    const filteredDataExcel =
      dataExport &&
      dataExport.map((obj) => {
        return {
          TANGGAL: obj.date,
          SHIFT: obj.shift,
          MESIN: obj.mesin,
          "JUMLAH BAHAN (Kg)": obj.jumlahBahan,
          KEPALA: obj.kepalaShift,
          CREATOR: obj.creator,
          Keterangan: obj.catatan,
        };
      });

    const workSheet = XLSX.utils.json_to_sheet(
      filteredDataExcel && filteredDataExcel
    );

    workSheet["!cols"] = [
      { wch: 15 },
      { wch: 6 },
      { wch: 6 },
      { wch: 18 },
      { wch: 10 },
      { wch: 23 },
      { wch: 60 },
    ];
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "P3");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, `Laporan P3 ${bulan} ${tahun}.xlsx`);

    setPopUpExport(false);
    setBulan("Pilih Bulan");
    setTahun(moment().utcOffset("+0700").format("YYYY"));
  };

  return {
    shift,
    setShift,
    handleSubmitP3,
    kepalaShift,
    setKepalaShift,
    itemProduksi,
    handleFormChange,
    isPopUp,
    setIsPopUp,
    subMenu,
    setSubMenu,
    dataUser,
    setDateP3,
    dateP3,
    dataP3,
    dateSubmit,
    setDateSubmit,
    handleExportP3,
    popUpExport,
    setPopUpExport,
    bulan,
    setBulan,
    tahun,
    setTahun,
  };
};
