import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import { getDateUtil, today, noFormat } from "../../util/formatDate";

export const useApprovalBarangJadi = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [subMenu, setSubMenu] = useState("Produksi");
  const [popUpApprove, setPopUpApprove] = useState(false);
  const [shiftApprovalBarangJadi, setShiftApprovalBarangJadi] =
    useState("Pilih Shift");
  const [dateApprovalBarangJadi, setDateApprovalBarangJadi] = useState(today);
  const [confirmationJumlahBarang, setConfirmationJumlahBarang] = useState();
  const [dataPickConfirmation, setDataPickConfirmation] = useState();
  const [isRefresh, setIsRefresh] = useState(false);
  const [dataApprovalBarangJadi, setDataApprovalBarangJadi] = useState();
  const [dataHistoryBarangJadi, setDataHistoryBarangJadi] = useState();

  const [dataSP, setDataSP] = useState();
  const [popUpDataSP, setPopUpDataSP] = useState(false);
  const [dataPickSP, setDataPickSP] = useState();
  const [historySP, setHistorySP] = useState();
  const [confirmJumlahBarangSP, setConfirmJumlahBarangSP] = useState();

  useEffect(() => {
    if (
      dataUser.role === "warehouse_admin" ||
      dataUser.role === "warehouse_user" ||
      dataUser.role === "super_user"
    ) {
      setSubMenu("Produksi");
    } else {
      setSubMenu("HistoryProduksi");
    }
  }, []);

  useEffect(() => {
    if (subMenu === "Produksi") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/approvalBarangJadi`,
        {
          tim: dataUser.tim,
          date: getDateUtil(dateApprovalBarangJadi),
          shift: shiftApprovalBarangJadi,
          type: "getProduksi",
          bagian: "WH",
        },
        dataUser.auth,
        setDataApprovalBarangJadi,
        ""
      );
    } else if (subMenu === "HistoryProduksi") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/approvalBarangJadi`,
        {
          tim: dataUser.tim,
          date: getDateUtil(dateApprovalBarangJadi),
          shift: shiftApprovalBarangJadi,
          type: "historyProduksi",
          bagian: "WH",
        },
        dataUser.auth,
        setDataHistoryBarangJadi,
        ""
      );
    }
    setIsRefresh(false);
  }, [shiftApprovalBarangJadi, dateApprovalBarangJadi, isRefresh]);

  useEffect(() => {
    if (subMenu === "SP") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/approvalBarangJadi`,
        {
          tim: dataUser.tim,
          date: dateApprovalBarangJadi,
          type: "getSP",
        },
        dataUser.auth,
        setDataSP,
        ""
      );
    }
    setIsRefresh(false);
  }, [dateApprovalBarangJadi, isRefresh, subMenu]);

  useEffect(() => {
    if (subMenu === "HistorySP") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/approvalBarangJadi`,
        {
          tim: dataUser.tim,
          date: dateApprovalBarangJadi,
          type: "historySP",
        },
        dataUser.auth,
        setHistorySP,
        ""
      );
    }
    setIsRefresh(false);
  }, [dateApprovalBarangJadi, isRefresh]);

  const handlePickData = (data, jumlahBarang) => {
    setConfirmationJumlahBarang(jumlahBarang);
    setDataPickConfirmation(data);
    setPopUpApprove(true);
  };

  const handleCancel = () => {
    setPopUpApprove(false);
    setDataPickConfirmation();
    setConfirmationJumlahBarang();
  };

  const handleKirimConfirmation = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/approvalBarangJadi`,
      {
        data: dataPickConfirmation,
        newValueJumlahBarang: confirmationJumlahBarang,
        approverBarangJadi: dataUser.name,
        dateApprove: noFormat,
        type: "confirmProduksi",
      },
      dataUser.auth,
      "",
      ""
    );
    setIsRefresh(true);
    setPopUpApprove(false);
  };

  const newList =
    dataSP &&
    dataSP.reduce((obj, item) => {
      let find = obj.find((i) => i.produk === item.produk);
      let _d = {
        ...item,
      };
      if (find) {
        find.jumlahOk += item.jumlahOk;
        find.reject += item.reject;
        find.repair += item.repair;
      } else {
        obj.push(_d);
      }
      return obj;
    }, []);

  const handlePickApproveSP = (data) => {
    setPopUpDataSP(true);
    setDataPickSP(data);
    setConfirmJumlahBarangSP(data.jumlahOk);
  };

  const handleCancelSP = () => {
    setPopUpDataSP(false);
    setDataPickSP();
    setConfirmJumlahBarangSP();
  };

  const handleKirimDataSP = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/approvalBarangJadi`,
      {
        data: dataPickSP,
        newValueJumlahBarang: Number(confirmJumlahBarangSP),
        approverBarangJadi: dataUser.name,
        dateApprove: noFormat,
        type: "confirmSP",
      },
      dataUser.auth,
      "",
      ""
    );
    setIsRefresh(true);
    setPopUpDataSP(false);
  };

  return {
    dataUser,
    subMenu,
    setSubMenu,
    popUpApprove,
    setPopUpApprove,
    dateApprovalBarangJadi,
    setDateApprovalBarangJadi,
    shiftApprovalBarangJadi,
    setShiftApprovalBarangJadi,
    confirmationJumlahBarang,
    setConfirmationJumlahBarang,
    dataApprovalBarangJadi,
    handlePickData,
    handleCancel,
    handleKirimConfirmation,
    dataHistoryBarangJadi,
    dataPickConfirmation,
    newList,
    popUpDataSP,
    handlePickApproveSP,
    handleKirimDataSP,
    handleCancelSP,
    confirmJumlahBarangSP,
    setConfirmJumlahBarangSP,
    dataPickSP,
    historySP,
  };
};
