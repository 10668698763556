import React from "react";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import { useRequestStockIn } from "./useRequestStockIn";
import "./RequestStockIn.css";
const RequestStockIn = () => {
  const {
    companyRequest,
    setCompanyRequest,
    setMaterialBahan,
    setMaterialName,
    setTypeMaterial,
    handleSubmitRequest,
    dataBahan,
    popUpRequest,
    materialBahan,
    jumlahRequest,
    setJumlahRequest,
    setKeteranganRequestStockIn,
    dataListBahan,
    handleKirimRequestIn,
    materialName,
    popUpErrorRequest,
    materialColor,
    typeMaterial,
    setMaterialColor,
    keteranganRequestStockIn,
  } = useRequestStockIn();

  return (
    <div className="container_request_stock_in">
      {popUpRequest ? (
        <PopUpComponent>
          <p>Terima Kasih! Anda Telah Membuat Request Stock Masuk Barang.</p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup"
              onClick={() => handleSubmitRequest("OK")}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {popUpErrorRequest ? (
        <PopUpComponent>
          <p>Mohon pastikan semua kolom yang Berbintang Merah Terisi !</p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup"
              onClick={() => handleSubmitRequest("OK")}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <p className="title_inventory">REQUEST STOK MASUK</p>
      <div className="flex_wrap_request">
        <div className="wrapper_input">
          <p className="font_weight_bold">
            Perusahaan<label style={{ color: "red" }}>*</label>
          </p>
          <select
            onChange={(e) => setCompanyRequest(e.target.value)}
            className="input_select"
            defaultValue="Pilih Nama Perusahaan"
          >
            <option selected={companyRequest === ""} disabled>
              Pilih Nama Perusahaan
            </option>
            {dataListBahan &&
              dataListBahan.map((data, i) => (
                <option
                  value={data.company}
                  key={i}
                  selected={companyRequest === data.company}
                >
                  {data.company}
                </option>
              ))}
          </select>
        </div>
        <div className="wrapper_input">
          <p className="font_weight_bold">
            Jenis Bahan<label style={{ color: "red" }}>*</label>
          </p>
          <div
            onClick={() => setMaterialBahan("BAKU")}
            className="wrapper_radio"
          >
            <input
              type="radio"
              value="BAKU"
              checked={materialBahan === "BAKU"}
            />
            <label style={{ fontSize: "14px" }}>BAHAN BAKU</label>
          </div>
          <div
            onClick={() => setMaterialBahan("PENDUKUNG")}
            className="wrapper_radio"
          >
            <input
              type="radio"
              value="PENDUKUNG"
              checked={materialBahan === "PENDUKUNG"}
            />
            <label style={{ fontSize: "14px" }}>BAHAN PENDUKUNG</label>
          </div>
        </div>
        <div className="wrapper_input">
          <p className="font_weight_bold">
            Nama Bahan<label style={{ color: "red" }}>*</label>
          </p>
          <select
            className="input_select"
            onChange={(e) => setMaterialName(e.target.value)}
            defaultValue="Pilih Nama Bahan"
          >
            <option selected={materialName === ""} disabled>
              Pilih Nama Bahan
            </option>
            {dataBahan &&
              dataBahan.map((data, i) => (
                <option
                  value={data.material}
                  key={i}
                  selected={materialName === data.material}
                >
                  {data.material}
                </option>
              ))}
          </select>
        </div>
        <div className="wrapper_input">
          <p className="font_weight_bold">
            Tipe Bahan<label style={{ color: "red" }}>*</label>
          </p>
          <select
            className="input_select"
            onChange={(e) => setTypeMaterial(e.target.value)}
            defaultValue="Pilih Tipe Bahan"
          >
            <option selected={typeMaterial === ""} disabled>
              Pilih Tipe Bahan
            </option>
            {dataBahan &&
              dataBahan.map((data, i) => (
                <option
                  value={data.type}
                  key={i}
                  selected={typeMaterial === data.type}
                >
                  {data.type}
                </option>
              ))}
          </select>
        </div>
        <div className="wrapper_input">
          <p className="font_weight_bold">
            Warna Bahan<label style={{ color: "red" }}>*</label>
          </p>
          <select
            className="input_select"
            onChange={(e) => setMaterialColor(e.target.value)}
            defaultValue="Pilih Warna Bahan"
          >
            <option selected={materialColor === ""} disabled>
              Pilih Warna Bahan
            </option>
            {dataBahan &&
              dataBahan.map((data, i) => (
                <option
                  value={data.color}
                  key={i}
                  selected={materialColor === data.color}
                >
                  {data.color}
                </option>
              ))}
          </select>
        </div>
        <div className="wrapper_input">
          <p className="font_weight_bold">
            Jumlah<label style={{ color: "red" }}>*</label>
          </p>
          <input
            type="number"
            className="input_jumlah"
            placeholder="Masukkan Jumlah"
            value={jumlahRequest}
            onChange={(e) => setJumlahRequest(e.target.value)}
            onWheel={(e) => e.target.blur()}
          />
        </div>
        <div style={{ display: "flex" }} className="column_mobile">
          <div className="wrapper_input">
            <p className="font_weight_bold">Keterangan</p>
            <textarea
              className="text_area"
              placeholder="Masukkan Keterangan Jika Diperlukan"
              onChange={(e) => setKeteranganRequestStockIn(e.target.value)}
              value={keteranganRequestStockIn}
            />
          </div>
          <div
            className="wrapper_button_kirim_request_in"
            onClick={() => handleKirimRequestIn()}
          >
            <div className="button_kirim_request_in">Kirim</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RequestStockIn;
