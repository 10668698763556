import React from "react";
import "./CheckSheet.css";
import { useCheckSheet } from "./useCheckSheet";
import { jumlahHari, bulan } from "../../util/constant";

const CheckSheet = () => {
  const {
    dataMerge,
    newList,
    bulanCheckSheet,
    setBulanCheckSheet,
    tahunCheckSheet,
    setTahunCheckSheet,
    produkCheckSheet,
    setProdukCheckSheet,
    customerCheckSheet,
    setCustomerCheckSheet,
    listProduk,
    dataProdukPick,
  } = useCheckSheet();

  const itemsTotalNG = [
    { date: 1, reject: 0 },
    { date: 2, reject: 0 },
    { date: 3, reject: 0 },
    { date: 4, reject: 0 },
    { date: 5, reject: 0 },
    { date: 6, reject: 0 },
    { date: 7, reject: 0 },
    { date: 8, reject: 0 },
    { date: 9, reject: 0 },
    { date: 10, reject: 0 },
    { date: 11, reject: 0 },
    { date: 12, reject: 0 },
    { date: 13, reject: 0 },
    { date: 14, reject: 0 },
    { date: 15, reject: 0 },
    { date: 16, reject: 0 },
    { date: 17, reject: 0 },
    { date: 18, reject: 0 },
    { date: 19, reject: 0 },
    { date: 20, reject: 0 },
    { date: 21, reject: 0 },
    { date: 22, reject: 0 },
    { date: 23, reject: 0 },
    { date: 24, reject: 0 },
    { date: 25, reject: 0 },
    { date: 26, reject: 0 },
    { date: 27, reject: 0 },
    { date: 28, reject: 0 },
    { date: 29, reject: 0 },
    { date: 30, reject: 0 },
    { date: 31, reject: 0 },
  ];

  newList &&
    newList.map((e) => {
      itemsTotalNG.forEach((element, index) => {
        if (element.date === e.date) {
          itemsTotalNG[index].reject = e.reject;
          itemsTotalNG[index].totalPengerjaan = e.totalPengerjaan;
          itemsTotalNG[index].downtime = e.downtime;
        }
      });
    });

  return (
    <div className="container_dashboard">
      <p className="title_inventory" style={{ marginTop: "24px" }}>
        Check Sheet - {produkCheckSheet}
      </p>
      <div style={{ display: "flex" }}>
        <div className="margin_right_27_qc">
          <p>Bulan</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <select
              defaultValue="Bulan"
              onChange={(e) => setBulanCheckSheet(e.target.value)}
              className="input_select_memo_ppic"
              style={{ width: "100%" }}
              value={bulanCheckSheet}
            >
              <option selected disabled>
                Bulan
              </option>
              {bulan.map((ele) => (
                <option value={ele.value}>{ele.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="margin_right_27_qc">
          <p>Tahun</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <select
              defaultValue="Tahun"
              onChange={(e) => setTahunCheckSheet(e.target.value)}
              className="input_select_memo_ppic"
              style={{ width: "100%" }}
              value={tahunCheckSheet}
            >
              <option selected disabled>
                Tahun
              </option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </select>
          </div>
        </div>
        <div className="margin_right_27_qc">
          <p>Cust</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <select
              defaultValue="Customer"
              onChange={(e) => setCustomerCheckSheet(e.target.value)}
              className="input_select_memo_ppic"
              style={{ width: "100%" }}
              value={customerCheckSheet}
            >
              <option selected disabled>
                Customer
              </option>
              {listProduk &&
                listProduk.map((ele) => (
                  <option value={ele.customer}>{ele.customer}</option>
                ))}
            </select>
          </div>
        </div>
        <div className="margin_right_27_qc">
          <p>Produk</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <select
              defaultValue="Produk"
              onChange={(e) => setProdukCheckSheet(e.target.value)}
              className="input_select_memo_ppic"
              style={{ width: "100%" }}
              value={produkCheckSheet}
            >
              <option selected disabled>
                Produk
              </option>
              {dataProdukPick &&
                dataProdukPick.map((ele) => (
                  <option value={ele.produk}>{ele.produk}</option>
                ))}
            </select>
          </div>
        </div>
      </div>
      <br />
      <div
        className="table_inventory_mobile"
        style={{ position: "absolute", zIndex: "-1" }}
      >
        <table style={{ width: "100%", paddingRight: "16px" }}>
          <tr>
            <th
              className="sidebar table_header sticky_column_number"
              style={{
                background: "#e8e9f8",
              }}
            >
              No.
            </th>
            <th
              className="sidebar table_header sticky_column_problem"
              style={{
                background: "#e8e9f8",
                borderRight: "1px solid lightgray",
              }}
            >
              Problem
            </th>
            {jumlahHari.map((ele) => (
              <th style={{ position: "inherit" }} className="table_header">
                {ele}
              </th>
            ))}
          </tr>
          {dataMerge &&
            dataMerge.map((e, index) => {
              const newList =
                e.data &&
                e.data.reduce((obj, item) => {
                  let find = obj.find((i) => i.date === item.date);
                  let _d = {
                    ...item,
                  };
                  find ? (find.reject += item.reject) : obj.push(_d);
                  return obj;
                }, []);

              const items = [
                { date: 1, reject: 0 },
                { date: 2, reject: 0 },
                { date: 3, reject: 0 },
                { date: 4, reject: 0 },
                { date: 5, reject: 0 },
                { date: 6, reject: 0 },
                { date: 7, reject: 0 },
                { date: 8, reject: 0 },
                { date: 9, reject: 0 },
                { date: 10, reject: 0 },
                { date: 11, reject: 0 },
                { date: 12, reject: 0 },
                { date: 13, reject: 0 },
                { date: 14, reject: 0 },
                { date: 15, reject: 0 },
                { date: 16, reject: 0 },
                { date: 17, reject: 0 },
                { date: 18, reject: 0 },
                { date: 19, reject: 0 },
                { date: 20, reject: 0 },
                { date: 21, reject: 0 },
                { date: 22, reject: 0 },
                { date: 23, reject: 0 },
                { date: 24, reject: 0 },
                { date: 25, reject: 0 },
                { date: 26, reject: 0 },
                { date: 27, reject: 0 },
                { date: 28, reject: 0 },
                { date: 29, reject: 0 },
                { date: 30, reject: 0 },
                { date: 31, reject: 0 },
              ];
              items.forEach((element, index) => {
                newList &&
                  newList.forEach((obj) => {
                    if (element.date === obj.date) {
                      items[index].reject = obj.reject;
                    }
                  });
              });
              return (
                <tr
                  style={
                    index % 2 === 0
                      ? {
                          background: "white",
                        }
                      : {
                          background: "#f9f9f9",
                        }
                  }
                >
                  <td
                    className="sidebar table_header sticky_column_number"
                    style={
                      index % 2 === 0
                        ? {
                            background: "white",
                            padding: "12px",
                            color: "black",
                          }
                        : {
                            background: "#f9f9f9",
                            padding: "12px",
                            color: "black",
                          }
                    }
                  >
                    {index + 1}
                  </td>
                  <td
                    className="sidebar table_header sticky_column_problem"
                    style={
                      index % 2 === 0
                        ? {
                            background: "white",
                            borderRight: "1px solid lightgray",
                            padding: "12px",
                            color: "black",
                          }
                        : {
                            background: "#f9f9f9",
                            borderRight: "1px solid lightgray",
                            padding: "12px",
                            color: "black",
                          }
                    }
                  >
                    {e.id}
                  </td>
                  {items.map((tanggal) => {
                    return (
                      <td style={{ padding: "12px" }}>
                        {tanggal.reject === 0 ? "" : tanggal.reject}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          <tr>
            <td
              className="sidebar table_header sticky_column_number"
              style={{
                border: "1px solid black",
                borderRight: "none",
                padding: "12px",
                borderBottom: "none",
                background: "#f6f6fc",
              }}
            ></td>
            <td
              className="sidebar table_header sticky_column_problem"
              style={{
                textAlign: "left",
                border: "1px solid black",
                borderLeft: "none",
                borderBottom: "none",
                padding: "12px",
                background: "#f6f6fc",
              }}
            >
              Total NG
            </td>
            {itemsTotalNG &&
              itemsTotalNG.map((e) => {
                return (
                  <>
                    <td
                      className="border_right_cs"
                      style={{
                        borderTop: "1px solid black",
                        padding: "12px",
                      }}
                    >
                      {e.reject === 0 ? "" : e.reject}
                    </td>
                  </>
                );
              })}
          </tr>
          <tr>
            <td
              className="sidebar table_header sticky_column_number"
              style={{
                border: "1px solid black",
                borderRight: "none",
                borderBottom: "none",
                background: "#f6f6fc",
              }}
            ></td>
            <td
              className="sidebar table_header sticky_column_problem"
              style={{
                textAlign: "left",
                border: "1px solid black",
                borderLeft: "none",
                borderBottom: "none",
                padding: "12px",
                background: "#f6f6fc",
              }}
            >
              Total Produksi
            </td>
            {itemsTotalNG &&
              itemsTotalNG.map((e) => {
                return (
                  <>
                    <td
                      className="border_right_cs"
                      style={{
                        borderTop: "1px solid black",
                        padding: "12px",
                      }}
                    >
                      {!e.totalPengerjaan ? "" : e.totalPengerjaan}
                    </td>
                  </>
                );
              })}
          </tr>
          <tr>
            <td
              className="sidebar table_header sticky_column_number"
              style={{
                border: "1px solid black",
                borderBottom: "none",
                borderRight: "none",
                background: "#f6f6fc",
              }}
            ></td>
            <td
              className="sidebar table_header sticky_column_problem"
              style={{
                textAlign: "left",
                border: "1px solid black",
                borderLeft: "none",
                borderBottom: "none",
                padding: "12px",
                background: "#f6f6fc",
              }}
            >
              Presentase (%)
            </td>

            {itemsTotalNG &&
              itemsTotalNG.map((e) => {
                const totalProduksi = e.totalPengerjaan ? e.totalPengerjaan : 0;
                const formula = e.reject / totalProduksi;

                return (
                  <>
                    <td
                      className="border_right_cs"
                      style={{
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                        borderBottom: "none",
                        padding: "12px",
                      }}
                    >
                      {formula ? formula.toFixed(2) : ""}
                    </td>
                  </>
                );
              })}
          </tr>
          <tr>
            <td
              className="sidebar table_header sticky_column_number"
              style={{
                border: "1px solid black",
                borderRight: "none",
                background: "#f6f6fc",
              }}
            ></td>
            <td
              className="sidebar table_header sticky_column_problem"
              style={{
                textAlign: "left",
                border: "1px solid black",
                borderLeft: "none",
                padding: "12px",
                background: "#f6f6fc",
              }}
            >
              Downtime (Menit)
            </td>

            {itemsTotalNG &&
              itemsTotalNG.map((e) => {
                const totalProduksi = e.totalPengerjaan ? e.totalPengerjaan : 0;
                const formula = e.reject / totalProduksi;

                return (
                  <>
                    <td
                      className="border_right_cs"
                      style={{
                        borderTop: "1px solid black",
                        borderBottom: "1px solid black",
                        padding: "12px",
                      }}
                    >
                      {e.downtime}
                    </td>
                  </>
                );
              })}
          </tr>
        </table>
      </div>
    </div>
  );
};
export default CheckSheet;
