import React from "react";
import { useApprovalBarangJadi } from "./useApprovalBarangJadi";
import ApproveLogo from "../../Assets/approve-icon.png";
import { changeFormatDate, getTime } from "../../util/formatDate";
import PopUpComponent from "../../components/PopUp/PopUpComponent";

const ApprovalBarangJadi = () => {
  const {
    dataUser,
    subMenu,
    setSubMenu,
    popUpApprove,
    setPopUpApprove,
    dateApprovalBarangJadi,
    setDateApprovalBarangJadi,
    shiftApprovalBarangJadi,
    setShiftApprovalBarangJadi,
    confirmationJumlahBarang,
    setConfirmationJumlahBarang,
    dataApprovalBarangJadi,
    handlePickData,
    handleCancel,
    handleKirimConfirmation,
    dataHistoryBarangJadi,
    dataPickConfirmation,
    newList,
    popUpDataSP,
    handlePickApproveSP,
    handleKirimDataSP,
    handleCancelSP,
    dataPickSP,
    confirmJumlahBarangSP,
    setConfirmJumlahBarangSP,
    historySP,
  } = useApprovalBarangJadi();

  const sortData =
    dataApprovalBarangJadi &&
    dataApprovalBarangJadi.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  const sortDataHistory =
    dataHistoryBarangJadi &&
    dataHistoryBarangJadi.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  const sortDataSP =
    newList &&
    newList.sort(function (a, b) {
      if (a.bagian < b.bagian) {
        return -1;
      }
      if (a.bagian > b.bagian) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="container_dashboard">
      <p className="title_inventory">Approval Barang Jadi</p>
      <div className="wrapper_sub_menu_history" style={{ margin: 0 }}>
        {dataUser.role === "warehouse_admin" ||
        dataUser.role === "warehouse_user" ||
        dataUser.role === "super_user" ? (
          <>
            <p
              className={
                subMenu === "Produksi"
                  ? "sub_menu_history border_active_history"
                  : "sub_menu_history"
              }
              onClick={() => setSubMenu("Produksi")}
            >
              Produksi
            </p>
            <p
              className={
                subMenu === "SP"
                  ? "sub_menu_history border_active_history"
                  : "sub_menu_history"
              }
              onClick={() => setSubMenu("SP")}
            >
              SP
            </p>
          </>
        ) : (
          ""
        )}
        <p
          className={
            subMenu === "HistoryProduksi"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenu("HistoryProduksi")}
        >
          History Produksi
        </p>
        <p
          className={
            subMenu === "HistorySP"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenu("HistorySP")}
        >
          History SP
        </p>
      </div>
      <div style={{ display: "flex" }}>
        {subMenu === "Produksi" || subMenu === "HistoryProduksi" ? (
          <div className="margin_right_27_qc">
            <p>Pilih Shift</p>
            <select
              className="input_select_shift"
              value={shiftApprovalBarangJadi}
              onChange={(e) => setShiftApprovalBarangJadi(e.target.value)}
            >
              <option
                selected={shiftApprovalBarangJadi === "Pilih Shift"}
                disabled
              >
                Pilih Shift
              </option>
              <option value="1">Shift 1</option>
              <option value="2">Shift 2</option>
              <option value="3">Shift 3</option>
            </select>
          </div>
        ) : (
          ""
        )}
        <div className="margin_right_27_qc">
          <p>Pilih Tanggal</p>
          <input
            type="date"
            className="date_pick_memo_ppic"
            value={dateApprovalBarangJadi}
            onChange={(e) => setDateApprovalBarangJadi(e.target.value)}
          />
        </div>
      </div>
      {popUpDataSP ? (
        <PopUpComponent>
          <div>
            <p>
              <b>{dataPickSP.produk}</b>
            </p>
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Jumlah Barang (Pcs)
                <label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="number"
                className="input_jumlah"
                placeholder="Konfirmasi Jumlah Barang(Pcs)"
                value={confirmJumlahBarangSP}
                onChange={(e) => setConfirmJumlahBarangSP(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </div>
          <div className="wrapper_button_submit_popup">
            <button className="button_popup" onClick={() => handleCancelSP()}>
              Cancel
            </button>
            <button
              className="button_popup background_red"
              onClick={() => handleKirimDataSP()}
            >
              Kirim
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {popUpApprove ? (
        <PopUpComponent>
          <div>
            <p>
              <b>Mesin {dataPickConfirmation.mesin}</b>
            </p>
            <p>
              <b>{dataPickConfirmation.moldType}</b>
            </p>
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Jumlah Barang (Pcs)
                <label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="number"
                className="input_jumlah"
                placeholder="Konfirmasi Jumlah Barang(Pcs)"
                value={confirmationJumlahBarang}
                onChange={(e) => setConfirmationJumlahBarang(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </div>
          <div className="wrapper_button_submit_popup">
            <button className="button_popup" onClick={() => handleCancel()}>
              Cancel
            </button>
            <button
              className="button_popup background_red"
              onClick={() => handleKirimConfirmation()}
            >
              Kirim
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {subMenu === "Produksi" ? (
        <div style={{ display: "flex", flexFlow: "wrap" }}>
          {sortData &&
            sortData.map((data) => {
              const isiPerKemasan = data.isiPerKemasan ? data.isiPerKemasan : 0;
              const sisaShiftSebelumnya = data.sisaShiftSebelumnya
                ? data.sisaShiftSebelumnya
                : 0;
              const jumlahBarangOK = data.jumlahBarang ? data.jumlahBarang : 0;
              const formula =
                (jumlahBarangOK + sisaShiftSebelumnya) / isiPerKemasan;
              return (
                <div className="wrapper_card_approval_laporan">
                  <div className="flex_justify_between">
                    <div style={{ width: "40%" }}>
                      <p>
                        <b>Mesin {data.mesin}</b>
                      </p>
                    </div>
                    <div style={{ width: "50%" }}>
                      <p className="font_bold">Isi PerKemasan</p>
                      <p className="font_14">{isiPerKemasan}&nbsp;Pcs</p>
                    </div>
                  </div>
                  <div className="flex_justify_between">
                    <p className="font_bold" style={{ marginTop: "0px" }}>
                      {data.moldType} - {data.color}
                    </p>
                  </div>
                  <div className="flex_justify_between">
                    <div style={{ width: "40%" }}>
                      <p className="font_bold" style={{ marginTop: "0px" }}>
                        Receh
                      </p>
                      <div>
                        <p style={{ fontSize: "16px" }}>
                          <b>
                            {jumlahBarangOK +
                              sisaShiftSebelumnya -
                              Math.floor(formula) * data.isiPerKemasan}
                          </b>
                          &nbsp;Pcs
                        </p>
                      </div>
                    </div>
                    <div style={{ width: "50%" }}>
                      <p className="font_bold" style={{ marginTop: "0px" }}>
                        Jumlah Barang (Dikali Kemasan)
                      </p>
                      <p className="font_14">
                        <b>{Math.floor(formula) * data.isiPerKemasan}</b>
                        &nbsp;Pcs
                      </p>
                    </div>
                  </div>
                  <div className="flex_justify_between">
                    <div style={{ width: "50%" }}>
                      <p className="font_bold" style={{ marginTop: "0px" }}>
                        Jumlah OK (BOX/KTG/PALET)
                      </p>
                      <div>
                        <p style={{ fontSize: "16px", marginBottom: "0px" }}>
                          <b>{Math.floor(formula)}</b>
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <img
                          className="approval_image"
                          src={ApproveLogo}
                          alt="approve"
                          onClick={() =>
                            handlePickData(
                              data,
                              Math.floor(formula) * data.isiPerKemasan
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      ) : subMenu === "SP" ? (
        <div style={{ display: "flex", flexFlow: "wrap" }}>
          {sortDataSP &&
            sortDataSP.map((data) => {
              const formatDot = (value) => {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              };
              return (
                <div className="wrapper_card_approval_laporan">
                  <div className="flex_justify_between">
                    <p className="font_bold">{data.produk}</p>
                  </div>
                  <p className="font_bold" style={{ marginTop: 0 }}>
                    {data.bagian === "SP" ? "FINISHING" : "PRINTING"}
                  </p>
                  <div className="flex_justify_between">
                    <p className="font_bold" style={{ marginTop: "0px" }}>
                      Jumlah OK (Pcs) :
                    </p>
                    <p style={{ fontSize: "17px", margin: "0px" }}>
                      <b>{formatDot(data.jumlahOk)}</b> Pcs
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginTop: "0px",
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <img
                        className="approval_image"
                        src={ApproveLogo}
                        alt="approve"
                        onClick={() => handlePickApproveSP(data)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      ) : subMenu === "HistorySP" ? (
        <div style={{ display: "flex", flexFlow: "wrap" }}>
          {historySP &&
            historySP.map((data) => {
              return (
                <div className="wrapper_card_approval_laporan">
                  <div className="flex_justify_between">
                    <p>
                      <b>{data.customer}</b>
                    </p>
                    <p>
                      <b>{data.produk}</b>
                    </p>
                  </div>
                  <br />
                  <div className="flex_justify_between">
                    <div>
                      <p className="font_bold" style={{ marginTop: "0px" }}>
                        Total
                      </p>
                      <div>
                        <p style={{ fontSize: "16px", marginBottom: "0px" }}>
                          <b>{data.total}</b>
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="font_bold" style={{ marginTop: "0px" }}>
                        Bagian
                      </p>
                      <p className="font_14">
                        <b>{data.bagian}</b>
                      </p>
                    </div>
                  </div>
                  <div className="flex_justify_between">
                    <p style={{ marginBottom: "0px" }}>
                      Approver:&nbsp;<b>{data.approverBarangJadi}</b>
                    </p>
                  </div>
                  <div className="flex_justify_between">
                    <p style={{ marginBottom: "0px" }}>
                      {changeFormatDate(data.dateApprove)}&nbsp;-&nbsp;
                      {getTime(data.dateApprove)}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <div style={{ display: "flex", flexFlow: "wrap" }}>
          {sortDataHistory &&
            sortDataHistory.map((data) => {
              const isiPerKemasan = data.isiPerKemasan ? data.isiPerKemasan : 0;
              const sisaShiftSebelumnya = data.sisaShiftSebelumnya
                ? data.sisaShiftSebelumnya
                : 0;
              const jumlahBarangOK = data.newValueJumlahBarang
                ? data.newValueJumlahBarang
                : data.jumlahBarang;

              const formula =
                (jumlahBarangOK + sisaShiftSebelumnya) / isiPerKemasan;
              const formulaJumlahBarang = data.newValueJumlahBarang
                ? data.newValueJumlahBarang
                : Math.floor(formula) * data.isiPerKemasan;

              const jumlahBarangLaporan = data.jumlahBarang
                ? data.jumlahBarang
                : 0;
              const formulaLaporan =
                (jumlahBarangLaporan + sisaShiftSebelumnya) / isiPerKemasan;
              return (
                <div className="wrapper_card_approval_laporan">
                  <div className="flex_justify_between">
                    <div style={{ width: "40%" }}>
                      <p>
                        <b>Mesin {data.mesin}</b>
                      </p>
                    </div>
                    <div style={{ width: "50%" }}>
                      <p className="font_bold">Isi PerKemasan</p>
                      <p className="font_14">{isiPerKemasan}&nbsp;Pcs</p>
                    </div>
                  </div>
                  <div className="flex_justify_between">
                    <p className="font_bold" style={{ marginTop: "0px" }}>
                      {data.namaBarang}
                    </p>
                  </div>
                  <div className="flex_justify_between">
                    <div style={{ width: "40%" }}>
                      <p className="font_bold" style={{ marginTop: "0px" }}>
                        Jumlah OK (BOX/KTG/PALET)
                      </p>
                      <div>
                        <p style={{ fontSize: "16px", marginBottom: "0px" }}>
                          <b>{Math.floor(formula)}</b>
                        </p>
                      </div>
                    </div>
                    <div style={{ width: "50%" }}>
                      <p className="font_bold" style={{ marginTop: "0px" }}>
                        Jumlah Barang (Dikali Kemasan)
                      </p>
                      <p className="font_14">
                        <b>{formulaJumlahBarang}</b>
                        &nbsp;Pcs
                      </p>
                    </div>
                  </div>
                  <br />
                  <div className="flex_justify_between">
                    <div>
                      <p className="font_bold" style={{ marginTop: "0px" }}>
                        Selisih (Pcs)
                      </p>
                      <p className="font_14">
                        <b>
                          {formulaJumlahBarang -
                            Math.floor(formulaLaporan) * isiPerKemasan}
                        </b>
                        &nbsp;Pcs
                      </p>
                    </div>
                  </div>
                  <div className="flex_justify_between">
                    <p style={{ marginBottom: "0px" }}>
                      Approver:&nbsp;<b>{data.approverBarangJadi}</b>
                    </p>
                  </div>
                  <div className="flex_justify_between">
                    <p style={{ marginBottom: "0px" }}>
                      {changeFormatDate(data.dateApprove)}&nbsp;-&nbsp;
                      {getTime(data.dateApprove)}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};
export default ApprovalBarangJadi;
