import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import { getDateUtil, noFormat, today, yesterday } from "../../util/formatDate";

export const useApprovalLaporan = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [shiftApprovalLaporan, setShiftApprovalLaporan] =
    useState("Pilih Shift");
  const [dateApprovalLaporan, setDateApprovalLaporan] = useState(today);
  const [dataApprovalLaporan, setDataApprovalLaporan] = useState([]);
  const [popUpApprovalLaporan, setPopUpApprovalLaporan] = useState(false);
  const [listTopPlate, setListTopPlate] = useState();
  const [listPasangKaret, setListPasangKaret] = useState();

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getLaporanOperator`,
      {
        tim: dataUser.tim,
        date: getDateUtil(dateApprovalLaporan),
        shift: shiftApprovalLaporan,
      },
      dataUser.auth,
      setDataApprovalLaporan,
      ""
    );

    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getLaporanPasangKaret`,
      {
        tim: dataUser.tim,
        date: getDateUtil(dateApprovalLaporan),
        shift: shiftApprovalLaporan,
        isKepala: false,
        type: "approvalKepala",
      },
      dataUser.auth,
      setListPasangKaret,
      ""
    );
  }, [shiftApprovalLaporan, dateApprovalLaporan]);

  useEffect(() => {
    const interval = setInterval(() => {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getLaporanOperator`,
        {
          tim: dataUser.tim,
          date: getDateUtil(dateApprovalLaporan),
          shift: shiftApprovalLaporan,
        },
        dataUser.auth,
        setDataApprovalLaporan,
        ""
      );

      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getLaporanPasangKaret`,
        {
          tim: dataUser.tim,
          date: getDateUtil(dateApprovalLaporan),
          shift: shiftApprovalLaporan,
          isKepala: false,
          type: "approvalKepala",
        },
        dataUser.auth,
        setListTopPlate,
        ""
      );
    }, 60000);
    return () => clearInterval(interval);
  }, [shiftApprovalLaporan]);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getDataTopPlate`,
      { tim: dataUser.tim, type: "editData" },
      dataUser.auth,
      setListTopPlate,
      ""
    );
  }, []);

  const handleKirim = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/submitLaporanKepala`,
      {
        tim: dataUser.tim,
        approvedBy: dataUser.name,
        shift: shiftApprovalLaporan,
        date: getDateUtil(dateApprovalLaporan),
        dataTopPlate: listTopPlate,
        yesterday: getDateUtil(yesterday),
        dateApprove: noFormat,
      },
      dataUser.auth,
      "",
      ""
    );
    setPopUpApprovalLaporan(true);
  };

  return {
    shiftApprovalLaporan,
    setShiftApprovalLaporan,
    dataApprovalLaporan,
    handleKirim,
    popUpApprovalLaporan,
    setPopUpApprovalLaporan,
    setDateApprovalLaporan,
    dateApprovalLaporan,
    listTopPlate,
    listPasangKaret,
  };
};
