export const validateRole = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  if (dataUser.role === "pr_admin" || dataUser.role === "pr_user") {
    return "PR";
  } else if (dataUser.role === "sp_admin" || dataUser.role === "sp_user") {
    return "SP";
  } else if (dataUser.role === "super_user" || dataUser.role === "ppic_admin") {
    return "super";
  } else {
    return "";
  }
};
