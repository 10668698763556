import React from "react";
import TableProduksi from "../../components/TableProduksi/TableProduksi";
import "./Produksi.css";
import { useProduksi } from "./useProduksi";
import PopUpComponent from "../../components/PopUp/PopUpComponent";

const Produksi = () => {
  const {
    handleFormChange,
    setDateProduksi,
    setShiftProduksi,
    handleKirim,
    shiftProduksi,
    dateProduksi,
    popUpProduksi,
    setPopUpProduksi,
    subMenuProduksi,
    setSubMenuProduksi,
    itemPendukung,
    handleKirimPendukung,
    handleFormChangePendukung,
    itemSatuan,
    itemProduksi,
  } = useProduksi();

  const handleOK = () => {
    setPopUpProduksi(false);
  };

  return (
    <div className="container_p3" id="selectProduksi">
      {popUpProduksi ? (
        <PopUpComponent>
          <p>Selamat Data Kamu Telah Dikirim!</p>
          <div className="wrapper_button_submit_popup">
            <button className="button_popup background_red" onClick={handleOK}>
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <p className="title_inventory">Produksi</p>
      <div className="wrapper_sub_menu_history" style={{ margin: 0 }}>
        <p
          className={
            subMenuProduksi === "BAKU"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenuProduksi("BAKU")}
        >
          Baku
        </p>
        <p
          className={
            subMenuProduksi === "PENDUKUNG"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenuProduksi("PENDUKUNG")}
        >
          Pendukung
        </p>
      </div>
      <div style={{ display: "flex" }}>
        <div className="container_pilih_shift_produksi">
          <p style={{ display: "flex" }}>
            Pilih Shift&nbsp;
            <span
              className="red-star"
              style={{ color: "red", fontSize: "6px" }}
            >
              ★
            </span>
          </p>
          <select
            className="input_select_shift"
            onChange={(e) => setShiftProduksi(e.target.value)}
          >
            <option selected={shiftProduksi === "0"} disabled>
              Pilih Shift
            </option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>
        <div className="container_pilih_shift_produksi">
          <p style={{ display: "flex" }}>
            Pilih Tanggal&nbsp;
            <span
              className="red-star"
              style={{ color: "red", fontSize: "6px" }}
            >
              ★
            </span>
          </p>
          <input
            type="date"
            className="date_pick_memo_ppic"
            value={dateProduksi}
            onChange={(e) => setDateProduksi(e.target.value)}
          />
        </div>
      </div>
      {subMenuProduksi === "BAKU" ? (
        <>
          <TableProduksi
            handleFormChange={handleFormChange}
            itemProduksi={itemProduksi}
            // dataSatuan={itemSatuan}
          />
          <button className="button_submit_memo" onClick={() => handleKirim()}>
            Kirim
          </button>
        </>
      ) : (
        <>
          <div className="container_header_produksi">
            <div className="header_no_mesin_produksi">Mesin</div>
            <div className="header_table_produksi font_bold header_pendukung">
              Produk
            </div>
            <div className="header_table_produksi font_bold header_pendukung">
              Reject
            </div>
            <div className="header_table_produksi font_bold header_pendukung">
              Bahan Terpakai <br />
              (Pcs)
            </div>
          </div>
          {itemPendukung &&
            itemPendukung.map((data, index) => (
              <div
                className={
                  index % 2 === 0
                    ? "container_data_memo_ppic"
                    : "container_data_memo_ppic background_odd"
                }
                key={index}
              >
                <div className="header_no_mesin_produksi">{data.mesin}</div>
                <div className="container_input_total_produksi font_size_12 container_input_pendukung">
                  {data.mold}
                </div>
                <div
                  className="container_input_total_produksi"
                  style={{ alignItems: "center" }}
                >
                  <input
                    className="width_input_produksi font_12_mobile"
                    type="number"
                    placeholder="Reject"
                    name="totalReject"
                    onChange={(e) =>
                      handleFormChangePendukung(index, e, "PENDUKUNG")
                    }
                    onWheel={(e) => e.target.blur()}
                  />
                  &nbsp;Pcs
                </div>
                <div
                  className="container_input_total_produksi"
                  style={{ alignItems: "center" }}
                >
                  <input
                    className="width_input_produksi font_12_mobile"
                    type="number"
                    placeholder="Barang"
                    name="totalBahan"
                    onChange={(e) =>
                      handleFormChangePendukung(index, e, "PENDUKUNG")
                    }
                    onWheel={(e) => e.target.blur()}
                  />
                  &nbsp;Pcs
                </div>
              </div>
            ))}
          {/* <p className="title_inventory">Satuan</p>
          <div className="container_header_produksi">
            <div className="header_no_mesin_produksi">Mesin</div>
            <div className="header_table_produksi font_bold header_pendukung">
              Produk
            </div>
            <div className="header_table_produksi font_bold header_pendukung">
              Reject
            </div>
            <div className="header_table_produksi font_bold header_pendukung">
              Bahan Terpakai <br />
              (Pcs)
            </div>
          </div>
          {itemSatuan &&
            itemSatuan.map((data, index) => {
              if (data.jenis === "PENDUKUNG") {
                return (
                  <>
                    <div
                      className={
                        index % 2 === 0
                          ? "container_data_memo_ppic"
                          : "container_data_memo_ppic background_odd"
                      }
                      key={index}
                    >
                      <div className="header_no_mesin_produksi">
                        {data.mesin}
                      </div>
                      <div className="container_input_total_produksi font_size_12 container_input_pendukung">
                        {data.mold}
                      </div>
                      <div
                        className="container_input_total_produksi"
                        style={{ alignItems: "center" }}
                      >
                        <input
                          className="width_input_produksi font_12_mobile"
                          type="number"
                          placeholder="Reject"
                          name="totalReject"
                          onChange={(e) =>
                            handleFormChangePendukung(index, e, "SATUAN")
                          }
                          onWheel={(e) => e.target.blur()}
                        />
                        &nbsp;Pcs
                      </div>
                      <div
                        className="container_input_total_produksi"
                        style={{ alignItems: "center" }}
                      >
                        <input
                          className="width_input_produksi font_12_mobile"
                          type="number"
                          placeholder="Barang"
                          name="totalBahan"
                          onChange={(e) =>
                            handleFormChangePendukung(index, e, "SATUAN")
                          }
                          onWheel={(e) => e.target.blur()}
                        />
                        &nbsp;Pcs
                      </div>
                    </div>
                  </>
                );
              }
            })} */}
          <button
            className="button_submit_memo"
            onClick={() => handleKirimPendukung()}
          >
            Kirim
          </button>
        </>
      )}
    </div>
  );
};
export default Produksi;
