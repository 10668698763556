import React from "react";
import moment from "moment";
import { useSecondProcess } from "./useSecondProcess";
import { validateRole } from "../../util/utilFunction";
import { bulan } from "../../util/constant";
import {
  pekerjaanPrinting,
  pekerjaanSP,
  constMesinSP,
  constWarnaPrinting,
  shiftTim1,
  rejectSP,
  rejectPR,
} from "../../util/constant";
import CloseLogo from "../../Assets/close_icon.png";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import "./SecondProcess.css";

const SecondProcess = () => {
  const {
    dataUser,
    produkMasukSP,
    setProdukMasukSP,
    jumlahMasukSP,
    setJumlahMasukSP,
    listProduk,
    customerSP,
    handlePickCustomer,
    listProdukPrinting,
    subMenuPrinting,
    setSubMenuPrinting,
    jenisPekerjaan,
    mesinSP,
    setMesinSP,
    warnaPrinting,
    setWarnaPrinting,
    dataProdukPick,
    produkLaporanSP,
    setProdukLaporanSP,
    setJenisPekerjaan,
    handleKirimLaporanSP,
    keteranganLaporanSP,
    setKeteranganLaporanSP,
    jumlahOkLaporanSP,
    setJumlahOkLaporanSP,
    rejectLaporanSP,
    setRejectLaporanSP,
    repairLaporanSP,
    setRepairLaporanSP,
    jumlahPengerjaanSP,
    setJumlahPengerjaanSP,
    dateLaporanSP,
    setDateLaporanSP,
    isSubmit,
    setIsSubmit,
    errMsgLaporanSP,
    addFields,
    removeFields,
    addPopUpNewReject,
    setAddPopUpNewReject,
    bagianReject,
    handlePickBagian,
    rejectDescProduksi,
    alasanRejectProduksi,
    setAlasanRejectProduksi,
    shiftProduksi,
    setShiftProduksi,
    jumlahReject,
    setJumlahReject,
    alasanRejectSP,
    setAlasanRejectSP,
    dateReject,
    setDateReject,
    dataReject,
    downtime,
    setDowntime,
    dataBarangSP,
    handleKirimBarangMasuk,
    dataBarangPolos,
    firstSortProdukPolos,
    idBarangPolos,
    setIdBarangPolos,
    dataMerge,
    bulanRejectProduksi,
    setBulanRejectProduksi,
    tahunRejectProduksi,
    setTahunRejectProduksi,
  } = useSecondProcess();

  return (
    <div className="container_approval">
      <p className="title_inventory">
        {subMenuPrinting === "masuk"
          ? "Stock Masuk"
          : subMenuPrinting === "laporan"
          ? "Laporan Harian"
          : "Reject Produksi"}
      </p>
      <div className="wrapper_sub_menu_history" style={{ margin: 0 }}>
        {dataUser.role === "produksi_admin" ? (
          <p
            className={
              subMenuPrinting === "rejectProduksi"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenuPrinting("rejectProduksi")}
          >
            Reject Produksi
          </p>
        ) : (
          ""
        )}
        {dataUser.role === "pr_admin" ||
        dataUser.role === "pr_user" ||
        dataUser.role === "sp_admin" ||
        dataUser.role === "sp_user" ? (
          <>
            <p
              className={
                subMenuPrinting === "masuk"
                  ? "sub_menu_history border_active_history"
                  : "sub_menu_history"
              }
              onClick={() => setSubMenuPrinting("masuk")}
            >
              Masuk
            </p>
            <p
              className={
                subMenuPrinting === "laporan"
                  ? "sub_menu_history border_active_history"
                  : "sub_menu_history"
              }
              onClick={() => setSubMenuPrinting("laporan")}
            >
              Laporan
            </p>
          </>
        ) : (
          ""
        )}
      </div>
      {subMenuPrinting === "masuk" ? (
        <>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div className="margin_right_27_qc">
              <p>Pilih Customer</p>
              <select
                className="input_select_shift"
                style={{ width: "144px" }}
                value={customerSP}
                onChange={(e) => handlePickCustomer(e.target.value)}
              >
                <option selected={customerSP === "Pilih Customer"} disabled>
                  Pilih Customer
                </option>
                {dataBarangSP &&
                  dataBarangSP.map((ele) => (
                    <option value={ele.company}>{ele.company}</option>
                  ))}
              </select>
            </div>
            <div className="margin_right_27_qc">
              <p>Pilih Produk</p>
              <select
                className="input_select_shift"
                style={{ width: "144px" }}
                value={produkMasukSP}
                onChange={(e) => setProdukMasukSP(e.target.value)}
              >
                <option selected={produkMasukSP === "Pilih Produk"} disabled>
                  Pilih Produk
                </option>
                {listProdukPrinting &&
                  listProdukPrinting.map((ele) => (
                    <option value={ele.namaBarang}>
                      {ele.namaBarang} - {ele.color}
                    </option>
                  ))}
              </select>
            </div>
            <div className="margin_right_27_qc">
              <p>Jumlah Produk</p>
              <input
                className="input_jumlah"
                type="number"
                placeholder="Jumlah Produk (Pcs)"
                value={jumlahMasukSP}
                onChange={(e) =>
                  setJumlahMasukSP(e.target.value.split(".").join(""))
                }
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </div>
          <button
            className="button_submit_memo"
            onClick={() => handleKirimBarangMasuk()}
          >
            Kirim
          </button>
        </>
      ) : subMenuPrinting === "laporan" ? (
        <>
          {addPopUpNewReject ? (
            <PopUpComponent>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <img
                  className="width_edit_logo"
                  src={CloseLogo}
                  onClick={() => setAddPopUpNewReject(false)}
                  alt="edit_logo"
                />
              </div>
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <div>
                  <p>
                    Pilih Tanggal<label style={{ color: "red" }}>*</label>
                  </p>
                  <input
                    type="date"
                    style={{ height: "27px", width: "139px" }}
                    value={dateReject}
                    onChange={(e) => setDateReject(e.target.value)}
                  />
                </div>
                <div style={{ width: "139px" }}>
                  <p>Bagian</p>
                  <div onClick={() => handlePickBagian("SP")}>
                    <input type="checkbox" checked={bagianReject === "SP"} />
                    <label>SP</label>
                  </div>
                  <br />
                  <div onClick={() => handlePickBagian("Produksi")}>
                    <input
                      type="checkbox"
                      checked={bagianReject === "Produksi"}
                    />
                    <label>Produksi</label>
                  </div>
                </div>
              </div>
              {bagianReject === "Produksi" ? (
                <div
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  <div>
                    <p>Reject Produksi</p>
                    <select
                      style={{ height: "32px", width: "144px" }}
                      value={alasanRejectProduksi}
                      onChange={(e) => setAlasanRejectProduksi(e.target.value)}
                    >
                      <option
                        selected={alasanRejectProduksi === "Pilih Alasan"}
                        disabled
                      >
                        Pilih Alasan
                      </option>
                      {rejectDescProduksi &&
                        rejectDescProduksi.map((obj) => (
                          <option value={obj.desc}>{obj.desc}</option>
                        ))}
                    </select>
                  </div>
                  <div>
                    <p>Pilih Shift</p>
                    <select
                      style={{ height: "32px", width: "144px" }}
                      value={shiftProduksi}
                      onChange={(e) => setShiftProduksi(e.target.value)}
                    >
                      <option
                        selected={shiftProduksi === "Pilih Shift"}
                        disabled
                      >
                        Pilih Shift
                      </option>
                      {shiftTim1 &&
                        shiftTim1.map((ele) => (
                          <option value={ele}>{ele}</option>
                        ))}
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div style={{ justifyContent: "space-between", display: "flex" }}>
                {bagianReject === "SP" ? (
                  <div>
                    <p>Reject SP</p>
                    {validateRole() === "SP" ? (
                      <select
                        style={{ height: "32px", width: "144px" }}
                        value={alasanRejectSP}
                        onChange={(e) => setAlasanRejectSP(e.target.value)}
                      >
                        <option
                          selected={alasanRejectSP === "Pilih Alasan"}
                          disabled
                        >
                          Pilih Alasan
                        </option>
                        {rejectSP &&
                          rejectSP.map((ele) => (
                            <option value={ele.desc}>{ele.desc}</option>
                          ))}
                      </select>
                    ) : (
                      <select
                        style={{ height: "32px", width: "144px" }}
                        value={alasanRejectSP}
                        onChange={(e) => setAlasanRejectSP(e.target.value)}
                      >
                        <option
                          selected={alasanRejectSP === "Pilih Alasan"}
                          disabled
                        >
                          Pilih Alasan
                        </option>
                        {rejectPR &&
                          rejectPR.map((ele) => (
                            <option value={ele.desc}>{ele.desc}</option>
                          ))}
                      </select>
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <p>
                    Jumlah Reject <label style={{ color: "red" }}>*</label>
                  </p>
                  <input
                    placeholder="Jumlah Reject"
                    type="number"
                    style={{ maxWidth: "93%", height: "24px" }}
                    value={jumlahReject}
                    onChange={(e) =>
                      setJumlahReject(e.target.value.split(".").join(""))
                    }
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
              </div>
              <button
                className="button_submit_memo"
                onClick={() => addFields()}
              >
                Kirim
              </button>
            </PopUpComponent>
          ) : (
            ""
          )}
          {isSubmit ? (
            <PopUpComponent>
              <p>Terima Kasih! Data Kamu Sudah Terkirim</p>
              <button
                className="button_submit_memo"
                style={{ margin: "24px 0 0 0" }}
                onClick={() => setIsSubmit(false)}
              >
                OK
              </button>
            </PopUpComponent>
          ) : (
            ""
          )}
          {errMsgLaporanSP ? (
            <p style={{ color: "red", fontWeight: "bold" }}>
              Mohon Mengisi kolom berbintang!
            </p>
          ) : (
            ""
          )}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div className="margin_right_27_qc">
              <p>
                Pilih Tanggal<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="date"
                style={{ height: "27px", width: "139px" }}
                value={dateLaporanSP}
                onChange={(e) => setDateLaporanSP(e.target.value)}
              />
            </div>
            <div className="margin_right_27_qc">
              <p>
                Mesin<label style={{ color: "red" }}>*</label>
              </p>
              <select
                style={{ height: "32px", width: "144px" }}
                value={mesinSP}
                onChange={(e) => setMesinSP(e.target.value)}
              >
                <option selected={mesinSP === "Pilih Mesin"} disabled>
                  Pilih Mesin
                </option>
                {constMesinSP &&
                  constMesinSP.map((ele) => <option value={ele}>{ele}</option>)}
              </select>
            </div>
            <div className="margin_right_27_qc">
              <p>
                Pilih Customer<label style={{ color: "red" }}>*</label>
              </p>
              <select
                style={{ height: "32px", width: "144px" }}
                value={customerSP}
                onChange={(e) => handlePickCustomer(e.target.value)}
              >
                <option selected={customerSP === "Pilih Customer"} disabled>
                  Pilih Customer
                </option>
                {dataBarangPolos &&
                  dataBarangPolos.map((ele) => (
                    <option value={ele.company}>{ele.company}</option>
                  ))}
              </select>
            </div>
            <div className="margin_right_27_qc">
              <p>
                Pilih Produk<label style={{ color: "red" }}>*</label>
              </p>
              <select
                style={{ height: "32px", width: "144px" }}
                value={produkLaporanSP}
                onChange={(e) => setProdukLaporanSP(e.target.value)}
              >
                <option selected={produkLaporanSP === "Pilih Produk"} disabled>
                  Pilih Produk
                </option>
                {dataProdukPick &&
                  dataProdukPick.map((ele) => (
                    <option value={ele.produk}>{ele.produk}</option>
                  ))}
              </select>
            </div>
            <div className="margin_right_27_qc">
              <p>
                Pilih Produk Polos<label style={{ color: "red" }}>*</label>
              </p>
              <select
                style={{ height: "32px", width: "144px" }}
                value={idBarangPolos}
                onChange={(e) => setIdBarangPolos(e.target.value)}
              >
                <option selected={idBarangPolos === "Pilih Produk"} disabled>
                  Pilih Produk
                </option>
                {firstSortProdukPolos &&
                  firstSortProdukPolos.data.map((ele) => (
                    <option value={ele.id}>{ele.produk}</option>
                  ))}
              </select>
            </div>
            <div className="margin_right_27_qc">
              <p>
                Jenis Pekerjaan<label style={{ color: "red" }}>*</label>
              </p>
              {validateRole() === "PR" ? (
                <select
                  style={{ height: "32px", width: "144px" }}
                  value={jenisPekerjaan}
                  onChange={(e) => setJenisPekerjaan(e.target.value)}
                >
                  <option
                    selected={jenisPekerjaan === "Pilih Jenis Pekerjaan"}
                    disabled
                  >
                    Pilih Jenis Pekerjaan
                  </option>
                  {pekerjaanPrinting &&
                    pekerjaanPrinting.map((ele) => (
                      <option value={ele.jenisPekerjaan}>
                        {ele.jenisPekerjaan}
                      </option>
                    ))}
                </select>
              ) : (
                <select
                  style={{ height: "32px", width: "144px" }}
                  value={jenisPekerjaan}
                  onChange={(e) => setJenisPekerjaan(e.target.value)}
                >
                  <option
                    selected={jenisPekerjaan === "Pilih Jenis Pekerjaan"}
                    disabled
                  >
                    Pilih Jenis Pekerjaan
                  </option>
                  {pekerjaanSP &&
                    pekerjaanSP.map((ele) => (
                      <option value={ele.jenisPekerjaan}>
                        {ele.jenisPekerjaan}
                      </option>
                    ))}
                </select>
              )}
            </div>
            {jenisPekerjaan === "Print" || validateRole() === "SP" ? (
              <div className="margin_right_27_qc">
                <p>
                  Pilih Warna<label style={{ color: "red" }}>*</label>
                </p>
                <select
                  style={{ height: "32px", width: "144px" }}
                  value={warnaPrinting}
                  onChange={(e) => setWarnaPrinting(e.target.value)}
                >
                  <option selected={warnaPrinting === "Pilih Warna"} disabled>
                    Pilih Warna
                  </option>
                  {constWarnaPrinting &&
                    constWarnaPrinting.map((ele) => (
                      <option value={ele}>{ele}</option>
                    ))}
                </select>
              </div>
            ) : (
              ""
            )}
            <div className="margin_right_27_qc">
              <p>
                Jumlah OK<label style={{ color: "red" }}>*</label>
              </p>
              <input
                placeholder="Jumlah OK"
                type="number"
                style={{ maxWidth: "93%", height: "24px" }}
                value={jumlahOkLaporanSP}
                onChange={(e) =>
                  setJumlahOkLaporanSP(e.target.value.split(".").join(""))
                }
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <div className="margin_right_27_qc">
              <p>Jumlah Reject</p>
              <input
                placeholder="Jumlah Reject"
                type="number"
                style={{ maxWidth: "93%", height: "24px" }}
                value={rejectLaporanSP}
                onChange={(e) =>
                  setRejectLaporanSP(e.target.value.split(".").join(""))
                }
                onWheel={(e) => e.target.blur()}
              />
            </div>
            {validateRole() === "PR" ? (
              <div className="margin_right_27_qc">
                <p>Repair</p>
                <input
                  placeholder="Jumlah Repair"
                  type="number"
                  style={{ maxWidth: "93%", height: "24px" }}
                  value={repairLaporanSP}
                  onChange={(e) =>
                    setRepairLaporanSP(e.target.value.split(".").join(""))
                  }
                  onWheel={(e) => e.target.blur()}
                />
              </div>
            ) : (
              ""
            )}
            <div className="margin_right_27_qc">
              <p>
                Jumlah Pengerjaan<label style={{ color: "red" }}>*</label>
              </p>
              <input
                placeholder="Jumlah Pengerjaan"
                type="text"
                style={{ maxWidth: "93%", height: "24px" }}
                value={jumlahPengerjaanSP}
                onChange={(e) => setJumlahPengerjaanSP(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <div className="margin_right_27_qc">
              <p>Downtime (Menit)</p>
              <input
                placeholder="Downtime (Menit)"
                type="number"
                style={{ maxWidth: "93%", height: "24px" }}
                value={downtime}
                onChange={(e) =>
                  setDowntime(e.target.value.split(".").join(""))
                }
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </div>
          <p>Kind Of Reject</p>
          <table style={{ width: "100%", fontSize: "14px" }}>
            <tr>
              <th style={{ zIndex: -1 }}>Reject</th>
              <th style={{ zIndex: -1 }}>Tgl</th>
              <th>Bagian</th>
              <th style={{ padding: "18px 24px 18px 6px" }}>Total</th>
            </tr>
            {dataReject &&
              dataReject.map((obj, index) => {
                return (
                  <tr
                    style={
                      index % 2 === 0
                        ? {
                            background: "white",
                            fontSize: "12px",
                          }
                        : {
                            background: "#f9f9f9",
                            fontSize: "12px",
                          }
                    }
                  >
                    <td style={{ padding: "12px" }}>{obj.id}</td>
                    <td style={{ padding: "12px" }}>{obj.dateReject}</td>
                    <td style={{ padding: "12px" }}>
                      {obj.shift ? obj.shift : obj.bagian}
                    </td>
                    <td style={{ padding: "12px" }}>{obj.reject}</td>
                  </tr>
                );
              })}
          </table>

          <div className="margin_right_27_qc">
            <div
              className="container_button_tambah"
              style={{ justifyContent: "end", marginTop: "24px" }}
            >
              <div>
                <button
                  className="button_plus"
                  style={{ marginRight: "16px" }}
                  onClick={() => removeFields()}
                >
                  &#8722;
                </button>
                <button
                  className="button_plus"
                  onClick={() => setAddPopUpNewReject(true)}
                >
                  &#43;
                </button>
              </div>
            </div>
          </div>
          <div style={{ fontSize: "13px" }}>
            <p>Keterangan</p>
            <textarea
              style={{
                width: "98%",
                resize: "none",
                height: "84px",
                fontFamily: "arial",
              }}
              placeholder="Keterangan Laporan"
              value={keteranganLaporanSP}
              onChange={(e) => setKeteranganLaporanSP(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              className="button_submit_memo"
              style={{
                marginRight: "24px",
                color: "black",
                border: "1px solid black",
                background: "white",
              }}
              onClick={() =>
                navigator.clipboard.readText().then(
                  (cliptext) => setKeteranganLaporanSP(cliptext),
                  (err) => console.log(err)
                )
              }
            >
              Tempel
            </button>
            <button
              className="button_submit_memo"
              onClick={() => handleKirimLaporanSP()}
            >
              Kirim
            </button>
          </div>
        </>
      ) : (
        <>
          <div style={{ display: "flex" }}>
            <div className="margin_right_27_qc">
              <p>Bulan</p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <select
                  defaultValue="Bulan"
                  onChange={(e) => setBulanRejectProduksi(e.target.value)}
                  className="input_select_memo_ppic"
                  style={{ width: "100%" }}
                  value={bulanRejectProduksi}
                >
                  <option selected disabled>
                    Bulan
                  </option>
                  {bulan.map((ele) => (
                    <option value={ele.value}>{ele.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="margin_right_27_qc">
              <p>Tahun</p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <select
                  defaultValue="Tahun"
                  onChange={(e) => setTahunRejectProduksi(e.target.value)}
                  className="input_select_memo_ppic"
                  style={{ width: "100%" }}
                  value={tahunRejectProduksi}
                >
                  <option selected disabled>
                    Tahun
                  </option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                </select>
              </div>
            </div>
          </div>
          <br />

          <table>
            <tr>
              <th>Part Name</th>
              <th>Warna</th>
              <th>Customer</th>
              <th>Shift</th>
              <th>Reject</th>
              <th>Jumlah</th>
              <th>Tgl Produksi</th>
            </tr>
            {dataMerge &&
              dataMerge.map((ele, index) => {
                return (
                  <tr
                    style={
                      index % 2 === 0
                        ? { background: "white" }
                        : { background: "#f9f9f9" }
                    }
                  >
                    <td style={{ padding: "16px" }}>{ele.produk}</td>
                    <td style={{ padding: "16px" }}>{ele.color}</td>
                    <td style={{ padding: "16px" }}>{ele.customer}</td>
                    <td style={{ padding: "16px" }}>{ele.shift}</td>
                    <td style={{ padding: "16px", whiteSpace: "nowrap" }}>
                      {ele.id}
                    </td>
                    <td style={{ padding: "16px" }}>{ele.reject}</td>
                    <td style={{ padding: "16px" }}>
                      {moment(ele.dateReject).format("DD/MM/YYYY")}
                    </td>
                  </tr>
                );
              })}
          </table>
        </>
      )}
    </div>
  );
};
export default SecondProcess;
