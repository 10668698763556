import { useEffect, useState } from "react";
import { memoBahanBakuConstant } from "../../util/constant";
import { postAxios } from "../../util/apiCall";
import { getDateUtil, today } from "../../util/formatDate";

export const useMemoPPIC = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [listProduk, setListProduk] = useState();
  const [subMenuMemo, setSubMenuMemo] = useState("BAKU");
  const [moldTemp, setMoldTemp] = useState();
  const [bahanPendukungTemp, setBahanPendukungTemp] = useState();
  const [dateMemoPPIC, setDateMemoPPIC] = useState(today);
  const [memoBahanPokok, setMemoBahanPokok] = useState(memoBahanBakuConstant);
  const [memoBahanPendukung, setMemoBahanPendukung] = useState([]);
  const [dataListMold, setDataListMold] = useState([]);
  const [memoBahanSatuan, setMemoBahanSatuan] = useState([]);
  const [memoGantiUtama, setMemoGantiUtama] = useState([]);
  const [memoGantiSatuan, setMemoGantiSatuan] = useState([]);
  const [checkArray, setCheckArray] = useState([]);
  const [popUpMemoPPIC, setPopUpMemoPPIC] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isEditJumlah, setIsEditJumlah] = useState(false);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getMemoPpic`,
      { date: getDateUtil(dateMemoPPIC), jenis: "BAKU", tim: dataUser.tim },
      dataUser.auth,
      setCheckArray,
      ""
    );
  }, []);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getMemoGantiMold`,
      { date: getDateUtil(dateMemoPPIC), type: "UTAMA", tim: dataUser.tim },
      dataUser.auth,
      setMemoGantiUtama,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getMemoGantiMold`,
      { date: getDateUtil(dateMemoPPIC), type: "SATUAN", tim: dataUser.tim },
      dataUser.auth,
      setMemoGantiSatuan,
      ""
    );
    setIsRefresh(false);
  }, [dateMemoPPIC, isRefresh]);

  useEffect(() => {
    if (checkArray.length > 0) {
      setMemoBahanPokok(checkArray);
    } else {
      setMemoBahanPokok(memoBahanBakuConstant);
    }
  }, [checkArray]);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListProduk`,
      { tim: dataUser.tim },
      dataUser.auth,
      setListProduk,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListMold`,
      { tim: dataUser.tim },
      dataUser.auth,
      setDataListMold,
      ""
    );
  }, []);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getMemoPpic`,
      {
        date: getDateUtil(dateMemoPPIC),
        jenis: "PENDUKUNG",
        tim: dataUser.tim,
      },
      dataUser.auth,
      setMemoBahanPendukung,
      ""
    );
  }, [dateMemoPPIC]);

  const handleChangeSubMenu = async (jenis) => {
    setSubMenuMemo(jenis);
    if (jenis === "BAKU") {
      setMemoBahanPendukung([]);
      await postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getMemoPpic`,
        { date: getDateUtil(dateMemoPPIC), jenis: "BAKU", tim: dataUser.tim },
        dataUser.auth,
        setCheckArray,
        ""
      );
      if (checkArray.length > 0) {
        setMemoBahanPokok(checkArray);
      } else {
        setMemoBahanPokok(memoBahanBakuConstant);
      }
    } else if (jenis === "PENDUKUNG") {
      setMemoBahanPokok(memoBahanBakuConstant);
      await postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getMemoPpic`,
        {
          date: getDateUtil(dateMemoPPIC),
          jenis: "PENDUKUNG",
          tim: dataUser.tim,
        },
        dataUser.auth,
        setMemoBahanPendukung,
        ""
      );
    } else {
      setMemoBahanPokok(memoBahanBakuConstant);
      await postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getMemoPpic`,
        {
          date: getDateUtil(dateMemoPPIC),
          jenis: "SATUAN",
          tim: dataUser.tim,
        },
        dataUser.auth,
        setMemoBahanSatuan,
        ""
      );
    }
  };

  const handleFormChange = (index, event, jenisBahan) => {
    if (jenisBahan === "BAKU") {
      let data = [...memoBahanPokok];

      data[index][event.target.name] = event.target.value;

      listProduk &&
        listProduk.forEach((obj) => {
          if (data[index].customer === obj.company) {
            if (jenisBahan === "BAKU") {
              data[index].listMoldTemp = obj.bahanBaku;
              setMoldTemp(obj.bahanBaku);
            }
          }
        });

      const temp = data[index].mold;

      dataListMold &&
        dataListMold.forEach((obj) => {
          if (data[index].customer === obj.company) {
            data[index].listMoldProduk = obj.data;
          }
        });

      moldTemp &&
        moldTemp.forEach((obj) => {
          if (temp === obj.mold) {
            data[index].material = obj.material;
            data[index].color = obj.color;
            data[index].total = obj.total;
            data[index].colorMaterial = obj.colorMaterial;
            data[index].runner = obj.runner;
            data[index].typeMaterial = obj.typeMaterial;
          }
        });

      setMemoBahanPokok(data);
    } else if (jenisBahan === "PENDUKUNG") {
      let data = [...memoBahanPendukung];

      data[index][event.target.name] = event.target.value;

      if (data[index].isBawa) {
        data[index].isBawa = event.target.checked;
      } else {
        data[index].isBawa = false;
      }

      listProduk &&
        listProduk.forEach((obj) => {
          if (data[index].customer === obj.company) {
            if (jenisBahan === "PENDUKUNG") {
              data[index].listMoldTemp = obj.bahanPendukung;
              setMoldTemp(obj.bahanPendukung);
            }
          }
        });
      const temp = data[index].mold;

      moldTemp &&
        moldTemp.forEach((obj) => {
          if (temp === obj.mold) {
            data[index].material = obj.material;
            data[index].color = obj.color;
            data[index].total = obj.total;
            data[index].colorMaterial = obj.colorMaterial;
            data[index].runner = obj.runner;
            data[index].typeMaterial = obj.typeMaterial;
          }
        });

      setMemoBahanPendukung(data);
    } else {
      let data = [...memoBahanSatuan];

      data[index][event.target.name] = event.target.value;

      listProduk &&
        listProduk.forEach((obj) => {
          if (data[index].customer === obj.company) {
            if (jenisBahan === "SATUAN") {
              if (data[index].jenisBahan === "BAKU") {
                data[index].listMoldTemp = obj.bahanBaku;
                setMoldTemp(obj.bahanBaku);
              } else {
                data[index].listMoldTemp = obj.bahanPendukung;
                setMoldTemp(obj.bahanPendukung);
              }
            }
          }
        });
      const temp = data[index].mold;

      moldTemp &&
        moldTemp.forEach((obj) => {
          if (temp === obj.mold) {
            data[index].material = obj.material;
            data[index].color = obj.color;
            data[index].total = obj.total;
            data[index].colorMaterial = obj.colorMaterial;
            data[index].runner = obj.runner;
            data[index].typeMaterial = obj.typeMaterial;
          }
        });
      setMemoBahanSatuan(data);
    }
  };

  const handleFormChangeGantiUtama = (index, event, jenisBahan) => {
    let data = [...memoGantiUtama];

    data[index][event.target.name] = event.target.value;

    listProduk &&
      listProduk.forEach((obj) => {
        if (data[index].customer === obj.company) {
          if (jenisBahan === "SATUAN") {
            data[index].listProdukBaku = obj.bahanBaku;
            data[index].listProdukPendukung = obj.bahanPendukung;
            setMoldTemp(obj.bahanBaku);
            setBahanPendukungTemp(obj.bahanPendukung);
          }
        }
      });
    const temp2 = data[index].produkPendukung;

    bahanPendukungTemp &&
      bahanPendukungTemp.forEach((obj) => {
        if (temp2 === obj.mold) {
          if (obj.total) {
            data[index].initialPendukung = obj.total;
            data[index].materialPendukung = obj.material;
          } else {
            data[index].materialPendukung = obj.material;
          }
        }
      });

    dataListMold &&
      dataListMold.forEach((obj) => {
        if (data[index].customer === obj.company) {
          data[index].listMoldProduk = obj.data;
        }
      });

    const temp = data[index].mold;

    moldTemp &&
      moldTemp.forEach((obj) => {
        if (temp === obj.mold) {
          data[index].material = obj.material;
          data[index].color = obj.color;
          data[index].total = obj.total;
          data[index].colorMaterial = obj.colorMaterial;
          data[index].runner = obj.runner;
          data[index].typeMaterial = obj.typeMaterial;
        }
      });
    setMemoGantiUtama(data);
  };

  const handleFormChangeGantiSatuan = (index, event, jenisBahan) => {
    let data = [...memoGantiSatuan];

    data[index][event.target.name] = event.target.value;

    listProduk &&
      listProduk.forEach((obj) => {
        if (data[index].customer === obj.company) {
          if (jenisBahan === "SATUAN") {
            data[index].listProdukBaku = obj.bahanBaku;
            data[index].listProdukPendukung = obj.bahanPendukung;
            setMoldTemp(obj.bahanBaku);
          }
        }
      });

    dataListMold &&
      dataListMold.forEach((obj) => {
        if (data[index].customer === obj.company) {
          data[index].listMoldProduk = obj.data;
        }
      });

    const temp = data[index].mold;

    moldTemp &&
      moldTemp.forEach((obj) => {
        if (temp === obj.mold) {
          data[index].material = obj.material;
          data[index].color = obj.color;
          data[index].total = obj.total;
          data[index].colorMaterial = obj.colorMaterial;
          data[index].runner = obj.runner;
          data[index].typeMaterial = obj.typeMaterial;
        }
      });
    setMemoGantiSatuan(data);
  };

  const addFields = (jenis, column) => {
    let newfield = {
      mesin: "1",
      customer: "",
      material: "",
      mold: "",
      color: "",
      total: "",
      produkPendukung: "",
      initialPendukung: "",
      urutan: "",
      materialPendukung: "",
      isNew: true,
    };
    if (jenis === "PENDUKUNG") {
      setMemoBahanPendukung([...memoBahanPendukung, newfield]);
    } else if (jenis === "SATUAN") {
      if (column === "UTAMA") {
        setMemoGantiUtama([...memoGantiUtama, newfield]);
      } else if (column === "SATUAN") {
        setMemoGantiSatuan([...memoGantiSatuan, newfield]);
      } else {
        setMemoBahanSatuan([...memoBahanSatuan, newfield]);
      }
    }
  };

  const removeFields = (jenis, column) => {
    if (jenis === "PENDUKUNG") {
      let data = [...memoBahanPendukung];
      data.splice(data.length - 1);
      setMemoBahanPendukung(data);
    } else if (jenis === "SATUAN") {
      if (column === "UTAMA") {
        let data = [...memoGantiUtama];
        data.splice(data.length - 1);
        setMemoGantiUtama(data);
      } else if (column === "SATUAN") {
        let data = [...memoGantiSatuan];
        data.splice(data.length - 1);
        setMemoGantiSatuan(data);
      } else {
        let data = [...memoBahanSatuan];
        data.splice(data.length - 1);
        setMemoBahanSatuan(data);
      }
    }
  };

  const handleSubmitBahanBaku = async () => {
    let tempData = [];
    memoBahanPokok &&
      memoBahanPokok.forEach((obj) => {
        const dataPick = () => {
          let data;
          dataListMold &&
            dataListMold.forEach((element) => {
              if (element.company === obj.customer) {
                data = element;
              }
            });
          return data;
        };
        const ProdukList = dataPick();
        const searchObject =
          ProdukList &&
          ProdukList.data.find((e) => (e.name == obj.moldType ? e.runner : 0));

        tempData.push({
          mesin: obj.mesin,
          customer: obj.customer,
          material: obj.material,
          mold: obj.mold,
          color: obj.color,
          total: obj.total,
          colorMaterial: obj.colorMaterial,
          typeMaterial: obj.typeMaterial,
          runner: searchObject ? searchObject.runner : 0,
          targetProduk: searchObject ? searchObject.targetProduk : 0,
          namaBarang: searchObject ? searchObject.namaBarang : "",
          bagian: searchObject ? searchObject.bagian : "",
          moldType: obj.moldType,
          status: "active",
        });
      });

    await postAxios(
      `${process.env.REACT_APP_ENDPOINT}/createMemoPpic`,
      {
        date: getDateUtil(dateMemoPPIC),
        creator: dataUser.name,
        jenis: "BAKU",
        data: tempData,
        tim: dataUser.tim,
      },
      dataUser.auth,
      "",
      ""
    );
    setPopUpMemoPPIC(true);
  };

  const handleSubmitBahanPendukung = async () => {
    let tempData = [];
    memoBahanPendukung &&
      memoBahanPendukung.forEach((obj) => {
        tempData.push({
          mesin: obj.mesin,
          customer: obj.customer,
          material: obj.material,
          mold: obj.mold,
          color: obj.color,
          total: obj.total,
          runner: obj.runner,
          totalBahan: Number(obj.totalBahan),
          isBawa: true,
          status: "active",
        });
      });

    await postAxios(
      `${process.env.REACT_APP_ENDPOINT}/createMemoPpic`,
      {
        date: getDateUtil(dateMemoPPIC),
        creator: dataUser.name,
        jenis: "PENDUKUNG",
        data: tempData,
        tim: dataUser.tim,
      },
      dataUser.auth,
      "",
      ""
    );
    setIsEditJumlah(false);
    setPopUpMemoPPIC(true);
  };

  const handleSubmitBahanSatuan = async () => {
    let tempData = [];
    memoBahanSatuan &&
      memoBahanSatuan.forEach((obj) => {
        tempData.push({
          mesin: obj.mesin,
          customer: obj.customer,
          material: obj.material,
          colorMaterial: obj.colorMaterial,
          typeMaterial: obj.typeMaterial,
          mold: obj.mold,
          color: obj.color,
          jenisBahan: obj.jenisBahan,
          total: Number(obj.total),
          totalBahan: Number(obj.totalBahan),
          runner: obj.runner,
          status: "active",
        });
      });

    await postAxios(
      `${process.env.REACT_APP_ENDPOINT}/createMemoPpic`,
      {
        date: getDateUtil(dateMemoPPIC),
        creator: dataUser.name,
        jenis: "SATUAN",
        data: tempData,
        tim: dataUser.tim,
      },
      dataUser.auth,
      "",
      ""
    );
    setPopUpMemoPPIC(true);
  };

  const handleSubmitGantiMold = async () => {
    let tempDataUtama = [];
    let tempDataSatuan = [];

    memoGantiUtama &&
      memoGantiUtama.forEach((obj) => {
        const dataPick = () => {
          let data;
          dataListMold &&
            dataListMold.forEach((element) => {
              if (element.company === obj.customer) {
                data = element;
              }
            });
          return data;
        };
        const ProdukList = dataPick();
        const searchObject =
          ProdukList &&
          ProdukList.data.find((e) =>
            e.name == obj.moldProduk ? true : false
          );

        tempDataUtama.push({
          mesin: obj.mesin,
          urutan: obj.urutan,
          customer: obj.customer,
          material: obj.material,
          colorMaterial: obj.colorMaterial,
          typeMaterial: obj.typeMaterial,
          mold: obj.mold,
          color: obj.color,
          isNew: obj.isNew,
          jenisBahan: obj.jenisBahan,
          total: Number(obj.total),
          totalBahan: Number(obj.totalBahan),
          produkPendukung: obj.produkPendukung,
          materialPendukung: obj.materialPendukung,
          initialPendukung: obj.initialPendukung,
          moldProduk: obj.moldProduk,
          runner: searchObject ? searchObject.runner : 0,
          targetProduk: searchObject ? searchObject.targetProduk : 0,
          namaBarang: searchObject ? searchObject.namaBarang : "",
          bagian: searchObject ? searchObject.bagian : "",
          status: "deactive",
          statusMold: {
            approver: "",
            isOk: "",
            date: "",
          },
          statusMesin: {
            isOk: "",
            approver: "",
            date: "",
          },
          statusWarehouse: {
            isOk: "",
            approver: "",
            date: "",
          },
          statusProduksi: {
            isOk: "",
            approver: "",
            date: "",
          },
          statusQuality: {
            isOk: "",
            approver: "",
            date: "",
          },
          comment: [],
        });
      });

    memoGantiSatuan &&
      memoGantiSatuan.forEach((obj) => {
        tempDataSatuan.push({
          mesin: obj.mesin,
          customer: obj.customer,
          material: obj.material,
          colorMaterial: obj.colorMaterial,
          typeMaterial: obj.typeMaterial,
          mold: obj.mold,
          color: obj.color,
          jenisBahan: obj.jenisBahan,
          total: Number(obj.total),
          totalBahan: Number(obj.totalBahan),
          produkPendukung: obj.produkPendukung,
          moldProduk: obj.moldProduk,
          runner: obj.runner,
          status: "deactive",
          statusMold: {
            approver: "",
            isOk: "",
            date: "",
          },
          statusMesin: {
            isOk: "",
            approver: "",
            date: "",
          },
          statusWarehouse: {
            isOk: "",
            approver: "",
            date: "",
          },
          statusProduksi: {
            isOk: "",
            approver: "",
            date: "",
          },
          statusQuality: {
            isOk: "",
            approver: "",
            date: "",
          },
          comment: [],
        });
      });

    await postAxios(
      `${process.env.REACT_APP_ENDPOINT}/createMemoGantiMold`,
      {
        date: getDateUtil(dateMemoPPIC),
        creator: dataUser.name,
        utama: tempDataUtama,
        satuan: tempDataSatuan,
        status: "active",
        tim: dataUser.tim,
      },
      dataUser.auth,
      "",
      ""
    );
    setPopUpMemoPPIC(true);
  };

  const handleDeleteMemoGantiMold = async (input) => {
    await postAxios(
      `${process.env.REACT_APP_ENDPOINT}/deleteGantiMold`,
      {
        date: getDateUtil(dateMemoPPIC),
        data: input,
        tim: dataUser.tim,
      },
      dataUser.auth,
      "",
      ""
    );
    setIsRefresh(true);
  };

  return {
    listProduk,
    addFields,
    memoBahanPokok,
    memoBahanPendukung,
    memoBahanSatuan,
    handleFormChange,
    removeFields,
    handleChangeSubMenu,
    subMenuMemo,
    handleSubmitBahanBaku,
    handleSubmitBahanPendukung,
    setDateMemoPPIC,
    dateMemoPPIC,
    popUpMemoPPIC,
    setPopUpMemoPPIC,
    handleSubmitBahanSatuan,
    memoGantiUtama,
    handleFormChangeGantiUtama,
    memoGantiSatuan,
    handleFormChangeGantiSatuan,
    handleSubmitGantiMold,
    handleDeleteMemoGantiMold,
    isEditJumlah,
    setIsEditJumlah,
  };
};
