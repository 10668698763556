import { useState, useEffect } from "react";
import { detailQc } from "../../util/constant";
import { postAxios } from "../../util/apiCall";
import { getDateUtil, today, yesterday, tomorrow } from "../../util/formatDate";

export const useQualityControl = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [listProduk, setListProduk] = useState();
  const [dataSatuan, setDataSatuan] = useState();
  const [shift, setShift] = useState("Pilih Shift");
  const [popUpQC, setPopUpQC] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [subMenuQc, setSubMenuQc] = useState("dataBerat");
  const [noMesinQc, setNoMesinQc] = useState("Pilih Mesin");
  const [customerQc, setCustomerQc] = useState("Pilih Customer");
  const [moldQc, setMoldQc] = useState("Pilih Mold");
  const [beratTopPlate, setBeratTopPlate] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [dataCavity, setDataCavity] = useState(detailQc);
  const [beratToco, setBeratToco] = useState("");
  const [beratRunner, setBeratRunner] = useState("");
  const [moldPick, setMoldPick] = useState("Pilih Mold");

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getMemoPpic`,
      {
        date: getDateUtil(today),
        jenis: "BAKU",
        tim: dataUser.tim,
        isQc: true,
        tomorrow: getDateUtil(tomorrow),
      },
      dataUser.auth,
      setListProduk,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getMemoPpic`,
      {
        date: getDateUtil(today),
        jenis: "SATUAN",
        tim: dataUser.tim,
        isQc: true,
        tomorrow: getDateUtil(tomorrow),
      },
      dataUser.auth,
      setDataSatuan,
      ""
    );
  }, []);

  const handleFormChange = (index, event, jenis) => {
    if (jenis === "BAKU") {
      let data = [...listProduk];

      data[index][event.target.name] = Number(event.target.value);
      setListProduk(data);
    } else {
      let data = [...dataSatuan];

      data[index][event.target.name] = Number(event.target.value);
      setDataSatuan(data);
    }
  };

  const handleFormChangeCavity = (index, event) => {
    let data = [...dataCavity];

    data[index][event.target.name] = Number(event.target.value);

    setDataCavity(data);
  };

  const handleKirimQc = () => {
    if (shift !== "Pilih Shift") {
      let dataArray = [];
      for (let index = 0; index < listProduk.length; index++) {
        const mergeObject = { ...listProduk[index], status: "active" };
        dataArray.push(mergeObject);
      }

      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/qualityControl`,
        {
          date: getDateUtil(today),
          yesterday: getDateUtil(yesterday),
          data: dataArray,
          satuan: dataSatuan,
          shift: Number(shift),
          checker: dataUser.name,
          tim: dataUser.tim,
        },
        dataUser.auth,
        "",
        ""
      );
      setShift("Pilih Shift");
      setPopUpQC(true);
      setPopUpMessage("Terima Kasih! Data Kamu Telah Dikirim");
    } else {
      setPopUpQC(true);
      setPopUpMessage("Mohon Mengisi Shift Terlebih dahulu!");
      let scrollDiv = document.getElementById("selectShift").offsetTop;
      window.scrollTo({ top: scrollDiv, behavior: "smooth" });
    }
  };

  const handleKirimTopPlate = () => {
    const objectTopPlate =
      listProduk && listProduk.find((u) => u.moldType === moldQc);
    if (
      (shift !== "Pilih Shift",
      noMesinQc !== "dataBerat",
      customerQc !== "Pilih Customer",
      moldQc !== "Pilih Mold",
      beratTopPlate !== "")
    ) {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/createTopPlate`,
        {
          date: getDateUtil(today),
          mesin: noMesinQc,
          customer: customerQc,
          moldType: moldQc,
          material: objectTopPlate.material,
          typeMaterial: objectTopPlate.typeMaterial,
          beratTopPlate: beratTopPlate,
          shift: shift,
          status: "active",
          tim: dataUser.tim,
        },
        dataUser.auth,
        "",
        ""
      );
      setNoMesinQc("Pilih Mesin");
      setCustomerQc("Pilih Customer");
      setMoldQc("Pilih Mold");
      setShift("Pilih Shift");
      setBeratTopPlate("");
      setPopUpQC(true);
      setErrorMessage(false);
      setPopUpMessage("Terima Kasih! Data Kamu Telah Dikirim");
    } else {
      setErrorMessage(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleKirimDetailProduk = () => {
    if (
      moldPick !== "Pilih Mold" &&
      beratRunner !== "" &&
      shift !== "Pilih Shift"
    ) {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/detailProduk`,
        {
          shift: shift,
          type: "create",
          date: getDateUtil(today),
          mold: moldPick,
          checker: dataUser.name,
          tim: dataUser.tim,
          toco: Number(beratToco),
          runner: Number(beratRunner),
          dataCavity: dataCavity,
        },
        dataUser.auth,
        "",
        ""
      );
      setMoldPick("Pilih Mold");
      setBeratRunner("");
      setBeratToco("");
      let data = [...dataCavity];
      data.forEach((element) => {
        element.berat = "";
      });
      setDataCavity(data);
      setDataCavity(detailQc);
      setShift("Pilih Shift");
    } else {
      setPopUpQC(true);
      setPopUpMessage("Mohon Isi Kolom Berbintang Merah!");
      let scrollDiv = document.getElementById("selectShift").offsetTop;
      window.scrollTo({ top: scrollDiv, behavior: "smooth" });
    }
  };

  return {
    listProduk,
    handleFormChange,
    handleKirimQc,
    shift,
    setShift,
    popUpQC,
    setPopUpQC,
    popUpMessage,
    dataSatuan,
    subMenuQc,
    setSubMenuQc,
    noMesinQc,
    setNoMesinQc,
    customerQc,
    setCustomerQc,
    moldQc,
    setMoldQc,
    beratTopPlate,
    setBeratTopPlate,
    handleKirimTopPlate,
    errorMessage,
    setErrorMessage,
    dataCavity,
    handleFormChangeCavity,
    setBeratToco,
    beratToco,
    setBeratRunner,
    beratRunner,
    moldPick,
    setMoldPick,
    handleKirimDetailProduk,
  };
};
