import React from "react";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import "./ApprovalLaporan.css";
import { useApprovalLaporan } from "./useApprovalLaporan";

const ApprovalLaporan = () => {
  const {
    shiftApprovalLaporan,
    setShiftApprovalLaporan,
    dataApprovalLaporan,
    handleKirim,
    popUpApprovalLaporan,
    setPopUpApprovalLaporan,
    setDateApprovalLaporan,
    dateApprovalLaporan,
    listTopPlate,
    listPasangKaret,
  } = useApprovalLaporan();

  const sortData =
    dataApprovalLaporan &&
    dataApprovalLaporan.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  const sortDataPasangKaret =
    listPasangKaret &&
    listPasangKaret.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  const handleOk = () => {
    setPopUpApprovalLaporan(false);
    window.location.reload();
  };

  return (
    <div className="container_dashboard">
      {popUpApprovalLaporan ? (
        <PopUpComponent>
          <p>Data Laporan Sudah Dikirim!</p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup background_red"
              onClick={() => handleOk()}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <p className="title_inventory">Approval Laporan Produksi</p>
      <div style={{ display: "flex" }}>
        <div className="margin_right_27_qc">
          <p>Pilih Shift</p>
          <select
            className="input_select_shift"
            value={shiftApprovalLaporan}
            onChange={(e) => setShiftApprovalLaporan(e.target.value)}
          >
            <option selected={shiftApprovalLaporan === "Pilih Shift"} disabled>
              Pilih Shift
            </option>
            <option value="1">Shift 1</option>
            <option value="2">Shift 2</option>
            <option value="3">Shift 3</option>
          </select>
        </div>
        <div className="margin_right_27_qc">
          <p>Pilih Tanggal</p>
          <input
            type="date"
            className="date_pick_memo_ppic"
            value={dateApprovalLaporan}
            onChange={(e) => setDateApprovalLaporan(e.target.value)}
          />
        </div>
      </div>

      <div
        className="container_card_approval_laporan"
        style={{
          marginTop: "18px",
        }}
      >
        {sortData &&
          sortData.map((data) => {
            const downtime = data.downtime ? data.downtime : 0;
            const dataCounterAwal = data.counterAwal ? data.counterAwal : 0;
            const dataCounterAkhir = data.counterAkhir ? data.counterAkhir : 0;

            const formula =
              (8 * 3600 - downtime * 60) / (dataCounterAkhir - dataCounterAwal);

            return (
              <div className="wrapper_card_approval_laporan">
                <div className="section_approval_laporan">
                  <p className="title_machine">Mesin&nbsp;{data.mesin}</p>
                  <p className="font_bold">{data.customer}</p>
                </div>
                <div className="flex_justify_between">
                  <p>{data.operator}</p>
                </div>
                <div className="flex_justify_between">
                  <p className="font_bold">{data.moldType}</p>
                </div>
                <div className="flex_justify_between">
                  <p className="font_bold">{data.color}</p>
                </div>
                <div className="flex_justify_between">
                  <p>{data.date}</p>
                  <p style={{ textAlign: "end" }}>Shift&nbsp;{data.shift}</p>
                </div>
                <div className="flex_justify_between">
                  <div style={{ width: "50%" }}>
                    <p className="font_bold">Isi Per Kemasan</p>
                    <p className="font_14">{data.isiPerKemasan}&nbsp;Pcs</p>
                  </div>
                  <div style={{ width: "40%" }}>
                    <p className="font_bold">Aktual Cavity</p>
                    <p className="font_14">{data.aktualCavity}</p>
                  </div>
                </div>

                <div className="flex_justify_between">
                  <div style={{ width: "50%" }}>
                    <p className="font_bold">Counter Akhir</p>
                    <p className="font_14">{data.counterAkhir}</p>
                  </div>
                  <div style={{ width: "40%" }}>
                    <p className="font_bold">Counter Awal</p>
                    <p className="font_14">{data.counterAwal}</p>
                  </div>
                </div>

                <div className="flex_justify_between">
                  <div style={{ width: "50%" }}>
                    <p className="font_bold">Jumlah "NG"</p>
                    <p className="font_14">{data.jumlahNG}&nbsp;Pcs</p>
                  </div>
                  <div style={{ width: "40%" }}>
                    <p className="font_bold">Selisih Counter</p>
                    <p className="font_14">
                      {data.counterAkhir - data.counterAwal}&nbsp;Pcs
                    </p>
                  </div>
                </div>
                <div className="flex_justify_between">
                  <div style={{ width: "50%" }}>
                    <p className="font_bold">Barang OK</p>
                    <p className="font_14">{data.jumlahBarang}&nbsp;Pcs</p>
                  </div>
                  <div style={{ width: "40%" }}>
                    <p className="font_bold">Down Time</p>
                    <p className="font_14">{downtime}&nbsp;Menit</p>
                  </div>
                </div>
                <div className="flex_justify_between">
                  <div style={{ width: "50%" }}>
                    <p className="font_bold">Cycle Time</p>
                    <p className="font_14">
                      {formula === Infinity ? 0 : formula.toFixed(1)}&nbsp;Detik
                    </p>
                  </div>
                  <div style={{ width: "40%" }}>
                    <p className="font_bold">Sisa Shift Sebelumnya</p>
                    <p className="font_14">
                      {data.sisaShiftSebelumnya ? data.sisaShiftSebelumnya : 0}
                      &nbsp;Pcs
                    </p>
                  </div>
                </div>

                <div>
                  <div className="flex_justify_between">
                    <p style={{ width: "50%", fontWeight: "bold" }}>
                      Perincian "NG"
                    </p>
                    <p style={{ width: "40%", fontWeight: "bold" }}>Jumlah</p>
                  </div>
                  {data.perincianNG &&
                    data.perincianNG.map((perincian) => {
                      return (
                        <div className="flex_justify_between">
                          <p style={{ fontSize: "14px", width: "50%" }}>
                            {perincian.id}
                          </p>
                          <p style={{ fontSize: "14px", width: "40%" }}>
                            {perincian.totalNG}&nbsp;Pcs
                          </p>
                        </div>
                      );
                    })}
                  <div>
                    <p className="font_bold">Keterangan</p>
                    <p style={{ wordWrap: "break-word" }}>{data.keterangan}</p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {listPasangKaret && listPasangKaret.length > 0 ? (
        <p className="title_inventory">Laporan Pasang Karet</p>
      ) : (
        ""
      )}
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {sortDataPasangKaret &&
          sortDataPasangKaret.map((obj) => {
            const downtimePasangKaret = obj.downtime ? obj.downtime : 0;
            const jumlahPasangKaret = obj.jumlah ? obj.jumlah : 0;
            const aktualCavityPasangKaret = obj.aktualCavity
              ? obj.aktualCavity
              : 0;
            const formula =
              (28800 - downtimePasangKaret * 60) / jumlahPasangKaret;

            return (
              <div className="card_approval_top_plate">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className="title_machine" style={{ width: "25%" }}>
                    Mesin {obj.mesin}
                  </p>
                  <p style={{ fontWeight: "bold" }}>{obj.operator}</p>
                </div>
                <p style={{ fontWeight: "bold" }}>
                  {obj.produk} - {obj.warna}
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <p style={{ fontWeight: "bold" }}>Target Produksi</p>
                    <p
                      style={
                        Math.floor(
                          ((28800 - obj.downtime * 60) / 43.2) *
                            aktualCavityPasangKaret
                        ) > jumlahPasangKaret
                          ? { color: "red" }
                          : {}
                      }
                    >
                      {(
                        ((28800 - obj.downtime * 60) / 43.2) *
                        aktualCavityPasangKaret
                      ).toFixed(0)}
                    </p>
                  </div>
                  <div style={{ width: "40%" }}>
                    <p style={{ fontWeight: "bold" }}>Cycle Time</p>
                    <p>
                      {(formula * aktualCavityPasangKaret).toFixed(1)} Detik
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p>
                      <b>Jumlah Pasang Karet</b>
                    </p>
                    <p>{obj.jumlah} Pcs</p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <p>
                      <b>Downtime</b>
                    </p>
                    <p>{obj.downtime} Menit</p>
                  </div>
                  <div style={{ width: "40%" }}>
                    <p>
                      <b>Aktual Cavity</b>
                    </p>
                    <p>{obj.aktualCavity ? obj.aktualCavity : ""}</p>
                  </div>
                </div>
                <p className="font_bold">Keterangan</p>
                {obj.keterangan &&
                  obj.keterangan.split("\n").map((subStr) => {
                    return <p>{subStr}</p>;
                  })}
              </div>
            );
          })}
      </div>

      {/* {listTopPlate && listTopPlate.length > 0 ? (
        <p className="title_inventory">Laporan Top Plate</p>
      ) : (
        ""
      )}
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {listTopPlate &&
          listTopPlate.map((obj) => {
            return (
              <div className="card_approval_top_plate">
                <div style={{ display: "flex" }}>
                  <p style={{ marginRight: "24px", fontWeight: "bold" }}>
                    Mesin {obj.mesin}
                  </p>
                  <p style={{ fontWeight: "bold" }}>{obj.customer}</p>
                </div>
                <p>{obj.moldType}</p>
                <p>
                  Jumlah Top Plate : <b>{obj.jumlah}</b>
                </p>
              </div>
            );
          })}
      </div> */}

      {(sortData && sortData.length > 0) ||
      (listPasangKaret && listPasangKaret.length > 0) ? (
        <button className="button_submit_memo" onClick={() => handleKirim()}>
          Kirim
        </button>
      ) : (
        ""
      )}
    </div>
  );
};
export default ApprovalLaporan;
