import { useRef, useState } from "react";
import { barcodeSecurity } from "../../util/constant";
import { Html5Qrcode } from "html5-qrcode";
import axios from "axios";

export const useSecurity = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const ref = useRef(null);
  const [errMsg, setErrMsg] = useState("");
  const [popUp, setPopUp] = useState(false);

  const handleScan = () => {
    const html5QrCode = new Html5Qrcode("reader");
    const qrCodeSuccessCallback = async (decodedText) => {
      let find = barcodeSecurity.find((i) => i === decodedText);
      if (find) {
        html5QrCode.pause();

        const { data } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/patrolSecurity`,
          {
            tim: dataUser.tim,
            place: find.toUpperCase(),
            creator: dataUser.name,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.auth}`,
            },
          }
        );

        if (data.data === 200) {
          setPopUp(true);
          setErrMsg("");
          html5QrCode.stop().then(() => {
            html5QrCode.clear();
          });
        } else {
          setErrMsg("Scan QR Belum Berhasil");
        }
      } else {
        setErrMsg("QR Code Tidak Cocok!");
      }
    };

    const onScanFailed = (err) => {
      // console.log(err);
    };
    const config = { fps: 10, qrbox: { width: 250, height: 250 } };
    html5QrCode.start(
      { facingMode: "environment" },
      config,
      qrCodeSuccessCallback,
      onScanFailed
    );
  };

  return { handleScan, popUp, setPopUp, errMsg, ref };
};
