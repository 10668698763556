import React from "react";
import { getDateUtil } from "../../util/formatDate";
import { useApprovalSP } from "./useApprovalSP";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import EditLogo from "../../Assets/editLogo.png";

const ApprovalSP = () => {
  const {
    dateApprovalLaporanSP,
    setDateApprovalLaporanSP,
    listLaporanSP,
    handleApproveLaporanSP,
    popUpKirim,
    setPopUpKirim,
    isEdit,
    setIsEdit,
    handleFormChange,
  } = useApprovalSP();

  const sortData =
    listLaporanSP &&
    listLaporanSP.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="container_dashboard">
      {popUpKirim ? (
        <PopUpComponent>
          <p>Data Laporan Sudah Dikirim!</p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup background_red"
              onClick={() => setPopUpKirim(false)}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <p className="title_inventory">APPROVAL LAPORAN SP</p>
      <div className="margin_right_27_qc">
        <p>Pilih Tanggal</p>
        <input
          type="date"
          className="date_pick_memo_ppic"
          value={dateApprovalLaporanSP}
          onChange={(e) => setDateApprovalLaporanSP(e.target.value)}
        />
      </div>
      <div
        className="container_card_approval_laporan"
        style={{
          marginTop: "18px",
        }}
      >
        {sortData &&
          sortData.map((data, index) => {
            const downtime = Number(data.downtime) ? Number(data.downtime) : 0;
            const formulaTargetProduksi =
              data.target * ((480 - downtime) / 480);
            return (
              <div className="wrapper_card_approval_laporan">
                <div className="section_approval_laporan">
                  <p className="title_machine">Mesin&nbsp;{data.mesin}</p>
                  <p>{getDateUtil(data.date)}</p>
                  <img
                    className="width_edit_logo"
                    style={{
                      background: "yellow",
                      borderRadius: "6px",
                    }}
                    onClick={() => setIsEdit(true)}
                    src={EditLogo}
                    alt="edit_logo"
                  />
                </div>
                <div className="flex_justify_between">
                  <p>{data.operator}</p>
                </div>
                <div className="flex_justify_between">
                  <p className="font_bold">{data.produk}</p>
                </div>
                <div className="flex_justify_between">
                  <p className="font_bold">{data.color}</p>
                  <p className="font_bold">{data.customer}</p>
                  <p className="font_bold">{data.jenisPekerjaan}</p>
                </div>
                <div className="flex_justify_between">
                  <div>
                    <p className="font_bold">TARGET PENGERJAAN</p>
                    <p
                      className="font_14"
                      style={
                        formulaTargetProduksi > data.jumlahOk + data.reject
                          ? { color: "red" }
                          : {}
                      }
                    >
                      {formulaTargetProduksi.toFixed(0)}
                    </p>
                  </div>
                  <div>
                    <p className="font_bold">BAGIAN</p>
                    <p className="font_14">{data.bagian}</p>
                  </div>
                </div>
                <div className="flex_justify_between">
                  <div>
                    <p className="font_bold">REJECT</p>
                    <p className="font_14">{data.reject}</p>
                  </div>
                  <div>
                    <p className="font_bold">REPAIR</p>
                    <p className="font_14">{data.repair}</p>
                  </div>
                  <div>
                    <p className="font_bold">OK</p>
                    <p className="font_14">{data.jumlahOk}</p>
                  </div>
                </div>
                <div className="flex_justify_between">
                  <div>
                    <p className="font_bold">JUMLAH PENGERJAAN</p>
                    <p className="font_14">{data.jumlahPengerjaan}</p>
                  </div>
                  <div>
                    <p className="font_bold">DOWNTIME</p>
                    {!isEdit ? (
                      <p className="font_14">{data.downtime} ( Menit )</p>
                    ) : (
                      <input
                        type="number"
                        className="input_dashboard_operator"
                        name="downtime"
                        value={data.downtime}
                        onChange={(e) => handleFormChange(index, e)}
                        onWheel={(e) => e.target.blur()}
                      />
                    )}
                  </div>
                </div>
                <p className="font_bold">Kind Of Reject</p>
                <div>
                  <table
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      tableLayout: "fixed",
                    }}
                  >
                    <tr>
                      <th style={{ position: "inherit" }}>Reject</th>
                      <th>Tgl</th>
                      <th>Bagian</th>
                      <th style={{ padding: "18px 12px 18px 6px" }}>Total</th>
                    </tr>
                    {data.dataReject &&
                      data.dataReject.map((obj, index) => {
                        return (
                          <tr
                            style={
                              index % 2 === 0
                                ? {
                                    background: "white",
                                    fontSize: "12px",
                                  }
                                : {
                                    background: "#f9f9f9",
                                    fontSize: "12px",
                                  }
                            }
                          >
                            <td style={{ padding: "12px" }}>{obj.id}</td>
                            <td style={{ padding: "12px" }}>
                              {obj.dateReject}
                            </td>
                            <td style={{ padding: "12px" }}>
                              {obj.shift ? obj.shift : obj.bagian}
                            </td>
                            <td style={{ padding: "12px" }}>{obj.reject}</td>
                          </tr>
                        );
                      })}
                  </table>
                </div>
                <div>
                  <p className="font_bold">Keterangan</p>
                  {data.keterangan &&
                    data.keterangan.split("\n").map((subStr) => {
                      return <p style={{ margin: "6px 0" }}>{subStr}</p>;
                    })}
                </div>
              </div>
            );
          })}
      </div>
      {sortData && sortData.length > 0 ? (
        <button
          className="button_submit_memo"
          onClick={() => handleApproveLaporanSP()}
        >
          Kirim
        </button>
      ) : (
        ""
      )}
    </div>
  );
};
export default ApprovalSP;
