import { useEffect, useRef, useState } from "react";
import { postAxios } from "../../util/apiCall";
import { noFormat, formatDate, today } from "../../util/formatDate";
import moment from "moment";
import axios from "axios";
import { Html5Qrcode } from "html5-qrcode";

export const usePatrolQc = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const ref = useRef(null);
  const [popUp, setPopUp] = useState(false);
  const [subMenu, setSubMenu] = useState("patrol");
  const [errMsg, setErrMsg] = useState("");
  const [dataQrMesin, setDataQrMesin] = useState();
  const [dataPatrol, setDataPatrol] = useState();
  const [datePatrolQc, setDatePatrolQc] = useState(today);
  const [shiftPatrol, setShiftPatrol] = useState("Pilih Shift");
  const [mesinPatrol, setMesinPatrol] = useState("Pilih Mesin");
  const [shiftHistoryPatrol, setShiftHistoryPatrol] = useState("Pilih Shift");
  const [mesinPick, setMesinPick] = useState("Pilih Nomor Mesin");
  const [troubleDesc, setTroubleDesc] = useState("");

  useEffect(() => {
    if (subMenu === "patrol") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getMesin`,
        {
          tim: dataUser.tim,
        },
        dataUser.auth,
        setDataQrMesin,
        ""
      );
    }
  }, [subMenu]);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getPatrol`,
      {
        tim: dataUser.tim,
        shift: shiftHistoryPatrol,
        mesin: mesinPatrol === "Pilih Mesin" ? "" : mesinPatrol,
        date: moment(datePatrolQc).utcOffset("+0700").format("YYYY-MM-DD"),
      },
      dataUser.auth,
      setDataPatrol,
      ""
    );
  }, [shiftHistoryPatrol, datePatrolQc, mesinPatrol]);

  const handlePickLaporan = () => {
    if (mesinPick !== "Pilih Nomor Mesin" && shiftPatrol !== "Pilih Shift") {
      const html5QrCode = new Html5Qrcode("patrol");

      let find =
        dataQrMesin && dataQrMesin.find((o) => o.id_mesin === mesinPick);

      const qrCodeSuccessCallback = async (decodedText) => {
        if (find.id_mesin === decodedText) {
          html5QrCode.pause();
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/submitPatrol`,
            {
              tim: dataUser.tim,
              name: dataUser.name,
              timestamp: noFormat,
              filterDate: formatDate,
              shift: shiftPatrol,
              id_mesin: find.id_mesin,
              mesin: find.mesin,
              troubleDesc: troubleDesc,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.auth}`,
              },
            }
          );
          if (data.data === 200) {
            setPopUp(true);
            setErrMsg("");
            setPopUp(false);
            setErrMsg("");
            setMesinPick("Pilih Nomor Mesin");
            setTroubleDesc("");
            html5QrCode.stop().then(() => {
              html5QrCode.clear();
            });
          } else {
            setErrMsg("Scan QR Belum Berhasil");
          }
        } else {
          if (html5QrCode.isScanning === true) {
            html5QrCode.stop().then(() => {
              html5QrCode.clear();
            });
          }
          setErrMsg("QR Code Tidak Cocok!");
        }
      };
      const onScanFailed = (err) => {
        // console.log(err);
      };
      const config = { fps: 10, qrbox: { width: 250, height: 250 } };
      html5QrCode.start(
        { facingMode: "environment" },
        config,
        qrCodeSuccessCallback,
        onScanFailed
      );
      setErrMsg("");
    } else {
      setErrMsg("Mohon Mengisi Semua Kolom!");
    }
  };

  const handleSubMenuHistoryPatrol = () => {
    setSubMenu("history");
  };

  return {
    popUp,
    setPopUp,
    ref,
    subMenu,
    setSubMenu,
    errMsg,
    handlePickLaporan,
    dataUser,
    dataQrMesin,
    mesinPick,
    setMesinPick,
    handleSubMenuHistoryPatrol,
    dataPatrol,
    shiftPatrol,
    setShiftPatrol,
    shiftHistoryPatrol,
    setShiftHistoryPatrol,
    datePatrolQc,
    setDatePatrolQc,
    mesinPatrol,
    setMesinPatrol,
    troubleDesc,
    setTroubleDesc,
  };
};
