import React from "react";
import { usePO } from "./usePO";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import "./PO.css";

const PO = () => {
  const {
    dataUser,
    subMenu,
    setSubMenu,
    dataListProduk,
    setCustomer,
    pickData,
    handlePickProduct,
    newColor,
    setJumlahPO,
    jumlahPO,
    setDatePO,
    datePO,
    handleKirim,
    dataPickProduk,
    customer,
    isError,
    isPopUp,
    setIsPopUp,
    listPO,
    monthDefaultFormat,
    setFilterBulan,
    filterBulan,
  } = usePO();

  const sortByName =
    listPO &&
    listPO.sort(function (a, b) {
      if (a.customer < b.customer) {
        return -1;
      }
      if (a.customer > b.customer) {
        return 1;
      }
      if (a.namaBarang < b.namaBarang) {
        return -1;
      }
      if (a.namaBarang > b.namaBarang) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="container_approval">
      <p className="title_inventory">List PO</p>
      {isPopUp ? (
        <PopUpComponent>
          <p>PO Berhasil Ditambahkan!</p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup background_red"
              onClick={() => setIsPopUp(false)}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {dataUser.role === "super_user" ||
      dataUser.role === "ppic_admin" ||
      dataUser.role === "ppic_user" ? (
        <div className="wrapper_sub_menu_history" style={{ margin: 0 }}>
          <p
            className={
              subMenu === "list"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenu("list")}
          >
            List PO
          </p>
          <p
            className={
              subMenu === "create"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenu("create")}
            // onClick={() => handleSubMenuHistoryPatrol()}
          >
            Create PO
          </p>
        </div>
      ) : (
        ""
      )}
      {subMenu === "list" ? (
        <div>
          <select
            defaultValue="Pilih Bulan"
            onChange={(e) => setFilterBulan(e.target.value)}
            className="input_select_memo_ppic"
            value={filterBulan}
            style={{ width: "10%" }}
          >
            <option selected={customer === ""} disabled>
              Pilih Bulan
            </option>
            {monthDefaultFormat &&
              monthDefaultFormat.map((data) => (
                <option value={data}>{data}</option>
              ))}
          </select>
          <div style={{ height: "80vh", overflowX: "auto" }}>
            <table>
              <tr>
                <th
                  className="sidebar"
                  style={{ background: "none", border: "none" }}
                >
                  CUSTOMER
                </th>
                <th
                  className="sidebar"
                  style={{
                    background: "none",
                    left: "139px",
                    border: "none",
                  }}
                >
                  NAMA BARANG
                </th>
                <th
                  className="sidebar"
                  style={{
                    background: "none",
                    left: "256px",
                    border: "none",
                  }}
                >
                  WARNA
                </th>
              </tr>
              {sortByName &&
                sortByName.map((obj) => {
                  const sortByTanggal =
                    obj &&
                    obj.data.sort(function (a, b) {
                      if (a.id < b.id) {
                        return -1;
                      }
                      if (a.id > b.id) {
                        return 1;
                      }
                      return 0;
                    });

                  return (
                    <tr>
                      <td
                        className="sidebar"
                        style={{
                          background: "lightgrey",
                          maxWidth: "116px",
                          wordBreak: "break-word",
                          borderBottom: "1px solid white",
                        }}
                      >
                        {obj.customer}
                      </td>
                      <td
                        className="sidebar"
                        style={{
                          left: "139px",
                          background: "lightgrey",
                          maxWidth: "116px",
                          wordBreak: "break-word",
                          borderBottom: "1px solid white",
                        }}
                      >
                        {obj.namaBarang}
                      </td>
                      <td
                        className="sidebar"
                        style={{
                          left: "256px",
                          background: "lightgrey",
                          maxWidth: "116px",
                          wordBreak: "break-word",
                          borderBottom: "1px solid white",
                        }}
                      >
                        {obj.color}
                      </td>

                      {sortByTanggal &&
                        sortByTanggal.map((e) => {
                          return (
                            <td
                              style={{
                                padding: "0px 24px 18px 24px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: 0,
                                  fontWeight: "bold",
                                  fontSize: "18px",
                                }}
                              >
                                {e.id}
                              </p>
                              <p style={{ marginBottom: 0 }}>
                                {e.jumlahPO} Pcs
                              </p>
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
            </table>
          </div>
        </div>
      ) : (
        <div>
          {isError ? (
            <p
              style={{ color: "red", fontWeight: "bold", textAlign: "center" }}
            >
              Mohon Mengisi Semua Kolom!
            </p>
          ) : (
            ""
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              margin: "24px 0 24px 0",
            }}
          >
            <select
              defaultValue="Pilih Customer"
              onChange={(e) => setCustomer(e.target.value)}
              className="input_select_memo_ppic"
              style={{ width: "10%" }}
            >
              <option selected={customer === ""} disabled>
                Pilih Customer
              </option>
              {dataListProduk &&
                dataListProduk.map((data) => (
                  <option value={data.company}>{data.company}</option>
                ))}
            </select>

            <select
              defaultValue="Pilih Produk"
              onChange={(e) => handlePickProduct(e)}
              className="input_select_memo_ppic"
              style={{ width: "30%" }}
            >
              <option selected={dataPickProduk === ""} disabled>
                Pilih Produk
              </option>
              {pickData &&
                pickData.data.map((data, index) => (
                  <option key={index} value={index}>
                    {data.name}
                  </option>
                ))}
            </select>
            <p>{newColor}</p>
            <input
              style={{ minWidth: "10%" }}
              type="number"
              className="input_select_memo_ppic"
              placeholder="Jumlah PO"
              value={jumlahPO}
              onChange={(e) => setJumlahPO(e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
            <input
              style={{ margin: "0" }}
              type="date"
              className="date_pick_memo_ppic"
              value={datePO}
              onChange={(e) => setDatePO(e.target.value)}
            />
          </div>
          <button
            style={{ float: "right" }}
            className="button_popup background_red"
            onClick={() => handleKirim()}
          >
            Kirim
          </button>
        </div>
      )}
    </div>
  );
};
export default PO;
