import React from "react";
import { useP3 } from "./useP3";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import { monthDefaultFormat } from "../../util/formatDate";
import "./P3.css";

const P3 = () => {
  const {
    dataUser,
    shift,
    setShift,
    handleSubmitP3,
    kepalaShift,
    setKepalaShift,
    itemProduksi,
    handleFormChange,
    isPopUp,
    setIsPopUp,
    subMenu,
    setSubMenu,
    setDateP3,
    dateP3,
    dataP3,
    dateSubmit,
    setDateSubmit,
    handleExportP3,
    popUpExport,
    setPopUpExport,
    bulan,
    setBulan,
    tahun,
    setTahun,
  } = useP3();

  const sortData =
    itemProduksi &&
    itemProduksi.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });
  const sortDataP3 =
    dataP3 &&
    dataP3.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="container_p3">
      {popUpExport ? (
        <PopUpComponent>
          <div style={{ display: "flex" }}>
            <div className="margin_right_27_qc">
              <p>Pilih Bulan</p>
              <select
                className="input_select_shift"
                value={bulan}
                onChange={(e) => setBulan(e.target.value)}
              >
                <option selected={bulan === "Pilih Bulan"} disabled>
                  Pilih Bulan
                </option>
                {monthDefaultFormat.map((data) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </div>
            <div className="margin_right_27_qc">
              <p>Pilih Tahun</p>
              <select
                defaultValue="Pilih No Mesin"
                onChange={(e) => setTahun(e.target.value)}
                className="input_select_memo_ppic"
                value={tahun}
              >
                <option selected disabled>
                  Pilih Tahun
                </option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
              </select>
            </div>
          </div>
          <button
            className="button_popup background_red"
            style={{ marginTop: "24px", float: "right" }}
            onClick={() => handleExportP3()}
          >
            Export
          </button>
        </PopUpComponent>
      ) : (
        ""
      )}
      {isPopUp ? (
        <PopUpComponent>
          <p>Selamat Data Kamu Telah Dikirim!</p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup background_red"
              onClick={() => setIsPopUp(false)}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <p className="title_inventory">Laporan Bahan P3</p>
      <div className="wrapper_sub_menu_history">
        <p
          className={
            subMenu === "Laporan"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenu("Laporan")}
        >
          Laporan
        </p>
        <p
          className={
            subMenu === "Riwayat"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenu("Riwayat")}
        >
          Riwayat
        </p>
      </div>
      {subMenu === "Laporan" ? (
        <>
          <div className="wrapper_input_p3">
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="margin_right_27_qc">
                <p>Pilih Tanggal</p>
                <input
                  type="date"
                  className="date_pick_memo_ppic"
                  value={dateSubmit}
                  onChange={(e) => setDateSubmit(e.target.value)}
                />
              </div>
              <div className="container_input_p3">
                <p>
                  Shift <b style={{ color: "red" }}>*</b>
                </p>
                <select
                  className="input_select_mesin"
                  value={shift}
                  onChange={(e) => setShift(e.target.value)}
                >
                  <option selected={shift === "Pilih Shift"} disabled>
                    Pilih
                  </option>
                  <option value="1">Shift 1</option>
                  <option value="2">Shift 2</option>
                  <option value="3">Shift 3</option>
                </select>
              </div>
              <div className="container_input_p3">
                <p>
                  Kepala Shift <b style={{ color: "red" }}>*</b>
                </p>
                <select
                  className="input_select_mesin"
                  value={kepalaShift}
                  onChange={(e) => setKepalaShift(e.target.value)}
                >
                  <option selected={shift === "Pilih Shift"} disabled>
                    Pilih
                  </option>
                  <option value="Supriadi">Supriadi</option>
                  <option value="Achmad S">Achmad S</option>
                  <option value="Saripudin">Saripudin</option>
                </select>
              </div>
            </div>
          </div>
          <div
            className="table_inventory_mobile"
            style={{ position: "absolute", zIndex: "-1", width: "85%" }}
          >
            <table style={{ width: "100%" }}>
              <tr>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                  }}
                >
                  MC
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Jumlah Bahan (Kg)
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Catatan
                </th>
              </tr>
              {sortData &&
                sortData.map((ele, index) => {
                  return (
                    <tr>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{
                          padding: "12px 6px",
                          lineHeight: "18px",
                        }}
                      >
                        {ele.mesin}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{
                          padding: "12px 6px",
                          lineHeight: "18px",
                        }}
                      >
                        <input
                          type="number"
                          style={{ width: "96px", height: "24px" }}
                          placeholder="Jumlah (Kg)"
                          onChange={(e) => handleFormChange(index, e, "BAKU")}
                          name="jumlahBahan"
                        />
                      </td>
                      <td className="container_input_total_produksi">
                        <textarea
                          style={{
                            width: "120px",
                            height: "48px",
                            fontSize: "12px",
                            fontFamily: "sans-serif",
                            resize: "none",
                          }}
                          onChange={(e) => handleFormChange(index, e, "BAKU")}
                          name="catatan"
                          placeholder="Catatan"
                        />
                      </td>
                    </tr>
                  );
                })}
            </table>
            <button
              className="button_submit_memo"
              onClick={() => handleSubmitP3()}
            >
              Kirim
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="wrapper_input_p3">
            <div className="margin_right_27_qc">
              <p>Pilih Tanggal</p>
              <input
                type="date"
                className="date_pick_memo_ppic"
                value={dateP3}
                onChange={(e) => setDateP3(e.target.value)}
              />
            </div>
            <div className="container_input_p3">
              <p>
                Shift <b style={{ color: "red" }}>*</b>
              </p>
              <select
                className="input_select_mesin"
                value={shift}
                onChange={(e) => setShift(e.target.value)}
              >
                <option selected={shift === "Pilih Shift"} disabled>
                  Pilih
                </option>
                <option value="1">Shift 1</option>
                <option value="2">Shift 2</option>
                <option value="3">Shift 3</option>
              </select>
            </div>
            {dataUser.role === "produksi_admin" ||
            dataUser.role === "super_user" ? (
              <p
                style={{
                  background: "green",
                  color: "white",
                  padding: "12px",
                  cursor: "pointer",
                  borderRadius: "6px",
                }}
                onClick={() => setPopUpExport(true)}
              >
                Export P3
              </p>
            ) : (
              ""
            )}
          </div>
          <div
            className="table_inventory_mobile"
            style={{ position: "absolute", zIndex: "-1", width: "85%" }}
          >
            <table style={{ width: "100%" }}>
              <tr>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                  }}
                >
                  MC
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Jumlah Bahan (Kg)
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Catatan
                </th>
              </tr>
              {sortDataP3 &&
                sortDataP3.map((ele, index) => {
                  return (
                    <tr>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{
                          padding: "12px 6px",
                          lineHeight: "18px",
                        }}
                      >
                        {ele.mesin}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{
                          padding: "12px 6px",
                          lineHeight: "18px",
                        }}
                      >
                        {ele.jumlahBahan} Kg
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{
                          padding: "12px 6px",
                          lineHeight: "18px",
                        }}
                      >
                        {ele.catatan}
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </>
      )}
    </div>
  );
};
export default P3;
