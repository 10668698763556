import React from "react";
import TableMemoPPIC from "../../components/TableMemoPPIC/TableMemoPPIC";
import TableMemoGantiMold from "../../components/TableMemoGantiMold/TableMemoGantiMold";
import { useMemoPPIC } from "./useMemoPPIC";
import PopUpComponent from "../../components/PopUp/PopUpComponent";

import "./MemoPPIC.css";

const MemoPPIC = () => {
  const {
    listProduk,
    addFields,
    memoBahanPokok,
    memoBahanPendukung,
    handleFormChange,
    removeFields,
    handleChangeSubMenu,
    subMenuMemo,
    handleSubmitBahanBaku,
    handleSubmitBahanPendukung,
    dateMemoPPIC,
    setDateMemoPPIC,
    popUpMemoPPIC,
    setPopUpMemoPPIC,
    handleSubmitBahanSatuan,
    memoBahanSatuan,
    memoGantiUtama,
    memoGantiSatuan,
    handleFormChangeGantiUtama,
    handleFormChangeGantiSatuan,
    handleSubmitGantiMold,
    handleDeleteMemoGantiMold,
    isEditJumlah,
    setIsEditJumlah,
  } = useMemoPPIC();

  const sortData =
    memoBahanPokok &&
    memoBahanPokok.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="container_memo_ppic">
      {popUpMemoPPIC ? (
        <PopUpComponent>
          <p>Terima Kasih! Data Kamu telah di submit!</p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup background_red"
              onClick={() => setPopUpMemoPPIC(false)}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <p className="title_inventory">MEMO PPIC</p>
      <div className="wrapper_sub_menu_history" style={{ margin: 0 }}>
        <p
          className={
            subMenuMemo === "BAKU"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => handleChangeSubMenu("BAKU")}
        >
          Bahan Baku
        </p>
        <p
          className={
            subMenuMemo === "PENDUKUNG"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => handleChangeSubMenu("PENDUKUNG")}
        >
          Bahan Pendukung
        </p>
        <p
          className={
            subMenuMemo === "GANTI_MOLD"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => handleChangeSubMenu("GANTI_MOLD")}
        >
          Ganti Mold
        </p>
      </div>
      <br />
      {subMenuMemo === "BAKU" ? (
        <>
          <p>Pilih Tanggal</p>
          <input
            type="date"
            className="date_pick_memo_ppic"
            defaultValue={dateMemoPPIC}
            value={dateMemoPPIC}
            onChange={(e) => setDateMemoPPIC(e.target.value)}
          />
          <TableMemoPPIC
            memoBahan={sortData}
            handleFormChange={handleFormChange}
            dataMemo={listProduk}
            jenisBahan="BAKU"
          />
          <button
            className="button_submit_memo"
            onClick={() => handleSubmitBahanBaku()}
          >
            Kirim
          </button>
        </>
      ) : subMenuMemo === "PENDUKUNG" ? (
        <>
          <p>Pilih Tanggal</p>
          <input
            type="date"
            className="date_pick_memo_ppic"
            value={dateMemoPPIC}
            onChange={(e) => setDateMemoPPIC(e.target.value)}
          />
          <TableMemoPPIC
            memoBahan={memoBahanPendukung}
            handleFormChange={handleFormChange}
            dataMemo={listProduk}
            jenisBahan="PENDUKUNG"
            isEditJumlah={isEditJumlah}
            setIsEditJumlah={() =>
              isEditJumlah ? setIsEditJumlah(false) : setIsEditJumlah(true)
            }
          />
          <div>
            <button
              className="button_tambah_kurang"
              onClick={() => addFields("PENDUKUNG")}
            >
              Tambah
            </button>
            <button
              className="button_tambah_kurang"
              onClick={() => removeFields("PENDUKUNG")}
            >
              kurang
            </button>
            <button
              className="button_submit_memo"
              onClick={() => handleSubmitBahanPendukung()}
            >
              Kirim
            </button>
          </div>
        </>
      ) : subMenuMemo === "SATUAN" ? (
        <>
          <p>Pilih Tanggal</p>
          <input
            type="date"
            className="date_pick_memo_ppic"
            value={dateMemoPPIC}
            onChange={(e) => setDateMemoPPIC(e.target.value)}
          />
          <TableMemoPPIC
            memoBahan={memoBahanSatuan}
            handleFormChange={handleFormChange}
            dataMemo={listProduk}
            jenisBahan="SATUAN"
          />
          <div>
            <button
              className="button_tambah_kurang"
              onClick={() => addFields("SATUAN")}
            >
              Tambah
            </button>
            <button
              className="button_tambah_kurang"
              onClick={() => removeFields("SATUAN")}
            >
              kurang
            </button>
            <button
              className="button_submit_memo"
              onClick={() => handleSubmitBahanSatuan()}
            >
              Kirim
            </button>
          </div>
        </>
      ) : (
        <>
          <p className="title_inventory">GANTI UTAMA</p>
          <p>Pilih Tanggal</p>
          <input
            type="date"
            className="date_pick_memo_ppic"
            value={dateMemoPPIC}
            onChange={(e) => setDateMemoPPIC(e.target.value)}
          />
          <TableMemoGantiMold
            memoBahan={memoGantiUtama}
            handleFormChange={handleFormChangeGantiUtama}
            dataMemo={listProduk}
            jenisBahan="SATUAN"
            isUtama={true}
            handleDeleteMemoGantiMold={handleDeleteMemoGantiMold}
          />
          <div>
            <button
              className="button_tambah_kurang"
              onClick={() => addFields("SATUAN", "UTAMA")}
            >
              Tambah
            </button>
            <button
              className="button_tambah_kurang"
              onClick={() => removeFields("SATUAN", "UTAMA")}
            >
              kurang
            </button>
          </div>
          <div>
            <button
              className="button_submit_memo"
              onClick={() => handleSubmitGantiMold()}
            >
              Kirim
            </button>
          </div>
        </>
      )}
    </div>
  );
};
export default MemoPPIC;
