import React from "react";
import "./TableInventory.css";

const TableInventory = ({ data }) => {
  const formatDot = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  return (
    <div className="wrapper_table">
      <div className="container_header">
        <div className="table_header">Material</div>
        <div className="table_header">Type</div>
        <div className="table_header">Color</div>
        <div className="table_header">Stock</div>
        <div className="table_header none">Updated By</div>
        <div className="table_header none">Update Time</div>
      </div>
      {data &&
        data.map((obj, i) => {
          const splitNama = obj.updatedBy.split(" ").slice(0, 2).join(" ");
          return (
            <div
              className={
                i % 2 === 0 ? "container_data" : "container_data background_odd"
              }
              key={i}
            >
              <div className="font_data">{obj.material}</div>
              <div className="font_data">{obj.type}</div>
              <div className="font_data">{obj.color}</div>
              <div className="font_data">
                {formatDot(Math.round(obj.weight))}{" "}
                {obj.jenis === "BAKU" ? "Kg" : "Pcs"}
              </div>
              <div className="font_data none">{splitNama}</div>
              <div className="font_data none">
                {obj.updateDate} {obj.updateTime}
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default TableInventory;
