import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import { validateRole } from "../../util/utilFunction";
import { getBulanNow, getTahunNow } from "../../util/formatDate";

export const useCheckSheet = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [listProduk, setListProduk] = useState();
  const [dataCheckSheet, setDataCheckSheet] = useState();
  const [isRefresh, setIsRefresh] = useState(false);
  const [customerCheckSheet, setCustomerCheckSheet] = useState("Customer");
  const [bulanCheckSheet, setBulanCheckSheet] = useState(getBulanNow);
  const [tahunCheckSheet, setTahunCheckSheet] = useState(getTahunNow);
  const [produkCheckSheet, setProdukCheckSheet] = useState("Produk");
  const [dataProdukPick, setDataProdukPick] = useState();

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/checkSheet`,
      {
        tim: dataUser.tim,
        bagian: validateRole(),
        month: `${bulanCheckSheet} ${tahunCheckSheet}`,
        jenisPekerjaan: validateRole() === "PR" ? "Print" : "",
        produk: produkCheckSheet,
      },
      dataUser.auth,
      setDataCheckSheet,
      ""
    );
    setIsRefresh(false);
  }, [
    bulanCheckSheet,
    tahunCheckSheet,
    isRefresh,
    customerCheckSheet,
    produkCheckSheet,
  ]);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/secondProcess`,
      { tim: dataUser.tim, bagian: validateRole(), type: "getProduk" },
      dataUser.auth,
      setListProduk,
      ""
    );
  }, []);

  useEffect(() => {
    listProduk &&
      listProduk.find((o) =>
        o.customer === customerCheckSheet ? setDataProdukPick(o.data) : ""
      );
  }, [customerCheckSheet]);

  let dataMerge = [];
  let dataTotalNG = [];
  dataCheckSheet &&
    dataCheckSheet.forEach((element) => {
      element.dataReject &&
        element.dataReject.forEach((obj) => {
          let newDate = new Date(obj.dateReject);
          let getDay = newDate.getDate();
          dataTotalNG.push({
            date: getDay,
            reject: Number(obj.reject),
            totalPengerjaan: element.jumlahOk + element.reject,
            downtime: element.downtime,
          });

          let el = dataMerge.filter(function (a) {
            return a.id === obj.id;
          });

          if (el.length) {
            el[0].data.push({
              date: getDay,
              reject: Number(obj.reject),
            });
          } else {
            dataMerge.push({
              id: obj.id,
              data: [
                {
                  date: getDay,
                  reject: Number(obj.reject),
                },
              ],
            });
          }
        });
    });

  const newList =
    dataTotalNG &&
    dataTotalNG.reduce((obj, item) => {
      let find = obj.find((i) => i.date === item.date);
      let _d = {
        ...item,
      };
      find ? (find.reject += item.reject) : obj.push(_d);
      return obj;
    }, []);

  return {
    dataMerge,
    newList,
    bulanCheckSheet,
    setBulanCheckSheet,
    tahunCheckSheet,
    setTahunCheckSheet,
    produkCheckSheet,
    setProdukCheckSheet,
    customerCheckSheet,
    setCustomerCheckSheet,
    listProduk,
    dataProdukPick,
  };
};
