import moment from "moment";
import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import {
  getDateUtil,
  monthDefaultFormat,
  today,
  tomorrow,
} from "../../util/formatDate";
import * as XLSX from "xlsx";

export const useHistory = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [subMenuHistory, setSubMenuHistory] = useState("stockIn");
  const [dataHistoryPPIC, setDataHistoryPPIC] = useState();
  const [dataHistorySatuanPPIC, setDataHistorySatuanPPIC] = useState();
  const [dataHistoryPendukungPPIC, setDataHistoryPendukungPPIC] = useState();
  const [dataHistoryRequestIn, setDataHistoryRequestIn] = useState();
  const [memoGanti, setMemoGanti] = useState();
  const [dateHistoryPPIC, setDateHistoryPPIC] = useState(today);
  const [dateQc, setDateQc] = useState(today);
  const [shiftQc, setShiftQc] = useState("Pilih Shift");
  const [dataQc, setDataQc] = useState();
  const [dataProduksi, setDataProduksi] = useState();
  const [dataProduksiPendukung, setDataProduksiPendukung] = useState();
  const [shiftProduksi, setShiftProduksi] = useState("1");
  const [shiftStockOut, setShiftStockOut] = useState("1");
  const [shiftVP, setShiftVP] = useState("1");
  const [dateProduksi, setDateProduksi] = useState(today);
  const [dateStockOut, setDateStockOut] = useState(today);
  const dateNow = new Date();
  const filterMonth = dateNow.getMonth();
  const [bulan, setBulan] = useState(monthDefaultFormat[filterMonth]);
  const [historyDataOperator, setHistoryDataOperator] = useState();
  const [popUpGantiMold, setPopUpGantiMold] = useState(false);
  const [dataPopUpGantiMold, setDataPopUpGantiMold] = useState();
  const [bagian, setBagian] = useState("");
  const [listTugas, setListTugas] = useState();
  const [listTugasTemp, setListTugasTemp] = useState();
  const [refresh, setRefresh] = useState(false);
  const [commentGantiMold, setCommentGantiMold] = useState("");
  const [jenisDataPopUp, setJenisDataPopUp] = useState("");
  const [dateCycleTime, setDateCycleTime] = useState(today);
  const [popUpLaporanProduksi, setPopUpLaporanProduksi] = useState("");
  const [dariTanggalLaporanProduksi, setDariTanggalLaporanProduksi] =
    useState(today);
  const [sampaiTanggalLaporanProduksi, setSampaiTanggalLaporanProduksi] =
    useState(today);
  const [dataExport, setDataExport] = useState();
  const [dataExportCycle, setDataExportCycle] = useState();
  const [listTopPlate, setListTopPlate] = useState();
  const [dataLaporanPasangKaret, setDataLaporanPasangKaret] = useState();
  const [dateLaporanPasangKaret, setDateLaporanPasangKaret] = useState(today);
  const [dateUntilLaporanPasangKaret, setDateUntilLaporanPasangKaret] =
    useState(today);
  const [dataExportPasangKaret, setDataExportPasangKaret] = useState();
  const [dataDetailProduk, setDataDetailProduk] = useState();

  useEffect(() => {
    if (subMenuHistory === "stockOut") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getDashboard`,
        {
          date: getDateUtil(dateStockOut),
          tim: dataUser.tim,
          shift: shiftStockOut,
          jenis: "BAKU",
        },
        dataUser.auth,
        setDataProduksi,
        ""
      );

      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getDashboard`,
        {
          date: getDateUtil(dateStockOut),
          tim: dataUser.tim,
          shift: shiftStockOut,
          jenis: "PENDUKUNG",
        },
        dataUser.auth,
        setDataProduksiPendukung,
        ""
      );
    }
  }, [dateStockOut, shiftStockOut, subMenuHistory]);

  useEffect(() => {
    if (subMenuHistory === "ventPlug") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getLaporanPasangKaret`,
        {
          tim: dataUser.tim,
          date: getDateUtil(dateLaporanPasangKaret),
          isKepala: true,
          isAdmin: true,
          type: "approvalKepala",
        },
        dataUser.auth,
        setDataLaporanPasangKaret,
        ""
      );
    }
  }, [dateLaporanPasangKaret, subMenuHistory]);

  useEffect(() => {
    if (subMenuHistory === "detailProduk") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/detailProduk`,
        {
          tim: dataUser.tim,
          date: getDateUtil(dateQc),
          shift: shiftQc,
          type: "get",
        },
        dataUser.auth,
        setDataDetailProduk,
        ""
      );
    }
  }, [dateQc, shiftQc]);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getHistoryRequestIn`,
      { tim: dataUser.tim, month: bulan },
      dataUser.auth,
      setDataHistoryRequestIn,
      ""
    );
  }, [bulan]);

  useEffect(() => {
    if (
      dataUser.role === "produksi_admin" ||
      dataUser.role === "super_user" ||
      dataUser.role === "ppic_admin"
    ) {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getDashboardOperator`,
        {
          operator: dataUser.name,
          shift: shiftProduksi,
          date: getDateUtil(dateProduksi),
          type: "admin",
          tim: dataUser.tim,
          isAdmin: true,
        },
        dataUser.auth,
        setHistoryDataOperator,
        ""
      );
      setSubMenuHistory("laporanProduksi");
    }
  }, [shiftProduksi, dateProduksi]);

  useEffect(() => {
    if (subMenuHistory === "gantiMold") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getHistoryGantiMold`,
        { date: getDateUtil(dateHistoryPPIC), tim: dataUser.tim },
        dataUser.auth,
        setMemoGanti,
        ""
      );
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getListTugas`,
        { tim: dataUser.tim },
        dataUser.auth,
        setListTugasTemp,
        ""
      );

      setRefresh(false);
    }
  }, [dateHistoryPPIC, subMenuHistory, refresh]);

  useEffect(() => {
    const interval = setInterval(() => {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getHistoryGantiMold`,
        { date: getDateUtil(dateHistoryPPIC), tim: dataUser.tim },
        dataUser.auth,
        setMemoGanti,
        ""
      );
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  const handleSubMenuHistory = (data) => {
    setSubMenuHistory(data);

    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getMemoPpic`,
      { date: getDateUtil(dateHistoryPPIC), jenis: "BAKU", tim: dataUser.tim },
      dataUser.auth,
      setDataHistoryPPIC,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getMemoPpic`,
      {
        date: getDateUtil(dateHistoryPPIC),
        jenis: "PENDUKUNG",
        tim: dataUser.tim,
      },
      dataUser.auth,
      setDataHistoryPendukungPPIC,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getMemoPpic`,
      {
        date: getDateUtil(dateHistoryPPIC),
        jenis: "SATUAN",
        tim: dataUser.tim,
      },
      dataUser.auth,
      setDataHistorySatuanPPIC,
      ""
    );
  };

  const handleChangeDate = (e) => {
    setDateHistoryPPIC(e.target.value);
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getMemoPpic`,
      { date: getDateUtil(e.target.value), jenis: "BAKU", tim: dataUser.tim },
      dataUser.auth,
      setDataHistoryPPIC,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getMemoPpic`,
      {
        date: getDateUtil(e.target.value),
        jenis: "PENDUKUNG",
        tim: dataUser.tim,
      },
      dataUser.auth,
      setDataHistoryPendukungPPIC,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getMemoPpic`,
      {
        date: getDateUtil(dateHistoryPPIC),
        jenis: "SATUAN",
        tim: dataUser.tim,
      },
      dataUser.auth,
      setDataHistorySatuanPPIC,
      ""
    );
  };

  const handleCariQc = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getDataTopPlate`,
      {
        tim: dataUser.tim,
        type: "history",
        date: getDateUtil(dateQc),
        shift: shiftQc,
      },
      dataUser.auth,
      setListTopPlate,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getQualityControl`,
      {
        date: getDateUtil(dateQc),
        shift: shiftQc,
        tim: dataUser.tim,
      },
      dataUser.auth,
      setDataQc,
      ""
    );
  };

  const handleEditChecklist = (data, jenis) => {
    setJenisDataPopUp(jenis);
    setDataPopUpGantiMold(data);
    setPopUpGantiMold(true);
    if (dataUser.role === "produksi_leader") {
      setListTugas(listTugasTemp.produksi);
      setBagian("Produksi");
    } else if (
      dataUser.role === "mold_admin" ||
      dataUser.role === "mold_user"
    ) {
      setListTugas(listTugasTemp.mold);
      setBagian("Mold");
    } else if (
      dataUser.role === "mesin_admin" ||
      dataUser.role === "mesin_user"
    ) {
      setListTugas(listTugasTemp.mesin);
      setBagian("Mesin");
    } else if (
      dataUser.role === "warehouse_admin" ||
      dataUser.role === "warehouse_user"
    ) {
      setListTugas(listTugasTemp.warehouse);
      setBagian("Warehouse");
    } else if (dataUser.role === "qc_user" || dataUser.role === "qc_admin") {
      setListTugas(listTugasTemp.quality);
      setBagian("Quality");
    }
  };

  const handleFormChange = (index, event) => {
    let data = [...listTugas];
    data[index][event.target.name] = event.target.value;
    setListTugas(data);
  };

  const handleKirimStatus = (status) => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/approvalGantiMold`,
      {
        role: dataUser.role,
        tim: dataUser.tim,
        data: dataPopUpGantiMold,
        status: status,
        name: dataUser.name,
        jenis: jenisDataPopUp,
        date: getDateUtil(dateHistoryPPIC),
        tomorrow: getDateUtil(tomorrow),
        dateGantiMemo: getDateUtil(today),
      },
      dataUser.auth,
      setDataHistorySatuanPPIC,
      ""
    );
    setPopUpGantiMold(false);
    setJenisDataPopUp("");
    setRefresh(true);
  };

  const handleKirimComment = (mesin, jenis) => {
    if (commentGantiMold !== "") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/addComment`,
        {
          role: dataUser.role,
          tim: dataUser.tim,
          mesin: mesin,
          name: dataUser.name,
          desc: commentGantiMold,
          jenis: jenis,
          date: getDateUtil(dateHistoryPPIC),
          commentDate: getDateUtil(today),
        },
        dataUser.auth,
        setDataHistorySatuanPPIC,
        ""
      );
      setCommentGantiMold("");
      setSubMenuHistory("gantiMold");
      setRefresh(true);
    }
  };

  const exportLaporanProduksi = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/exportLaporanProduksi`,
      {
        tim: dataUser.tim,
        date: getDateUtil(dariTanggalLaporanProduksi),
        dateUntil: getDateUtil(sampaiTanggalLaporanProduksi),
        type: "laporanProduksi",
      },
      dataUser.auth,
      setDataExport,
      ""
    );

    const firstSort =
      dataExport &&
      dataExport.sort(function (a, b) {
        if (Number(a.mesin) < Number(b.mesin)) {
          return -1;
        }
        if (Number(a.mesin) > Number(b.mesin)) {
          return 1;
        }
        return 0;
      });

    const secondSort =
      firstSort &&
      firstSort.sort(function (a, b) {
        return a.isoDate < b.isoDate ? -1 : a.isoDate > b.isoDate ? 1 : 0;
      });

    const filteredDataExcel =
      secondSort &&
      secondSort.map((obj) => {
        const downtime = obj.downtime ? obj.downtime : 0;
        const dataCounterAwal = obj.counterAwal ? obj.counterAwal : 0;
        const dataCounterAkhir = obj.counterAkhir ? obj.counterAkhir : 0;
        const reject = obj.jumlahNG ? obj.jumlahNG : 0;
        const jumlahBarang = obj.jumlahBarang ? obj.jumlahBarang : 0;
        const formula =
          (28800 - downtime * 60) / (dataCounterAkhir - dataCounterAwal);
        const formulaDefect = (reject / (jumlahBarang + reject)) * 100;
        const dataTargetProduk = obj.targetProduk ? obj.targetProduk : 0;
        const dataAktualCavity = obj.aktualCavity ? obj.aktualCavity : 0;
        const formulaTargetProduksi =
          dataTargetProduk * dataAktualCavity * ((480 - downtime) / 480);
        const validateFormula = !formulaDefect ? 0 : formulaDefect;
        const Bubble =
          obj && obj.perincianNG.find((x) => (x.id === "Bubble" ? x : ""));
        const BedaWarna =
          obj && obj.perincianNG.find((x) => (x.id === "Beda Warna" ? x : ""));
        const BekasPotongGate =
          obj &&
          obj.perincianNG.find((x) => (x.id === "Bekas Potong Gate" ? x : ""));
        const Bergelombang =
          obj &&
          obj.perincianNG.find((x) => (x.id === "Bergelombang" ? x : ""));
        const BlackWhiteSpot =
          obj &&
          obj.perincianNG.find((x) => (x.id === "Black / White Spot" ? x : ""));
        const Bolong =
          obj && obj.perincianNG.find((x) => (x.id === "Bolong" ? x : ""));
        const Burning =
          obj && obj.perincianNG.find((x) => (x.id === "Burning" ? x : ""));
        const ExSilicon =
          obj && obj.perincianNG.find((x) => (x.id === "Ex Silicon" ? x : ""));
        const Crack =
          obj &&
          obj.perincianNG.find((x) =>
            x.id === "Crack / Fragile (Retak / Pecah)" ? x : ""
          );
        const Deformasi =
          obj &&
          obj.perincianNG.find((x) =>
            x.id === "Dimensi Pole Berubah / Deformasi" ? x : ""
          );
        const Flash =
          obj && obj.perincianNG.find((x) => (x.id === "Flash" ? x : ""));
        const FlowMark =
          obj &&
          obj.perincianNG.find((x) =>
            x.id === "Flow Mark / Jetting" ? x : ""
          );
        const InjectKosong =
          obj &&
          obj.perincianNG.find((x) => (x.id === "Inject Kosong" ? x : ""));
        const InjectDouble =
          obj &&
          obj.perincianNG.find((x) => (x.id === "Inject Double" ? x : ""));
        const PoleTerbalik =
          obj &&
          obj.perincianNG.find((x) =>
            x.id === "Kabel / Pole Terbalik, kembar, kejepit" ? x : ""
          );
        const Kabut =
          obj &&
          obj.perincianNG.find((x) => (x.id === "Kabut / Berawan" ? x : ""));
        const Unfill =
          obj &&
          obj.perincianNG.find((x) =>
            x.id === "Kurang Bahan / Unfill" ? x : ""
          );
        const Kepanasan =
          obj &&
          obj.perincianNG.find((x) =>
            x.id === "Lobdrok / Kepanasan" ? x : ""
          );
        const TestQC =
          obj &&
          obj.perincianNG.find((x) =>
            x.id === "(Test Bocor) Qc / Checker" ? x : ""
          );
        const Kotor =
          obj && obj.perincianNG.find((x) => (x.id === "Kotor" ? x : ""));
        const Baret =
          obj &&
          obj.perincianNG.find((x) => (x.id === "Scratch / Baret" ? x : ""));
        const ShinkMark =
          obj && obj.perincianNG.find((x) => (x.id === "Shink Mark" ? x : ""));
        const Silver =
          obj && obj.perincianNG.find((x) => (x.id === "Silver" ? x : ""));
        const TanpaTimah =
          obj &&
          obj.perincianNG.find((x) =>
            x.id === "Tanpa Timah / Kuningan / Connector / Kabel" ? x : ""
          );
        const TanpaKeterangan =
          obj &&
          obj.perincianNG.find((x) => (x.id === "Tanpa Keterangan" ? x : ""));
        const TimahNongol =
          obj &&
          obj.perincianNG.find((x) => (x.id === "Timah Nongol" ? x : ""));
        const Weldline =
          obj && obj.perincianNG.find((x) => (x.id === "Weldline" ? x : ""));
        const WhiteMark =
          obj &&
          obj.perincianNG.find((x) =>
            x.id === "White Mark / Ejector Mark" ? x : ""
          );
        const BercakTimah =
          obj &&
          obj.perincianNG.find((x) => (x.id === "Bercak Timah" ? x : ""));
        const Nempel =
          obj && obj.perincianNG.find((x) => (x.id === "Nempel" ? x : ""));
        const GarisAir =
          obj && obj.perincianNG.find((x) => (x.id === "Garis Air" ? x : ""));
        const BercakSilicon =
          obj &&
          obj.perincianNG.find((x) => (x.id === "Bercak Silicon" ? x : ""));
        const Oval =
          obj && obj.perincianNG.find((x) => (x.id === "Oval" ? x : ""));
        const ExAutosol =
          obj && obj.perincianNG.find((x) => (x.id === "Ex Autosol" ? x : ""));
        const Ketarik =
          obj && obj.perincianNG.find((x) => (x.id === "Ketarik" ? x : ""));

        const validateNama = obj.operator.split(" ").slice(0, 2).join(" ");

        return {
          TANGGAL: obj.date,
          "No MC": obj.mesin,
          "PART NAME": obj.moldType,
          WARNA: obj.color,
          CUSTOMER: obj.customer,
          SHIFT: obj.approvedKepala,
          "COUNTER AWAL": obj.counterAwal,
          "COUNTER AKHIR": obj.counterAkhir,
          "SELISIH COUNTER": obj.counterAkhir - obj.counterAwal,
          "PRODUK OK": obj.jumlahBarang,
          "PRODUK REJECT": obj.jumlahNG,
          "TOTAL PRODUKSI": obj.jumlahBarang + obj.jumlahNG,
          "DEFECT (%)": validateFormula.toFixed(2),
          "CYCLE TIME": formula.toFixed(1),
          OPERATOR: validateNama,
          BERAT: obj.beratQc,
          TARGET: formulaTargetProduksi.toFixed(0),
          "HASIL PASANG KARET VP": "",
          "DOWNTIME PASANG KARET VP": "",
          CAVITY: obj.aktualCavity,
          "DOWNTIME PRODUKSI": downtime,
          "Beda Warna": BedaWarna && BedaWarna.totalNG,
          "Bekas potongan gate": BekasPotongGate && BekasPotongGate.totalNG,
          Bergelombang: Bergelombang && Bergelombang.totalNG,
          "Black Spot/White Spot": BlackWhiteSpot && BlackWhiteSpot.totalNG,
          Bolong: Bolong && Bolong.totalNG,
          Bubble: Bubble && Bubble.totalNG,
          Burning: Burning && Burning.totalNG,
          "Ex Silicon": ExSilicon && ExSilicon.totalNG,
          "Crack/Fragile (retak/pecah)": Crack && Crack.totalNG,
          "Dimensi pole berubah/Deformasi": Deformasi && Deformasi.totalNG,
          "(Test Bocor) QC/Checker": TestQC && TestQC.totalNG,
          Flash: Flash && Flash.totalNG,
          "Flow Mark/Jetting": FlowMark && FlowMark.totalNG,
          "Inject Kosong": InjectKosong && InjectKosong.totalNG,
          "Inject Double": InjectDouble && InjectDouble.totalNG,
          "Kabel/Pole terbalik kembar kejepit":
            PoleTerbalik && PoleTerbalik.totalNG,
          "Kabut/Berawan": Kabut && Kabut.totalNG,
          Kotor: Kotor && Kotor.totalNG,
          "Kurang bahan/Unfill": Unfill && Unfill.totalNG,
          "Lobdrok/Kepanasan": Kepanasan && Kepanasan.totalNG,
          Nempel: Nempel && Nempel.totalNG,
          "Scratch/Baret": Baret && Baret.totalNG,
          "Shink Mark": ShinkMark && ShinkMark.totalNG,
          Silver: Silver && Silver.totalNG,
          "Tanpa timah/Kuningan/ Conector/ Kabel":
            TanpaTimah && TanpaTimah.totalNG,
          "TANPA KETERANGAN": TanpaKeterangan && TanpaKeterangan.totalNG,
          "Timah Nongol": TimahNongol && TimahNongol.totalNG,
          Weldline: Weldline && Weldline.totalNG,
          "White Mark/ Ejector Mark": WhiteMark && WhiteMark.totalNG,
          "Bercak Timah": BercakTimah && BercakTimah.totalNG,
          "Garis Air": GarisAir && GarisAir.totalNG,
          "Bercak Slicon": BercakSilicon && BercakSilicon.totalNG,
          Oval: Oval && Oval.totalNG,
          "EX AUTOSOL ": ExAutosol && ExAutosol.totalNG,
          KETARIK: Ketarik && Ketarik.totalNG,
          "Reject (Sortir Second Process)": "",
        };
      });

    const workSheet = XLSX.utils.json_to_sheet(
      filteredDataExcel && filteredDataExcel
    );

    workSheet["!cols"] = [
      { wch: 13 },
      { wch: 6 },
      { wch: 40 },
      { wch: 13 },
      { wch: 10 },
      { wch: 14 },
      { wch: 14 },
      { wch: 14 },
      { wch: 15 },
      { wch: 14 },
      { wch: 14 },
      { wch: 15 },
      { wch: 10 },
      { wch: 10 },
      { wch: 25 },
      { wch: 6 },
      { wch: 21 },
      { wch: 26 },
      { wch: 6.5 },
      { wch: 20 },
    ];
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Laporan");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workBook,
      `Laporan Produksi ${getDateUtil(
        dariTanggalLaporanProduksi
      )} - ${getDateUtil(sampaiTanggalLaporanProduksi)}.xlsx`
    );

    setPopUpLaporanProduksi("");
    setDariTanggalLaporanProduksi(today);
    setSampaiTanggalLaporanProduksi(today);
  };

  const exportDataCycleTime = async () => {
    await postAxios(
      `${process.env.REACT_APP_ENDPOINT}/exportLaporanProduksi`,
      {
        tim: dataUser.tim,
        date: getDateUtil(dateCycleTime),
        type: "dataCycleTime",
      },
      dataUser.auth,
      setDataExportCycle,
      ""
    );

    const output = Object.values(
      dataExportCycle &&
        dataExportCycle.reduce((acc, cur) => {
          acc[cur.moldType] = acc[cur.moldType] || {
            date: cur.date,
            moldType: cur.moldType,
            color: cur.color,
            aktualCavity: cur.aktualCavity,
            customer: cur.customer,
            target: cur.targetProduk * cur.aktualCavity,
            mesin: cur.mesin,
            cycleTime: Number((28800 / cur.targetProduk).toFixed(1)),
            data: [],
          };
          acc[cur.moldType].data.push(cur);
          return acc;
        }, {})
    );

    const sortByName =
      output &&
      output.sort(function (a, b) {
        if (a.customer < b.customer) {
          return -1;
        }
        if (a.customer > b.customer) {
          return 1;
        }
        if (a.moldType < b.moldType) {
          return -1;
        }
        if (a.moldType > b.moldType) {
          return 1;
        }
        return 0;
      });

    const filteredDataExcel = sortByName.map((obj) => {
      const result = (data) => {
        return obj.data.find((e) => e.shift === data);
      };

      const dataShiftOne = result("1");
      const dataShiftTwo = result("2");
      const dataShiftThree = result("3");
      const downtime = (e) => {
        if (e === "1") {
          return dataShiftOne ? dataShiftOne.downtime : 0;
        } else if (e === "2") {
          return dataShiftTwo ? dataShiftTwo.downtime : 0;
        } else {
          return dataShiftThree ? dataShiftThree.downtime : 0;
        }
      };
      const counterAkhirValidation = (e) => {
        if (e === "1") {
          return dataShiftOne ? dataShiftOne.counterAkhir : 0;
        } else if (e === "2") {
          return dataShiftTwo ? dataShiftTwo.counterAkhir : 0;
        } else {
          return dataShiftThree ? dataShiftThree.counterAkhir : 0;
        }
      };
      const counterAwalValidation = (e) => {
        if (e === "1") {
          return dataShiftOne ? dataShiftOne.counterAwal : 0;
        } else if (e === "2") {
          return dataShiftTwo ? dataShiftTwo.counterAwal : 0;
        } else {
          return dataShiftThree ? dataShiftThree.counterAwal : 0;
        }
      };
      const defectValidation = (e) => {
        if (e === "1") {
          const reject = dataShiftOne ? dataShiftOne.jumlahNG : 0;
          const jumlah = dataShiftOne ? dataShiftOne.jumlahBarang : 0;
          return (reject / (jumlah + reject)) * 100;
        } else if (e === "2") {
          const reject = dataShiftTwo ? dataShiftTwo.jumlahNG : 0;
          const jumlah = dataShiftTwo ? dataShiftTwo.jumlahBarang : 0;
          return (reject / (jumlah + reject)) * 100;
        } else {
          const reject = dataShiftThree ? dataShiftThree.jumlahNG : 0;
          const jumlah = dataShiftThree ? dataShiftThree.jumlahBarang : 0;
          return (reject / (jumlah + reject)) * 100;
        }
      };

      const validationMesin = (shift) => {
        if (shift === "1") {
          if (dataShiftOne && dataShiftOne.tim === 1) {
            if (
              dataShiftOne.approvedKepala === "MARDIANSYAH" ||
              dataShiftOne.approvedKepala === "SLAMET WAHYUDI" ||
              // dataShiftOne.approvedKepala === "OKA FITRA SITEPU" ||
              dataShiftOne.approvedKepala === "SARIPUDIN"
            ) {
              if (Number(dataShiftOne.mesin) <= 14) {
                return "SLAMET";
              } else if (
                Number(dataShiftOne.mesin) > 14 &&
                Number(dataShiftOne.mesin) < 29
              ) {
                return "MARDIANSYAH";
              } else {
                return "SARIPUDIN";
              }
            } else {
              if (Number(dataShiftOne.mesin) <= 14) {
                return "AHMAD H";
              } else if (
                Number(dataShiftOne.mesin) > 14 &&
                Number(dataShiftOne.mesin) < 29
              ) {
                return "OKA F";
              } else {
                return "ACHMAD S";
              }
            }
          }
        } else if (shift === "2") {
          if (dataShiftTwo && dataShiftTwo.tim === 1) {
            if (
              dataShiftTwo.approvedKepala === "SUPRIADI" ||
              dataShiftTwo.approvedKepala === "ZAKARIA" ||
              dataShiftTwo.approvedKepala === "YANDRI"
              // dataShiftTwo.approvedKepala === "SLAMET WAHYUDI"
            ) {
              if (Number(dataShiftTwo.mesin) <= 14) {
                return "ZAKARIA";
              } else if (
                Number(dataShiftTwo.mesin) > 14 &&
                Number(dataShiftTwo.mesin) < 29
              ) {
                return "YANDRI";
              } else {
                return "SUPRIADI";
              }
            }
          }
        } else if (shift === "3") {
          if (dataShiftThree.tim === 1) {
            if (
              dataShiftThree.approvedKepala === "AHMAD HAMBALI" ||
              dataShiftThree.approvedKepala === "OKA FITRA SITEPU" ||
              // dataShiftThree.approvedKepala === "YANDRI" ||
              dataShiftThree.approvedKepala === "ACHMAD SANIN"
            ) {
              if (Number(dataShiftThree.mesin) <= 14) {
                return "AHMAD H";
              } else if (
                Number(dataShiftThree.mesin) > 14 &&
                Number(dataShiftThree.mesin) < 29
              ) {
                return "OKA F";
              } else {
                return "ACHMAD S";
              }
            } else {
              if (Number(dataShiftThree.mesin) <= 14) {
                return "SLAMET";
              } else if (
                Number(dataShiftThree.mesin) > 14 &&
                Number(dataShiftThree.mesin) < 29
              ) {
                return "MARDIANSYAH";
              } else {
                return "SARIPUDIN";
              }
            }
          }
        }
      };

      return {
        Tgl: obj.date,
        "Nama Produk": obj.moldType,
        Warna: obj.color,
        Cust: obj.customer,
        "Cav.": obj.aktualCavity,
        Target: obj.target,
        Cycle: obj.cycleTime === Infinity ? "" : obj.cycleTime,
        "S1 Total": dataShiftOne
          ? dataShiftOne.jumlahBarang + dataShiftOne.jumlahNG
          : "",
        "S1 NG": dataShiftOne ? dataShiftOne.jumlahNG : "",
        "S1 OK": dataShiftOne ? dataShiftOne.jumlahBarang : "",
        "S1 %": isNaN(defectValidation("1"))
          ? ""
          : defectValidation("1").toFixed(2),
        "S1 CT Act.":
          (28800 - downtime("1") * 60) /
            (counterAkhirValidation("1") - counterAwalValidation("1")) ===
          Infinity
            ? ""
            : (
                (28800 - downtime("1") * 60) /
                (counterAkhirValidation("1") - counterAwalValidation("1"))
              ).toFixed(1),
        "CT Indikator 1":
          (28800 - downtime("1") * 60) /
            (counterAkhirValidation("1") - counterAwalValidation("1")) ===
          Infinity
            ? ""
            : (
                (28800 - downtime("1") * 60) /
                (counterAkhirValidation("1") - counterAwalValidation("1"))
              ).toFixed(1) - (obj.cycleTime === Infinity ? "" : obj.cycleTime),
        "S1 Operator": dataShiftOne
          ? dataShiftOne.operator.split(" ").slice(0, 2).join(" ")
          : "",
        "S2 Total": dataShiftTwo
          ? dataShiftTwo.jumlahBarang + dataShiftTwo.jumlahNG
          : "",
        "S2 NG": dataShiftTwo ? dataShiftTwo.jumlahNG : "",
        "S2 OK": dataShiftTwo ? dataShiftTwo.jumlahBarang : "",
        "S2 %": isNaN(defectValidation("2"))
          ? ""
          : defectValidation("2").toFixed(2),
        "S2 CT Act.":
          (28800 - downtime("2") * 60) /
            (counterAkhirValidation("2") - counterAwalValidation("2")) ===
          Infinity
            ? ""
            : (
                (28800 - downtime("2") * 60) /
                (counterAkhirValidation("2") - counterAwalValidation("2"))
              ).toFixed(1),
        "CT Indikator 2":
          (28800 - downtime("2") * 60) /
            (counterAkhirValidation("2") - counterAwalValidation("2")) ===
          Infinity
            ? ""
            : (
                (28800 - downtime("2") * 60) /
                (counterAkhirValidation("2") - counterAwalValidation("2"))
              ).toFixed(1) - (obj.cycleTime === Infinity ? "" : obj.cycleTime),
        "S2 Operator": dataShiftTwo
          ? dataShiftTwo.operator.split(" ").slice(0, 2).join(" ")
          : "",
        "S3 Total": dataShiftThree
          ? dataShiftThree.jumlahBarang + dataShiftThree.jumlahNG
          : "",
        "S3 NG": dataShiftThree ? dataShiftThree.jumlahNG : "",
        "S3 OK": dataShiftThree ? dataShiftThree.jumlahBarang : "",
        "S3 %": isNaN(defectValidation("3"))
          ? ""
          : defectValidation("3").toFixed(2),
        "S3 CT Act.":
          (28800 - downtime("3") * 60) /
            (counterAkhirValidation("3") - counterAwalValidation("3")) ===
          Infinity
            ? ""
            : (
                (28800 - downtime("3") * 60) /
                (counterAkhirValidation("3") - counterAwalValidation("3"))
              ).toFixed(1),
        "CT Indikator 3":
          (28800 - downtime("3") * 60) /
            (counterAkhirValidation("3") - counterAwalValidation("3")) ===
          Infinity
            ? ""
            : (
                (28800 - downtime("3") * 60) /
                (counterAkhirValidation("3") - counterAwalValidation("3"))
              ).toFixed(1) - (obj.cycleTime === Infinity ? "" : obj.cycleTime),
        "S3 Operator": dataShiftThree
          ? dataShiftThree.operator.split(" ").slice(0, 2).join(" ")
          : "",
        Mc: obj.mesin,
        "Shift 1": dataShiftOne ? validationMesin("1") : "",
        "Shift 2": dataShiftTwo ? validationMesin("2") : "",
        "Shift 3": dataShiftThree ? validationMesin("3") : "",
      };
    });

    const workSheet = XLSX.utils.json_to_sheet(
      filteredDataExcel && filteredDataExcel
    );

    workSheet["!cols"] = [
      { wch: 13 },
      { wch: 40 },
      { wch: 13 },
      { wch: 4 },
      { wch: 3 },
      { wch: 6 },
      { wch: 5 },
      { wch: 7 },
      { wch: 6 },
      { wch: 7 },
      { wch: 5 },
      { wch: 9 },
      { wch: 11 },
      { wch: 19 },
      { wch: 7 },
      { wch: 6 },
      { wch: 7 },
      { wch: 5 },
      { wch: 9 },
      { wch: 11 },
      { wch: 19 },
      { wch: 7 },
      { wch: 6 },
      { wch: 7 },
      { wch: 5 },
      { wch: 9 },
      { wch: 11 },
      { wch: 19 },
      { wch: 4 },
    ];

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Target Cycle Time");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workBook,
      `Laporan Cycle Time ${getDateUtil(dateCycleTime)}.xlsx`
    );

    setPopUpLaporanProduksi("");
    setDateCycleTime(today);
    setDataExportCycle();
  };

  const exportVentPlug = async () => {
    const tomorrow = new Date(dateUntilLaporanPasangKaret);
    tomorrow.setDate(tomorrow.getDate() + 1);

    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getLaporanPasangKaret`,
      {
        tim: dataUser.tim,
        date: getDateUtil(dateLaporanPasangKaret),
        dateUntil: getDateUtil(tomorrow),
        isKepala: true,
        isAdmin: true,
        type: "exportPasangKaret",
      },
      dataUser.auth,
      setDataExportPasangKaret,
      ""
    );

    const dataSorted =
      dataExportPasangKaret &&
      dataExportPasangKaret.sort(function (a, b) {
        if (a.date < b.date) {
          return -1;
        }
        if (a.date > b.date) {
          return 1;
        }
        if (a.kepalaShift < b.kepalaShift) {
          return -1;
        }
        if (a.kepalaShift > b.kepalaShift) {
          return 1;
        }
        if (Number(a.mesin) < Number(b.mesin)) {
          return -1;
        }
        if (Number(a.mesin) > Number(b.mesin)) {
          return 1;
        }
        return 0;
      });

    const dataExcel =
      dataSorted &&
      dataSorted.map((obj) => {
        return {
          Tanggal: moment(obj.date).format("DD/MM/YYYY"),
          "No.Alat/Mesin": obj.mesin,
          Produk: obj.produk,
          Warna: obj.warna,
          Shift: obj.kepalaShift,
          Pendapatan: obj.jumlah,
          Downtime: obj.downtime,
          Keterangan: obj.keterangan,
          Cavity: obj.aktualCavity,
          Operator: obj.operator,
        };
      });

    const workSheet = XLSX.utils.json_to_sheet(dataExcel && dataExcel);

    workSheet["!cols"] = [
      { wch: 10 },
      { wch: 12 },
      { wch: 25 },
      { wch: 6 },
      { wch: 9 },
      { wch: 10 },
      { wch: 9 },
      { wch: 125 },
    ];

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Target Pasang Karet");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workBook,
      `Laporan Pasang Karet ${getDateUtil(
        dateLaporanPasangKaret
      )} - ${getDateUtil(dateUntilLaporanPasangKaret)}.xlsx`
    );

    setPopUpLaporanProduksi("");
    setDateLaporanPasangKaret(today);
    setDateUntilLaporanPasangKaret(today);
  };

  return {
    subMenuHistory,
    setSubMenuHistory,
    dataHistoryRequestIn,
    handleSubMenuHistory,
    dataHistoryPPIC,
    dateHistoryPPIC,
    handleChangeDate,
    dataHistoryPendukungPPIC,
    dateQc,
    setDateQc,
    shiftQc,
    setShiftQc,
    dataQc,
    handleCariQc,
    dataHistorySatuanPPIC,
    memoGanti,
    monthDefaultFormat,
    bulan,
    setBulan,
    dataUser,
    shiftProduksi,
    setShiftProduksi,
    dateProduksi,
    setDateProduksi,
    dataProduksi,
    dataProduksiPendukung,
    historyDataOperator,
    handleEditChecklist,
    popUpGantiMold,
    setPopUpGantiMold,
    dataPopUpGantiMold,
    bagian,
    listTugas,
    handleFormChange,
    handleKirimStatus,
    setCommentGantiMold,
    commentGantiMold,
    handleKirimComment,
    exportLaporanProduksi,
    exportDataCycleTime,
    popUpLaporanProduksi,
    setPopUpLaporanProduksi,
    dariTanggalLaporanProduksi,
    setDariTanggalLaporanProduksi,
    sampaiTanggalLaporanProduksi,
    setSampaiTanggalLaporanProduksi,
    setDateStockOut,
    dateStockOut,
    shiftStockOut,
    setShiftStockOut,
    listTopPlate,
    dateCycleTime,
    setDateCycleTime,
    dateLaporanPasangKaret,
    dateUntilLaporanPasangKaret,
    setDateUntilLaporanPasangKaret,
    setDateLaporanPasangKaret,
    dataLaporanPasangKaret,
    exportVentPlug,
    shiftVP,
    setShiftVP,
    dataDetailProduk,
  };
};
