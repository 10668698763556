import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import { getDateUtil, today } from "../../util/formatDate";

export const useRequestStockIn = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [dataListBahan, setDataListBahan] = useState();
  const [companyRequest, setCompanyRequest] = useState("");
  const [materialBahan, setMaterialBahan] = useState("");
  const [materialName, setMaterialName] = useState("");
  const [typeMaterial, setTypeMaterial] = useState("");
  const [jumlahRequest, setJumlahRequest] = useState("");
  const [keteranganRequestStockIn, setKeteranganRequestStockIn] = useState("");
  const [popUpRequest, setPopUpRequest] = useState(false);
  const [popUpErrorRequest, setPopUpErrorRequest] = useState(false);
  const [materialColor, setMaterialColor] = useState("");
  const [isReload, setIsReload] = useState(false);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListBahan`,
      { tim: dataUser.tim },
      dataUser.auth,
      setDataListBahan,
      ""
    );
    setIsReload(false);
  }, [isReload]);

  const validateNamaBahan = () => {
    let data;
    dataListBahan &&
      dataListBahan.forEach((obj) => {
        if (obj.company === companyRequest) {
          if (materialBahan === "PENDUKUNG") {
            data = obj.bahanPendukung;
          } else if (materialBahan === "BAKU") {
            data = obj.bahanBaku;
          }
        }
      });
    return data;
  };

  useEffect(() => {
    validateNamaBahan();
  }, [companyRequest]);

  const dataBahan = validateNamaBahan();

  const handleSubmitRequest = (button) => {
    if (button === "OK") {
      setPopUpRequest(false);
      setPopUpErrorRequest(false);
    }
  };

  const handleKirimRequestIn = async () => {
    if (
      companyRequest !== "" &&
      materialBahan !== "" &&
      materialName !== "" &&
      typeMaterial !== "" &&
      companyRequest !== "" &&
      jumlahRequest !== "" &&
      materialColor !== ""
    ) {
      setPopUpRequest(true);
      await postAxios(
        `${process.env.REACT_APP_ENDPOINT}/requestStockIn`,
        {
          company: companyRequest,
          jenis: materialBahan,
          material: materialName,
          color: materialColor,
          type: typeMaterial,
          total: Number(jumlahRequest),
          keterangan: keteranganRequestStockIn,
          date: getDateUtil(today),
          requestor: dataUser.name,
          tim: dataUser.tim,
        },
        dataUser.auth,
        "",
        ""
      );
      setIsReload(true);
      setCompanyRequest("");
      setMaterialBahan("");
      setMaterialName("");
      setTypeMaterial("");
      setJumlahRequest("");
      setKeteranganRequestStockIn("");
      setMaterialColor("");
    } else {
      setPopUpErrorRequest(true);
    }
  };

  return {
    companyRequest,
    setCompanyRequest,
    setMaterialBahan,
    setMaterialName,
    setTypeMaterial,
    handleSubmitRequest,
    dataBahan,
    popUpRequest,
    materialBahan,
    setPopUpRequest,
    jumlahRequest,
    setJumlahRequest,
    setKeteranganRequestStockIn,
    keteranganRequestStockIn,
    dataListBahan,
    handleKirimRequestIn,
    materialName,
    typeMaterial,
    popUpErrorRequest,
    materialColor,
    setMaterialColor,
  };
};
