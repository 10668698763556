import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import { noFormat, today } from "../../util/formatDate";
import { validateRole } from "../../util/utilFunction";

export const useInventory = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [companyPick, setCompanyPick] = useState("GS");
  const [dataInventory, setDataInventory] = useState();
  const [dataBarangJadi, setDataBarangJadi] = useState();
  const [popUpKirimPo, setPopUpKirimPo] = useState(false);
  const [subMenu, setSubMenu] = useState("bahanBaku");
  const [dataKeluar, setDataKeluar] = useState();
  const [nomorPo, setNomorPo] = useState("");
  const [jumlahKirim, setJumlahKirim] = useState("");
  const [errMsg, setErrMsg] = useState(false);
  const [tanggalKirim, setTanggalKirim] = useState(today);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isVentPlug, setIsVentPlug] = useState(false);

  const [dataHistoryKeluarBarang, setDataHistoryKeluarBarang] = useState();
  const [dateHistoryKeluarBarang, setDateHistoryKeluarBarang] = useState(today);
  const [dateHistoryBarangPolos, setDateHistoryBarangPolos] = useState(today);
  const [dataHistoryBarangPolos, setDataHistoryBarangPolos] = useState();
  const [dataBarangPolos, setDataBarangPolos] = useState();

  const [customerAddVP, setCustomerAddVP] = useState("Pilih Customer");
  const [jumlahVP, setJumlahVP] = useState("");
  const [produkVP, setProdukVP] = useState();
  const [ventPlugPick, setVentPlugPick] = useState("Pilih Vent Plug");
  const [dateAddVP, setDateAddVP] = useState(today);
  const [historyVP, setHistoryVP] = useState();

  useEffect(() => {
    if (dataUser.role === "pr_admin" || dataUser.role === "sp_admin") {
      setSubMenu("barangPolos");
    } else if (
      dataUser.role === "warehouse_admin" ||
      dataUser.role === "super_user"
    ) {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getListMold`,
        { tim: dataUser.tim },
        dataUser.auth,
        setProdukVP,
        ""
      );
    }
  }, []);

  useEffect(() => {
    if (subMenu === "bahanBaku") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getInventory`,
        { tim: dataUser.tim, company: companyPick },
        dataUser.auth,
        setDataInventory,
        ""
      );
    }
  }, [companyPick]);

  useEffect(() => {
    if (subMenu === "barangPolos") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getInventory`,
        { tim: dataUser.tim, bagian: validateRole(), type: "barangPolos" },
        dataUser.auth,
        setDataBarangPolos,
        ""
      );
    }
  }, [subMenu]);

  useEffect(() => {
    if (subMenu === "barangJadi") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getInventory`,
        { tim: dataUser.tim, type: "barangJadi" },
        dataUser.auth,
        setDataBarangJadi,
        ""
      );
      setIsRefresh(false);
    }
  }, [subMenu, isRefresh]);

  useEffect(() => {
    if (subMenu === "historyBarangPolos") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/secondProcess`,
        {
          tim: dataUser.tim,
          type: "historyBarangPolos",
          date: dateHistoryBarangPolos,
          bagian: validateRole(),
        },
        dataUser.auth,
        setDataHistoryBarangPolos,
        ""
      );
      setIsRefresh(false);
    }
  }, [subMenu, dateHistoryBarangPolos]);

  useEffect(() => {
    if (dataUser.role === "warehouse_admin" || dataUser.role === "super_user") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/ventPlugWarehouse`,
        { tim: dataUser.tim, type: "history", date: dateHistoryKeluarBarang },
        dataUser.auth,
        setHistoryVP,
        ""
      );
    }
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/kirimBarang`,
      {
        tim: dataUser.tim,
        dateExit: dateHistoryKeluarBarang,
        type: "history",
      },
      dataUser.auth,
      setDataHistoryKeluarBarang,
      ""
    );
  }, [dateHistoryKeluarBarang, isRefresh, subMenu]);

  const searchBarangJadi =
    dataBarangJadi &&
    dataBarangJadi.find((e) => (e.company == companyPick ? e : 0));

  const dataPick = () => {
    let data;
    dataInventory &&
      dataInventory.forEach((element) => {
        if (element.company === companyPick) {
          data = element;
        }
      });
    return data;
  };
  const Material = dataPick();

  const handlePickItem = (data) => {
    setDataKeluar(data);
    setPopUpKirimPo(true);
  };

  const handleKirimPo = () => {
    if (nomorPo !== "" && jumlahKirim !== "") {
      setErrMsg(false);
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/kirimBarang`,
        {
          tim: dataUser.tim,
          company: companyPick,
          poNumber: nomorPo,
          totalExit: Number(jumlahKirim),
          dateExit: tanggalKirim,
          creator: dataUser.name,
          data: dataKeluar,
          createdAt: noFormat,
          type: "submit",
        },
        dataUser.auth,
        "",
        ""
      );

      setIsRefresh(true);
      setPopUpKirimPo(false);
      setNomorPo("");
      setJumlahKirim("");
    } else {
      setErrMsg(true);
    }
  };

  const handleClosePopUp = () => {
    setIsVentPlug(false);
    setCustomerAddVP("Pilih Customer");
    setJumlahVP("");
    setVentPlugPick("Pilih Vent Plug");
    setDateAddVP(today);
  };

  let firstFindVP =
    produkVP && produkVP.find((o) => o.company === customerAddVP);

  let dataVP = [];

  firstFindVP &&
    firstFindVP.data.forEach((element) => {
      if (element.bagian === "SC") {
        if (!dataVP.some((d) => element.namaBarang === d.namaBarang)) {
          dataVP.push(element);
        }
      }
    });

  const handleKirimAddVP = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/ventPlugWarehouse`,
      {
        tim: dataUser.tim,
        creator: dataUser.name,
        createdDate: noFormat,
        total: Number(jumlahVP),
        namaBarang: ventPlugPick,
        date: dateAddVP,
        company: customerAddVP,
        type: "submit",
      },
      dataUser.auth,
      "",
      ""
    );
    setIsRefresh(true);
    handleClosePopUp();
  };

  return {
    Material,
    setCompanyPick,
    companyPick,
    subMenu,
    setSubMenu,
    dataUser,
    searchBarangJadi,
    handlePickItem,
    popUpKirimPo,
    setPopUpKirimPo,
    dataKeluar,
    nomorPo,
    setNomorPo,
    jumlahKirim,
    setJumlahKirim,
    handleKirimPo,
    errMsg,
    setTanggalKirim,
    tanggalKirim,
    dateHistoryKeluarBarang,
    setDateHistoryKeluarBarang,
    dataHistoryKeluarBarang,
    dateHistoryBarangPolos,
    setDateHistoryBarangPolos,
    dataHistoryBarangPolos,
    dataBarangPolos,
    isVentPlug,
    setIsVentPlug,
    customerAddVP,
    setCustomerAddVP,
    jumlahVP,
    setJumlahVP,
    handleClosePopUp,
    dataVP,
    ventPlugPick,
    setVentPlugPick,
    handleKirimAddVP,
    dateAddVP,
    setDateAddVP,
    historyVP,
  };
};
