import React from "react";
import CardComponent from "../../components/Card/CardComponent";
import LogoGs from "../../Assets/GS-Logo.png";
import LogoYBI from "../../Assets/YBI-Logo.jpeg";
import LogoTBP from "../../Assets/TBP-Logo.jpeg";
import LogoMelco from "../../Assets/MELCO-Logo.png";
import LogoTIM from "../../Assets/TimLogo.png";
import LogoCBI from "../../Assets/CBI-Logo.png";
import TableInventory from "../../components/TableInventory/TableInventory";
import PopUpComponent from "../..//components/PopUp/PopUpComponent";
import CloseLogo from "../../Assets/close_icon.png";
import { useInventory } from "./useInventory";
import { constCustomer } from "../../util/constant";
import "./Inventory.css";
import { validateRole } from "../../util/utilFunction";
import moment from "moment";

const Inventory = () => {
  const {
    Material,
    setCompanyPick,
    companyPick,
    subMenu,
    setSubMenu,
    searchBarangJadi,
    dataUser,
    handlePickItem,
    popUpKirimPo,
    setPopUpKirimPo,
    dataKeluar,
    nomorPo,
    setNomorPo,
    jumlahKirim,
    setJumlahKirim,
    handleKirimPo,
    errMsg,
    setTanggalKirim,
    tanggalKirim,
    setDateHistoryKeluarBarang,
    dateHistoryKeluarBarang,
    dataHistoryKeluarBarang,
    dateHistoryBarangPolos,
    setDateHistoryBarangPolos,
    dataHistoryBarangPolos,
    dataBarangPolos,
    isVentPlug,
    setIsVentPlug,
    customerAddVP,
    setCustomerAddVP,
    jumlahVP,
    setJumlahVP,
    handleClosePopUp,
    dataVP,
    ventPlugPick,
    setVentPlugPick,
    handleKirimAddVP,
    dateAddVP,
    setDateAddVP,
    historyVP,
  } = useInventory();

  const sortData =
    dataHistoryBarangPolos &&
    dataHistoryBarangPolos.sort(function (a, b) {
      if (a.type < b.type) {
        return -1;
      }
      if (a.type > b.type) {
        return 1;
      }
      return 0;
    });

  const sortDataBarangPolos =
    dataBarangPolos &&
    dataBarangPolos.sort(function (a, b) {
      if (a.bagian < b.bagian) {
        return -1;
      }
      if (a.bagian > b.bagian) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="container_inventory">
      {popUpKirimPo ? (
        <PopUpComponent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>
              <b>{dataKeluar.namaBarang}</b>
            </p>
            <img
              className="width_edit_logo"
              src={CloseLogo}
              onClick={() => setPopUpKirimPo(false)}
              alt="edit_logo"
            />
          </div>
          <p style={{ marginTop: 0 }}>
            <b>{dataKeluar.color}</b>
          </p>
          {errMsg ? (
            <p style={{ color: "red" }}>
              Mohon Mengisi Semua Kolom Berbintang merah!
            </p>
          ) : (
            ""
          )}
          <div className="margin_right_27_qc">
            <p>Pilih Tanggal</p>
            <input
              type="date"
              className="date_pick_memo_ppic"
              value={tanggalKirim}
              onChange={(e) => setTanggalKirim(e.target.value)}
            />
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "12px" }}>
              <p>
                No. PO <b style={{ color: "red" }}>*</b>
              </p>
              <input
                className="input_jumlah"
                type="text"
                placeholder="Nomor PO"
                value={nomorPo}
                onChange={(e) => setNomorPo(e.target.value)}
              />
            </div>
            <div>
              <p>
                Jumlah Kirim <b style={{ color: "red" }}>*</b>
              </p>
              <input
                className="input_jumlah"
                type="number"
                placeholder="Jumlah Barang Kirim (Pcs)"
                value={jumlahKirim}
                onChange={(e) => setJumlahKirim(e.target.value)}
              />
            </div>
          </div>
          <button
            className="button_submit_memo"
            style={{ margin: "24px 0 0 0" }}
            onClick={() => handleKirimPo()}
          >
            Kirim
          </button>
        </PopUpComponent>
      ) : (
        ""
      )}
      {isVentPlug ? (
        <PopUpComponent small>
          <div style={{ display: "flex" }}>
            <div className="margin_right_27_qc" style={{ width: "50%" }}>
              <p>Pilih Tanggal</p>
              <input
                type="date"
                className="date_pick_memo_ppic"
                value={dateAddVP}
                onChange={(e) => setDateAddVP(e.target.value)}
              />
            </div>
            <div className="margin_right_27_qc">
              <p>Pilih Customer</p>
              <select
                defaultValue="Pilih Customer"
                onChange={(e) => setCustomerAddVP(e.target.value)}
                className="input_select_memo_ppic"
                style={{ width: "100%" }}
                name="mesin"
                value={customerAddVP}
              >
                <option selected disabled>
                  Pilih Customer
                </option>
                {constCustomer &&
                  constCustomer.map((data) => (
                    <option value={data}>{data}</option>
                  ))}
              </select>
            </div>
            <img
              className="width_edit_logo"
              src={CloseLogo}
              onClick={() => handleClosePopUp()}
              alt="edit_logo"
            />
          </div>
          <div style={{ display: "flex" }}>
            <div className="margin_right_27_qc" style={{ width: "50%" }}>
              <p>Pilih Vent Plug</p>
              <select
                defaultValue="Pilih Vent Plug"
                onChange={(e) => setVentPlugPick(e.target.value)}
                className="input_select_memo_ppic width_mobile_select_vp"
                name="mesin"
                value={ventPlugPick}
              >
                <option selected disabled>
                  Pilih Vent Plug
                </option>
                {dataVP &&
                  dataVP.map((data) => (
                    <option value={data.namaBarang}>{data.namaBarang}</option>
                  ))}
              </select>
            </div>
            <div className="margin_right_27_qc">
              <div className="wrapper_input">
                <p>Jumlah (Pcs)</p>
                <div className="wrapper_radio">
                  <input
                    className="input_jumlah"
                    style={{ width: "120px" }}
                    type="number"
                    placeholder="Jumlah Vent Plug"
                    value={jumlahVP}
                    onChange={(e) => setJumlahVP(e.target.value)}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            className="button_submit_memo"
            style={{ margin: "24px 0 0 0" }}
            onClick={() => handleKirimAddVP()}
          >
            Kirim
          </button>
        </PopUpComponent>
      ) : (
        ""
      )}

      <div className="wrapper_header">
        <p className="title_inventory">INVENTORY</p>
        {dataUser.role === "warehouse_admin" ||
        dataUser.role === "super_user" ? (
          <div
            className="wrapper_tambah_list"
            onClick={() => setIsVentPlug(true)}
          >
            <div className="icon_add_list">+&nbsp;</div>
            <div className="font_tambah_button">Vent Plug</div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="flex_wrap">
        <CardComponent
          active={companyPick === "GS"}
          title="PT. GS"
          logo={LogoGs}
          onClick={() => setCompanyPick("GS")}
        />
        <CardComponent
          active={companyPick === "YBI"}
          title="PT. YBI"
          logo={LogoYBI}
          onClick={() => setCompanyPick("YBI")}
        />
        <CardComponent
          active={companyPick === "TBP"}
          title="PT. TBP"
          logo={LogoTBP}
          onClick={() => setCompanyPick("TBP")}
        />
        <CardComponent
          active={companyPick === "MELCO"}
          title="PT. MELCO"
          logo={LogoMelco}
          onClick={() => setCompanyPick("MELCO")}
        />
        <CardComponent
          active={companyPick === "CBI"}
          title="PT. CBI"
          logo={LogoCBI}
          onClick={() => setCompanyPick("CBI")}
        />
        <CardComponent
          active={companyPick === "TIM"}
          title="PT. TIM"
          logo={LogoTIM}
          onClick={() => setCompanyPick("TIM")}
        />
      </div>

      <div className="wrapper_sub_menu_history">
        {validateRole() === "" || validateRole() === "super" ? (
          <>
            <p
              className={
                subMenu === "bahanBaku"
                  ? "sub_menu_history border_active_history"
                  : "sub_menu_history"
              }
              onClick={() => setSubMenu("bahanBaku")}
            >
              Bahan Baku
            </p>
            <p
              className={
                subMenu === "barangJadi"
                  ? "sub_menu_history border_active_history"
                  : "sub_menu_history"
              }
              onClick={() => setSubMenu("barangJadi")}
            >
              Barang Jadi
            </p>
            <p
              className={
                subMenu === "history"
                  ? "sub_menu_history border_active_history"
                  : "sub_menu_history"
              }
              onClick={() => setSubMenu("history")}
            >
              History
            </p>
            <p
              className={
                subMenu === "historyVP"
                  ? "sub_menu_history border_active_history"
                  : "sub_menu_history"
              }
              onClick={() => setSubMenu("historyVP")}
            >
              History Vent Plug
            </p>
          </>
        ) : (
          ""
        )}

        {validateRole() !== "" || validateRole() === "super" ? (
          <>
            <p
              className={
                subMenu === "barangPolos"
                  ? "sub_menu_history border_active_history"
                  : "sub_menu_history"
              }
              onClick={() => setSubMenu("barangPolos")}
            >
              Barang Polos
            </p>
            <p
              className={
                subMenu === "historyBarangPolos"
                  ? "sub_menu_history border_active_history"
                  : "sub_menu_history"
              }
              onClick={() => setSubMenu("historyBarangPolos")}
            >
              History Barang Polos
            </p>
          </>
        ) : (
          ""
        )}
      </div>

      {subMenu === "bahanBaku" ? (
        <>
          <p className="title_inventory">STOCK BAHAN BAKU PT. {companyPick}</p>
          <TableInventory data={Material && Material.bahanBaku} />
          <p className="title_inventory">
            STOCK BAHAN PENDUKUNG PT. {companyPick}
          </p>
          <TableInventory data={Material && Material.bahanPendukung} />
        </>
      ) : subMenu === "barangJadi" ? (
        <>
          <p className="title_inventory">STOCK BARANG JADI PT. {companyPick}</p>
          <br />
          <div className="table_inventory_mobile">
            <table style={{ width: "100%" }}>
              <tr>
                <th className="table_header">No.</th>
                <th className="table_header">Nama Produk</th>
                <th className="table_header">Warna</th>
                <th className="table_header">Jumlah (Pcs)</th>
                <th className="table_header">Update Date</th>
                {(dataUser && dataUser.role === "super_user") ||
                dataUser.role === "warehouse_admin" ? (
                  <th className="table_header"></th>
                ) : (
                  ""
                )}
              </tr>
              {searchBarangJadi &&
                searchBarangJadi.data.map((element, index) => {
                  return (
                    <tr
                      style={
                        index % 2 === 0
                          ? { background: "white" }
                          : { background: "#f9f9f9" }
                      }
                    >
                      <td className="font_data">{index + 1}</td>
                      <td
                        className="font_data"
                        style={{ whiteSpace: "nowrap", padding: "24px" }}
                      >
                        {element.namaBarang}
                      </td>
                      <td className="font_data">{element.color}</td>
                      <td className="font_data" style={{ padding: "24px" }}>
                        {element.total} Pcs
                      </td>
                      <td className="font_data" style={{ padding: "24px" }}>
                        {element.dateUpdate
                          ? moment(element.dateUpdate).format(
                              "ddd MMM DD YYYY HH:mm:ss"
                            )
                          : ""}
                      </td>
                      {dataUser.role === "super_user" ||
                      dataUser.role === "warehouse_admin" ? (
                        <td>
                          <p
                            onClick={() => handlePickItem(element)}
                            className="button_kirim_po"
                          >
                            Kirim
                          </p>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                })}
            </table>
          </div>
        </>
      ) : subMenu === "barangPolos" ? (
        <>
          <p className="title_inventory">
            STOCK BARANG POLOS PT. {companyPick}
          </p>
          <div className="table_inventory_mobile">
            <table style={{ width: "100%" }}>
              <tr>
                <th className="table_header" style={{ position: "inherit" }}>
                  Cust
                </th>
                <th className="table_header">Produk</th>
                <th className="table_header">Color</th>
                <th className="table_header">Bagian</th>
                <th className="table_header">Total (Pcs)</th>
              </tr>
              {sortDataBarangPolos &&
                sortDataBarangPolos.map((element, index) => {
                  return (
                    <>
                      {element.data.map((obj, i) => {
                        return (
                          <tr
                            style={
                              index % 2 === 0
                                ? { background: "white" }
                                : { background: "#f9f9f9" }
                            }
                          >
                            {/* <td className="font_data">{index + 1}</td> */}
                            <td
                              className="font_data"
                              style={{ whiteSpace: "nowrap", padding: "24px" }}
                            >
                              {element.company}
                            </td>
                            <td className="font_data">{obj.produk}</td>
                            <td className="font_data">{obj.color}</td>
                            <td className="font_data">{element.bagian}</td>
                            <td className="font_data">{obj.total}</td>
                          </tr>
                        );
                      })}
                    </>
                  );
                })}
            </table>
          </div>
        </>
      ) : subMenu === "historyBarangPolos" ? (
        <>
          <div className="margin_right_27_qc">
            <p>Pilih Tanggal</p>
            <input
              type="date"
              className="date_pick_memo_ppic"
              value={dateHistoryBarangPolos}
              onChange={(e) => setDateHistoryBarangPolos(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {sortData &&
              sortData.map((obj) => {
                return (
                  <div className="card_approval_top_plate">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p className="title_machine">{obj.customer}</p>
                      <p style={{ fontWeight: "bold" }}>{obj.bagian}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p className="title_machine">{obj.data.produk}</p>
                      <p style={{ fontWeight: "bold" }}>{obj.data.color}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p className="title_machine">Dibuat Oleh :</p>
                      <p style={{ fontWeight: "bold" }}>{obj.creator}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={
                          obj.type === "masuk"
                            ? { fontWeight: "bold", color: "green" }
                            : { fontWeight: "bold", color: "red" }
                        }
                      >
                        {obj.type === "masuk" ? "MASUK" : "KELUAR"}
                      </p>
                      <p style={{ fontWeight: "bold" }}>{obj.data.total} Pcs</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      ) : subMenu === "historyVP" ? (
        <>
          <p className="title_inventory">HISTORY VENT PLUG</p>
          <div className="margin_right_27_qc">
            <p>Pilih Tanggal</p>
            <input
              type="date"
              className="date_pick_memo_ppic"
              value={dateHistoryKeluarBarang}
              onChange={(e) => setDateHistoryKeluarBarang(e.target.value)}
            />
          </div>
          <div className="table_inventory_mobile">
            <table style={{ width: "100%" }}>
              <tr>
                <th className="table_header">Cust</th>
                <th className="table_header">Produk</th>
                <th className="table_header">Status</th>
                <th className="table_header">Creator</th>
                <th className="table_header">Total (Pcs)</th>
              </tr>
              {historyVP &&
                historyVP.map((element, index) => {
                  const splitNama = element.creator
                    .split(" ")
                    .slice(0, 2)
                    .join(" ");
                  return (
                    <tr
                      style={
                        index % 2 === 0
                          ? { background: "white" }
                          : { background: "#f9f9f9" }
                      }
                    >
                      {/* <td className="font_data">{index + 1}</td> */}
                      <td
                        className="font_data"
                        style={{ whiteSpace: "nowrap", padding: "24px" }}
                      >
                        {element.company}
                      </td>
                      <td className="font_data">{element.namaBarang}</td>
                      <td
                        className="font_data"
                        style={
                          element.type === "Masuk"
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        {element.type}
                      </td>
                      <td className="font_data">{splitNama}</td>
                      <td className="font_data">{element.total}</td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </>
      ) : (
        <>
          <p className="title_inventory">HISTORY STOCK KELUAR</p>
          <div className="margin_right_27_qc">
            <p>Pilih Tanggal</p>
            <input
              type="date"
              className="date_pick_memo_ppic"
              value={dateHistoryKeluarBarang}
              onChange={(e) => setDateHistoryKeluarBarang(e.target.value)}
            />
          </div>
          <div className="table_inventory_mobile">
            <table style={{ width: "100%" }}>
              <tr>
                <th className="table_header">No</th>
                <th className="table_header">Tanggal</th>
                <th className="table_header">No. PO</th>
                <th className="table_header">Cust</th>
                <th className="table_header">Produk</th>
                <th className="table_header">Creator</th>
                <th className="table_header">Total</th>
              </tr>
              {dataHistoryKeluarBarang &&
                dataHistoryKeluarBarang.map((obj, index) => {
                  return (
                    <tr
                      style={
                        index % 2 === 0
                          ? {
                              background: "white",
                            }
                          : {
                              background: "#f9f9f9",
                            }
                      }
                    >
                      <td className="font_size_td_mobile">{index + 1}</td>
                      <td className="font_size_td_mobile">{obj.date}</td>
                      <td className="font_size_td_mobile">{obj.poNumber}</td>
                      <td className="font_size_td_mobile">{obj.customer}</td>
                      <td className="font_size_td_mobile">{obj.produk}</td>
                      <td className="font_size_td_mobile">{obj.creator}</td>
                      <td className="font_size_td_mobile">{obj.totalExit}</td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default Inventory;
