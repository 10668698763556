import React from "react";
import Login from "./Pages/Login/Login";
import { Route, BrowserRouter } from "react-router-dom";
import IndexPage from "./Pages/IndexPage";
import { createBrowserHistory } from "history";

const App = () => {
  const history = createBrowserHistory();
  if (history.location.pathname === "/") {
    history.push("/login");
  }
  return (
    <BrowserRouter>
      <Route exact path={"/login"} component={Login}>
        <Login />
      </Route>
      <Route exact path={"/dashboard"} component={IndexPage}>
        <IndexPage />
      </Route>
    </BrowserRouter>
  );
};
export default App;
