import React from "react";
import DashboardPPIC from "../../components/DashboardPPIC/DashboardPPIC";
import EditLogo from "../../Assets/editLogo.png";
import { constMesinSP, bulan } from "../../util/constant";
import { useDashboard } from "./useDashboard";
import "./Dashboard.css";
import DeleteIcon from "../../Assets/delete_icon.png";
import PopUpComponent from "../../components/PopUp/PopUpComponent";

const Dashboard = () => {
  const {
    dateDashboard,
    setDateDashboard,
    shiftDashboard,
    setShiftDashboard,
    dataDashbord,
    historyDataOperator,
    handleFormChange,
    handleKirimEditOperator,
    isEdit,
    setIsEdit,
    handleKirimEditAdmin,
    popUpSubmit,
    setPopUpSubmit,
    dataLaporanOperatorQc,
    popUpDelete,
    handleDeleteLaporan,
    handleSetDataDelete,
    dataDelete,
    handleCancelDelete,
    dataLaporanPasangKaret,
    handleDeleteLaporanPasangKaret,
    handleSetDataDeletePasangKaret,
    dataDeletePasangKaret,
    popUpDeletePasangKaret,
    handleCancelDeletePasangKaret,
    dataQc,
    handleChangeMesin,
    noMesinEdit,
    handleFormChangePasangKaret,
    subMenu,
    setSubMenu,
    laporanSP,
    setDateMonitoringSP,
    dateMonitoringSP,
    isEditSP,
    setIsEditSP,
    handleFormChangeSP,
    handleKirimEditSP,
    exportBulanan,
    popUpLaporanBulananSP,
    setPopUpLaporanBulananSP,
    bulanExportSP,
    setBulanExportSP,
    tahunExportSP,
    setTahunExportSP,
    isOn,
  } = useDashboard();

  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);
  const sortData =
    historyDataOperator &&
    historyDataOperator.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  const sortDataMonitoring =
    dataDashbord &&
    dataDashbord.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });
  const sortDataQc =
    dataLaporanOperatorQc &&
    dataLaporanOperatorQc.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });
  const sortDataPasangKaret =
    dataLaporanPasangKaret &&
    dataLaporanPasangKaret.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });
  const sortDataSP =
    laporanSP &&
    laporanSP.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="container_dashboard">
      {popUpLaporanBulananSP ? (
        <PopUpComponent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <select
              defaultValue="Pilih No Mesin"
              onChange={(e) => setBulanExportSP(e.target.value)}
              className="input_select_memo_ppic"
              style={{ width: "48%" }}
              value={bulanExportSP}
            >
              <option selected disabled>
                Pilih Bulan
              </option>
              {bulan &&
                bulan.map((data) => (
                  <option value={data.value}>{data.name}</option>
                ))}
            </select>
            <select
              defaultValue="Pilih No Mesin"
              onChange={(e) => setTahunExportSP(e.target.value)}
              className="input_select_memo_ppic"
              style={{ width: "48%" }}
              value={tahunExportSP}
            >
              <option selected disabled>
                Pilih Tahun
              </option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2025">2026</option>
            </select>
          </div>
          <br />
          <div className="wrapper_button_submit_popup">
            <div className="wrapper_button_submit_popup">
              <button
                className="button_popup"
                onClick={() => setPopUpLaporanBulananSP(false)}
              >
                Cancel
              </button>
              <button
                className="button_popup background_red"
                onClick={() => exportBulanan()}
              >
                Export
              </button>
            </div>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {popUpDeletePasangKaret ? (
        <PopUpComponent>
          <p>
            Apakah Kamu Yakin ingin menghapus laporan Pasang Karet{" "}
            <b>{dataDeletePasangKaret && dataDeletePasangKaret.mesin}</b> ?
          </p>
          <div className="wrapper_button_submit_popup">
            <div className="wrapper_button_submit_popup">
              <button
                className="button_popup"
                onClick={() => handleCancelDeletePasangKaret()}
              >
                Cancel
              </button>
              <button
                className="button_popup background_red"
                onClick={() => handleDeleteLaporanPasangKaret()}
              >
                Yakin
              </button>
            </div>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {popUpDelete ? (
        <PopUpComponent>
          <p>
            Apakah Kamu Yakin ingin menghapus laporan Mesin{" "}
            <b>{dataDelete && dataDelete.mesin}</b> ?
          </p>
          <div className="wrapper_button_submit_popup">
            <div className="wrapper_button_submit_popup">
              <button
                className="button_popup"
                onClick={() => handleCancelDelete()}
              >
                Cancel
              </button>
              <button
                className="button_popup background_red"
                onClick={() => handleDeleteLaporan()}
              >
                Yakin
              </button>
            </div>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {popUpSubmit ? (
        <PopUpComponent>
          <p>Data Kamu Telah Dikirim!</p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup background_red"
              onClick={() => setPopUpSubmit(false)}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {dataUser.role === "super_user" || dataUser.role === "ppic_admin" ? (
        <div className="wrapper_sub_menu_history" style={{ margin: 0 }}>
          <p
            className={
              subMenu === "Produksi"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenu("Produksi")}
          >
            Produksi
          </p>
          <p
            className={
              subMenu === "SP"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenu("SP")}
          >
            Second Process
          </p>
        </div>
      ) : (
        ""
      )}

      {subMenu === "Produksi" ? (
        <>
          {dataUser.role === "super_user" ||
          dataUser.role === "ppic_admin" ||
          dataUser.role === "produksi_leader" ||
          dataUser.role === "mold_user" ? (
            <>
              <div style={{ display: "flex" }}>
                <div className="margin_right_27_qc">
                  <p>Pilih Shift</p>
                  <select
                    className="input_select_shift"
                    value={shiftDashboard}
                    onChange={(e) => setShiftDashboard(e.target.value)}
                  >
                    <option
                      selected={shiftDashboard === "Pilih Shift"}
                      disabled
                    >
                      Pilih Shift
                    </option>
                    <option value="1">Shift 1</option>
                    <option value="2">Shift 2</option>
                    <option value="3">Shift 3</option>
                  </select>
                </div>
                <div className="margin_right_27_qc">
                  <p>Pilih Tanggal</p>
                  <input
                    type="date"
                    className="date_pick_memo_ppic"
                    value={dateDashboard}
                    onChange={(e) => setDateDashboard(e.target.value)}
                  />
                </div>
              </div>
              <DashboardPPIC data={sortDataMonitoring} />
              {dataLaporanPasangKaret &&
              dataLaporanPasangKaret.length > 0 &&
              !isOn ? (
                <p className="title_inventory">Laporan Pasang Karet</p>
              ) : (
                ""
              )}
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {sortDataPasangKaret &&
                  sortDataPasangKaret.map((obj) => {
                    const downtimePasangKaret = obj.downtime ? obj.downtime : 0;
                    const jumlahPasangKaret = obj.jumlah ? obj.jumlah : 0;
                    const aktualCavityPasangKaret = obj.aktualCavity
                      ? obj.aktualCavity
                      : 0;
                    const formula =
                      (28800 - downtimePasangKaret * 60) / jumlahPasangKaret;

                    return (
                      <div className="card_approval_top_plate">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <p className="title_machine" style={{ width: "25%" }}>
                            Mesin {obj.mesin}
                          </p>
                          <p style={{ fontWeight: "bold" }}>{obj.operator}</p>
                        </div>
                        <p style={{ fontWeight: "bold" }}>
                          {obj.produk} - {obj.warna}
                        </p>
                        {!isOn ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <p style={{ fontWeight: "bold" }}>
                                Target Produksi
                              </p>
                              <p
                                style={
                                  Math.floor(
                                    ((28800 - obj.downtime * 60) / 43.2) *
                                      aktualCavityPasangKaret
                                  ) > jumlahPasangKaret
                                    ? { color: "red" }
                                    : {}
                                }
                              >
                                {(
                                  ((28800 - obj.downtime * 60) / 43.2) *
                                  aktualCavityPasangKaret
                                ).toFixed(0)}
                              </p>
                            </div>
                            <div>
                              <p style={{ fontWeight: "bold" }}>Cycle Time</p>
                              <p>
                                {(formula * aktualCavityPasangKaret).toFixed(1)}{" "}
                                Detik
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <p>
                              <b>Jumlah Pasang Karet</b>
                            </p>
                            <p>{obj.jumlah} Pcs</p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "50%" }}>
                            <p>
                              <b>Downtime</b>
                            </p>
                            <p>{obj.downtime} Menit</p>
                          </div>
                          <div style={{ width: "40%" }}>
                            <p>
                              <b>Aktual Cavity</b>
                            </p>
                            <p>{obj.aktualCavity ? obj.aktualCavity : ""}</p>
                          </div>
                        </div>
                        <p className="font_bold">Keterangan</p>
                        {obj.keterangan &&
                          obj.keterangan.split("\n").map((subStr) => {
                            return <p>{subStr}</p>;
                          })}
                      </div>
                    );
                  })}
              </div>
            </>
          ) : dataUser.role === "produksi_user" ? (
            <>
              <div style={{ display: "flex" }}>
                <div className="margin_right_27_qc">
                  <p>Pilih Shift</p>
                  <select
                    className="input_select_shift"
                    value={shiftDashboard}
                    onChange={(e) => setShiftDashboard(e.target.value)}
                  >
                    <option
                      selected={shiftDashboard === "Pilih Shift"}
                      disabled
                    >
                      Pilih Shift
                    </option>
                    <option value="1">Shift 1</option>
                    <option value="2">Shift 2</option>
                    <option value="3">Shift 3</option>
                  </select>
                </div>
                <div className="margin_right_27_qc">
                  <p>Pilih Tanggal</p>
                  <input
                    type="date"
                    className="date_pick_memo_ppic"
                    value={dateDashboard}
                    onChange={(e) => setDateDashboard(e.target.value)}
                  />
                </div>
              </div>
              <div
                className="container_card_approval_laporan"
                style={{
                  marginTop: "18px",
                }}
              >
                {sortData &&
                  sortData.map((data, index) => {
                    const downtime = data.downtime ? data.downtime : 0;
                    const dataCounterAwal = data.counterAwal
                      ? data.counterAwal
                      : 0;
                    const dataCounterAkhir = data.counterAkhir
                      ? data.counterAkhir
                      : 0;

                    const formula =
                      (28800 - downtime * 60) /
                      (dataCounterAkhir - dataCounterAwal);
                    return (
                      <div className="wrapper_card_approval_laporan">
                        <div className="section_approval_laporan">
                          <p className="title_machine">
                            Mesin&nbsp;{data.mesin}
                          </p>
                          <p>{data.customer}</p>
                          {data.isKepala === false ? (
                            <img
                              className="width_edit_logo"
                              style={{
                                background: "yellow",
                                borderRadius: "6px",
                              }}
                              onClick={() => setIsEdit(true)}
                              src={EditLogo}
                              alt="edit_logo"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="flex_justify_between">
                          <p>{data.operator}</p>
                        </div>
                        <div className="flex_justify_between">
                          <p className="font_bold">{data.moldType}</p>
                        </div>
                        <div className="flex_justify_between">
                          <p className="font_bold">{data.color}</p>
                        </div>
                        <div className="flex_justify_between">
                          <p>{data.date}</p>
                          <p style={{ textAlign: "end" }}>
                            Shift&nbsp;{data.shift}
                          </p>
                        </div>
                        <div className="flex_justify_between">
                          <div style={{ width: "50%" }}>
                            <p className="font_bold">Isi Per Kemasan</p>
                            <p className="font_14">
                              {data.isiPerKemasan}&nbsp;Pcs
                            </p>
                          </div>
                          <div style={{ width: "40%" }}>
                            <p className="font_bold">Aktual Cavity</p>
                            <p className="font_14">{data.aktualCavity}</p>
                          </div>
                        </div>

                        <div className="flex_justify_between">
                          <div style={{ width: "50%" }}>
                            <p className="font_bold">Counter Akhir</p>
                            {!isEdit ? (
                              <p className="font_14">{data.counterAkhir}</p>
                            ) : (
                              <input
                                type="number"
                                className="input_dashboard_operator"
                                name="counterAkhir"
                                value={data.counterAkhir}
                                onChange={(e) => handleFormChange(index, e)}
                                onWheel={(e) => e.target.blur()}
                              />
                            )}
                          </div>
                          <div style={{ width: "40%" }}>
                            <p className="font_bold">Counter Awal</p>
                            {!isEdit ? (
                              <p className="font_14">{data.counterAwal}</p>
                            ) : (
                              <input
                                type="number"
                                className="input_dashboard_operator"
                                name="counterAwal"
                                value={data.counterAwal}
                                onChange={(e) => handleFormChange(index, e)}
                                onWheel={(e) => e.target.blur()}
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex_justify_between">
                          <div style={{ width: "50%" }}>
                            <p className="font_bold">Selisih Counter</p>
                            <p className="font_14">
                              {data.counterAkhir - data.counterAwal}&nbsp;Pcs
                            </p>
                          </div>
                          <div style={{ width: "40%" }}>
                            <p className="font_bold">Sisa Shift Sebelumnya</p>
                            <p className="font_14">
                              {data.sisaShiftSebelumnya
                                ? data.sisaShiftSebelumnya
                                : 0}
                              &nbsp;Pcs
                            </p>
                          </div>
                        </div>
                        <div className="flex_justify_between">
                          <div style={{ width: "50%" }}>
                            <p className="font_bold">Jumlah "NG"</p>
                            {!isEdit ? (
                              <p className="font_14">
                                {data.jumlahNG}&nbsp;Pcs
                              </p>
                            ) : (
                              <input
                                type="number"
                                className="input_dashboard_operator"
                                name="jumlahNG"
                                value={data.jumlahNG}
                                onChange={(e) => handleFormChange(index, e)}
                                onWheel={(e) => e.target.blur()}
                              />
                            )}
                          </div>
                          <div style={{ width: "40%" }}>
                            <p className="font_bold">Barang OK</p>
                            {!isEdit ? (
                              <p className="font_14">
                                {data.jumlahBarang}&nbsp;Pcs
                              </p>
                            ) : (
                              <input
                                type="number"
                                className="input_dashboard_operator"
                                name="jumlahBarang"
                                value={data.jumlahBarang}
                                onChange={(e) => handleFormChange(index, e)}
                                onWheel={(e) => e.target.blur()}
                              />
                            )}
                          </div>
                        </div>
                        {!isOn ? (
                          <div className="flex_justify_between">
                            <div style={{ width: "50%" }}>
                              <p className="font_bold">Down Time</p>
                              <p className="font_14">{downtime}&nbsp;Menit</p>
                            </div>
                            <div style={{ width: "40%" }}>
                              <p className="font_bold">Cycle Time</p>
                              {formula === Infinity ? 0 : formula.toFixed(1)}
                              &nbsp;Detik
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          <div className="flex_justify_between">
                            <p style={{ width: "50%", fontWeight: "bold" }}>
                              Perincian "NG"
                            </p>
                            <p style={{ width: "40%", fontWeight: "bold" }}>
                              Jumlah
                            </p>
                          </div>
                          {data.perincianNG &&
                            data.perincianNG.map((perincian) => {
                              return (
                                <div className="flex_justify_between">
                                  <p style={{ fontSize: "14px", width: "50%" }}>
                                    {perincian.id}
                                  </p>
                                  <p style={{ fontSize: "14px", width: "40%" }}>
                                    {perincian.totalNG}&nbsp;Pcs
                                  </p>
                                </div>
                              );
                            })}
                          <div>
                            <p className="font_bold">Keterangan</p>
                            {data.keterangan &&
                              data.keterangan.split("\n").map((subStr) => {
                                return <p>{subStr}</p>;
                              })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {dataLaporanPasangKaret &&
              dataLaporanPasangKaret.length > 0 &&
              !isOn ? (
                <p className="title_inventory">Laporan Pasang Karet</p>
              ) : (
                ""
              )}
              {sortDataPasangKaret &&
                sortDataPasangKaret.map((obj) => {
                  const downtimePasangKaret = obj.downtime ? obj.downtime : 0;
                  const jumlahPasangKaret = obj.jumlah ? obj.jumlah : 0;
                  const aktualCavityPasangKaret = obj.aktualCavity
                    ? obj.aktualCavity
                    : 0;
                  const formula =
                    (28800 - downtimePasangKaret * 60) / jumlahPasangKaret;

                  return (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="card_approval_top_plate">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <p className="title_machine" style={{ width: "25%" }}>
                            Mesin {obj.mesin}
                          </p>
                          <p style={{ fontWeight: "bold" }}>{obj.operator}</p>
                        </div>
                        {!isOn ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <p style={{ fontWeight: "bold" }}>
                                Target Produksi
                              </p>
                              <p
                                style={
                                  Math.floor(
                                    (28800 - downtimePasangKaret * 60) / formula
                                  ) > jumlahPasangKaret
                                    ? { color: "red" }
                                    : {}
                                }
                              >
                                {Math.floor(
                                  (28800 - downtimePasangKaret * 60) / formula
                                )}
                              </p>
                            </div>
                            <div>
                              <p style={{ fontWeight: "bold" }}>Cycle Time</p>
                              <p>
                                {(formula * aktualCavityPasangKaret).toFixed(1)}{" "}
                                Detik
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <p style={{ fontWeight: "bold" }}>
                          {obj.produk} - {obj.warna}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <p>
                              <b>Jumlah Pasang Karet</b>
                            </p>
                            <p>{obj.jumlah} Pcs</p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "50%" }}>
                            <p>
                              <b>Downtime</b>
                            </p>
                            <p>{obj.downtime} Menit</p>
                          </div>
                          <div style={{ width: "40%" }}>
                            <p>
                              <b>Aktual Cavity</b>
                            </p>
                            <p>{obj.aktualCavity ? obj.aktualCavity : ""}</p>
                          </div>
                        </div>
                        <p className="font_bold">Keterangan</p>
                        {obj.keterangan &&
                          obj.keterangan.split("\n").map((subStr) => {
                            return <p>{subStr}</p>;
                          })}
                      </div>
                    </div>
                  );
                })}
              {historyDataOperator &&
              historyDataOperator.length > 0 &&
              isEdit ? (
                <button
                  className="button_submit_memo"
                  onClick={() => handleKirimEditOperator()}
                >
                  Kirim
                </button>
              ) : (
                ""
              )}
            </>
          ) : dataUser.role === "produksi_admin" ? (
            <>
              <div style={{ display: "flex" }}>
                <div className="margin_right_27_qc">
                  <p>Pilih Shift</p>
                  <select
                    className="input_select_shift"
                    value={shiftDashboard}
                    onChange={(e) => setShiftDashboard(e.target.value)}
                  >
                    <option
                      selected={shiftDashboard === "Pilih Shift"}
                      disabled
                    >
                      Pilih Shift
                    </option>
                    <option value="1">Shift 1</option>
                    <option value="2">Shift 2</option>
                    <option value="3">Shift 3</option>
                  </select>
                </div>
                <div className="margin_right_27_qc">
                  <p>Pilih Tanggal</p>
                  <input
                    type="date"
                    className="date_pick_memo_ppic"
                    value={dateDashboard}
                    onChange={(e) => setDateDashboard(e.target.value)}
                  />
                </div>
              </div>
              <div
                className="container_card_approval_laporan"
                style={{
                  marginTop: "18px",
                }}
              >
                {sortData &&
                  sortData.map((data, index) => {
                    const downtime = data.downtime ? data.downtime : 0;
                    const dataCounterAwal = data.counterAwal
                      ? data.counterAwal
                      : 0;
                    const dataTargetProduk = data.targetProduk
                      ? data.targetProduk
                      : 0;
                    const dataAktualCavity = data.aktualCavity
                      ? data.aktualCavity
                      : 0;
                    const dataCounterAkhir = data.counterAkhir
                      ? data.counterAkhir
                      : 0;

                    const formula =
                      (28800 - downtime * 60) /
                      (dataCounterAkhir - dataCounterAwal);

                    const formulaTargetProduksi =
                      dataTargetProduk * dataAktualCavity;

                    const indicatorCavity =
                      (data.jumlahBarang + data.jumlahNG) /
                      (data.counterAkhir - data.counterAwal);
                    return (
                      <div className="wrapper_card_approval_laporan">
                        <div className="section_approval_laporan">
                          <p className="title_machine">
                            Mesin&nbsp;{data.mesin}
                          </p>
                          <p className="font_bold">{data.customer}</p>
                          <img
                            style={{ width: "24px", cursor: "pointer" }}
                            src={DeleteIcon}
                            alt="delete-icon"
                            onClick={() => handleSetDataDelete(data)}
                          />
                          <img
                            className="width_edit_logo"
                            style={{
                              background: "yellow",
                              borderRadius: "6px",
                            }}
                            onClick={() => setIsEdit(true)}
                            src={EditLogo}
                            alt="edit_logo"
                          />
                        </div>
                        <div className="flex_justify_between">
                          <p>{data.operator}</p>
                        </div>
                        <div className="flex_justify_between">
                          {!isEdit ? (
                            <p className="font_bold">{data.moldType}</p>
                          ) : (
                            <select
                              onChange={(e) => handleChangeMesin(data.id, e)}
                              className="input_select_memo_ppic"
                              style={{ width: "100%", marginTop: "18px" }}
                              name="mold"
                              value={data.moldType}
                            >
                              <option selected disabled>
                                Pilih Mold Type
                              </option>
                              {dataQc &&
                                dataQc.data.map((e) => {
                                  return (
                                    <option value={e.moldType}>
                                      Mc {e.mesin} - {e.moldType}
                                    </option>
                                  );
                                })}
                            </select>
                          )}
                        </div>
                        <div className="flex_justify_between">
                          <p>{data.color}</p>
                        </div>
                        <div className="flex_justify_between">
                          <p>{data.date}</p>
                          <p style={{ textAlign: "end" }}>
                            Shift&nbsp;{data.shift}
                          </p>
                        </div>
                        <div className="flex_justify_between">
                          <div>
                            <p>Target Produksi</p>
                            <p
                              style={
                                formulaTargetProduksi > data.jumlahBarang
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {formulaTargetProduksi}
                            </p>
                          </div>
                          <div>
                            <p>Target Cycle Time</p>
                            <p
                              style={
                                28800 / dataTargetProduk < formula &&
                                28800 / dataTargetProduk !== Infinity
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {28800 / dataTargetProduk === Infinity
                                ? 0
                                : (28800 / dataTargetProduk).toFixed(1)}
                              &nbsp;Detik
                            </p>
                          </div>
                        </div>
                        <div className="flex_justify_between">
                          <div style={{ width: "50%" }}>
                            <p className="font_bold">Isi Per Kemasan</p>
                            {!isEdit ? (
                              <p className="font_14">
                                {data.isiPerKemasan}&nbsp;Pcs
                              </p>
                            ) : (
                              <input
                                type="number"
                                className="input_dashboard_operator"
                                name="isiPerKemasan"
                                value={data.isiPerKemasan}
                                onChange={(e) => handleFormChange(index, e)}
                                onWheel={(e) => e.target.blur()}
                              />
                            )}
                          </div>
                          <div style={{ width: "40%" }}>
                            <p className="font_bold">Aktual Cavity</p>
                            {!isEdit ? (
                              <p className="font_14">{data.aktualCavity}</p>
                            ) : (
                              <input
                                type="number"
                                className="input_dashboard_operator"
                                name="aktualCavity"
                                value={data.aktualCavity}
                                onChange={(e) => handleFormChange(index, e)}
                                onWheel={(e) => e.target.blur()}
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex_justify_between">
                          <div style={{ width: "50%" }}>
                            <p className="font_bold">Counter Akhir</p>
                            {!isEdit ? (
                              <p className="font_14">{data.counterAkhir}</p>
                            ) : (
                              <input
                                type="number"
                                className="input_dashboard_operator"
                                name="counterAkhir"
                                value={data.counterAkhir}
                                onChange={(e) => handleFormChange(index, e)}
                                onWheel={(e) => e.target.blur()}
                              />
                            )}
                          </div>
                          <div style={{ width: "40%" }}>
                            <p className="font_bold">Counter Awal</p>
                            {!isEdit ? (
                              <p className="font_14">{data.counterAwal}</p>
                            ) : (
                              <input
                                type="number"
                                className="input_dashboard_operator"
                                name="counterAwal"
                                value={data.counterAwal}
                                onChange={(e) => handleFormChange(index, e)}
                                onWheel={(e) => e.target.blur()}
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex_justify_between">
                          <div style={{ width: "50%" }}>
                            <p className="font_bold">Selisih Counter</p>
                            <p className="font_14">
                              {data.counterAkhir - data.counterAwal}
                            </p>
                          </div>
                          <div style={{ width: "40%" }}>
                            <p className="font_bold">Jumlah "NG"</p>
                            {!isEdit ? (
                              <p className="font_14">
                                {data.jumlahNG}&nbsp;Pcs
                              </p>
                            ) : (
                              <input
                                type="number"
                                className="input_dashboard_operator"
                                name="jumlahNG"
                                value={data.jumlahNG}
                                onChange={(e) => handleFormChange(index, e)}
                                onWheel={(e) => e.target.blur()}
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex_justify_between">
                          <div style={{ width: "50%" }}>
                            <p className="font_bold">Barang OK</p>
                            {!isEdit ? (
                              <p className="font_14">
                                {data.jumlahBarang}&nbsp;Pcs
                              </p>
                            ) : (
                              <input
                                type="number"
                                className="input_dashboard_operator"
                                name="jumlahBarang"
                                value={data.jumlahBarang}
                                onChange={(e) => handleFormChange(index, e)}
                                onWheel={(e) => e.target.blur()}
                              />
                            )}
                          </div>
                          <div
                            style={
                              indicatorCavity.toString().indexOf(".") !== -1 ||
                              indicatorCavity === 0
                                ? { width: "40%", color: "red" }
                                : { width: "40%" }
                            }
                          >
                            <p className="font_bold">Indikator Cavity</p>
                            <p className="font_14">
                              {indicatorCavity.toString().indexOf(".") !== -1
                                ? indicatorCavity.toFixed(3)
                                : indicatorCavity}
                            </p>
                          </div>
                        </div>
                        <div className="flex_justify_between">
                          <div style={{ width: "50%" }}>
                            <p className="font_bold">Sisa Shift Sebelumnya</p>
                            {!isEdit ? (
                              <p className="font_14">
                                {data.sisaShiftSebelumnya}&nbsp;Pcs
                              </p>
                            ) : (
                              <input
                                type="number"
                                className="input_dashboard_operator"
                                name="sisaShiftSebelumnya"
                                value={data.sisaShiftSebelumnya}
                                onChange={(e) => handleFormChange(index, e)}
                                onWheel={(e) => e.target.blur()}
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex_justify_between">
                          <div style={{ width: "50%" }}>
                            <p className="font_bold">Down Time</p>
                            {!isEdit ? (
                              <p className="font_14">{downtime}&nbsp;Menit</p>
                            ) : (
                              <input
                                type="number"
                                className="input_dashboard_operator"
                                name="downtime"
                                value={data.downtime}
                                onChange={(e) => handleFormChange(index, e)}
                                onWheel={(e) => e.target.blur()}
                              />
                            )}
                          </div>
                          <div style={{ width: "40%" }}>
                            <p className="font_bold">Cycle Time</p>
                            <p className="font_14">
                              {formula === Infinity ? 0 : formula.toFixed(1)}
                              &nbsp;Detik
                            </p>
                          </div>
                        </div>
                        <div>
                          <div className="flex_justify_between">
                            <p style={{ width: "50%", fontWeight: "bold" }}>
                              Perincian "NG"
                            </p>
                            <p style={{ width: "40%", fontWeight: "bold" }}>
                              Jumlah
                            </p>
                          </div>
                          {data.perincianNG &&
                            data.perincianNG.map((perincian) => {
                              return (
                                <div className="flex_justify_between">
                                  <p style={{ fontSize: "14px", width: "50%" }}>
                                    {perincian.id}
                                  </p>
                                  <p style={{ fontSize: "14px", width: "40%" }}>
                                    {perincian.totalNG}&nbsp;Pcs
                                  </p>
                                </div>
                              );
                            })}
                          <div>
                            <p className="font_bold">Keterangan</p>
                            {!isEdit ? (
                              <>
                                {data.keterangan &&
                                  data.keterangan.split("\n").map((subStr) => {
                                    return <p>{subStr}</p>;
                                  })}
                              </>
                            ) : (
                              <textarea
                                style={{
                                  width: "98%",
                                  resize: "none",
                                  height: "84px",
                                  fontFamily: "arial",
                                }}
                                name="keterangan"
                                value={data.keterangan}
                                onChange={(e) => handleFormChange(index, e)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {dataLaporanPasangKaret && dataLaporanPasangKaret.length > 0 ? (
                <p className="title_inventory">Laporan Pasang Karet</p>
              ) : (
                ""
              )}
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {sortDataPasangKaret &&
                  sortDataPasangKaret.map((obj, index) => {
                    return (
                      <div className="card_approval_top_plate">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <p className="title_machine">Mesin {obj.mesin}</p>
                          <img
                            style={{ width: "24px", cursor: "pointer" }}
                            src={DeleteIcon}
                            alt="delete-icon"
                            onClick={() => handleSetDataDeletePasangKaret(obj)}
                          />
                          <img
                            className="width_edit_logo"
                            style={{
                              background: "yellow",
                              borderRadius: "6px",
                            }}
                            onClick={() => setIsEdit(true)}
                            src={EditLogo}
                            alt="edit_logo"
                          />
                        </div>
                        <p style={{ fontWeight: "bold" }}>
                          {obj.produk} - {obj.warna}
                        </p>
                        <p>{obj.operator}</p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <p>
                              <b>Jumlah Pasang Karet</b>
                            </p>
                            {!isEdit ? (
                              <p>{obj.jumlah} Pcs</p>
                            ) : (
                              <input
                                type="number"
                                className="input_dashboard_operator"
                                name="jumlah"
                                value={obj.jumlah}
                                onChange={(e) =>
                                  handleFormChangePasangKaret(index, e)
                                }
                                onWheel={(e) => e.target.blur()}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "50%" }}>
                            <p>
                              <b>Downtime</b>
                            </p>
                            {!isEdit ? (
                              <p>{obj.downtime} Menit</p>
                            ) : (
                              <input
                                type="number"
                                className="input_dashboard_operator"
                                name="downtime"
                                value={obj.downtime}
                                onChange={(e) =>
                                  handleFormChangePasangKaret(index, e)
                                }
                                onWheel={(e) => e.target.blur()}
                              />
                            )}
                          </div>
                          <div style={{ width: "40%" }}>
                            <p>
                              <b>Aktual Cavity</b>
                            </p>
                            {!isEdit ? (
                              <p>{obj.aktualCavity ? obj.aktualCavity : ""}</p>
                            ) : (
                              <input
                                type="number"
                                className="input_dashboard_operator"
                                name="aktualCavity"
                                value={obj.aktualCavity}
                                onChange={(e) =>
                                  handleFormChangePasangKaret(index, e)
                                }
                                onWheel={(e) => e.target.blur()}
                              />
                            )}
                          </div>
                        </div>
                        <p className="font_bold">Keterangan</p>
                        {obj.keterangan &&
                          obj.keterangan.split("\n").map((subStr) => {
                            return <p>{subStr}</p>;
                          })}
                      </div>
                    );
                  })}
              </div>
              {(historyDataOperator && historyDataOperator.length > 0) ||
              isEdit ||
              (dataLaporanPasangKaret && dataLaporanPasangKaret.length > 0) ? (
                <button
                  className="button_submit_memo"
                  onClick={() => handleKirimEditAdmin()}
                >
                  Kirim
                </button>
              ) : (
                ""
              )}
            </>
          ) : dataUser.role === "qc_user" || dataUser.role === "qc_admin" ? (
            <>
              <div style={{ display: "flex" }}>
                <div className="margin_right_27_qc">
                  <p>Pilih Shift</p>
                  <select
                    className="input_select_shift"
                    value={shiftDashboard}
                    onChange={(e) => setShiftDashboard(e.target.value)}
                  >
                    <option
                      selected={shiftDashboard === "Pilih Shift"}
                      disabled
                    >
                      Pilih Shift
                    </option>
                    <option value="1">Shift 1</option>
                    <option value="2">Shift 2</option>
                    <option value="3">Shift 3</option>
                  </select>
                </div>
                <div className="margin_right_27_qc">
                  <p>Pilih Tanggal</p>
                  <input
                    type="date"
                    className="date_pick_memo_ppic"
                    value={dateDashboard}
                    onChange={(e) => setDateDashboard(e.target.value)}
                  />
                </div>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {sortDataQc &&
                  sortDataQc.map((data) => {
                    const isiPerKemasan = data.isiPerKemasan
                      ? data.isiPerKemasan
                      : 0;
                    const sisaShiftSebelumnya = data.sisaShiftSebelumnya
                      ? data.sisaShiftSebelumnya
                      : 0;
                    const jumlahBarangOK = data.jumlahBarang
                      ? data.jumlahBarang
                      : 0;

                    const formula =
                      (jumlahBarangOK + sisaShiftSebelumnya) / isiPerKemasan;

                    return (
                      <>
                        <div className="wrapper_card_approval_laporan">
                          <div className="section_approval_laporan">
                            <p className="title_machine">
                              Mesin&nbsp;{data.mesin}
                            </p>
                            <p className="font_bold">{data.customer}</p>
                            <p style={{ textAlign: "end" }}>
                              Shift&nbsp;{data.shift}
                            </p>
                          </div>
                          <div className="flex_justify_between">
                            <p>{data.operator}</p>
                          </div>
                          <div className="flex_justify_between">
                            <p className="font_bold">
                              {data.moldType} - {data.color}
                            </p>
                          </div>
                          <div className="flex_justify_between">
                            <div style={{ width: "40%" }}>
                              <p className="font_bold">Isi PerKemasan</p>
                              <p className="font_14">
                                {data.isiPerKemasan}&nbsp;Pcs
                              </p>
                            </div>
                            <div style={{ width: "50%" }}></div>
                          </div>
                          <div className="flex_justify_between">
                            <div style={{ width: "40%" }}>
                              <p className="font_bold">Jumlah "NG"</p>
                              <p className="font_14">
                                {data.jumlahNG}&nbsp;Pcs
                              </p>
                            </div>
                            <div style={{ width: "50%" }}>
                              <p className="font_bold">
                                Jumlah Barang (Dikali Kemasan)
                              </p>
                              <p className="font_14">
                                {Math.floor(formula) * data.isiPerKemasan}
                                &nbsp;Pcs
                              </p>
                            </div>
                          </div>
                          <div className="flex_justify_between">
                            <div style={{ width: "40%" }}>
                              <p className="font_bold">Receh</p>
                              <div>
                                <p style={{ fontSize: "16px" }}>
                                  <b>
                                    {jumlahBarangOK +
                                      sisaShiftSebelumnya -
                                      Math.floor(formula) * data.isiPerKemasan}
                                  </b>
                                  &nbsp;Pcs
                                </p>
                              </div>
                            </div>
                            <div style={{ width: "50%" }}>
                              <p className="font_bold">
                                Jumlah OK (BOX/KTG/PALET)
                              </p>
                              <div>
                                <p style={{ fontSize: "16px" }}>
                                  <b>{Math.floor(formula)}</b>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
              {dataLaporanPasangKaret && dataLaporanPasangKaret.length > 0 ? (
                <p className="title_inventory">Laporan Pasang Karet</p>
              ) : (
                ""
              )}
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {sortDataPasangKaret &&
                  sortDataPasangKaret.map((obj) => {
                    return (
                      <div className="card_approval_top_plate">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <p className="title_machine">Mesin {obj.mesin}</p>
                          <p style={{ fontWeight: "bold" }}>{obj.operator}</p>
                        </div>
                        <p style={{ fontWeight: "bold" }}>
                          {obj.produk} - {obj.warna}
                        </p>
                        <p>
                          Jumlah Pasang Karet : <b>{obj.jumlah} Pcs</b>
                        </p>
                        <p className="font_bold">Keterangan</p>
                        {obj.keterangan &&
                          obj.keterangan.split("\n").map((subStr) => {
                            return <p>{subStr}</p>;
                          })}
                      </div>
                    );
                  })}
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ) : subMenu === "SP" ? (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="margin_right_27_qc">
              <p>Pilih Tanggal</p>
              <input
                type="date"
                className="date_pick_memo_ppic"
                value={dateMonitoringSP}
                onChange={(e) => setDateMonitoringSP(e.target.value)}
              />
            </div>
            {dataUser.role === "pr_admin" ||
            dataUser.role === "sp_admin" ||
            dataUser.role === "super_user" ||
            dataUser.role === "document_control" ? (
              <button
                className="button_export_sp"
                style={{ margin: 0 }}
                onClick={() => setPopUpLaporanBulananSP(true)}
              >
                Laporan Bulanan
              </button>
            ) : (
              ""
            )}
          </div>

          <div className="container_card_approval_laporan">
            {sortDataSP &&
              sortDataSP.map((data, index) => {
                return (
                  <div className="card_approval_top_plate">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {!isEditSP ? (
                        <p className="font_14">
                          <p className="title_machine">Mesin {data.mesin}</p>
                        </p>
                      ) : (
                        <select
                          style={{ height: "32px", width: "144px" }}
                          name="mesin"
                          value={data.mesin}
                          onChange={(e) => handleFormChangeSP(index, e)}
                        >
                          <option
                            selected={data.mesin === "Pilih Mesin"}
                            disabled
                          >
                            Pilih Mesin
                          </option>
                          {constMesinSP &&
                            constMesinSP.map((ele) => (
                              <option value={ele}>Mesin {ele}</option>
                            ))}
                        </select>
                      )}
                      <p style={{ fontWeight: "bold" }}>{data.operator}</p>
                      {data.isKepala === false ? (
                        <img
                          className="width_edit_logo"
                          style={{
                            background: "yellow",
                            borderRadius: "6px",
                          }}
                          onClick={() => setIsEditSP(true)}
                          src={EditLogo}
                          alt="edit_logo"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <p style={{ fontWeight: "bold" }}>{data.produk}</p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {data.color ? (
                        <p>
                          <b>{data.color}</b>
                        </p>
                      ) : (
                        ""
                      )}
                      <p style={{ fontWeight: "bold", fontWeight: "bold" }}>
                        {data.customer}
                      </p>
                      <p style={{ fontWeight: "bold", fontWeight: "bold" }}>
                        {data.jenisPekerjaan}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <p style={{ fontWeight: "bold" }}>Target (Pcs)</p>
                        <p>{data.target}</p>
                      </div>
                      <div>
                        <p>
                          <b>Bagian</b>
                        </p>
                        <p>{data.bagian}</p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <p>
                          <b>Reject (Pcs)</b>
                        </p>
                        {!isEditSP ? (
                          <p className="font_14">
                            <p>{data.reject}</p>
                          </p>
                        ) : (
                          <input
                            type="number"
                            style={{ width: "54px" }}
                            className="input_dashboard_operator"
                            name="reject"
                            value={data.reject}
                            onChange={(e) => handleFormChangeSP(index, e)}
                            onWheel={(e) => e.target.blur()}
                          />
                        )}
                      </div>
                      <div>
                        <p>
                          <b>Repair (Pcs)</b>
                        </p>
                        {!isEditSP ? (
                          <p className="font_14">
                            <p>{data.repair}</p>
                          </p>
                        ) : (
                          <input
                            type="number"
                            style={{ width: "54px" }}
                            className="input_dashboard_operator"
                            name="repair"
                            value={data.repair}
                            onChange={(e) => handleFormChangeSP(index, e)}
                            onWheel={(e) => e.target.blur()}
                          />
                        )}
                      </div>
                      <div>
                        <p>
                          <b>OK (Pcs)</b>
                        </p>
                        {!isEditSP ? (
                          <p className="font_14">
                            <p>{data.jumlahOk}</p>
                          </p>
                        ) : (
                          <input
                            type="number"
                            className="input_dashboard_operator"
                            name="jumlahOk"
                            style={{ width: "54px" }}
                            value={data.jumlahOk}
                            onChange={(e) => handleFormChangeSP(index, e)}
                            onWheel={(e) => e.target.blur()}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <p>
                          <b>Jumlah Pengerjaan</b>
                        </p>
                        {!isEditSP ? (
                          <p className="font_14">
                            <p>{data.jumlahPengerjaan}</p>
                          </p>
                        ) : (
                          <input
                            type="text"
                            className="input_dashboard_operator"
                            name="jumlahPengerjaan"
                            value={data.jumlahPengerjaan}
                            onChange={(e) => handleFormChangeSP(index, e)}
                            onWheel={(e) => e.target.blur()}
                          />
                        )}
                      </div>
                      {!isOn ? (
                        <div>
                          <p>
                            <b>Downtime</b>
                          </p>
                          <p>{data.downtime} (Menit)</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <p className="font_bold">Kind Of Reject</p>
                    <table style={{ width: "100%", fontSize: "14px" }}>
                      <tr>
                        <th style={{ position: "inherit" }}>Reject</th>
                        <th>Tgl</th>
                        <th>Bagian</th>
                        <th style={{ padding: "18px 12px 18px 6px" }}>Total</th>
                      </tr>
                      {data.dataReject &&
                        data.dataReject.map((obj, index) => {
                          return (
                            <tr
                              style={
                                index % 2 === 0
                                  ? {
                                      background: "white",
                                      fontSize: "12px",
                                    }
                                  : {
                                      background: "#f9f9f9",
                                      fontSize: "12px",
                                    }
                              }
                            >
                              <td style={{ padding: "12px" }}>{obj.id}</td>
                              <td style={{ padding: "12px" }}>
                                {obj.dateReject}
                              </td>
                              <td style={{ padding: "12px" }}>
                                {obj.shift ? obj.shift : obj.bagian}
                              </td>
                              <td style={{ padding: "12px" }}>{obj.reject}</td>
                            </tr>
                          );
                        })}
                    </table>
                    <p className="font_bold">Keterangan</p>

                    {!isEditSP ? (
                      <>
                        {data.keterangan &&
                          data.keterangan.split("\n").map((subStr) => {
                            return <p>{subStr}</p>;
                          })}
                      </>
                    ) : (
                      <textarea
                        style={{
                          width: "98%",
                          resize: "none",
                          height: "84px",
                          fontFamily: "arial",
                        }}
                        name="keterangan"
                        value={data.keterangan}
                        onChange={(e) => handleFormChangeSP(index, e)}
                      />
                    )}
                  </div>
                );
              })}
          </div>
          {isEditSP ? (
            <button
              className="button_submit_memo"
              onClick={() => handleKirimEditSP()}
            >
              Kirim
            </button>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};
export default Dashboard;
