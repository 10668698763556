import React from "react";
import PopUpComponent from "../PopUp/PopUpComponent";
import LogoGS from "../../Assets/GS-Logo.png";
import LogoYBI from "../../Assets/YBI-Logo.jpeg";
import LogoTBP from "../../Assets/TBP-Logo.jpeg";
import LogoMELCO from "../../Assets/MELCO-Logo.png";
import LogoTIM from "../../Assets/TimLogo.png";
import LogoCBI from "../../Assets/CBI-Logo.png";
import ApproveLogo from "../../Assets/approve-icon.png";
import CancelLogo from "../../Assets/cancel-icon.png";
import "./CardApproval.css";
const CardApproval = ({
  data,
  dataPick,
  submitApproval,
  approvalType,
  popUpRequestIn,
  setPopUpRequestIn,
  popUpMessage,
  setPopUpMessage,
}) => {
  const validateLogo = () => {
    if (data.company === "GS") {
      return LogoGS;
    } else if (data.company === "YBI") {
      return LogoYBI;
    } else if (data.company === "TBP") {
      return LogoTBP;
    } else if (data.company === "MELCO") {
      return LogoMELCO;
    } else if (data.company === "CBI") {
      return LogoCBI;
    } else if (data.company === "TIM") {
      return LogoTIM;
    }
  };

  const handleValidatePopup = (button) => {
    if (button === "approved") {
      setPopUpMessage("MENYETUJUI");
      approvalType("approved");
      dataPick(data);
    } else if (button === "reject") {
      setPopUpMessage("MENOLAK");
      approvalType("reject");
      dataPick(data);
    }
    setPopUpRequestIn(true);
  };

  const handleCancelApproval = () => {
    setPopUpRequestIn(false);
  };
  return (
    <div className="container_approval_card">
      {popUpRequestIn ? (
        <PopUpComponent>
          <p>
            Apakah Anda yakin <b>{popUpMessage}</b> Request ini?
          </p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup"
              onClick={() => handleCancelApproval()}
            >
              Cancel
            </button>
            <button
              className="button_popup background_red"
              onClick={submitApproval}
            >
              Yakin
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <div>
        <p style={{ fontSize: "12px" }}>{data.requestId}</p>
        <p className="company_title_approval">PT. {data.company}</p>
        <p className="font_14">
          <b>Jenis:</b> BAHAN {data.jenisBahan}
        </p>
        <p className="font_14">
          <b>Nama:</b> {data.namaBahan}
        </p>
        <p className="font_14">
          <b>Tipe:</b> {data.tipeBahan}
        </p>
        <p className="font_14">
          <b>Warna:</b> {data.color}
        </p>
        <p className="font_14">
          <b>Jumlah:</b> {data.jumlah}{" "}
          {data.namaBahan === "PENDUKUNG" ? "Pcs" : "Kg"}
        </p>
        <p className="font_14">
          <b>Keterangan:</b> {data.keterangan}
        </p>
        <p className="font_14">
          <b>Requestor:</b> {data.requestor}
        </p>
        <p className="font_14">
          {data.date} {data.time}
        </p>
      </div>
      <div className="container_logo_approval">
        <img src={validateLogo()} alt="Logo" style={{ width: "80px" }} />
        <div className="container_approval_logo">
          <img
            className="approval_image"
            src={ApproveLogo}
            alt="approve"
            onClick={() => handleValidatePopup("approved")}
          />
          <img
            className="approval_image"
            src={CancelLogo}
            alt="cancel"
            onClick={() => handleValidatePopup("reject")}
          />
        </div>
      </div>
    </div>
  );
};
export default CardApproval;
