import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";

export const useListBahan = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [companyPickList, setCompanyPickList] = useState("GS");
  const [addCompany, setAddCompany] = useState("Pilih Nama Perusahaan");
  const [addMaterial, setAddMaterial] = useState("");
  const [popUp, setPopUp] = useState(false);
  const [popUpDeleteBahan, setPopUpDeleteBahan] = useState(false);
  const [dataListBahan, setDataListBahan] = useState();
  const [isApiRefresh, setIsApiRefresh] = useState(false);
  const [dataListBahanDelete, setDataListBahanDelete] = useState();
  const [addNamaBahan, setAddNamaBahan] = useState("");
  const [tipeBahan, setTipeBahan] = useState("");
  const [warnaBahan, setWarnaBahan] = useState("");

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListBahan`,
      { tim: dataUser.tim },
      dataUser.auth,
      setDataListBahan,
      ""
    );
    setIsApiRefresh(false);
  }, [isApiRefresh]);

  const dataPick = () => {
    let data;
    dataListBahan &&
      dataListBahan.forEach((element) => {
        if (element.company === companyPickList) {
          data = element;
        }
      });
    return data;
  };
  const MaterialList = dataPick();

  const handleCancelAddBahan = () => {
    setPopUp(false);
    setAddMaterial("");
    setAddNamaBahan("");
    setTipeBahan("");
    setWarnaBahan("");
  };

  const handleAddBahan = async () => {
    await postAxios(
      `${process.env.REACT_APP_ENDPOINT}/addNewBahan`,
      {
        company: addCompany,
        material: addNamaBahan,
        type: tipeBahan,
        color: warnaBahan,
        jenis: addMaterial,
        tim: dataUser.tim,
      },
      dataUser.auth,
      "",
      ""
    );
    setIsApiRefresh(true);
    handleCancelAddBahan();
  };

  const handleDeleteListBahan = async () => {
    await postAxios(
      `${process.env.REACT_APP_ENDPOINT}/deleteBahan`,
      {
        company: companyPickList,
        material: dataListBahanDelete.material,
        type: dataListBahanDelete.type,
        color: dataListBahanDelete.color,
        jenis: dataListBahanDelete.jenis,
        tim: dataUser.tim,
      },
      dataUser.auth,
      "",
      ""
    );
    setIsApiRefresh(true);
    setPopUpDeleteBahan(false);
  };

  return {
    popUp,
    addCompany,
    setAddCompany,
    addMaterial,
    setAddMaterial,
    handleAddBahan,
    setPopUp,
    companyPickList,
    setCompanyPickList,
    MaterialList,
    dataListBahan,
    handleDeleteListBahan,
    setPopUpDeleteBahan,
    popUpDeleteBahan,
    setDataListBahanDelete,
    setAddNamaBahan,
    setTipeBahan,
    setWarnaBahan,
    handleCancelAddBahan,
  };
};
