import React from "react";
import { useLaporanBahanBaku } from "./useLaporanBahanBaku";
import { constantNoMesin } from "../../util/constant";
import { constCustomer } from "../../util/constant";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import CloseLogo from "../../Assets/close_icon.png";
import "./LaporanBahanBaku.css";

const LaporanBahanBaku = () => {
  const {
    dataUser,
    subMenuLaporanBahanBaku,
    setSubMenuLaporanBahanBaku,
    companyPick,
    setCompanyPick,
    MaterialList,
    jenisLaporan,
    setJenisLaporan,
    mesin,
    setMesin,
    dateLaporan,
    setDateLaporan,
    jumlahBahan,
    setJumlahBahan,
    pickBahan,
    setPickBahan,
    handleKirim,
    errMsg,
    popUpErr,
    setPopUpErr,
    handlePopUp,
    isError,
    dataRiwayat,
    dataBahanBaku,
    jumlahReject,
    setJumlahReject,
    handleKirimReject,
    isErrorProduksi,
    dataRejectSetting,
    tipeBahan,
    setTipeBahan,
    shift,
    setShift,
    tipeNg,
    setTipeNg,
    jenisSetting,
    setJenisSetting,
    dataListProduk,
    produkReject,
    setProdukReject,
    listProduk,
    filterShift,
    setFilterShift,
    warnaBahan,
    setWarnaBahan,
    kepalaShift,
    setKepalaShift,
    secondMerge,
    jenisNGLain,
    setJenisNGLain,
    isPopUp,
    setIsPopUp,
    isPopUpGumpalan,
    setIsPopUpGumpalan,
    dateExport,
    setDateExport,
    dateUntil,
    setDateUntil,
    exportProduksi,
    exportGumpalan,
  } = useLaporanBahanBaku();

  const sortData =
    dataRiwayat &&
    dataRiwayat.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  const sortDataMonitoring =
    secondMerge &&
    secondMerge.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  const sortDataRejectSetting =
    dataRejectSetting &&
    dataRejectSetting.sort(function (a, b) {
      if (Number(a.mesin) < Number(b.mesin)) {
        return -1;
      }
      if (Number(a.mesin) > Number(b.mesin)) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="container_inventory">
      {popUpErr ? (
        <PopUpComponent>
          <p>{errMsg}</p>
          <button
            className="button_submit_memo"
            style={{ margin: "0px" }}
            onClick={() => handlePopUp()}
          >
            OK
          </button>
        </PopUpComponent>
      ) : (
        ""
      )}
      {isPopUp ? (
        <PopUpComponent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>Laporan Produksi</p>
            <img
              className="width_edit_logo"
              src={CloseLogo}
              onClick={() => setIsPopUp(false)}
              alt="edit_logo"
            />
          </div>
          <div style={{ display: "flex" }}>
            <div className="margin_right_27_qc">
              <p>Dari</p>
              <input
                type="date"
                className="date_pick_memo_ppic"
                value={dateExport}
                onChange={(e) => setDateExport(e.target.value)}
              />
            </div>
            <div className="margin_right_27_qc">
              <p>Sampai</p>
              <input
                type="date"
                className="date_pick_memo_ppic"
                value={dateUntil}
                onChange={(e) => setDateUntil(e.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "12px",
            }}
          >
            <button
              className="button_popup background_red"
              onClick={() => exportProduksi()}
            >
              Kirim
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {isPopUpGumpalan ? (
        <PopUpComponent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>Laporan Produksi</p>
            <img
              className="width_edit_logo"
              src={CloseLogo}
              onClick={() => setIsPopUpGumpalan(false)}
              alt="edit_logo"
            />
          </div>
          <div style={{ display: "flex" }}>
            <div className="margin_right_27_qc">
              <p>Dari</p>
              <input
                type="date"
                className="date_pick_memo_ppic"
                value={dateExport}
                onChange={(e) => setDateExport(e.target.value)}
              />
            </div>
            <div className="margin_right_27_qc">
              <p>Sampai</p>
              <input
                type="date"
                className="date_pick_memo_ppic"
                value={dateUntil}
                onChange={(e) => setDateUntil(e.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "12px",
            }}
          >
            <button
              className="button_popup background_red"
              onClick={() => exportGumpalan()}
            >
              Kirim
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <p className="title_inventory">LAPORAN BAHAN BAKU</p>
      <div className="wrapper_sub_menu_history">
        {dataUser.role === "warehouse_admin" ||
        dataUser.role === "warehouse_user" ||
        dataUser.role === "super_user" ? (
          <p
            className={
              subMenuLaporanBahanBaku === "Laporan"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenuLaporanBahanBaku("Laporan")}
          >
            Laporan
          </p>
        ) : (
          ""
        )}
        <p
          className={
            subMenuLaporanBahanBaku === "Riwayat"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenuLaporanBahanBaku("Riwayat")}
        >
          Riwayat Warehouse
        </p>
        {dataUser.role === "produksi_leader" ||
        dataUser.role === "super_user" ? (
          <p
            className={
              subMenuLaporanBahanBaku === "Produksi"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenuLaporanBahanBaku("Produksi")}
          >
            Produksi
          </p>
        ) : (
          ""
        )}
        <p
          className={
            subMenuLaporanBahanBaku === "RiwayatProduksi"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenuLaporanBahanBaku("RiwayatProduksi")}
        >
          Riwayat Produksi
        </p>
        <p
          className={
            subMenuLaporanBahanBaku === "Monitoring"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenuLaporanBahanBaku("Monitoring")}
        >
          Monitoring
        </p>
      </div>
      {subMenuLaporanBahanBaku === "Laporan" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {isError ? (
            <p style={{ color: "red", margin: "0px" }}>
              Mohon Mengisi Semua Kolom Berbintang merah!
            </p>
          ) : (
            ""
          )}
          <div style={{ width: "40%" }}>
            <p>
              Pilih Tanggal<label style={{ color: "red" }}>*</label>
            </p>
            <input
              type="date"
              value={dateLaporan}
              style={{ height: "28px", width: "97%" }}
              onChange={(e) => setDateLaporan(e.target.value)}
            />
          </div>
          <div style={{ width: "50%", marginRight: "12px" }}>
            <p>
              Pilih Mesin<label style={{ color: "red" }}>*</label>
            </p>
            <select
              style={{ height: "32px", width: "100%" }}
              value={mesin}
              onChange={(e) => setMesin(e.target.value)}
            >
              <option selected disabled>
                Pilih Mesin
              </option>
              {constantNoMesin &&
                constantNoMesin.map((data) => (
                  <option value={data}>{data}</option>
                ))}
            </select>
          </div>
          <div className="margin_right_27_qc" style={{ width: "40%" }}>
            <p>
              Pilih Customer<label style={{ color: "red" }}>*</label>
            </p>
            <select
              defaultValue="Pilih Customer"
              onChange={(e) => setCompanyPick(e.target.value)}
              className="input_select_memo_ppic"
              style={{ width: "100%" }}
              name="mesin"
              value={companyPick}
            >
              <option selected disabled>
                Pilih Customer
              </option>
              {constCustomer &&
                constCustomer.map((data) => (
                  <option value={data}>{data}</option>
                ))}
            </select>
          </div>
          <div className="margin_right_27_qc" style={{ width: "50%" }}>
            <p>
              Pilih Bahan<label style={{ color: "red" }}>*</label>
            </p>
            <select
              defaultValue="Pilih Bahan"
              onChange={(e) => setPickBahan(e.target.value)}
              className="input_select_memo_ppic"
              style={{ width: "100%" }}
              name="bahan"
              value={pickBahan}
            >
              <option selected disabled>
                Pilih Bahan
              </option>
              {MaterialList &&
                MaterialList.bahanBaku.map((data) => (
                  <option value={data.id}>
                    {data.material} {data.type}
                  </option>
                ))}
            </select>
          </div>
          <div className="margin_right_27_qc" style={{ width: "40%" }}>
            <p>
              Pilih Warna<label style={{ color: "red" }}>*</label>
            </p>
            <select
              defaultValue="Pilih Warna"
              onChange={(e) => setWarnaBahan(e.target.value)}
              className="input_select_memo_ppic"
              style={{ width: "100%" }}
              name="warna"
              value={warnaBahan}
            >
              <option selected disabled>
                Pilih Warna
              </option>
              <option value="BIRU">BIRU</option>
              <option value="HIJAU">HIJAU</option>
              <option value="HITAM">HITAM</option>
              <option value="KUNING">KUNING</option>
              <option value="MERAH">MERAH</option>
              <option value="NATURAL">NATURAL</option>
              <option value="ORANGE">ORANGE</option>
            </select>
          </div>
          <div style={{ width: "53%" }}>
            <p>
              Jumlah Bahan<label style={{ color: "red" }}>*</label>
            </p>
            <input
              placeholder="Jumlah Bahan (KG)"
              type="number"
              style={{ maxWidth: "90%", height: "32px" }}
              value={jumlahBahan}
              onChange={(e) => setJumlahBahan(e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
          </div>
          <div className="wrapper_input" style={{ width: "50%" }}>
            <p>
              Jenis Serah Terima<label style={{ color: "red" }}>*</label>
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "12px 0",
              }}
              onClick={() => setJenisLaporan("Keluar")}
            >
              <input
                type="radio"
                value="WH"
                style={{ margin: "0 6px 0 0" }}
                checked={jenisLaporan === "Keluar"}
              />
              <label style={{ fontSize: "16px" }}>Ke Produksi</label>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "12px 0",
              }}
              onClick={() => setJenisLaporan("Masuk")}
            >
              <input
                type="radio"
                value="WH"
                style={{ margin: "0 6px 0 0" }}
                checked={jenisLaporan === "Masuk"}
              />
              <label style={{ fontSize: "16px" }}>Kembali</label>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <button
              className="button_submit_memo"
              onClick={() => handleKirim()}
            >
              Kirim
            </button>
          </div>
        </div>
      ) : subMenuLaporanBahanBaku === "Riwayat" ? (
        <>
          <div style={{ display: "flex", alignItems: "end" }}>
            <div style={{ marginRight: "24px" }}>
              <p>
                Pilih Tanggal<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="date"
                value={dateLaporan}
                style={{ height: "28px" }}
                className="width_date_laporan"
                onChange={(e) => setDateLaporan(e.target.value)}
              />
            </div>
          </div>
          <br />
          <div
            className="table_inventory_mobile"
            style={{ position: "absolute", zIndex: "-1", width: "90%" }}
          >
            <table style={{ width: "100%" }}>
              <tr>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    padding: "12px 6px",
                    width: "6%",
                  }}
                >
                  MC
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Bahan Baku
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Jenis
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Jumlah (KG)
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Warna
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Pembuat
                </th>
              </tr>

              {sortData &&
                sortData.map((ele) => {
                  let firstFind =
                    dataBahanBaku &&
                    dataBahanBaku.find((o) => o.company === ele.customer);
                  let secondFind =
                    firstFind &&
                    firstFind.bahanBaku.find((o) => o.id === ele.pickBahan);
                  const splitNama = ele.creator
                    .split(" ")
                    .slice(0, 1)
                    .join(" ");

                  return (
                    <tr>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{
                          padding: "12px 6px",
                          lineHeight: "18px",
                        }}
                      >
                        {ele.mesin}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku text_center_bahan_baku"
                        style={{
                          padding: "12px 6px",
                          lineHeight: "18px",
                        }}
                      >
                        {secondFind.material} {secondFind.type}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={
                          ele.jenisLaporan === "Keluar"
                            ? {
                                padding: "12px 6px",
                                color: "red",
                              }
                            : {
                                padding: "12px 6px",
                                color: "green",
                              }
                        }
                      >
                        {ele.jenisLaporan}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {ele.jumlah}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {ele.warnaBahan}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {splitNama}
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </>
      ) : subMenuLaporanBahanBaku === "Produksi" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {isErrorProduksi ? (
            <p style={{ color: "red", margin: "0px" }}>
              Mohon Mengisi Semua Kolom Berbintang merah!
            </p>
          ) : (
            ""
          )}
          <div style={{ width: "40%" }}>
            <p>
              Pilih Tanggal<label style={{ color: "red" }}>*</label>
            </p>
            <input
              type="date"
              value={dateLaporan}
              style={{ height: "28px", width: "97%" }}
              onChange={(e) => setDateLaporan(e.target.value)}
            />
          </div>
          <div style={{ width: "50%", marginRight: "12px" }}>
            <p>
              Pilih Mesin<label style={{ color: "red" }}>*</label>
            </p>
            <select
              style={{ height: "32px", width: "100%" }}
              value={mesin}
              onChange={(e) => setMesin(e.target.value)}
            >
              <option selected disabled>
                Pilih Mesin
              </option>
              {constantNoMesin &&
                constantNoMesin.map((data) => (
                  <option value={data}>{data}</option>
                ))}
            </select>
          </div>
          <div style={{ width: "40%", marginRight: "12px" }}>
            <p>
              Pilih Customer<label style={{ color: "red" }}>*</label>
            </p>
            <select
              style={{ height: "32px", width: "100%" }}
              value={companyPick}
              onChange={(e) => setCompanyPick(e.target.value)}
            >
              <option selected disabled>
                Pilih Customer
              </option>
              {constCustomer &&
                constCustomer.map((data) => (
                  <option value={data}>{data}</option>
                ))}
            </select>
          </div>
          <div style={{ width: "50%", marginRight: "12px" }}>
            <p>
              Pilih Produk<label style={{ color: "red" }}>*</label>
            </p>
            <select
              style={{ height: "32px", width: "100%" }}
              value={produkReject}
              onChange={(e) => setProdukReject(e.target.value)}
            >
              <option selected disabled>
                Pilih Produk
              </option>
              {dataListProduk &&
                dataListProduk.data.map((data, index) => (
                  <option value={index}>
                    {data.name} - {data.color}
                  </option>
                ))}
            </select>
          </div>
          <div style={{ width: "40%", marginRight: "12px" }}>
            <p>
              Pilih Tipe Bahan<label style={{ color: "red" }}>*</label>
            </p>
            <select
              style={{ height: "32px", width: "100%" }}
              value={tipeBahan}
              onChange={(e) => setTipeBahan(e.target.value)}
            >
              <option selected disabled>
                Pilih Tipe Bahan
              </option>
              <option value={"Crusher"}>Crusher</option>
              <option value={"Murni"}>Murni</option>
            </select>
          </div>
          <div style={{ width: "50%", marginRight: "12px" }}>
            <p>
              Pilih Shift<label style={{ color: "red" }}>*</label>
            </p>
            <select
              style={{ height: "32px", width: "100%" }}
              value={shift}
              onChange={(e) => setShift(e.target.value)}
            >
              <option selected disabled>
                Pilih Shift
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>
          <div style={{ width: "40%", marginRight: "12px" }}>
            <p>
              Pilih Kepala Shift<label style={{ color: "red" }}>*</label>
            </p>
            <select
              style={{ height: "32px", width: "100%" }}
              value={kepalaShift}
              onChange={(e) => setKepalaShift(e.target.value)}
            >
              <option selected disabled>
                Pilih Kepala Shift
              </option>
              <option value="Supriadi">Supriadi</option>
              <option value="Achmad S">Achmad S</option>
              <option value="Saripudin">Saripudin</option>
            </select>
          </div>
          <div style={{ width: "50%", marginRight: "12px" }}>
            <p>
              Jenis NG<label style={{ color: "red" }}>*</label>
            </p>
            <select
              style={{ height: "32px", width: "100%" }}
              value={tipeNg}
              onChange={(e) => setTipeNg(e.target.value)}
            >
              <option selected disabled>
                Pilih Tipe NG
              </option>
              <option value="Flash">Flash</option>
              <option value="Gelombang">Gelombang</option>
              <option value="Ejector Mark">Ejector Mark</option>
              <option value="Ex Minyak">Ex Minyak</option>
              <option value="Belang">Belang</option>
              <option value="Unfill">Unfill</option>
              <option value="Tanpa Timah">Tanpa Timah</option>
              <option value="Black Spot">Black Spot</option>
              <option value="Lain - Lain">Lain - Lain</option>
            </select>
          </div>
          {tipeNg === "Lain - Lain" ? (
            <div style={{ width: "40%", marginRight: "12px" }}>
              <p>Lain - Lain</p>
              <input
                placeholder="Jenis NG Lain - Lain"
                style={{ maxWidth: "95%", height: "32px" }}
                value={jenisNGLain}
                type="text"
                onChange={(e) => setJenisNGLain(e.target.value)}
              />
            </div>
          ) : (
            ""
          )}
          <div
            style={
              tipeNg === "Lain - Lain"
                ? { width: "50%", marginRight: "12px" }
                : { width: "40%", marginRight: "12px" }
            }
          >
            <p>
              Pilih Jenis Setting<label style={{ color: "red" }}>*</label>
            </p>
            <select
              style={{ height: "32px", width: "100%" }}
              value={jenisSetting}
              onChange={(e) => setJenisSetting(e.target.value)}
            >
              <option selected disabled>
                Pilih Jenis Setting
              </option>
              <option value="Setting Berjalan">Setting Berjalan</option>
              <option value="Ganti Mold">Ganti Mold</option>
              <option value="Ganti Warna">Ganti Warna</option>
              <option value="Ganti Mold & Warna">Ganti Mold & Warna</option>
            </select>
          </div>
          <div style={{ width: "53%" }}>
            <p>
              Jumlah Reject<label style={{ color: "red" }}>*</label>
            </p>
            <input
              placeholder="Jumlah Reject (Pcs)"
              type="number"
              style={{ maxWidth: "95%", height: "32px" }}
              value={jumlahReject}
              onChange={(e) => setJumlahReject(e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
          </div>
          <div style={{ width: "100%" }}>
            <button
              className="button_submit_memo"
              onClick={() => handleKirimReject()}
            >
              Kirim
            </button>
          </div>
        </div>
      ) : subMenuLaporanBahanBaku === "RiwayatProduksi" ? (
        <>
          <div style={{ display: "flex", alignItems: "end" }}>
            <div style={{ marginRight: "24px" }}>
              <p>
                Pilih Tanggal<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="date"
                value={dateLaporan}
                style={{ height: "28px" }}
                className="width_date_laporan"
                onChange={(e) => setDateLaporan(e.target.value)}
              />
            </div>
            <div>
              <p>
                Pilih Shift<label style={{ color: "red" }}>*</label>
              </p>
              <select
                style={{ height: "32px", width: "100%" }}
                value={filterShift}
                onChange={(e) => setFilterShift(e.target.value)}
              >
                <option selected disabled>
                  Pilih Shift
                </option>
                <option value="Supriadi">Supriadi</option>
                <option value="Achmad S">Achmad S</option>
                <option value="Saripudin">Saripudin</option>
              </select>
            </div>
            {dataUser.role === "produksi_admin" ||
            dataUser.role === "super_user" ? (
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    background: "green",
                    color: "white",
                    padding: "12px",
                    borderRadius: "6px",
                    margin: "0",
                    cursor: "pointer",
                    marginLeft: "24px",
                  }}
                  onClick={() => setIsPopUp(true)}
                >
                  Export
                </p>
                <p
                  style={{
                    background: "green",
                    color: "white",
                    padding: "12px",
                    borderRadius: "6px",
                    margin: "0",
                    cursor: "pointer",
                    marginLeft: "24px",
                  }}
                  onClick={() => setIsPopUpGumpalan(true)}
                >
                  Gumpalan
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <br />

          <div className="table_laporan_bahan_baku">
            <table style={{ width: "100%" }}>
              <tr>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    padding: "12px 6px",
                    width: "6%",
                  }}
                >
                  MC
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Produk
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Warna
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Tipe NG
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Jumlah (Pcs)
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Setting
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Pembuat
                </th>
              </tr>

              {sortDataRejectSetting &&
                sortDataRejectSetting.map((ele) => {
                  let firstFind =
                    listProduk &&
                    listProduk.find((o) => o.company === ele.customer);
                  let secondFind =
                    firstFind && firstFind.data[ele.produkReject];

                  const splitNama = ele.creator
                    .split(" ")
                    .slice(0, 1)
                    .join(" ");

                  return (
                    <tr>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{
                          padding: "12px 6px",
                          lineHeight: "18px",
                        }}
                      >
                        {ele.mesin}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {secondFind && secondFind.name}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {secondFind && secondFind.color}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {ele.tipeNg}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {ele.jumlah}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {ele.jenisSetting}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {splitNama}
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </>
      ) : subMenuLaporanBahanBaku === "Monitoring" ? (
        <>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "24px" }}>
              <p>
                Pilih Tanggal<label style={{ color: "red" }}>*</label>
              </p>
              <input
                type="date"
                value={dateLaporan}
                style={{ height: "28px" }}
                className="width_date_laporan"
                onChange={(e) => setDateLaporan(e.target.value)}
              />
            </div>
            <div>
              <p>
                Pilih Shift<label style={{ color: "red" }}>*</label>
              </p>
              <select
                style={{ height: "32px", width: "100%" }}
                value={shift}
                onChange={(e) => setShift(e.target.value)}
              >
                <option selected disabled>
                  Pilih Shift
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </div>
          <p
            style={{ color: "#FFB000", fontSize: "14px", marginBottom: "0px" }}
          >
            * Indikator Loss jika <b>Minus (-)</b> menandakan Loss produksi
          </p>
          <br />
          <div className="table_laporan_bahan_baku">
            <table style={{ width: "100%" }}>
              <tr>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    padding: "12px 6px",
                    width: "6%",
                  }}
                >
                  MC
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Produk
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Berat (gr)
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Bahan
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Terpakai (Kg)
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Gumpalan (Kg)
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Reject (Kg)
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Loss (Kg)
                </th>
                {/* <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  (%)
                </th> */}
              </tr>

              {sortDataMonitoring &&
                sortDataMonitoring.map((ele, index) => {
                  const berat = ele.beratQc ? ele.beratQc : 0;
                  const barang = ele.jumlahBarang ? ele.jumlahBarang : 0;
                  const ng = ele.jumlahNG ? ele.jumlahNG : 0;
                  const jumlahRejectSetting = ele.jumlah ? ele.jumlah : 0;
                  const runner = ele.runner ? ele.runner : 0;
                  const bahan = ele.jumlahBahan ? ele.jumlahBahan : 0;
                  const gumpalan = ele.gumpalan ? ele.gumpalan : 0;

                  const formulaReject = (
                    ((jumlahRejectSetting + ng) * berat) /
                    1000
                  ).toFixed(3);

                  const formulaLoss =
                    bahan -
                    (barang * berat +
                      barang * runner +
                      ng * berat +
                      jumlahRejectSetting * berat +
                      gumpalan) /
                      1000;

                  return (
                    <tr
                      style={
                        index % 2 === 0
                          ? {
                              background: "white",
                              fontSize: "12px",
                            }
                          : {
                              background: "#f9f9f9",
                              fontSize: "12px",
                            }
                      }
                    >
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{
                          padding: "18px 6px",
                          lineHeight: "18px",
                        }}
                      >
                        {ele.mesin}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "18px 6px" }}
                      >
                        {ele.mold}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "18px 6px" }}
                      >
                        {ele.beratQc}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "18px 6px" }}
                      >
                        {ele.material}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "18px 6px" }}
                      >
                        {ele.jumlahBahan}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "18px 6px" }}
                      >
                        {ele.gumpalan
                          ? (ele.gumpalan / 1000).toFixed(2).replace(".", ",")
                          : ""}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "18px 6px" }}
                      >
                        {formulaReject === "0.000"
                          ? ""
                          : formulaReject.replace(".", ",")}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "18px 6px" }}
                      >
                        {(formulaLoss * -1).toFixed(1).replace(".", ",")}
                      </td>
                      {/* <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "18px 6px" }}
                      >
                        %
                      </td> */}
                    </tr>
                  );
                })}
            </table>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default LaporanBahanBaku;
