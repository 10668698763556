import React from "react";
import "./TableHistoryPPIC.css";

const TableHistoryPPIC = ({ data, jenisBahan, satuan }) => {
  return (
    <div className="wrapper_table_memo_ppic">
      <div className="container_header_memo_ppic">
        <div className="table_header_memo_ppic width_number_mesin min_width_history_width_15">
          Mesin
        </div>
        <div className="table_header_memo_ppic min_width_15">Cust</div>
        <div className="table_header_memo_ppic min_width_17">Mold</div>
        <div className="table_header_memo_ppic min_width_17">Bahan</div>
        <div className="table_header_memo_ppic min_width_15">Color</div>
        <div className="table_header_memo_ppic width_number_mesin min_width_15">
          Total
        </div>
      </div>
      {data &&
        data.map((obj, index) => {
          return (
            <>
              {!obj.isProduksi ? (
                <div
                  className={
                    index % 2 === 0
                      ? "container_data_memo_ppic"
                      : "container_data_memo_ppic background_odd"
                  }
                  key={index}
                >
                  <div className="width_number_mesin min_width_history_width_15">
                    <p style={{ margin: "0 6px" }}>{obj.mesin}</p>
                  </div>
                  <div className="min_width min_width_15">{obj.customer}</div>
                  <div className="min_width min_width_17">{obj.mold}</div>
                  <div className="min_width min_width_17">
                    {Array.isArray(obj.material)
                      ? obj.material.map((el) => {
                          return (
                            <>
                              {el.material}
                              <br />
                              <br />
                            </>
                          );
                        })
                      : obj.material}
                  </div>
                  <div className="min_width min_width_history_width_15">
                    {obj.color}
                  </div>
                  {jenisBahan === "SATUAN" ? (
                    <div className="width_number_mesin min_width_history_width_15">
                      {obj.total}&nbsp; Pcs
                    </div>
                  ) : (
                    <div className="width_number_mesin min_width_history_width_15">
                      {obj.totalBahan
                        ? Array.isArray(obj.material)
                          ? obj.material.map((el) => {
                              return (
                                <>
                                  {el.perbandingan * obj.totalBahan} &nbsp;
                                  {`${jenisBahan === "BAKU" ? "Kg" : "Pcs"}`}
                                  <br />
                                  <br />
                                </>
                              );
                            })
                          : `${obj.totalBahan} ${
                              jenisBahan === "BAKU" ? "Kg" : "Pcs"
                            }`
                        : Array.isArray(obj.material)
                        ? obj.material.map((el) => {
                            return (
                              <>
                                {el.total} &nbsp;
                                {`${jenisBahan === "BAKU" ? "Kg" : "Pcs"}`}
                                <br />
                                <br />
                              </>
                            );
                          })
                        : `${obj.total} ${
                            jenisBahan === "BAKU" ? "Kg" : "Pcs"
                          }`}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </>
          );
        })}
    </div>
  );
};
export default TableHistoryPPIC;
