import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import { constantNoMesin, shiftTim1, constCustomer } from "../../util/constant";
import { today } from "../../util/formatDate";
import { v4 as uuidv4 } from "uuid";

export const useKarantina = () => {
  const v4Id = uuidv4();
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [subMenu, setSubMenu] = useState("Monitoring");
  const [popUpEdit, setPopUpEdit] = useState(false);
  const [popUpPengerjaan, setPopUpPengerjaan] = useState(false);

  const [pelanggan, setPelanggan] = useState("");
  const [tanggalProduksi, setTanggalproduksi] = useState("");
  const [produk, setProduk] = useState([]);
  const [produkPick, setProdukPick] = useState("");
  const [bagian, setBagian] = useState("");
  const [warna, setWarna] = useState("");
  const [shift, setShift] = useState("");
  const [operator, setOperator] = useState("");
  const [mesin, setMesin] = useState("");
  const [isiPerKemasan, setIsiPerKemasan] = useState("");
  const [jumlahKemasan, setJumlahKemasan] = useState("");
  const [jumlahTotal, setJumlahTotal] = useState("");
  const [keterangan, setKeterangan] = useState("");

  const [errPopUp, setErrPopUp] = useState(false);
  const [popUpKirim, setPopUpKirim] = useState(false);

  const [popUpSuccess, setPopUpSuccess] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);

  const [listNota, setListNota] = useState([]);

  let date = new Date(today);
  const getMonth = date.getMonth();
  const getYear = date.getFullYear();
  const [filterMonth, setFilterMonth] = useState(getMonth + 1);
  const [filterYear, setFilterYear] = useState(getYear);

  const [pickNota, setPickNota] = useState("");
  const [jumlahPengerjaan, setJumlahPengerjaan] = useState(null);
  const [jumlahNG, setJumlahNG] = useState(null);

  const [listRiwayatPengerjaan, setListRiwayatPengerjaan] = useState(null);

  const [filterBagian, setFilterBagian] = useState(null);
  const [filterProduk, setFilterProduk] = useState(null);
  const [produkPopUp, setProdukPopUp] = useState(null);

  const [editPengerjaan, setEditPengerjaan] = useState(null);
  const [editNG, setEditNG] = useState(null);
  const [dataPickEdit, setDataPickEdit] = useState(null);

  const [isEditNota, setIsEditNota] = useState(false);
  const [jumlahTotalEdit, setJumlahTotalEdit] = useState(null);
  const [jumlahKemasanEdit, setJumlahKemasanEdit] = useState(null);

  const [popUpDelete, setPopUpDelete] = useState(false);
  const [dataPickDelete, setDataPickDelete] = useState(null);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListMold`,
      { tim: dataUser.tim },
      dataUser.auth,
      setProduk,
      ""
    );
  }, []);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getNotaKarantina`,
      {
        tim: dataUser.tim,
        month: filterMonth,
        filterBagian: filterBagian,
        year: filterYear,
      },
      dataUser.auth,
      setListNota,
      ""
    );

    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/riwayatPengerjaanKarantina`,
      {
        tim: dataUser.tim,
        month: Number(filterMonth),
        year: filterYear,
        filterBagian: filterBagian,
      },
      dataUser.auth,
      setListRiwayatPengerjaan,
      ""
    );
  }, [popUpKirim, isRefresh, subMenu, filterMonth, filterBagian, filterYear]);

  const dataPick = () => {
    let data;
    produk &&
      produk.forEach((element) => {
        if (element.company === pelanggan) {
          data = element;
        }
      });
    return data;
  };

  const listDataPick = dataPick();

  let listProduk = [];
  listDataPick &&
    listDataPick.data.map((data) => {
      listProduk.push({ value: data.name, label: data.name });
    });

  const asalBarang = [
    { value: "Finishing", label: "Finishing" },
    { value: "Gudang", label: "Gudang" },
    { value: "Printing", label: "Printing" },
    { value: "Produksi", label: "Produksi" },
  ];
  const warnaBarang = [
    { value: "Biru", label: "Biru" },
    { value: "Hijau", label: "Hijau" },
    { value: "Hitam", label: "Hitam" },
    { value: "Kuning", label: "Kuning" },
    { value: "Merah", label: "Merah" },
    { value: "Orange", label: "Orange" },
    { value: "Putih", label: "Putih" },
  ];
  let customer = [];
  constCustomer.map((data) => {
    customer.push({ value: data, label: data });
  });

  let noMesin = [];
  constantNoMesin.map((data) => {
    noMesin.push({ value: data, label: data });
  });

  let shiftProduksi = [];

  shiftTim1.map((data) => {
    shiftProduksi.push({ value: data, label: data });
  });

  const handleKirim = () => {
    if (
      pelanggan &&
      tanggalProduksi &&
      produkPick.value &&
      bagian &&
      warna &&
      shift &&
      mesin &&
      operator &&
      isiPerKemasan &&
      jumlahKemasan &&
      jumlahTotal &&
      keterangan
    ) {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/addNotaKarantina`,
        {
          id: v4Id,
          pelanggan: pelanggan,
          tanggalProduksi: tanggalProduksi,
          produkPick: produkPick.value,
          bagian: bagian,
          warna: warna,
          shift: shift,
          mesin: mesin,
          operator: operator,
          isiPerKemasan: Number(isiPerKemasan),
          jumlahKemasan: jumlahKemasan,
          jumlahTotal: Number(jumlahTotal),
          keterangan: keterangan,
          creatorNota: dataUser.name,
          tim: dataUser.tim,
          dataPengerjaan: [],
          month: filterMonth,
          year: filterYear,
        },
        dataUser.auth,
        "",
        ""
      );
      setPopUpKirim(true);
      setPelanggan("");
      setTanggalproduksi("");
      setProdukPick("");
      setBagian("");
      setWarna("");
      setShift("");
      setOperator("");
      setMesin("");
      setIsiPerKemasan("");
      setJumlahKemasan("");
      setJumlahTotal("");
      setKeterangan("");
      setIsRefresh(true);
    } else {
      setErrPopUp(true);
    }
  };

  const handleClosePopUp = () => {
    setPopUpKirim(false);
    setSubMenu("Monitoring");
  };

  const handlePickNota = (id) => {
    setPickNota(id);
    setPopUpPengerjaan(true);

    const findNota = listNota && listNota.find((e) => (e.id === id ? e : ""));
    setProdukPopUp(findNota);
  };

  const handleKirimPengerjaan = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/addNotaPengerjaan`,
      {
        id: v4Id,
        idNota: pickNota,
        jumlahPengerjaan: Number(jumlahPengerjaan),
        jumlahNG: Number(jumlahNG),
        operator: dataUser.name,
      },
      dataUser.auth,
      "",
      ""
    );
    setPopUpPengerjaan(false);
    setPopUpSuccess(true);
    setIsRefresh(true);
    setPickNota("");
    setJumlahPengerjaan(null);
    setJumlahNG(null);
  };

  let aggregateSortiran = [];

  listNota &&
    listNota.forEach((a) => {
      let jumlahPengerjaanAgregasi = a && a.jumlahTotal;
      a &&
        a.dataPengerjaan.forEach((b) => {
          jumlahPengerjaanAgregasi -= b.jumlahPengerjaan;
        });
      aggregateSortiran.push({
        id: a.id,
        pelanggan: a.pelanggan,
        tanggalProduksi: a.tanggalProduksi,
        produkPick: a.produkPick,
        bagian: a.bagian,
        warna: a.warna,
        shift: a.shift,
        mesin: a.mesin,
        operator: a.operator,
        isiPerKemasan: a.isiPerKemasan,
        jumlahKemasan: a.jumlahKemasan,
        jumlahTotal: a.jumlahTotal,
        keterangan: a.keterangan,
        creatorNota: a.creatorNota,
        tanggalNota: a.tanggalNota,
        month: a.month,
        sisa: jumlahPengerjaanAgregasi,
        tim: a.tim,
      });
    });

  const pickEdit = (data) => {
    setEditPengerjaan(data.jumlahPengerjaan);
    setEditNG(data.jumlahNG);
    setDataPickEdit(data);
    setPopUpEdit(true);
  };

  const handleKirimEdit = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/editPengerjaanKarantina`,
      {
        id: dataPickEdit.id,
        idNota: dataPickEdit.idNota,
        editPengerjaan: editPengerjaan,
        editNG: editNG,
      },
      dataUser.auth,
      "",
      ""
    );
    setPopUpEdit(false);
    setIsRefresh(true);
  };

  const handleKirimEditNota = (data) => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/editNotaKarantina`,
      {
        id: data.id,
        jumlahTotal: jumlahTotalEdit
          ? Number(jumlahTotalEdit)
          : data.jumlahTotal,
        jumlahKemasan: jumlahKemasanEdit ? jumlahKemasanEdit : jumlahKemasan,
      },
      dataUser.auth,
      "",
      ""
    );
    setIsEditNota(false);
    setJumlahTotalEdit(null);
    setJumlahKemasanEdit(null);
    setIsRefresh(true);
  };

  const pickEditDelete = (data) => {
    setDataPickDelete(data);
    setPopUpDelete(true);
  };

  const handleDeletePengerjaan = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/deleteRiwayatPengerjaanKarantina`,
      {
        id: dataPickDelete.id,
        idNota: dataPickDelete.idNota,
      },
      dataUser.auth,
      "",
      ""
    );
    setPopUpDelete(false);
    setIsRefresh(true);
  };

  return {
    subMenu,
    setSubMenu,
    asalBarang,
    warnaBarang,
    customer,
    noMesin,
    shiftProduksi,
    popUpEdit,
    setPopUpEdit,
    popUpPengerjaan,
    setPopUpPengerjaan,
    pelanggan,
    setPelanggan,
    tanggalProduksi,
    setTanggalproduksi,
    bagian,
    setBagian,
    warna,
    setWarna,
    shift,
    setShift,
    operator,
    setOperator,
    mesin,
    setMesin,
    isiPerKemasan,
    setIsiPerKemasan,
    jumlahKemasan,
    setJumlahKemasan,
    jumlahTotal,
    setJumlahTotal,
    keterangan,
    setKeterangan,
    listProduk,
    produkPick,
    setProdukPick,
    handleKirim,
    popUpKirim,
    setPopUpKirim,
    errPopUp,
    setErrPopUp,
    popUpSuccess,
    setPopUpSuccess,
    handleKirimPengerjaan,
    handlePickNota,
    jumlahPengerjaan,
    jumlahNG,
    setJumlahPengerjaan,
    setJumlahNG,
    listRiwayatPengerjaan,
    filterMonth,
    setFilterMonth,
    filterBagian,
    setFilterBagian,
    aggregateSortiran,
    produkPopUp,
    dataUser,
    handleClosePopUp,
    editPengerjaan,
    setEditPengerjaan,
    editNG,
    setEditNG,
    pickEdit,
    handleKirimEdit,
    filterProduk,
    setFilterProduk,
    isEditNota,
    setIsEditNota,
    jumlahTotalEdit,
    setJumlahTotalEdit,
    handleKirimEditNota,
    jumlahKemasanEdit,
    setJumlahKemasanEdit,
    popUpDelete,
    setPopUpDelete,
    handleDeletePengerjaan,
    pickEditDelete,
    filterYear,
    setFilterYear,
  };
};
