import { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

export const useLogin = () => {
  let history = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [ktpNumber, setKtpNumber] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [popUpLogin, setPopUpLogin] = useState("");
  const [popUpChangePassword, setPopUpChangePassword] = useState(false);

  const [catatan, setCatatan] = useState(localStorage.getItem("catatan"));
  const [popUpCatatan, setPopUpCatatan] = useState(false);

  const handleSaveCatatan = () => {
    localStorage.setItem("catatan", catatan);
  };

  const handleLogin = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/login`,
        {
          username: username,
          password: password,
        }
      );
      if (data.code === 200) {
        localStorage.setItem("auth", JSON.stringify(data.data));
        history.push("/dashboard");
      } else {
        setPopUpLogin(data.code);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleKirimGantiPassword = async () => {
    if (ktpNumber !== "" && newPassword !== "" && oldPassword !== "")
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/changePassword`,
          {
            ktpNumber: ktpNumber,
            oldPassword: oldPassword,
            newPassword: newPassword,
          }
        );

        if (data.code === 200) {
          setKtpNumber("");
          setOldPassword("");
          setNewPassword("");
          setPopUpChangePassword(false);
        }
      } catch (error) {
        console.log(error);
      }
  };

  return {
    username,
    setUsername,
    password,
    setPassword,
    handleLogin,
    popUpLogin,
    setPopUpLogin,
    popUpChangePassword,
    setPopUpChangePassword,
    ktpNumber,
    setKtpNumber,
    newPassword,
    setNewPassword,
    oldPassword,
    setOldPassword,
    handleKirimGantiPassword,
    handleSaveCatatan,
    setCatatan,
    popUpCatatan,
    setPopUpCatatan,
    catatan,
  };
};
