import { useState, useEffect } from "react";
import { postAxios } from "../../util/apiCall";
import { today, getDateUtil } from "../../util/formatDate";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";

export const useLaporanBahanBaku = () => {
  const v4Id = uuidv4();
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [dateLaporan, setDateLaporan] = useState(today);
  const [companyPick, setCompanyPick] = useState("Pilih Customer");
  const [subMenuLaporanBahanBaku, setSubMenuLaporanBahanBaku] =
    useState("Laporan");

  const [dataBahanBaku, setDataBahanBaku] = useState("");
  const [jenisLaporan, setJenisLaporan] = useState("");
  const [mesin, setMesin] = useState("Pilih Mesin");
  const [jumlahBahan, setJumlahBahan] = useState("");
  const [pickBahan, setPickBahan] = useState("Pilih Bahan");
  const [errMsg, setErrMsg] = useState("");
  const [popUpErr, setPopUpErr] = useState(false);
  const [isError, setIsError] = useState(false);
  const [dataRiwayat, setDataRiwayat] = useState();
  const [jumlahReject, setJumlahReject] = useState("");
  const [isErrorProduksi, setIsErrorProduksi] = useState(false);
  const [dataRejectSetting, setDataRejectSetting] = useState();
  const [tipeBahan, setTipeBahan] = useState("Pilih Tipe Bahan");
  const [shift, setShift] = useState("Pilih Shift");
  const [tipeNg, setTipeNg] = useState("Pilih Tipe NG");
  const [jenisSetting, setJenisSetting] = useState("Pilih Jenis Setting");
  const [listProduk, setListProduk] = useState();
  const [produkReject, setProdukReject] = useState("Pilih Produk");
  const [filterShift, setFilterShift] = useState("Pilih Shift");
  const [warnaBahan, setWarnaBahan] = useState("Pilih Warna");
  const [kepalaShift, setKepalaShift] = useState("Pilih Kepala Shift");
  const [jenisNGLain, setJenisNGLain] = useState("");

  const [dataMonitoringShift, setDataMonitoringShift] = useState();
  const [dataMonitoringQc, setDataMonitoringQc] = useState();
  const [dataMonitoringOperator, setDataMonitoringOperator] = useState();
  const [dataMonitoringP3, setDataMonitoringP3] = useState();
  const [dataGumpalan, setDataGumpalan] = useState();
  const [dataExport, setDataExport] = useState();
  const [dataExportGumpalan, setDataExportGumpalan] = useState();
  const [dateExport, setDateExport] = useState(today);
  const [dateUntil, setDateUntil] = useState(today);
  const [isPopUp, setIsPopUp] = useState(false);
  const [isPopUpGumpalan, setIsPopUpGumpalan] = useState(false);

  useEffect(() => {
    if (
      dataUser.role === "produksi_leader" ||
      dataUser.role === "produksi_admin"
    ) {
      setSubMenuLaporanBahanBaku("Produksi");
    } else if (dataUser.role === "ppic_admin" || dataUser.role === "qc_admin") {
      setSubMenuLaporanBahanBaku("Monitoring");
    } else if (dataUser.role === "p3_user") {
      setSubMenuLaporanBahanBaku("Riwayat");
    }
  }, []);
  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListBahan`,
      { tim: dataUser.tim },
      dataUser.auth,
      setDataBahanBaku,
      ""
    );
  }, [companyPick]);

  useEffect(() => {
    if (subMenuLaporanBahanBaku === "Riwayat") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getPenggunaanBahan`,
        {
          tim: dataUser.tim,
          date: getDateUtil(dateLaporan),
        },
        dataUser.auth,
        setDataRiwayat,
        ""
      );
    }
  }, [dateLaporan, subMenuLaporanBahanBaku]);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/monitoringRejectShift`,
      {
        tim: dataUser.tim,
        date: getDateUtil(dateLaporan),
        shift: shift,
      },
      dataUser.auth,
      setDataMonitoringShift,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getDashboardOperator`,
      {
        tim: dataUser.tim,
        date: getDateUtil(dateLaporan),
        shift: shift,
        type: "monitoring",
      },
      dataUser.auth,
      setDataMonitoringOperator,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getQualityControl`,
      {
        date: getDateUtil(dateLaporan),
        shift: shift,
        tim: dataUser.tim,
      },
      dataUser.auth,
      setDataMonitoringQc,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getHistoryP3`,
      {
        date: getDateUtil(dateLaporan),
        shift: shift,
        tim: dataUser.tim,
        type: "History",
      },
      dataUser.auth,
      setDataMonitoringP3,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getGumpalan`,
      {
        date: getDateUtil(dateLaporan),
        shift: shift,
        tim: dataUser.tim,
      },
      dataUser.auth,
      setDataGumpalan,
      ""
    );
  }, [dateLaporan, shift]);

  useEffect(() => {
    if (
      subMenuLaporanBahanBaku === "Produksi" ||
      subMenuLaporanBahanBaku === "RiwayatProduksi"
    ) {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getListMold`,
        { tim: dataUser.tim },
        dataUser.auth,
        setListProduk,
        ""
      );
    }
  }, [dateLaporan, subMenuLaporanBahanBaku]);

  useEffect(() => {
    if (subMenuLaporanBahanBaku === "RiwayatProduksi") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getLaporanRejectSetting`,
        {
          tim: dataUser.tim,
          date: getDateUtil(dateLaporan),
          shift: filterShift,
        },
        dataUser.auth,
        setDataRejectSetting,
        ""
      );
    }
  }, [dateLaporan, subMenuLaporanBahanBaku, filterShift]);

  const dataPick = () => {
    let data;
    dataBahanBaku &&
      dataBahanBaku.forEach((element) => {
        if (element.company === companyPick) {
          data = element;
        }
      });
    return data;
  };
  const MaterialList = dataPick();

  const dataPickListProduk = () => {
    let data;
    listProduk &&
      listProduk.forEach((element) => {
        if (element.company === companyPick) {
          data = element;
        }
      });
    return data;
  };

  const dataListProduk = dataPickListProduk();

  const handleKirim = () => {
    if (
      companyPick !== "Pilih Customer" &&
      mesin !== "Pilih Mesin" &&
      jenisLaporan !== "" &&
      pickBahan !== "Pilih Bahan" &&
      jumlahBahan !== "" &&
      warnaBahan !== "Pilih Warna"
    ) {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/addPenggunaanBahan`,
        {
          id: v4Id,
          tim: dataUser.tim,
          date: getDateUtil(dateLaporan),
          customer: companyPick,
          mesin: mesin,
          jenisLaporan: jenisLaporan,
          pickBahan: Number(pickBahan),
          creator: dataUser.name,
          warnaBahan: warnaBahan,
          jumlah: Number(jumlahBahan),
        },
        dataUser.auth,
        "",
        ""
      );

      setCompanyPick("Pilih Customer");
      setMesin("Pilih Mesin");
      setJenisLaporan("");
      setPickBahan("Pilih Bahan");
      setJumlahBahan("");
      setErrMsg("Data Telah Dikirim!");
      setWarnaBahan("Pilih Warna");

      setPopUpErr(true);
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  const handlePopUp = () => {
    setPopUpErr(false);
    setErrMsg("");
  };

  const handleKirimReject = () => {
    if (
      mesin !== "Pilih Mesin" &&
      jumlahReject !== "" &&
      jenisSetting !== "Pilih Jenis Setting" &&
      tipeNg !== "Pilih Tipe NG" &&
      shift !== "Pilih Shift" &&
      tipeBahan !== "Pilih Tipe Bahan" &&
      produkReject !== "Pilih Produk"
    ) {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/addRejectSetting`,
        {
          id: v4Id,
          tim: dataUser.tim,
          customer: companyPick,
          date: getDateUtil(dateLaporan),
          mesin: mesin,
          jumlah: Number(jumlahReject),
          creator: dataUser.name,
          jenisSetting: jenisSetting,
          produk: produkReject,
          tipeNg: tipeNg === "Lain - Lain" ? jenisNGLain : tipeNg,
          shift: shift,
          kepalaShift: kepalaShift,
          tipeBahan: tipeBahan,
          produkReject: produkReject,
        },
        dataUser.auth,
        "",
        ""
      );

      setProdukReject("Pilih Produk");
      setErrMsg("Data Telah Dikirim!");
      setCompanyPick("Pilih Customer");
      setPopUpErr(true);
      setMesin("Pilih Mesin");
      setJumlahReject("");
      setIsErrorProduksi(false);
      setJenisSetting("Pilih Jenis Setting");
      setTipeNg("Pilih Tipe NG");
      setShift("Pilih Shift");
      setTipeBahan("Pilih Tipe Bahan");
      setJenisNGLain("");
    } else {
      setIsErrorProduksi(true);
    }
  };

  const data1 = dataMonitoringShift && dataMonitoringShift;
  const data2 = dataMonitoringQc && dataMonitoringQc.data;
  const data3 = dataMonitoringOperator && dataMonitoringOperator;
  const data4 = dataMonitoringP3 && dataMonitoringP3;
  const data5 = dataGumpalan && dataGumpalan;

  const mergeById = () =>
    data1 &&
    data1.map((itm) => ({
      ...(data2 && data2.find((item) => item.mesin === itm.mesin && item)),
      ...itm,
    }));

  const merge = mergeById();

  const dataConcat = merge ? data3 && data3.concat(merge) : data3;

  const dataConcat2 = data5
    ? dataConcat && dataConcat.concat(data5)
    : dataConcat;

  const newArray =
    dataConcat2 &&
    dataConcat2.reduce(function (acc, curr) {
      let findIfNameExist = acc.findIndex(function (item) {
        return item.mesin === curr.mesin;
      });
      if (findIfNameExist === -1) {
        let obj = {
          mesin: curr.mesin,
          material: curr.material,
          customer: curr.customer,
          jumlahBarang: curr.jumlahBarang,
          jumlahNG: curr.jumlahNG,
          produkReject: curr.produkReject ? curr.produkReject : "",
          jumlah: curr.jumlah ? curr.jumlah : 0,
          typeMaterial: curr.typeMaterial,
          runner: curr.runner,
          mold: curr.mold,
          customer: curr.customer,
          color: curr.color,
          beratQc: curr.beratQc,
          aktualCavity: curr.aktualCavity,
          gumpalan: curr.gumpalan,
        };
        acc.push(obj);
      } else {
        let counthNg = curr.jumlahNG ? curr.jumlahNG : 0;
        let countBarang = curr.jumlahBarang ? curr.jumlahBarang : 0;
        let countjumlahReject = curr.jumlah ? curr.jumlah : 0;
        acc[findIfNameExist].jumlahNG += counthNg;
        acc[findIfNameExist].jumlahBarang += countBarang;
        acc[findIfNameExist].produkReject = curr.produkReject;
        acc[findIfNameExist].jumlah += countjumlahReject;
        acc[findIfNameExist].gumpalan = curr.gumpalan;
      }
      return acc;
    }, []);

  const mergeWithP3 = () =>
    newArray &&
    newArray.map((itm) => ({
      ...(data4 && data4.find((item) => item.mesin === itm.mesin && item)),
      ...itm,
    }));

  const secondMerge = mergeWithP3();

  const exportProduksi = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/exportRejectProduksi`,
      {
        tim: dataUser.tim,
        date: getDateUtil(dateExport),
        dateUntil: getDateUtil(dateUntil),
        type: "setting",
      },
      dataUser.auth,
      setDataExport,
      ""
    );

    const sort =
      dataExport &&
      dataExport.sort(function (a, b) {
        if (Number(a.isoDate) < Number(b.isoDate)) {
          return -1;
        }
        if (Number(a.isoDate) > Number(b.isoDate)) {
          return 1;
        }
        if (Number(a.shift) > Number(b.shift)) {
          return -1;
        }
        if (Number(a.shift) < Number(b.shift)) {
          return 1;
        }
        return 0;
      });

    const filteredDataExcel =
      sort &&
      sort.map((obj) => {
        let firstFind =
          listProduk && listProduk.find((o) => o.company === obj.customer);
        let secondFind = firstFind && firstFind.data[obj.produkReject];

        return {
          TANGGAL: obj.date,
          MC: obj.mesin,
          PRODUK: secondFind.name,
          WARNA: secondFind.color,
          "TIPE NG": obj.tipeNg,
          JUMLAH: obj.jumlah,
          SETTING: obj.jenisSetting,
          SHIFT: obj.kepalaShift,
        };
      });

    const workSheet = XLSX.utils.json_to_sheet(
      filteredDataExcel && filteredDataExcel
    );

    workSheet["!cols"] = [
      { wch: 16 },
      { wch: 4 },
      { wch: 40 },
      { wch: 13 },
      { wch: 14 },
      { wch: 8 },
      { wch: 15 },
      { wch: 12 },
    ];
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "attendance");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workBook,
      `Laporan Reject Produksi ${getDateUtil(dateExport)} - ${getDateUtil(
        dateUntil
      )}.xlsx`
    );

    setIsPopUp(false);
    setDateExport(today);
    setDateUntil(today);
  };

  const exportGumpalan = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/exportRejectProduksi`,
      {
        tim: dataUser.tim,
        date: getDateUtil(dateExport),
        dateUntil: getDateUtil(dateUntil),
        type: "gumpalan",
      },
      dataUser.auth,
      setDataExportGumpalan,
      ""
    );

    const sort =
      dataExportGumpalan &&
      dataExportGumpalan.sort(function (a, b) {
        if (Number(a.isoDate) < Number(b.isoDate)) {
          return -1;
        }
        if (Number(a.isoDate) > Number(b.isoDate)) {
          return 1;
        }
        if (Number(a.shift) > Number(b.shift)) {
          return -1;
        }
        if (Number(a.shift) < Number(b.shift)) {
          return 1;
        }
        return 0;
      });

    const filteredDataExcel =
      sort &&
      sort.map((obj) => {
        return {
          TANGGAL: obj.date,
          MESIN: obj.mesin,
          "GUMPALAN (gr)": obj.gumpalan,
          SHIFT: obj.shift,
        };
      });

    const workSheet = XLSX.utils.json_to_sheet(
      filteredDataExcel && filteredDataExcel
    );

    workSheet["!cols"] = [{ wch: 15 }, { wch: 6 }, { wch: 14 }, { wch: 5 }];
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Gumpalan");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workBook,
      `Laporan Gumpalan ${getDateUtil(dateExport)} - ${getDateUtil(
        dateUntil
      )}.xlsx`
    );

    setIsPopUpGumpalan(false);
    setDateExport(today);
    setDateUntil(today);
  };

  return {
    dataUser,
    subMenuLaporanBahanBaku,
    setSubMenuLaporanBahanBaku,
    companyPick,
    setCompanyPick,
    MaterialList,
    jenisLaporan,
    setJenisLaporan,
    mesin,
    setMesin,
    dateLaporan,
    setDateLaporan,
    jumlahBahan,
    setJumlahBahan,
    pickBahan,
    setPickBahan,
    handleKirim,
    errMsg,
    popUpErr,
    setPopUpErr,
    handlePopUp,
    isError,
    dataRiwayat,
    dataBahanBaku,
    jumlahReject,
    setJumlahReject,
    handleKirimReject,
    isErrorProduksi,
    dataRejectSetting,
    tipeBahan,
    setTipeBahan,
    shift,
    setShift,
    tipeNg,
    setTipeNg,
    jenisSetting,
    setJenisSetting,
    dataListProduk,
    produkReject,
    setProdukReject,
    listProduk,
    filterShift,
    setFilterShift,
    warnaBahan,
    setWarnaBahan,
    kepalaShift,
    setKepalaShift,
    shift,
    secondMerge,
    jenisNGLain,
    setJenisNGLain,
    exportProduksi,
    isPopUp,
    setIsPopUp,
    dateExport,
    setDateExport,
    dateUntil,
    setDateUntil,
    isPopUpGumpalan,
    setIsPopUpGumpalan,
    exportGumpalan,
  };
};
