import React from "react";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import Select from "react-select";
import { useKarantina } from "./useKarantina";
import EditLogo from "../../Assets/editLogo.png";
import CloseLogo from "../../Assets/close_icon.png";
import DeleteIcon from "../../Assets/delete_icon.png";
import moment from "moment";
import { filterMonthConstant, filterYearConstant } from "../../util/formatDate";
import "./Karantina.css";
import * as XLSX from "xlsx";

const Karantina = () => {
  const {
    dataUser,
    subMenu,
    setSubMenu,
    asalBarang,
    warnaBarang,
    customer,
    noMesin,
    filterProduk,
    setFilterProduk,
    popUpEdit,
    setPopUpEdit,
    popUpPengerjaan,
    setPopUpPengerjaan,
    pelanggan,
    setPelanggan,
    tanggalProduksi,
    setTanggalproduksi,
    bagian,
    setBagian,
    warna,
    setWarna,
    shift,
    setShift,
    operator,
    setOperator,
    mesin,
    setMesin,
    isiPerKemasan,
    setIsiPerKemasan,
    jumlahKemasan,
    setJumlahKemasan,
    jumlahTotal,
    setJumlahTotal,
    keterangan,
    setKeterangan,
    listProduk,
    produkPick,
    setProdukPick,
    handleKirim,
    popUpKirim,
    errPopUp,
    setErrPopUp,
    popUpSuccess,
    setPopUpSuccess,
    handleKirimPengerjaan,
    handlePickNota,
    jumlahPengerjaan,
    jumlahNG,
    setJumlahPengerjaan,
    setJumlahNG,
    listRiwayatPengerjaan,
    filterMonth,
    setFilterMonth,
    filterBagian,
    setFilterBagian,
    aggregateSortiran,
    produkPopUp,
    handleClosePopUp,
    editPengerjaan,
    setEditPengerjaan,
    editNG,
    setEditNG,
    pickEdit,
    handleKirimEdit,
    isEditNota,
    setIsEditNota,
    jumlahTotalEdit,
    setJumlahTotalEdit,
    handleKirimEditNota,
    jumlahKemasanEdit,
    setJumlahKemasanEdit,
    popUpDelete,
    setPopUpDelete,
    handleDeletePengerjaan,
    pickEditDelete,
    filterYear,
    setFilterYear,
  } = useKarantina();

  const formatDot = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const formatDateNota = (date) => {
    const newDate = new Date(date);
    return moment(newDate).format("DD/MM/YYYY");
  };

  let dataMerge = [];
  let listFilterProduk = [];

  listRiwayatPengerjaan &&
    listRiwayatPengerjaan.forEach((e) => {
      if (!listFilterProduk.some((ele) => ele === e.produkPick)) {
        listFilterProduk.push(e.produkPick);
      }

      if (e.produkPick === filterProduk) {
        e &&
          e.dataPengerjaan.forEach((data) => {
            dataMerge.push({
              tanggalNota: e.tanggalNota,
              time: data.time,
              operator: data.operator,
              produkPick: e.produkPick,
              jumlahPengerjaan: data.jumlahPengerjaan,
              jumlahNG: data.jumlahNG,
              bagian: e.bagian,
              id: data.id,
              idNota: e.id,
            });
          });
      } else if (!filterProduk) {
        e &&
          e.dataPengerjaan.forEach((data) => {
            dataMerge.push({
              tanggalNota: e.tanggalNota,
              time: data.time,
              operator: data.operator,
              produkPick: e.produkPick,
              jumlahPengerjaan: data.jumlahPengerjaan,
              jumlahNG: data.jumlahNG,
              bagian: e.bagian,
              id: data.id,
              idNota: e.id,
            });
          });
      }
    });

  const exportRiwayatPengerjaan = async () => {
    const formatDot = (value) => {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    const filteredDataExcel =
      dataMerge &&
      dataMerge.map((obj) => {
        const bagian = obj.bagian ? obj.bagian : "";
        const jumlahNG = obj.jumlahNG ? obj.jumlahNG : 0;
        const jumlahPengerjaan = obj.jumlahPengerjaan
          ? obj.jumlahPengerjaan
          : 0;
        const validateNama = obj.operator ? obj.operator : "";
        const produkPick = obj.produkPick ? obj.produkPick : "";
        const tanggalNota = obj.tanggalNota ? obj.tanggalNota : "";
        const time = obj.time ? obj.time : "";

        return {
          NOTA: formatDateNota(tanggalNota),
          PENGERJAAN: formatDateNota(time),
          OPERATOR: validateNama,
          PRODUK: produkPick,
          "PENGERJAAN(Pcs)": formatDot(jumlahPengerjaan),
          "NG(PCS)": formatDot(jumlahNG),
          BAGIAN: bagian,
        };
      });

    const workSheet = XLSX.utils.json_to_sheet(
      filteredDataExcel && filteredDataExcel
    );

    workSheet["!cols"] = [
      { wch: 11 },
      { wch: 11 },
      { wch: 30 },
      { wch: 40 },
      { wch: 15 },
      { wch: 7 },
      { wch: 8 },
    ];

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Laporan");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, `Laporan Karantina.xlsx`);
  };

  const sortDataRiwayat = dataMerge && dataMerge.reverse();

  aggregateSortiran &&
    aggregateSortiran.sort(function (a, b) {
      if (a.sisa !== 0 && new Date(a.time) > b.sisa !== 0 && new Date(b.time)) {
        return -1;
      }
      if (a.sisa !== 0 && new Date(a.time) < b.sisa !== 0 && new Date(b.time)) {
        return 1;
      }
      return 0;
    });

  return (
    <div className="container_dashboard">
      <p className="title_inventory">Karantina</p>
      <div className="wrapper_sub_menu_history" style={{ margin: 0 }}>
        <p
          className={
            subMenu === "Monitoring"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenu("Monitoring")}
        >
          Monitoring
        </p>
        <p
          className={
            subMenu === "Riwayat"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenu("Riwayat")}
        >
          Riwayat Pengerjaan
        </p>
        {dataUser.role === "super_user" ||
        dataUser.role === "qc_admin" ||
        dataUser.role === "qc_user" ||
        dataUser.role === "sp_admin" ||
        dataUser.role === "sp_user" ||
        dataUser.role === "pr_admin" ||
        dataUser.role === "pr_user" ||
        dataUser.role === "ppic_admin" ? (
          <p
            className={
              subMenu === "Nota"
                ? "sub_menu_history border_active_history"
                : "sub_menu_history"
            }
            onClick={() => setSubMenu("Nota")}
          >
            Buat Nota
          </p>
        ) : (
          ""
        )}
      </div>
      {subMenu === "Nota" ? (
        <>
          {errPopUp ? (
            <PopUpComponent>
              <p>
                <label style={{ color: "red" }}>&#9888; </label>Mohon Isi Semua
                Kolom Berbintang!
              </p>
              <div className="wrapper_button_submit_popup">
                <button
                  className="button_popup background_red"
                  onClick={() => setErrPopUp(false)}
                >
                  OK
                </button>
              </div>
            </PopUpComponent>
          ) : (
            ""
          )}
          {popUpKirim ? (
            <PopUpComponent>
              <p>Selamat Nota Karantina Telah dibuat!</p>
              <div className="wrapper_button_submit_popup">
                <button
                  className="button_popup background_red"
                  onClick={() => handleClosePopUp()}
                >
                  OK
                </button>
              </div>
            </PopUpComponent>
          ) : (
            ""
          )}
          <p style={{ color: "red", margin: 0 }}>
            &#9888; Mohon Isi Sesuai Packing List!
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "48%" }}>
              <p>
                Pelanggan <label style={{ color: "red" }}>*</label>
              </p>
              <Select
                options={customer}
                value={customer.find(function (option) {
                  return option.value === pelanggan;
                })}
                onChange={(e) => setPelanggan(e.value)}
              />
            </div>
            <div style={{ width: "48%" }}>
              <p>
                Tgl. Produksi <label style={{ color: "red" }}>*</label>
              </p>
              <input
                className="input_jumlah"
                type="text"
                placeholder="Contoh: 1/5/24 - 3/5/24"
                value={tanggalProduksi}
                onChange={(e) => setTanggalproduksi(e.target.value)}
              />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <p>
                Nama Barang <label style={{ color: "red" }}>*</label>
              </p>
              <Select
                options={listProduk}
                value={listProduk.find(function (option) {
                  return option.value === produkPick;
                })}
                onChange={setProdukPick}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "48%" }}>
              <p>
                Asal Barang <label style={{ color: "red" }}>*</label>
              </p>
              <Select
                options={asalBarang}
                value={asalBarang.find(function (option) {
                  return option.value === bagian;
                })}
                onChange={(e) => setBagian(e.value)}
              />
            </div>
            <div style={{ width: "48%" }}>
              <p>
                Warna <label style={{ color: "red" }}>*</label>
              </p>
              <Select
                options={warnaBarang}
                value={warnaBarang.find(function (option) {
                  return option.value === warna;
                })}
                onChange={(e) => setWarna(e.value)}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "48%" }}>
              <p>
                Shift <label style={{ color: "red" }}>*</label>
              </p>
              {/* <Select
                options={shiftProduksi}
                value={shiftProduksi.find(function (option) {
                  return option.value === shift;
                })}
                onChange={(e) => setShift(e.value)}
              /> */}
              <input
                className="input_jumlah"
                type="text"
                placeholder="Contoh: 1/2/3"
                value={shift}
                onChange={(e) => setShift(e.target.value)}
              />

              <p>
                Mesin <label style={{ color: "red" }}>*</label>
              </p>
              <Select
                options={noMesin}
                value={noMesin.find(function (option) {
                  return option.value === mesin;
                })}
                onChange={(e) => setMesin(e.value)}
              />
            </div>
            <div style={{ width: "48%" }}>
              <p>
                Operator <label style={{ color: "red" }}>*</label>
              </p>
              <input
                className="input_jumlah"
                type="text"
                placeholder="Contoh: Toni/Dewi/Mei"
                value={operator}
                onChange={(e) => setOperator(e.target.value)}
              />
              <p>
                Isi / Kemasan <label style={{ color: "red" }}>*</label>
              </p>
              <input
                className="input_jumlah"
                type="number"
                placeholder="Isi Per Kemasan"
                value={isiPerKemasan}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => setIsiPerKemasan(e.target.value)}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "48%" }}>
              <p>
                Jumlah Kemasan <label style={{ color: "red" }}>*</label>
              </p>
              <input
                className="input_jumlah"
                type="text"
                placeholder="Jumlah Kemasan"
                value={jumlahKemasan}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => setJumlahKemasan(e.target.value)}
              />
            </div>
            <div style={{ width: "48%" }}>
              <p>
                Jumlah Total <label style={{ color: "red" }}>*</label>
              </p>
              <input
                className="input_jumlah"
                type="number"
                placeholder="Jumlah Total"
                value={jumlahTotal}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => setJumlahTotal(e.target.value)}
              />
            </div>
          </div>
          <p>
            Keterangan Masalah <label style={{ color: "red" }}>*</label>
          </p>
          <textarea
            style={{
              width: "98%",
              height: "96px",
              fontSize: "12px",
              fontFamily: "sans-serif",
              resize: "none",
              padding: "6px",
            }}
            onChange={(e) => setKeterangan(e.target.value)}
            name="Keterangan"
            placeholder="Keterangan"
            value={keterangan}
          />

          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup background_red"
              onClick={() => handleKirim()}
            >
              Kirim
            </button>
          </div>
          <br />
        </>
      ) : subMenu === "Riwayat" ? (
        <>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div
              className="margin_right_27_qc"
              style={{ marginBottom: "12px" }}
            >
              <p>Pilih Bulan</p>
              <select
                className="input_select_shift"
                value={filterMonth}
                onChange={(e) => setFilterMonth(e.target.value)}
              >
                <option disabled>Pilih Bulan</option>
                {filterMonthConstant.map((data) => (
                  <option value={data.value}>{data.label}</option>
                ))}
              </select>
            </div>
            <div
              className="margin_right_27_qc"
              style={{ marginBottom: "12px" }}
            >
              <p>Pilih Tahun</p>
              <select
                className="input_select_shift"
                value={filterYear}
                onChange={(e) => setFilterYear(e.target.value)}
              >
                <option disabled>Pilih Bulan</option>
                {filterYearConstant.map((data) => (
                  <option value={data.value}>{data.label}</option>
                ))}
              </select>
            </div>
            <div
              className="margin_right_27_qc"
              style={{ marginBottom: "12px" }}
            >
              <p>Asal Barang</p>
              <select
                className="input_select_shift"
                value={filterBagian}
                onChange={(e) => setFilterBagian(e.target.value)}
              >
                <option disabled selected={!filterBagian}>
                  Pilih Asal Barang
                </option>
                {asalBarang.map((data) => (
                  <option value={data.value}>{data.label}</option>
                ))}
              </select>
            </div>
            <div
              className="margin_right_27_qc"
              style={{ marginBottom: "12px" }}
            >
              <p>Produk</p>
              <select
                className="input_select_shift"
                value={filterProduk}
                onChange={(e) => setFilterProduk(e.target.value)}
              >
                <option disabled selected={!filterProduk}>
                  Pilih Produk
                </option>
                {listFilterProduk &&
                  listFilterProduk.map((data) => (
                    <option value={data}>{data}</option>
                  ))}
              </select>
            </div>
            {dataUser.role === "super_user" ||
            dataUser.role === "ppic_admin" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                }}
              >
                <p
                  style={{
                    background: "green",
                    color: "white",
                    padding: "12px",
                    borderRadius: "6px",
                    marginRight: "12px",
                    cursor: "pointer",
                  }}
                  onClick={() => exportRiwayatPengerjaan()}
                >
                  Export
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          {popUpEdit ? (
            <PopUpComponent>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}></div>
                <img
                  className="width_edit_logo"
                  src={CloseLogo}
                  onClick={() => setPopUpEdit(false)}
                  alt="edit_logo"
                />
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div style={{ margin: "0 32px 0 0" }}>
                  <p>Pengerjaan (PCS)</p>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => setEditPengerjaan(Number(e.target.value))}
                    value={editPengerjaan}
                    placeholder="Jumlah (Pcs)"
                    style={{
                      height: "36px",
                      width: "124px",
                      marginRight: "6px",
                    }}
                  />
                </div>
                <div>
                  <p>NG (PCS)</p>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => setEditNG(Number(e.target.value))}
                    value={editNG}
                    placeholder="Jumlah NG (Pcs)"
                    style={{
                      height: "36px",
                      width: "124px",
                      marginRight: "6px",
                    }}
                  />
                </div>
              </div>
              <div className="wrapper_button_submit_popup">
                <button
                  className="button_popup background_red"
                  onClick={() => handleKirimEdit()}
                >
                  Edit
                </button>
              </div>
            </PopUpComponent>
          ) : (
            ""
          )}

          {popUpDelete ? (
            <PopUpComponent>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p>Apakah Kamu Yakin ingin menghapus riwayat Pengerjaan ini?</p>
                <img
                  className="width_edit_logo"
                  src={CloseLogo}
                  onClick={() => setPopUpDelete(false)}
                  alt="edit_logo"
                />
              </div>
              <div className="wrapper_button_submit_popup">
                <button
                  className="button_popup background_red"
                  onClick={() => handleDeletePengerjaan()}
                >
                  Delete
                </button>
              </div>
            </PopUpComponent>
          ) : (
            ""
          )}
          <div
            className="table_inventory_mobile width-table-karantina"
            style={{ position: "absolute", zIndex: "-1" }}
          >
            <table style={{ width: "100%" }}>
              <tr>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    padding: "12px 6px",
                    width: "6%",
                    borderRight: "1px solid lightgray",
                  }}
                >
                  No
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Nota
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Pengerjaan
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Operator
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Produk
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    lineHeight: "24px",
                  }}
                >
                  Pengerjaan <br />
                  (PCS)
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                    lineHeight: "24px",
                  }}
                >
                  NG
                  <br />
                  (PCS)
                </th>
                <th
                  className="table_header"
                  style={{
                    background: "#e8e9f8",
                    borderRight: "1px solid lightgray",
                    padding: "6px",
                  }}
                >
                  Bagian
                </th>
                {dataUser.role === "super_user" ||
                dataUser.role === "ppic_admin" ? (
                  <th
                    className="table_header"
                    style={{
                      background: "#e8e9f8",
                      borderRight: "1px solid lightgray",
                      padding: "6px",
                    }}
                  >
                    Edit
                  </th>
                ) : (
                  ""
                )}
                {dataUser.role === "super_user" ||
                dataUser.role === "ppic_admin" ? (
                  <th
                    className="table_header"
                    style={{
                      background: "#e8e9f8",
                      borderRight: "1px solid lightgray",
                      padding: "6px",
                    }}
                  >
                    Delete
                  </th>
                ) : (
                  ""
                )}
              </tr>
              {sortDataRiwayat &&
                sortDataRiwayat.map((e, index) => {
                  return (
                    <tr
                      style={
                        index % 2 === 0
                          ? {
                              background: "white",
                              fontSize: "12px",
                            }
                          : {
                              background: "#f9f9f9",
                              fontSize: "12px",
                            }
                      }
                    >
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{
                          padding: "12px 6px",
                          lineHeight: "18px",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku text_center_bahan_baku"
                        style={{
                          padding: "12px 6px",
                          lineHeight: "18px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {formatDateNota(e.tanggalNota)}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku text_center_bahan_baku"
                        style={{
                          padding: "12px 6px",
                          lineHeight: "18px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {formatDateNota(e.time)}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {e.operator}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px", whiteSpace: "nowrap" }}
                      >
                        {e.produkPick}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {e.jumlahPengerjaan}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {e.jumlahNG}
                      </td>
                      <td
                        className="font_td_laporan_bahan_baku"
                        style={{ padding: "12px 6px" }}
                      >
                        {e.bagian}
                      </td>
                      {dataUser.role === "super_user" ||
                      dataUser.role === "ppic_admin" ? (
                        <td
                          className="font_td_laporan_bahan_baku"
                          style={{ padding: "6px" }}
                        >
                          <img
                            className="width_edit_logo"
                            style={{ background: "yellow" }}
                            src={EditLogo}
                            onClick={() => pickEdit(e)}
                            alt="edit_logo"
                          />
                        </td>
                      ) : (
                        ""
                      )}
                      {dataUser.role === "super_user" ||
                      dataUser.role === "ppic_admin" ? (
                        <td
                          className="font_td_laporan_bahan_baku"
                          style={{ padding: "6px" }}
                        >
                          <img
                            style={{ width: "24px", cursor: "pointer" }}
                            src={DeleteIcon}
                            alt="delete-icon"
                            onClick={() => pickEditDelete(e)}
                          />
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                })}
            </table>
            <br />
          </div>
        </>
      ) : (
        <>
          <div style={{ display: "flex" }}>
            <div
              className="margin_right_27_qc"
              style={{ marginBottom: "12px" }}
            >
              <p>Pilih Bulan</p>
              <select
                className="input_select_shift"
                value={filterMonth}
                onChange={(e) => setFilterMonth(Number(e.target.value))}
              >
                <option disabled>Pilih Bulan</option>
                {filterMonthConstant.map((data) => (
                  <option value={data.value}>{data.label}</option>
                ))}
              </select>
            </div>
            <div
              className="margin_right_27_qc"
              style={{ marginBottom: "12px" }}
            >
              <p>Pilih Tahun</p>
              <select
                className="input_select_shift"
                value={filterYear}
                onChange={(e) => setFilterYear(Number(e.target.value))}
              >
                <option disabled>Pilih Bulan</option>
                {filterYearConstant.map((data) => (
                  <option value={data.value}>{data.label}</option>
                ))}
              </select>
            </div>
            <div
              className="margin_right_27_qc"
              style={{ marginBottom: "12px" }}
            >
              <p>Pilih Asal Barang</p>
              <select
                className="input_select_shift"
                value={filterBagian}
                onChange={(e) => setFilterBagian(e.target.value)}
              >
                <option disabled selected={!filterBagian}>
                  Pilih Asal Barang
                </option>
                {asalBarang.map((data) => (
                  <option value={data.value}>{data.label}</option>
                ))}
              </select>
            </div>
          </div>
          <p style={{ color: "red" }}>
            &#9888; Klik Pada Bagian Kuning Kartu Nota!
          </p>
          {popUpSuccess ? (
            <PopUpComponent>
              <p>Cek Riwayat Pengerjaan!</p>
              <div className="wrapper_button_submit_popup">
                <button
                  className="button_popup background_red"
                  onClick={() => setPopUpSuccess(false)}
                >
                  OK
                </button>
              </div>
            </PopUpComponent>
          ) : (
            ""
          )}
          {popUpPengerjaan ? (
            <PopUpComponent>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p>
                  <b>Nota:</b>
                  <br />
                  {formatDateNota(produkPopUp.tanggalNota)}
                </p>
                <img
                  className="width_edit_logo"
                  src={CloseLogo}
                  onClick={() => setPopUpPengerjaan(false)}
                  alt="edit_logo"
                />
              </div>
              <p>
                <b>{produkPopUp.produkPick}</b>
              </p>

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div style={{ margin: "0 24px 0 0" }}>
                  <p>Pengerjaan (PCS)</p>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    value={jumlahPengerjaan}
                    onChange={(e) => setJumlahPengerjaan(e.target.value)}
                    placeholder="Jumlah (Pcs)"
                    style={{
                      height: "36px",
                      width: "124px",
                      marginRight: "6px",
                    }}
                  />
                </div>
                <div>
                  <p>NG (PCS)</p>
                  <input
                    value={jumlahNG}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => setJumlahNG(e.target.value)}
                    placeholder="Jumlah NG (Pcs)"
                    style={{
                      height: "36px",
                      width: "124px",
                      marginRight: "6px",
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="wrapper_button_submit_popup">
                <button
                  className="button_popup background_red"
                  onClick={() => handleKirimPengerjaan()}
                >
                  Kirim
                </button>
              </div>
            </PopUpComponent>
          ) : (
            ""
          )}
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {aggregateSortiran &&
              aggregateSortiran.map((data) => {
                return (
                  <div
                    className="container-card-karantina"
                    style={
                      data.sisa > 0
                        ? { background: "khaki" }
                        : { background: "lightgreen" }
                    }
                    onClick={() =>
                      data.sisa > 0 &&
                      dataUser.role !== "super_user" &&
                      dataUser.role !== "ppic_admin"
                        ? handlePickNota(data.id)
                        : ""
                    }
                  >
                    {dataUser.role === "super_user" ||
                    dataUser.role === "ppic_admin" ? (
                      <div
                        style={{
                          margin: "6px 0 0 0",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <img
                          className="width_edit_logo"
                          style={{ background: "yellow" }}
                          src={EditLogo}
                          onClick={() => setIsEditNota(true)}
                          alt="edit_logo"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div>
                      <div style={{ display: "flex" }}>
                        <div style={{ margin: "0 32px 0 0" }}>
                          <p className="lineHeight-card-mobile">
                            <div className="flex-mobile-karantina">
                              <b>Tanggal Nota : &nbsp;</b>
                              {formatDateNota(data.tanggalNota)}
                            </div>
                          </p>
                          <p className="lineHeight-card-mobile">
                            <div className="flex-mobile-karantina">
                              <b>Nama Barang : &nbsp;</b>
                              {data.produkPick}
                            </div>
                          </p>
                          <p className="lineHeight-card-mobile">
                            <div className="flex-mobile-karantina">
                              <b>Warna : &nbsp;</b>
                              {data.warna}
                            </div>
                          </p>
                          <p className="lineHeight-card-mobile">
                            <div className="flex-mobile-karantina">
                              <b>Pelanggan : &nbsp;</b> PT. {data.pelanggan}
                            </div>
                          </p>
                          <p className="lineHeight-card-mobile">
                            <div className="flex-mobile-karantina">
                              <b>TGL. Produksi :&nbsp;</b>
                              {data.tanggalProduksi}
                            </div>
                          </p>
                          <p className="lineHeight-card-mobile">
                            <div
                              className="flex-mobile-karantina"
                              style={
                                data.sisa > 0
                                  ? { color: "red" }
                                  : { color: "black" }
                              }
                            >
                              <b>Sisa Sortiran : </b>
                              {formatDot(data.sisa)} Pcs
                            </div>
                          </p>
                        </div>
                        <div>
                          <p className="lineHeight-card-mobile">
                            <div className="flex-mobile-karantina">
                              <b>Shift/Mesin : &nbsp;</b>
                              {data.shift} / {data.mesin}
                            </div>
                          </p>
                          <p className="lineHeight-card-mobile">
                            <div className="flex-mobile-karantina">
                              <b>Operator :&nbsp;</b> {data.operator}
                            </div>
                          </p>
                          <p className="lineHeight-card-mobile">
                            <div className="flex-mobile-karantina">
                              <b>Isi/Kemasan : &nbsp;</b>
                              {formatDot(data.isiPerKemasan)} Pcs
                            </div>
                          </p>
                          {isEditNota ? (
                            <div className="flex-mobile-karantina">
                              <b>Jumlah Kemasan :&nbsp;</b>
                              <input
                                className="input_jumlah"
                                style={{ margin: "12px 0 0 0" }}
                                type="text"
                                placeholder="Isi Per Kemasan"
                                value={jumlahKemasanEdit}
                                onChange={(e) =>
                                  setJumlahKemasanEdit(e.target.value)
                                }
                              />
                            </div>
                          ) : (
                            <p className="lineHeight-card-mobile">
                              <div className="flex-mobile-karantina">
                                <b>Jumlah Kemasan :&nbsp;</b>
                                {formatDot(data.jumlahKemasan)}
                              </div>
                            </p>
                          )}
                          <p className="lineHeight-card-mobile">
                            {isEditNota ? (
                              <div className="flex-mobile-karantina">
                                <b>Jumlah Kemasan :&nbsp;</b>
                                <input
                                  className="input_jumlah"
                                  style={{ margin: "12px 0 0 0" }}
                                  type="number"
                                  placeholder="Jumlah Total"
                                  value={jumlahTotalEdit}
                                  onChange={(e) =>
                                    setJumlahTotalEdit(e.target.value)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                />
                              </div>
                            ) : (
                              <div className="flex-mobile-karantina">
                                <b>Jumlah Total : </b>
                                {formatDot(data.jumlahTotal)} Pcs
                              </div>
                            )}
                          </p>
                        </div>
                      </div>
                      <p
                        className="lineHeight-card-mobile"
                        style={{ margin: "0px" }}
                      >
                        <b> Keterangan :&nbsp;</b>
                      </p>
                      <p
                        className="flex-mobile-karantina"
                        style={{ margin: "12px 0 16px 0" }}
                      >
                        {data.keterangan}
                      </p>
                    </div>
                    {isEditNota ? (
                      <div
                        className="wrapper_button_submit_popup"
                        style={{ margin: "0 0 12px 0" }}
                      >
                        <button
                          className="button_popup background_red"
                          onClick={() => handleKirimEditNota(data)}
                        >
                          Kirim
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default Karantina;
