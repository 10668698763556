import { useState, useEffect } from "react";
import { getDateUtil, today } from "../../util/formatDate";
import { postAxios } from "../../util/apiCall";

export const useMold = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [companyPickList, setCompanyPickList] = useState("GS");
  const [dataListMold, setDataListMold] = useState();
  const [namaMold, setNamaMold] = useState("");
  const [editRunner, setEditRunner] = useState("");
  const [runner, setRunner] = useState("");
  const [color, setColor] = useState("");
  const [popUp, setPopUp] = useState(false);
  const [popUpDeleteMold, setPopUpDeleteMold] = useState(false);
  const [popUpEditMold, setPopUpEditMold] = useState(false);
  const [isApiRefresh, setIsApiRefresh] = useState(false);
  const [popUpTambahMold, setPopUpTambahMold] = useState(false);
  const [dataListDelete, setDataListDelete] = useState();
  const [newMold, setNewMold] = useState("");
  const [newMoldColor, setNewMoldColor] = useState("");
  const [companyNewMold, setCompanyNewMold] = useState("");
  const [newTargetProduk, setNewTargetProduk] = useState("");
  const [editNamaMold, setEditNamaMold] = useState("");
  const [editNamaBarangJadi, setEditNamaBarangJadi] = useState("");
  const [radioValue, setRadioValue] = useState("");
  const [editColorMold, setEditColorMold] = useState("");

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListMold`,
      { tim: dataUser.tim },
      dataUser.auth,
      setDataListMold,
      ""
    );
    setIsApiRefresh(false);
  }, [isApiRefresh]);

  const dataPick = () => {
    let data;
    dataListMold &&
      dataListMold.forEach((element) => {
        if (element.company === companyPickList) {
          data = element;
        }
      });
    return data;
  };

  const MoldList = dataPick();

  const handleCancelAddMold = () => {
    setPopUp(false);
    setNamaMold("");
    setColor("");
  };

  const handleDeleteMold = async () => {
    const merge = {
      company: companyPickList,
      type: "delete",
      tim: dataUser.tim,
      ...dataListDelete,
    };

    await postAxios(
      `${process.env.REACT_APP_ENDPOINT}/deleteMold`,
      merge,
      dataUser.auth,
      "",
      ""
    );
    setIsApiRefresh(true);
    setPopUpDeleteMold(false);
  };

  const handleEditMold = async () => {
    const merge = {
      company: companyPickList,
      date: getDateUtil(today),
      type: "edit",
      tim: dataUser.tim,
      runnerEdit: Number(editRunner),
      editMoldName: editNamaMold,
      editMoldColor: editColorMold,
      editNamaBarangJadi: editNamaBarangJadi,
      newTargetProduk: Number(newTargetProduk),
      radioValue: radioValue,
      ...dataListDelete,
    };

    await postAxios(
      `${process.env.REACT_APP_ENDPOINT}/deleteMold`,
      merge,
      dataUser.auth,
      "",
      ""
    );
    setEditRunner("");
    setIsApiRefresh(true);
    setPopUpEditMold(false);
  };

  const handleKirimNewMold = () => {
    if (newMoldColor !== "" && newMold !== "" && companyNewMold !== "") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/addNewMold`,
        {
          tim: dataUser.tim,
          name: newMold,
          color: newMoldColor,
          company: companyNewMold,
        },
        dataUser.auth,
        setDataListMold,
        ""
      );
      setIsApiRefresh(false);
      setPopUpTambahMold(false);
      setNewMoldColor("");
      setNewMold("");
      setCompanyNewMold("");
      setIsApiRefresh(true);
    }
  };

  return {
    companyPickList,
    setCompanyPickList,
    MoldList,
    setDataListDelete,
    popUpDeleteMold,
    setPopUpDeleteMold,
    handleDeleteMold,
    handleEditMold,
    popUpEditMold,
    setPopUpEditMold,
    editRunner,
    setEditRunner,
    popUpTambahMold,
    setPopUpTambahMold,
    dataListMold,
    handleKirimNewMold,
    newMold,
    setNewMold,
    newMoldColor,
    setNewMoldColor,
    companyNewMold,
    setCompanyNewMold,
    setNewTargetProduk,
    newTargetProduk,
    editNamaMold,
    setEditNamaMold,
    editNamaBarangJadi,
    setEditNamaBarangJadi,
    radioValue,
    setRadioValue,
    editColorMold,
    setEditColorMold,
  };
};
