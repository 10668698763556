import React from "react";
import "./TableHistory.css";

const TableHistory = ({ data }) => {
  const formatDot = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };
  return (
    <div className="wrapper_table_history">
      <div className="container_header_history">
        <div className="table_header_history">Company</div>
        <div className="table_header_history">Material</div>
        <div className="table_header_history">Type</div>
        <div className="table_header_history">Color</div>
        <div className="table_header_history">Total</div>
        <div className="table_header_history none">Requestor</div>
        <div className="table_header_history none">Time</div>
        <div className="table_header_history none">Approver</div>
      </div>
      {data &&
        data.map((obj, i) => {
          return (
            <div
              className={
                obj.status === "approved"
                  ? "container_data_history color_history_approved"
                  : "container_data_history color_history_reject"
              }
              key={i}
            >
              <div className="font_data_history">PT. {obj.company}</div>
              <div className="font_data_history">{obj.namaBahan}</div>
              <div className="font_data_history">{obj.tipeBahan}</div>
              <div className="font_data_history">{obj.color}</div>
              <div className="font_data_history">
                {formatDot(obj.jumlah)}{" "}
                {obj.jenisBahan === "BAKU" ? "Kg" : "Pcs"}
              </div>
              <div className="font_data_history none">{obj.requestor}</div>
              <div className="font_data_history none">
                {obj.date} {obj.time}
              </div>
              <div className="font_data_history none">{obj.approver}</div>
            </div>
          );
        })}
    </div>
  );
};
export default TableHistory;
