import React from "react";
import "./PopUpComponent.css";

const PopUpComponent = ({ children, style, small }) => {
  return (
    <div className="container_popup">
      <div
        className={small ? "wrapper_popup width_popup" : "wrapper_popup"}
        style={style}
      >
        {children}
      </div>
    </div>
  );
};
export default PopUpComponent;
