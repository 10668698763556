import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import { monthDefaultFormat, today } from "../../util/formatDate";

export const usePO = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const defaultDay = new Date(today);
  const defaultMonth = defaultDay.getMonth();
  const [subMenu, setSubMenu] = useState("list");
  const [listPO, setListPO] = useState();
  const [dataListProduk, setDataListProduk] = useState();
  const [isApiRefresh, setIsApiRefresh] = useState(false);
  const [customer, setCustomer] = useState("");
  const [dataPickProduk, setDataPickProduk] = useState("");
  const [newColor, setNewColor] = useState("COLOR");
  const [datePO, setDatePO] = useState(today);
  const [jumlahPO, setJumlahPO] = useState("");
  const [isError, setIsError] = useState(false);
  const [isPopUp, setIsPopUp] = useState(false);
  const [filterBulan, setFilterBulan] = useState(
    monthDefaultFormat[defaultMonth]
  );

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListMold`,
      { tim: dataUser.tim },
      dataUser.auth,
      setDataListProduk,
      ""
    );
  }, []);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getDataPO`,
      { tim: dataUser.tim, month: filterBulan },
      dataUser.auth,
      setListPO,
      ""
    );
    setIsApiRefresh(false);
  }, [isApiRefresh, filterBulan, subMenu]);

  const pickData =
    dataListProduk &&
    dataListProduk.find((x) => (x.company === customer ? x : ""));

  const handlePickProduct = (e) => {
    const dataColor = pickData && pickData.data[e.target.value];
    setDataPickProduk(dataColor.name);
    if (e.target.value !== "") {
      setNewColor(dataColor.color);
    }
  };

  const handleKirim = () => {
    const getDateID = new Date(datePO);
    const date = getDateID.getDate();
    const month = getDateID.getMonth();
    if (
      customer !== "" &&
      jumlahPO !== "" &&
      newColor !== "" &&
      dataPickProduk !== ""
    ) {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/addNewPO`,
        {
          tim: dataUser.tim,
          customer: customer,
          namaBarang: dataPickProduk,
          color: newColor,
          tanggalPO: datePO,
          jumlahPO: Number(jumlahPO),
          month: monthDefaultFormat[month],
          id: date,
        },
        dataUser.auth,
        "",
        ""
      );
      setIsPopUp(true);
      setJumlahPO("");
      setDatePO(today);
      setNewColor("COLOR");
      setDataPickProduk("");
      setCustomer("");
      setIsError(false);
      setIsApiRefresh(true);
    } else {
      setIsError(true);
    }
  };

  return {
    dataUser,
    subMenu,
    setSubMenu,
    dataListProduk,
    setCustomer,
    pickData,
    handlePickProduct,
    newColor,
    handleKirim,
    setJumlahPO,
    jumlahPO,
    setDatePO,
    dataPickProduk,
    datePO,
    customer,
    isError,
    isPopUp,
    setIsPopUp,
    listPO,
    monthDefaultFormat,
    setFilterBulan,
    filterBulan,
  };
};
