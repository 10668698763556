import React from "react";
import PopUpComponent from "../PopUp/PopUpComponent";
import EditLogo from "../../Assets/editLogo.png";
import "./TableListProduk.css";

const TableListProduk = ({
  data,
  onClick,
  onClickEdit,
  popUpDeleteProduk,
  setPopUpDeleteProduk,
  popUpEditProduk,
  setPopUpEditProduk,
  setDataPickDelete,
  editRunner,
  setEditRunner,
  bahan,
}) => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const handleDeleteBahan = (button, data) => {
    setDataPickDelete(data);
    if (button === "Cancel") {
      setPopUpDeleteProduk(false);
    }
  };
  const handleEditBahan = (button, data) => {
    setDataPickDelete(data);
    if (button === "Cancel") {
      setPopUpEditProduk(false);
    }
  };

  return (
    <div className="wrapper_table">
      {popUpDeleteProduk ? (
        <PopUpComponent>
          <p>
            Apakah Kamu yakin untuk <b>MENGHAPUS</b> List Bahan ini?
          </p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup"
              onClick={() => handleDeleteBahan("Cancel")}
            >
              Cancel
            </button>
            <button className="button_popup background_red" onClick={onClick}>
              Kirim
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {popUpEditProduk ? (
        <PopUpComponent>
          <div className="wrapper_input">
            <p className="font_weight_bold">Runner (gr)</p>
            <div className="wrapper_radio">
              <input
                className="width_input_produksi font_12_mobile"
                type="number"
                placeholder="Runner"
                name="runner"
                onChange={(e) => setEditRunner(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </div>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup"
              onClick={() => handleEditBahan("Cancel")}
            >
              Cancel
            </button>
            <button
              className="button_popup background_red"
              onClick={onClickEdit}
            >
              Kirim
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <div className="container_header_list_bahan">
        <div className="table_header_list_produk width_30_list_produk">
          Produk
        </div>
        <div className="table_header_list_produk">Color</div>
        <div className="table_header_list_produk width_30_list_produk">
          Material
        </div>
        <div className="table_header_list_produk width_delete_button">
          Total
        </div>
        <div className="table_header_list_produk width_delete_button">
          {dataUser.role === "super_user" || dataUser.role === "ppic_admin"
            ? "Delete"
            : ""}
        </div>
      </div>
      {data &&
        data.map((obj, i) => {
          return (
            <div
              className={
                i % 2 === 0
                  ? "container_data_list_bahan"
                  : "container_data_list_bahan background_odd_list_bahan"
              }
              key={i}
            >
              <div className="table_header_list_produk font_data_produk width_30_list_produk">
                {obj.mold}
              </div>
              <div className="table_header_list_produk font_data_produk">
                {obj.color}
              </div>
              <div className="table_header_list_produk font_data_produk width_30_list_produk">
                {Array.isArray(obj.material)
                  ? obj.material.map((el) => {
                      return (
                        <>
                          {el.material}
                          <br />
                          <br />
                        </>
                      );
                    })
                  : `${obj.material} ${obj.typeMaterial}`}
              </div>
              <div className="table_header_list_produk font_data_produk width_delete_button">
                {Array.isArray(obj.material) ? (
                  obj.material.map((el) => {
                    return (
                      <>
                        {el.total} Pcs
                        <br />
                        <br />
                      </>
                    );
                  })
                ) : (
                  <>{obj.total} &nbsp; Kg</>
                )}
              </div>
              <div
                className="table_header_list_produk width_delete_button"
                onClick={() => handleDeleteBahan("", data[i])}
              >
                {dataUser.role === "super_user" ||
                dataUser.role === "ppic_admin" ? (
                  <button
                    className="button_delete_list_bahan"
                    onClick={() => setPopUpDeleteProduk(true)}
                  >
                    Delete
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default TableListProduk;
