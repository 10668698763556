import React, { useEffect, useState } from "react";
import MenuComponent from "../components/Menu/MenuComponent";
import Inventory from "./Inventory/Inventory";
import RequestStockIn from "./Request/RequestStockIn";
import History from "./History/History";
import ListBahan from "./ListBahan/ListBahan";
import MemoPPIC from "./MemoPPIC/MemoPPIC";
import ApprovalRequestIn from "./ApprovalRequestIn/ApprovalRequestIn";
import QualityControl from "./QC/QualityControl";
import P3 from "./P3/P3";
import Produksi from "./Produksi/Produksi";
import ListProduk from "./Produk/ListProduk";
import Dashboard from "./Dashboard/Dashboard";
import Operator from "./Operator/Operator";
import Mold from "./Mold/Mold";
import ApprovalLaporan from "./ApprovalLaporan/ApprovalLaporan";
import PatrolQc from "./PatrolQc/PatrolQc.mjs";
import Security from "./Security/Security";
import PO from "./PO/PO";
import SecondProcess from "./SecondProcess/SecondProcess";
import { useHistory } from "react-router-dom";
import ApprovalBarangJadi from "./ApprovalBarangJadi/ApprovalBarangJadi";
import ListProdukSP from "./listProdukSP/listProdukSP";
import ApprovalSP from "./ApprovalSP/ApprovalSP";
import CheckSheet from "./CheckSheet/CheckSheet";
import LaporanBahanBaku from "./LaporanBahanBaku/LaporanBahanBaku";
import Karantina from "./Karantina/Karantina";

const IndexPage = () => {
  let history = useHistory();
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);
  const [menu, setMenu] = useState("Dashboard");

  useEffect(() => {
    if (dataUser === null) {
      history.push("/login");
    } else if (dataUser.role === "p3_user") {
      setMenu("P3");
    } else if (dataUser.role === "warehouse_user") {
      setMenu("Request");
    } else if (dataUser.role === "warehouse_admin") {
      setMenu("Approval");
    } else if (dataUser.role === "qc_user") {
      setMenu("QC");
    } else if (dataUser.role === "produksi_user") {
      setMenu("Operator");
    } else if (dataUser.role === "security") {
      setMenu("Security");
    }
  }, []);

  return (
    <div>
      {dataUser !== null ? <MenuComponent setMenu={setMenu} menu={menu} /> : ""}
      {menu === "Dashboard" ? (
        <Dashboard />
      ) : menu === "Inventory" ? (
        <Inventory />
      ) : menu === "Request" ? (
        <RequestStockIn />
      ) : menu === "Approval" ? (
        <ApprovalRequestIn />
      ) : menu === "History" ? (
        <History />
      ) : menu === "ListBahan" ? (
        <ListBahan />
      ) : menu === "MemoPPIC" ? (
        <MemoPPIC />
      ) : menu === "QC" ? (
        <QualityControl />
      ) : menu === "P3" ? (
        <P3 />
      ) : menu === "Produksi" ? (
        <Produksi />
      ) : menu === "Produk" ? (
        <ListProduk />
      ) : menu === "Operator" ? (
        <Operator />
      ) : menu === "ApprovalLaporan" ? (
        <ApprovalLaporan />
      ) : menu === "Mold" ? (
        <Mold />
      ) : menu === "Patrol" ? (
        <PatrolQc />
      ) : menu === "PO" ? (
        <PO />
      ) : menu === "ApprovalBarangJadi" ? (
        <ApprovalBarangJadi />
      ) : menu === "SP" ? (
        <SecondProcess />
      ) : menu === "listProdukSP" ? (
        <ListProdukSP />
      ) : menu === "ApprovalLaporanSP" ? (
        <ApprovalSP />
      ) : menu === "checkSheet" ? (
        <CheckSheet />
      ) : menu === "BahanBaku" ? (
        <LaporanBahanBaku />
      ) : menu === "Security" ? (
        <Security />
      ) : menu === "Karantina" ? (
        <Karantina />
      ) : (
        ""
      )}
    </div>
  );
};
export default IndexPage;
