import React, { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import "./DashboardPPIC.css";

const DashboardPPIC = ({ data }) => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [isOn, setIsOn] = useState(false);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/emergency`,
      { tim: dataUser.tim, dataType: "emergency", type: "get" },
      dataUser.auth,
      setIsOn,
      ""
    );
  }, []);

  return (
    <div className="flex_wrap">
      {data &&
        data.map((obj) => {
          const downtime = obj.downtime ? obj.downtime : 0;
          const dataCounterAwal = obj.counterAwal ? obj.counterAwal : 0;
          const dataCounterAkhir = obj.counterAkhir ? obj.counterAkhir : 0;
          const dataTargetProduk = obj.targetProduk ? obj.targetProduk : 0;
          const dataAktualCavity = obj.aktualCavity ? obj.aktualCavity : 0;

          const formulaTargetProduksi =
            dataTargetProduk * dataAktualCavity * ((480 - downtime) / 480);

          const formula =
            (8 * 3600 - downtime * 60) / (dataCounterAkhir - dataCounterAwal);
          return (
            <div className="wrapper_card_approval_laporan">
              <div className="section_approval_laporan">
                <p className="title_machine">Mesin&nbsp;{obj.mesin}</p>
                <p className="font_bold">{obj.customer}</p>
              </div>
              <div className="flex_justify_between">
                <p>{obj.operator}</p>
              </div>
              <div className="flex_justify_between">
                <p className="font_bold">{obj.moldType}</p>
              </div>
              <div className="flex_justify_between">
                <p className="font_bold">{obj.color}</p>
              </div>
              <div className="flex_justify_between">
                <p>{obj.date}</p>
                <p style={{ textAlign: "end" }}>Shift&nbsp;{obj.shift}</p>
              </div>
              {!isOn ? (
                <div className="flex_justify_between">
                  <div>
                    <p>Target Produksi</p>
                    <p
                      style={
                        formulaTargetProduksi > obj.jumlahBarang + obj.jumlahNG
                          ? { color: "red" }
                          : {}
                      }
                    >
                      {formulaTargetProduksi.toFixed(0)}
                    </p>
                  </div>
                  <div>
                    <p>Target Cycle Time</p>
                    <p
                      style={
                        28800 / dataTargetProduk < formula &&
                        28800 / dataTargetProduk !== Infinity
                          ? { color: "red" }
                          : {}
                      }
                    >
                      {28800 / dataTargetProduk === Infinity
                        ? 0
                        : (28800 / dataTargetProduk).toFixed(2)}
                      &nbsp;Detik
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="flex_justify_between">
                <div style={{ width: "50%" }}>
                  <p className="font_bold">Isi Per Kemasan</p>
                  <p className="font_14">{obj.isiPerKemasan}&nbsp;Pcs</p>
                </div>
                <div style={{ width: "40%" }}>
                  <p className="font_bold">Aktual Cavity</p>
                  <p className="font_14">{obj.aktualCavity}</p>
                </div>
              </div>

              <div className="flex_justify_between">
                <div style={{ width: "50%" }}>
                  <p className="font_bold">Counter Akhir</p>
                  <p className="font_14">{obj.counterAkhir}</p>
                </div>
                <div style={{ width: "40%" }}>
                  <p className="font_bold">Counter Awal</p>
                  <p className="font_14">{obj.counterAwal}</p>
                </div>
              </div>

              <div className="flex_justify_between">
                <div style={{ width: "50%" }}>
                  <p className="font_bold">Selisih Counter</p>
                  <p className="font_14">
                    {obj.counterAkhir - obj.counterAwal}
                  </p>
                </div>
                <div style={{ width: "40%" }}>
                  <p className="font_bold">Sisa Shift Sebelumnya</p>
                  <p className="font_14">
                    {obj.sisaShiftSebelumnya ? obj.sisaShiftSebelumnya : 0}
                    &nbsp;Pcs
                  </p>
                </div>
              </div>
              <div className="flex_justify_between">
                <div style={{ width: "50%" }}>
                  <p className="font_bold">Jumlah "NG"</p>
                  <p className="font_14">{obj.jumlahNG}&nbsp;Pcs</p>
                </div>
                <div style={{ width: "40%" }}>
                  <p className="font_bold">Barang OK</p>
                  <p className="font_14">{obj.jumlahBarang}&nbsp;Pcs</p>
                </div>
              </div>
              {!isOn ? (
                <div className="flex_justify_between">
                  <div style={{ width: "50%" }}>
                    <p className="font_bold">Down Time</p>
                    <p className="font_14">{downtime}&nbsp;Menit</p>
                  </div>
                  <div style={{ width: "40%" }}>
                    <p className="font_bold">Cycle Time</p>
                    <p className="font_14">
                      {formula === Infinity ? 0 : formula.toFixed(1)}&nbsp;Detik
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div>
                <div className="flex_justify_between">
                  <p style={{ width: "50%", fontWeight: "bold" }}>
                    Perincian "NG"
                  </p>
                  <p style={{ width: "40%", fontWeight: "bold" }}>Jumlah</p>
                </div>
                {obj.perincianNG &&
                  obj.perincianNG.map((perincian) => {
                    return (
                      <div className="flex_justify_between">
                        <p style={{ fontSize: "14px", width: "50%" }}>
                          {perincian.id}
                        </p>
                        <p style={{ fontSize: "14px", width: "40%" }}>
                          {perincian.totalNG}&nbsp;Pcs
                        </p>
                      </div>
                    );
                  })}
                <div>
                  <p className="font_bold">Keterangan</p>
                  <p style={{ wordWrap: "break-word" }}>{obj.keterangan}</p>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default DashboardPPIC;
