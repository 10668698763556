import React from "react";
import PopUpComponent from "../PopUp/PopUpComponent";
import "./TableListBahan.css";

const TableListBahan = ({
  data,
  onClick,
  popUpDeleteBahan,
  setPopUpDeleteBahan,
  setDataPickDelete,
}) => {
  const handleDeleteBahan = (button, data) => {
    setDataPickDelete(data);
    if (button === "Cancel") {
      setPopUpDeleteBahan(false);
    }
  };
  return (
    <div className="wrapper_table">
      {popUpDeleteBahan ? (
        <PopUpComponent>
          <p>
            Apakah Kamu yakin untuk <b>MENGHAPUS</b> List Bahan ini?
          </p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup"
              onClick={() => handleDeleteBahan("Cancel")}
            >
              Cancel
            </button>
            <button className="button_popup background_red" onClick={onClick}>
              Kirim
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <div className="container_header_list_bahan">
        <div className="table_header_list_bahan">Material</div>
        <div className="table_header_list_bahan">Type</div>
        <div className="table_header_list_bahan">Color</div>
        <div className="table_header_list_bahan">Action</div>
      </div>
      {data &&
        data.map((obj, i) => {
          return (
            <div
              className={
                i % 2 === 0
                  ? "container_data_list_bahan"
                  : "container_data_list_bahan background_odd_list_bahan"
              }
              key={i}
            >
              <div className="font_data_list_bahan">{obj.material}</div>
              <div className="font_data_list_bahan">{obj.type}</div>
              <div className="font_data_list_bahan">{obj.color}</div>
              <div
                className="font_data_list_bahan"
                onClick={() => handleDeleteBahan("", data[i])}
              >
                <button
                  className="button_delete_list_bahan"
                  onClick={() => setPopUpDeleteBahan(true)}
                >
                  Delete
                </button>
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default TableListBahan;
