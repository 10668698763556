import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import { today, noFormat } from "../../util/formatDate";
import { validateRole } from "../../util/utilFunction";

export const useApprovalSP = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [dateApprovalLaporanSP, setDateApprovalLaporanSP] = useState(today);
  const [listLaporanSP, setListLaporanSP] = useState();
  const [isRefresh, setIsRefresh] = useState(false);
  const [popUpKirim, setPopUpKirim] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/secondProcess`,
      {
        tim: dataUser.tim,
        date: dateApprovalLaporanSP,
        bagian: validateRole(),
        type: "getLaporanAdmin",
      },
      dataUser.auth,
      setListLaporanSP,
      ""
    );
    setIsRefresh(false);
  }, [dateApprovalLaporanSP, isRefresh]);

  const handleFormChange = (index, event) => {
    let data = [...listLaporanSP];

    data[index][event.target.name] = event.target.value;

    setListLaporanSP(data);
  };

  console.log(listLaporanSP);

  const handleApproveLaporanSP = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/secondProcess`,
      {
        tim: dataUser.tim,
        date: dateApprovalLaporanSP,
        bagian: validateRole(),
        type: "approveLaporan",
        data: listLaporanSP,
        approvedBy: dataUser.name,
        dateApprove: noFormat,
        isEdit: isEdit,
      },
      dataUser.auth,
      setListLaporanSP,
      ""
    );
    setPopUpKirim(true);
    setIsRefresh(true);
    setIsEdit(false);
  };

  return {
    dateApprovalLaporanSP,
    setDateApprovalLaporanSP,
    listLaporanSP,
    handleApproveLaporanSP,
    popUpKirim,
    setPopUpKirim,
    isEdit,
    setIsEdit,
    handleFormChange,
  };
};
